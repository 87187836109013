import axios from '../middlewares/axios';
import { formatTextToFloat } from '../utils/Utils';

const defaultRoute = '/meeting';

class MeetingService {
  static getMeetings() {
    return axios.get(defaultRoute)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static createNewMeeting(meetingNumber, form) {
    const json = {
      ...form,
      number: formatTextToFloat(meetingNumber),
    };
    return axios.post(defaultRoute, json)
      .catch((error) => {
        throw error;
      });
  }

  static getAllNutritionMeetingsByClient(id) {
    const url = `/meeting/nutrition?clientId=${id}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewNutritionMeeting(newMeeting) {
    const url = '/meeting/nutrition';
    return axios.post(url, newMeeting)
      .catch((error) => {
        throw error;
      });
  }

  static putNutritionMeeting(updatedMeeting) {
    const url = '/meeting/nutrition';
    return axios.put(url, { id: updatedMeeting._id, updatedMeeting })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getPhysicalEducationMeetings(clientId) {
    return axios.get(`${defaultRoute}/physicalEducation?clientId=${clientId}`)
      .catch((error) => {
        throw error;
      });
  }

  static createNewPhysicalEducationMeeting(form) {
    const json = {
      ...form,
    };
    return axios.post(`${defaultRoute}/physicalEducation`, json)
      .catch((error) => {
        throw error;
      });
  }

  static getAllNursingMeetingsByClient(id) {
    const url = `/meeting/nursing?clientId=${id}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewNursingMeeting(newMeeting) {
    const url = '/meeting/nursing';
    return axios.post(url, newMeeting)
      .catch((error) => {
        throw error;
      });
  }

  static putNursingMeeting(updatedMeeting) {
    const url = '/meeting/nursing';
    return axios.put(url, { id: updatedMeeting._id, updatedMeeting })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewTrainingMeeting(clientId) {
    const url = '/meeting/training';
    return axios.post(url, {
      clientId,
    })
      .catch((error) => {
        throw error;
      });
  }

  static getTrainingMeetings(events) {
    const url = `/meeting/training?events=${events.join(',')}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getTrainingMeetingsId(ids) {
    const url = '/meeting/training/TrainingMeetingList';
    const params = { ids };

    return axios.get(url, { params })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static putTrainingMeeting(id, updatedTrainingMeeting) {
    const url = '/meeting/training';
    return axios.put(url, { id, updatedTrainingMeeting })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default MeetingService;
