/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import icClose from '../../assets/svgs/ic-close.svg';

import './HauxPdfModal.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const renderWidth = () => {
  let { innerWidth } = window;

  if (innerWidth > 1024) {
    innerWidth -= 300;
  } else {
    innerWidth -= 100;
  }

  return innerWidth;
};


const HauxPdfModal = ({
  open, handleClose, link,
}) => {
  const [pages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <Modal
        className="modal-pdf"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="paper-modal">
            <div className="icon-container">
              <Button onClick={handleClose}>
                <img src={icClose} alt="" />
              </Button>
            </div>
            <div className="haux__container__document">
              <Document
                file={{ url: link }}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {
                Array.from(
                  new Array(pages),
                  (el, index) => (
                    <Page
                      width={renderWidth()}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                    />
                  ),
                )
              }
              </Document>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

HauxPdfModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  link: PropTypes.string,
};

HauxPdfModal.defaultProps = {
  open: false,
  handleClose: () => {},
  link: '',
};

export default HauxPdfModal;
