import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Guides from '../components/Guides';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';
import TrainingMeetingFollowUp from '../components/trainingMeetingFollowUp';
import MeetingService from '../../../service/MeetingService';
import HauxSnackbar from '../../../components/HauxSnackbar';

const initialSnackbar = {
  type: null,
  message: null,
  show: false,
};

const FollowUp = () => {
  const location = useLocation();
  const [followUpFiles, setFollowUpFiles] = useState([]);
  const [snackbar, setSnackbar] = useState(initialSnackbar);

  useEffect(() => {
    if (location.state && location.state.participants) {
      const ids = location.state.participants.map((item) => item._id);
      MeetingService.getTrainingMeetingsId(ids).then(
        (res) => {
          setFollowUpFiles(res);
        },
      );
    }
  }, [location.state]);

  const handleSave = async (id, meeting) => {
    try {
      const data = await MeetingService.putTrainingMeeting(id, meeting);

      if (data) {
        setSnackbar({
          type: "success",
          message: `Ficha de acompanhamento do(a) cliente(a) ${data.client.name} atualizada!`,
          show: true, 
        });

        followUpFiles((trainingFile) => {
          const newTrainingFiles = trainingFile.filter((file) => file._id !== data._id);
          newTrainingFiles.push(data);
          return newTrainingFiles;
        });
      }

    } catch (error) { }
  }

  return (
    <div className="trainingContainer">
      <HauxSnackbar
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(ps => ({ ...ps, show: false }))}
        openSnackbar={snackbar.show}
      />
      <Guides />
      <HauxExpansionPanel items={
        followUpFiles
          .map((item) => ({
            name: item.client.name,
            renderDetails: (
              <TrainingMeetingFollowUp
                key={item._id}
                client={item}
                handleSave={handleSave}
              />
            ),
          }))
      }
      />
    </div>
  );
};

export default FollowUp;
