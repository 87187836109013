import axios from "../middlewares/axios";

class FinanceService {
  static getAllFinanceTypes() {
    const url = "/financeType";
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getFinances(franchise, startDate, endDate, pStatus, search) {
    const url = "/finance";

    const entries = Object.entries(pStatus);
    const allUnchecked = entries.every(([, value]) => value);

    let status = [];

    if (allUnchecked) {
      status = "none";
    } else {
      entries.forEach(([key, value]) => {
        if (value) status.push(key);
      });
    }

    const params = {
      franchise,
      startDate,
      endDate,
      status,
      search,
    };

    return axios
      .get(url, { params })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postFinance(finance) {
    const url = "/finance";
    return axios
      .post(url, finance)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postFinanceType(financeType) {
    const url = "/financeType";
    return axios.post(url, financeType).catch((error) => {
      throw error;
    });
  }

  static updateStatus(id, status) {
    const url = `/financeType/updateStatus?id=${id}&active=${status}`;
    return axios.post(url).catch((error) => {
      throw error;
    });
  }

  static getMotherTypes() {
    const url = "/financeType/mother";
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static updtateFinance(id, update) {
    const url = "/finance";
    return axios
      .put(url, { id, update })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static cancelFinance(id) {
    const url = `/finance/${id}`;

    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default FinanceService;
