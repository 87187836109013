import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ReactComponent as IcClose } from "../../../../../assets/svgs/ic-close.svg";
import HauxInput from "../../../../../components/HauxInput";
import HauxSwitch from "../../../../../components/HauxSwitch";
import HAUXButton from "../../../../../components/HauxButton";
import FinanceService from "../../../../../service/FinanceService";
import AnalyticsManager from "../../../../../AnalyticsManager";

const PAYMENT_TYPE = [
  "Elo - Débito",
  "Elo - Crédito",
  "Visa - Débito",
  "Visa - Crédito",
  "Master - Débito",
  "Master - Crédito",
  "Banricompras",
  "Dinheiro",
  "Cheque",
  "Cheque pré-datado",
  "Transferência bancária",
  "Boleto bancário",
];

const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 40px",
    minWidth: 800,
  },
  formRow: {
    marginBottom: 32,
  },
  cancelButtonContainer: {
    marginLeft: -10,
  },
  confirmButtonContainer: {
    marginRight: -10,
  },
  popOver: {
    padding: 24,
  },
  popOverText: {
    padding: "16px 0px",
  },
}));

export default function ConfirmForm({
  selectedPayment,
  onClose,
  onFinanceUpdate,
  handleRequestsErrors,
}) {
  const [paymentType, setPaymentType] = useState("");
  const [parcelValue, setParcelValue] = useState(0);
  const [installmentDate, setInstallmentDate] = useState(moment());
  const [paymentDate, setPaymentDate] = useState(moment());
  const [interest, setInterest] = useState(0);
  const [paidValue, setPaidValue] = useState(0);
  const [observations, setObservations] = useState("");
  const [disableEdit, setDisableEdit] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [valueError, setValueError] = useState({ error: false, message: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (selectedPayment && Object.keys(selectedPayment).length) {
      setPaymentDate(moment(selectedPayment.paymentDate));
      setPaymentType(selectedPayment.paymentType);
      setInstallmentDate(moment(selectedPayment.installmentDate));
      setParcelValue(selectedPayment.installmentValue);
      if (selectedPayment.interest) {
        setInterest(selectedPayment.interest);
      } else {
        setInterest(0);
      }
      if (selectedPayment.observations) {
        setObservations(selectedPayment.observations);
      } else {
        setObservations("");
      }
      if (selectedPayment.paidValue) {
        setPaidValue(selectedPayment.paidValue);
      } else {
        setPaidValue(0);
      }
      setDisableEdit(!!(selectedPayment.isPaid || selectedPayment.isCancelled));
      setIsPaid(!!selectedPayment.isPaid);
    }
  }, [selectedPayment]);

  async function confirm() {
    AnalyticsManager.track("Clinic_finances button_save_release clicked");
    try {
      const update = {
        ...selectedPayment,
        paymentDate: paymentDate.valueOf(),
        interest,
        paidValue,
        observations,
        isPaid,
      };

      const updated = await FinanceService.updtateFinance(
        selectedPayment._id,
        update
      );
      onFinanceUpdate(updated);
      onClose();
    } catch (error) {
      handleRequestsErrors(error);
    }
  }

  function renderPopover() {
    return (
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <IcClose
          style={{
            position: "absolute",
            top: 32,
            right: 24,
            cursor: "pointer",
          }}
          onClick={() => setAnchorEl(null)}
        />
        <Grid container direction="column" className={classes.popOver}>
          <Grid item className={classes.popOverText}>
            <span className="paragraph">
              Confirma o cancelamento deste lançamento?
            </span>
          </Grid>
          <Grid item container justify="space-around">
            <Grid item>
              <HAUXButton
                text="Não"
                className="destructive"
                onClick={() => {
                  AnalyticsManager.track(
                    "Clinic_finances button_not_cancel_launch clicked"
                  );
                  setAnchorEl(null);
                }}
              />
            </Grid>
            <Grid item>
              <HAUXButton
                text="Sim, cancelar lançamento"
                className="destructive"
                type="secondary"
                onClick={async () => {
                  AnalyticsManager.track(
                    "Clinic_finances button_cancel_launch clicked"
                  );
                  try {
                    const deleted = await FinanceService.cancelFinance(
                      selectedPayment._id
                    );
                    setAnchorEl(null);
                    onFinanceUpdate(deleted);
                    onClose();
                  } catch (error) {
                    handleRequestsErrors(error);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container direction="column" className={classes.root}>
        <Grid item className={classes.formRow}>
          <span className="h6NormalStyle">{`${selectedPayment.type.identifier} - ${selectedPayment.description}`}</span>
        </Grid>
        <Grid
          item
          container
          justify="space-between"
          className={classes.formRow}
        >
          <Grid item xs={5}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              label="Venc. da parcela"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              fullWidth
              value={moment(installmentDate)}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <HauxInput
              label="Forma de pagamento"
              fullWidth
              autocomplete
              disabled
              autocompleteProps={{
                options: PAYMENT_TYPE,
                getOptionLabel: (option) => option,
              }}
              value={paymentType}
            />
          </Grid>
          <Grid item xs={2}>
            <HauxInput
              label="Valor"
              fullWidth
              disabled
              value={Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(parcelValue || 0)}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.formRow}>
          <HauxSwitch onChange={() => setIsPaid(!isPaid)} checked={isPaid} />
          <span className="h6NormalStyle">Pagamento realizado</span>
        </Grid>
        <Grid
          item
          container
          className={classes.formRow}
          justify="space-between"
        >
          <Grid item xs={4}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              label="Data do pagamento"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              fullWidth
              disabled={disableEdit}
              value={moment(paymentDate)}
              onChange={(d) => setPaymentDate(moment(d))}
            />
          </Grid>
          <Grid item xs={3}>
            <HauxInput
              label="Juros/Multa"
              fullWidth
              disabled={disableEdit}
              value={Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(interest || 0)}
              onChange={(e) => {
                setInterest(
                  parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0
                );
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <HauxInput
              label="Valor pago"
              fullWidth
              disabled={disableEdit}
              value={Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(paidValue || 0)}
              onChange={(e) => {
                setPaidValue(
                  parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0
                );
              }}
              error={valueError.error}
              errorMsg={valueError.message}
              onFocus={() => {
                if (valueError.error) {
                  setValueError({ error: false, message: "" });
                }
              }}
              onBlur={() => {
                if (!paidValue) {
                  setValueError({ error: true, message: "Campo obrigatório" });
                }
              }}
            />
          </Grid>
        </Grid>
        {selectedPayment.nf && selectedPayment.nf !== "" && (
          <Grid item className={classes.formRow}>
            <span className="h6NormalStyle">
              {`Número NF: ${selectedPayment.nf}`}
            </span>
          </Grid>
        )}
        {selectedPayment.comments !== "" && (
          <Grid item className={classes.formRow}>
            <span className="h6NormalStyle">
              {`Observações de ${
                selectedPayment.type.type === "exit" ? "saída" : "entrada"
              }: ${selectedPayment.comments}`}
            </span>
          </Grid>
        )}
        <Grid item container className={classes.formRow}>
          <TextField
            variant="outlined"
            multiline
            placeholder="Observações (opcional)"
            rows={3}
            fullWidth
            disabled={disableEdit}
            value={observations}
            onChange={(e) => {
              setObservations(e.target.value);
            }}
          />
        </Grid>
        <Grid item container justify="space-between">
          <Grid item xs={1} className={classes.cancelButtonContainer}>
            <HAUXButton
              text="Fechar"
              type="secondary"
              onClick={() => {
                AnalyticsManager.track("Clinic_finances button_close clicked");
                onClose();
              }}
            />
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            xs={9}
            className={classes.confirmButtonContainer}
          >
            {renderPopover()}
            <Grid item>
              <HAUXButton
                text="Cancelar lançamento"
                className="destructive"
                type="secondary"
                disabled={disableEdit}
                onClick={(event) => {
                  AnalyticsManager.track(
                    "Clinic_finances button_cancel_launch clicked"
                  );
                  setAnchorEl(event.currentTarget);
                }}
              />
            </Grid>
            <Grid item>
              <HAUXButton text="Salvar lançamento" onClick={confirm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

ConfirmForm.propTypes = {
  selectedPayment: PropTypes.shape({
    paymentType: PropTypes.string,
    installmentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    installmentDate: PropTypes.number,
    paymentDate: PropTypes.number,
    interest: PropTypes.number,
    observations: PropTypes.string,
    paidValue: PropTypes.number,
    type: PropTypes.object,
    description: PropTypes.string,
    isPaid: PropTypes.bool,
    comments: PropTypes.string,
    _id: PropTypes.string,
    isCancelled: PropTypes.bool,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onFinanceUpdate: PropTypes.func.isRequired,
  handleRequestsErrors: PropTypes.func.isRequired,
};
