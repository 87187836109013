import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HauxMeetingCollapsible } from '../../../../components';
import PhysicalEducationForm from './physicalEducationForm';

const PhysicalEducationCollapsible = ({
  meeting, openPhysicalEvaluation, startChat,
}) => {
  const [expanded, setExpanded] = useState(startChat);
  const { name, number } = meeting;

  return (
    <HauxMeetingCollapsible
      key={name}
      meeting={number.toString()}
      expanded={expanded}
      description={name}
      onChange={() => setExpanded(!expanded)}
      renderContent={() => (
        <PhysicalEducationForm
          openChat={startChat}
          initialMeeting={meeting}
          openPhysicalEvaluation={openPhysicalEvaluation}
        />
      )}
    />
  );
};

export default PhysicalEducationCollapsible;

PhysicalEducationCollapsible.propTypes = {
  meeting: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.number,
  }),
  startChat: PropTypes.bool,
  openPhysicalEvaluation: PropTypes.func,
};

PhysicalEducationCollapsible.defaultProps = {
  meeting: {},
  openPhysicalEvaluation: () => { },
  startChat: false,
};
