import React from 'react';
import Table from './table';

const Partners = () => (
  <>
    <Table />
  </>
);

export default Partners;
