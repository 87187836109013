import React from 'react';

import Grid from '@material-ui/core/Grid';

function Stretching() {
  return (
    <Grid justify="center" container>
      <span className="h3Style">Alongamento</span>
    </Grid>
  );
}

export default Stretching;
