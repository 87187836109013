import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import strings from '../../../assets/strings.json';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';
import HAUXButton from '../../../components/HauxButton';
import AnalyticsManager from '../../../AnalyticsManager';
import {
  getCurrentUser, getNutritionistForm, getNurseForm, getPhysicalEducatorForm, setPayload,
} from '../../../utils/Utils';
import ClientContext from '../../../ClientContex';

const {
  PROFESSIONALS, NURSE_CONSULTS,
  NUTRITIONIST_CONSULTS, PHYSICAL_EDUCATOR_CONSULTS,
  PT_PROFESSIONALS, DATE_FORMATS,
  CLININAL_ANAMNESIS, NUTRITIONAL_ASSESSMENT, PHYSICAL_ASSESSMENT,
  CLININAL_ANAMNESIS_PLURAL, NUTRITIONAL_ASSESSMENT_PLURAL, PHYSICAL_ASSESSMENT_PLURAL,
} = strings;

const {
  PT_NURSE, PT_COORDINATOR, PT_NUTRITIONIST,
  PT_PSYCHOLOGIST, PT_PHYSICAL_EDUCATOR,
} = PT_PROFESSIONALS;

const {
  ADMIN, NURSE, NUTRITIONIST, PHYSICAL_EDUCATOR, PSYCHOLOGIST,
} = PROFESSIONALS;

const useStyles = makeStyles(() => ({
  expansionPanelContainer: {
    marginTop: 40,
  },
}));

function ConsultsArea({ dispatch }) {
  const { client } = useContext(ClientContext);
  const classes = useStyles();
  const user = getCurrentUser();
  const history = useHistory();

  const getFormByType = (type) => {
    if (!client.clientId) return [];
    if (type === NURSE && client.nursingAnamnesis) {
      return client.nursingAnamnesis;
    }
    if (type === NUTRITIONIST && client.nutritionAnamnesis) {
      return client.nutritionAnamnesis;
    }
    if (type === PHYSICAL_EDUCATOR && client.physicalEducationAnamnesis) {
      return client.physicalEducationAnamnesis;
    }
    return [];
  };

  const getModalDataByType = (type, form) => {
    let result = <></>;
    if (type === NUTRITIONIST) {
      result = getNutritionistForm(form, client);
    }
    if (type === NURSE) {
      result = getNurseForm(form, client);
    }
    if (type === PHYSICAL_EDUCATOR) {
      result = getPhysicalEducatorForm(form, client);
    }
    return result;
  };

  const renderFormsButtons = (type, name) => {
    const formArray = getFormByType(type);

    return (
      <>
        <div className="consultItem">
          <span className="paragraph">
            {`Histórico de ${name.toLowerCase()}`}
          </span>
        </div>
        <div className="consultsHistoryList">
          {formArray.map((form) => (
            <div
              className="consultsHistoryListButton"
              key={form.date}
            >
              <HAUXButton
                type="tertiary"
                text={moment(form.date).format(DATE_FORMATS.NORMAL)}
                onClick={() => {
                  dispatch(setPayload('MODAL_DATA', getModalDataByType(type, form)));
                  dispatch(setPayload('MODAL_OPEN', true));
                }}
              />
            </div>
          ))}
        </div>

        <>
          <div className="consultItem" style={{ marginTop: 50 }}>
            <span className="paragraph">
              {`Publicação pendente de ${name.toLowerCase()}`}
            </span>
          </div>
          <div className="consultsHistoryList">
            {(client.pendingNursingAnamnesis || []).map((form) => (
              <div
                className="consultsHistoryListButton"
                key={form._id}
              >
                <HAUXButton
                  type="quaternary"
                  text={moment(form.date).format(DATE_FORMATS.NORMAL)}
                  onClick={() => {
                    history.push('/client/nursingConsult', { professional: user.name, pendingNursingAnamnesis: form });
                  }}
                />
              </div>
            ))}
          </div>
        </>
      </>
    );
  };

  const renderButtons = (type, buttonName, pluralButtonName, action, meetingsAction) => (

    <div className="consultsHistory">
      {((type === NURSE && (user.profileType === PT_NURSE || user.profileType === PT_COORDINATOR))
        || (type === NUTRITIONIST && user.profileType === PT_NUTRITIONIST)
        || (type === PSYCHOLOGIST && user.profileType === PT_PSYCHOLOGIST)
        || (type === PHYSICAL_EDUCATOR && user.profileType === PT_PHYSICAL_EDUCATOR)
        || user.profileType === ADMIN) && (
        <div className="consultsHistoryList">
          <HAUXButton text={buttonName} type="secondary" onClick={action} />
          {meetingsAction && <HAUXButton text="Acompanhamento de encontros" type="primary" onClick={meetingsAction} />}
        </div>
      )}
      { ([
        PT_COORDINATOR, PT_NURSE, PT_NUTRITIONIST, PT_PSYCHOLOGIST, PT_PHYSICAL_EDUCATOR, ADMIN,
      ].includes(user.profileType)) && (
      <div className="historySpaces">
        {renderFormsButtons(type, pluralButtonName)}
      </div>
      )}
    </div>

  );

  const consultationsArray = [
    {
      name: NURSE_CONSULTS,
      renderDetails: renderButtons(NURSE, CLININAL_ANAMNESIS, CLININAL_ANAMNESIS_PLURAL, () => {
        AnalyticsManager.track('Dashboard button_new_nurse_form clicked');
        history.push('/client/nursingConsult', { professional: user.name });
      }, () => {
        AnalyticsManager.track('Dashboard button_new_nursing_meeting clicked');
        history.push('/meetings/nursing');
      }),
    },
    {
      name: NUTRITIONIST_CONSULTS,
      renderDetails: renderButtons(NUTRITIONIST,
        NUTRITIONAL_ASSESSMENT, NUTRITIONAL_ASSESSMENT_PLURAL, () => {
          AnalyticsManager.track('Dashboard button_new_nutritionist_form clicked');
          history.push('/client/nutritionConsult', { professional: user.name });
        }, () => {
          AnalyticsManager.track('Dashboard button_new_nutrition_meeting clicked');
          history.push('/meetings/nutrition');
        }),
    },
    {
      name: PHYSICAL_EDUCATOR_CONSULTS,
      renderDetails: renderButtons(PHYSICAL_EDUCATOR,
        PHYSICAL_ASSESSMENT, PHYSICAL_ASSESSMENT_PLURAL, () => {
          AnalyticsManager.track('Dashboard button_new_physical_educator_form clicked');
          history.push('/client/physicalEducationConsult', { professional: user.name });
        }, () => {
          AnalyticsManager.track('Dashboard button_new_physical_education_meeting clicked');
          history.push('/meetings/physicalEducation');
        }),
    },
  ];

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} className={classes.expansionPanelContainer}>
        <Grid item>
          <span className="h5Purple">Encontros</span>
        </Grid>
        <Grid item xs={12}>
          <HauxExpansionPanel items={consultationsArray} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConsultsArea;

ConsultsArea.propTypes = {
  state: PropTypes.shape({
    client: PropTypes.shape({
      clientId: PropTypes.string,
      nursingAnamnesis: PropTypes.arrayOf(PropTypes.object),
      nutritionAnamnesis: PropTypes.arrayOf(PropTypes.object),
      physicalEducationAnamnesis: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};
