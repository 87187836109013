import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, HauxFormModal } from '../../../../components';
import FollowUpHeader from '../../components/MeetingsHeader';
import PhysicalEducationCollapsible from './physicalEducationCollapse';
import AnalyticsManager from '../../../../AnalyticsManager';
import { getPhysicalEducatorForm } from '../../../../utils/Utils';
import MeetingService from '../../../../service/MeetingService';
import ClientContext from '../../../../ClientContex';
import ViewContext from '../../../../ViewContext';
import strings from '../../../../assets/strings.json';
import useStyle from './styles';
import MEETINGS from './constants';
import '../../meetings.scss';

function PhysicalEducationMeetings() {
  const [lastIndexToShow, setLastIndexToShow] = useState(0);
  const [modalConfig, setModalConfig] = useState({ open: false, data: {} });
  const [startChat, setStartChat] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const { client, loadClient } = useContext(ClientContext);
  const {
    setRouteProperties, handleRequestsErrors, setLoading, loading,
  } = useContext(ViewContext);
  const { state } = useLocation();
  const classes = useStyle();

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Acompanhamento de condicionamento físico',
      breadcrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (state && Object.keys(state).length) {
      if (state.openMeeting) setExpanded(lastIndexToShow);
      if (state.isAtHome) setStartChat(true);
    }
  }, [state, lastIndexToShow]);

  useEffect(() => {
    const getMeetings = async () => {
      setLoading(true);
      await loadClient();

      MeetingService.getPhysicalEducationMeetings(client.clientId)
        .then(({ data }) => {
          const allMeetings = MEETINGS;

          data.forEach((meeting) => {
            const indexToUpdate = allMeetings.findIndex(({ number }) => number === meeting.number);

            allMeetings.splice(indexToUpdate, 1, { ...allMeetings[indexToUpdate], ...meeting });
            setLastIndexToShow(indexToUpdate + 1);
          });

          setMeetings(allMeetings);
        })
        .catch(handleRequestsErrors)
        .finally(() => setLoading(false));
    };

    AnalyticsManager.track('View Receptionist');
    getMeetings();
  }, [client.clientId, handleRequestsErrors, loadClient, setLoading]);

  const renderItem = () => {
    const openPhysicalEvaluation = () => setModalConfig({
      open: true,
      data: getPhysicalEducatorForm(
        client.physicalEducationAnamnesis[client.physicalEducationAnamnesis.length - 1],
        client,
      ),
    });

    return (
      <Grid container className={classes.rootContainer}>
        <Grid item xs={12}>
          <FollowUpHeader consultType={strings.PROFESSIONALS.PHYSICAL_EDUCATOR} />
        </Grid>
        {
          !loading && (
            <Grid item xs={12} className="marginTp">
              {
                meetings
                  .filter((_, index) => index <= lastIndexToShow)
                  .map((meeting, index) => (
                    <PhysicalEducationCollapsible
                      key={meeting.number.toString()}
                      meeting={meeting}
                      openPhysicalEvaluation={openPhysicalEvaluation}
                      startChat={index === expanded && startChat}
                    />
                  ))
              }
            </Grid>
          )
        }
        <HauxFormModal
          open={modalConfig.open}
          handleClose={() => setModalConfig({ open: false, data: {} })}
          data={modalConfig.data}
        />
      </Grid>
    );
  };

  return renderItem();
}

export default PhysicalEducationMeetings;
