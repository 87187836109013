import React, { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import { debounce } from "debounce";
import { useHistory } from "react-router-dom";
import moment from "moment";
import strings from "../../assets/strings.json";
import { ReactComponent as IcSchedule } from "../../assets/svgs/ic-schedule.svg";
import { ReactComponent as AcCam } from "../../assets/svgs/ac-cam.svg";
import HauxInputSearch from "../../components/HauxInputSearch";
import HauxButton from "../../components/HauxButton";
import ClientService from "../../service/ClientService";
import ScheduleService from "../../service/ScheduleService";
import AnalyticsManager from "../../AnalyticsManager";
import ClientContext from "../../ClientContex";
import ViewContext from "../../ViewContext";
import "./meetings.scss";

const {
  NO_CLIENTS_FOUND,
  MINIMUN_SEARCH_LENGTH,
  AREAS,
  DATE_FORMATS,
  NAME,
} = strings;

const useStyles = makeStyles(() => ({
  rootContainer: {
    position: "relative",
    maxWidth: 1156,
    zIndex: 2,
    top: -40,
  },
  inputContainer: {
    minWidth: "75%",
    paddingBottom: 80,
  },
  optionContainer: {
    borderWidth: 2,
    display: "flex",
  },
  clientStatus: {
    borderRadius: 50,
    backgroundColor: "#E0E0E0",
    padding: "4px 8px 4px 8px",
    width: 98,
    display: "flex",
    justifyContent: "center",
  },
  clientActive: {
    backgroundColor: "#4ED25D",
  },
  clientInactive: {
    backgroundColor: "#DB8D8D",
  },
  paperContainer: {
    minWidth: "100%",
  },
  paper: {
    padding: 40,
    marginLeft: -10,
  },
  eventInfoContainer: {
    paddingTop: 16,
    paddingBottom: 4,
  },
  clientInfo: {
    paddingBottom: 12,
  },
  consultButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: -10,
    marginBottom: -10,
  },
  atHomeIconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "auto",
    marginRight: 8,
  },
  placeholder: {
    width: 16,
  },
}));

function Meetings() {
  const { setClient } = useContext(ClientContext);
  const {
    setSelected,
    setRouteProperties,
    handleRequestsErrors,
    setLoading,
  } = useContext(ViewContext);
  const [nextEvents, setNextEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [searched, setSearched] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setRouteProperties({
      pageTitle: "encontros haux",
    });
  }, [setRouteProperties]);

  useEffect(() => {
    setLoading(true);
    ScheduleService.getNextEvents()
      .then((events) => setNextEvents(events))
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, handleRequestsErrors]);

  useEffect(() => {
    AnalyticsManager.track("View Receptionist");
  }, []);

  useEffect(() => {
    if (!searchClient) {
      setSearched(false);
      setClients([]);
    }
  }, [searchClient]);

  const debouncedSearch = debounce((name) => {
    ClientService.getClientsFilteredByName(name, 1)
      .then((response) => {
        if (name.length >= 3) {
          setClients(response);
          setSearched(true);
        }
      })
      .catch(handleRequestsErrors);
  }, 750);

  const handleSearchInputChange = (event, selectedEvent) => {
    if (selectedEvent && selectedEvent.name) {
      setSearchClient(selectedEvent.name);
    } else {
      setSearchClient(event.target.value);
    }
    if (searchClient && event.target.value) {
      if (event.target.value.length >= 3) {
        debouncedSearch(event.target.value);
      } else if (searchClient && event.target.value.length < 3 && searched) {
        setClients([]);
        setSearched(false);
      }
    }
  };

  const renderAutocompleteOptionIndicator = (option) => {
    if (option.active) {
      return (
        <Grid
          item
          className={`${classes.clientStatus} ${classes.clientActive}`}
        >
          <span className="descriptionWhite">Cliente Ativo</span>
        </Grid>
      );
    }

    return (
      <Grid
        item
        className={`${classes.clientStatus} ${classes.clientInactive}`}
      >
        <span className="descriptionWhite">Cliente Inativo</span>
      </Grid>
    );
  };

  const selectSpecialityTextStyle = (speciality) => {
    switch (speciality) {
      case AREAS[1]:
        return "nutrition";
      case AREAS[2]:
        return "physicalEducation";
      default:
        return "nursing";
    }
  };

  const getName = (event) => {
    try {
      return event.participants && event.participants.length > 0
        ? event.participants.map((p) => p.name).join(", ")
        : event.client.name;
    } catch (e) {
      return "";
    }
  };

  const renderNextEventsInfo = (event, key) => (
    <Grid
      item
      container
      alignItems="center"
      key={key}
      className={`${classes.eventInfoContainer} borderControl`}
    >
      <Grid item className={classes.atHomeIconContainer}>
        {event.client.franchises.length &&
        event.client.franchises[0].slug === "at_home" ? (
          <AcCam height={20} />
        ) : (
          <div className={classes.placeholder} />
        )}
      </Grid>
      <Grid item xs={8} container direction="column">
        <Grid item className={classes.clientInfo}>
          <button
            type="button"
            className="nameButton"
            onClick={() => {
              setClient(event.client);
              history.push("/client/dashboard");
            }}
          >
            <span className="h6NormalPurpleStyle">{getName(event)}</span>
          </button>
        </Grid>
        <Grid item className={classes.clientInfo}>
          <span
            className={`description ${selectSpecialityTextStyle(
              event.speciality
            )}`}
          >
            {`${event.speciality} - encontro ${event.consultNumber}`}
          </span>
        </Grid>
        {event.observations && (
          <Grid item className={classes.clientInfo}>
            <span className="description">{event.observations}</span>
          </Grid>
        )}
      </Grid>
      <Grid item xs={1}>
        <span className="hourText">
          {moment(event.start).format(DATE_FORMATS.HOUR)}
        </span>
      </Grid>
      <Grid item xs className={classes.consultButtonContainer}>
        <HauxButton
          text="Iniciar atendimento"
          onClick={() => {
            setClient(event.client);
            history.push(
              `/meetings/${selectSpecialityTextStyle(event.speciality)}`,
              {
                openMeeting: true,
                isAtHome: event.client.franchises[0].slug === "at_home",
              }
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      className={classes.rootContainer}
      alignItems="center"
    >
      <Grid item className={classes.inputContainer} container justify="center">
        <Grid item xs={8}>
          <HauxInputSearch
            label={NAME}
            noOptionsText={searched ? NO_CLIENTS_FOUND : MINIMUN_SEARCH_LENGTH}
            rightIcon={() => {}}
            value={searchClient}
            onChange={handleSearchInputChange}
            onSelect={(client) => {
              setClient(client);
              history.push("/client/dashboard");
            }}
            fullWidth
            autocomplete
            autocompleteProps={{
              options: clients,
              getOptionLabel: (option) => option.name || "",
            }}
            renderOption={(option) => (
              <Grid container>
                <Grid
                  item
                  xs={10}
                  container
                  className={classes.optionContainer}
                >
                  <Grid item xs={2}>
                    {renderAutocompleteOptionIndicator(option)}
                  </Grid>
                  <Grid item xs={4}>
                    <span className="paragraph">{option.name}</span>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid item container alignItems="center">
              <IcSchedule className="paperTitleIcon" />
              <span className="h6StylePurple">Próximos atendimentos</span>
            </Grid>
            <Grid item container direction="column">
              {nextEvents.map((event, index) =>
                renderNextEventsInfo(event, index.toString())
              )}
            </Grid>
            <Grid item container justify="flex-end">
              <Grid item>
                <HauxButton
                  type="secondary"
                  text="Ver agenda"
                  onClick={() => {
                    history.push("/schedule/consults");
                    setSelected(1);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Meetings;
