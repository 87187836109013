/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './hauxSnackbar.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

function transitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const HauxSnackbar = ({
  type, message, openSnackbar, onClose, action,
}) => (
  <Grid
    container
    spacing={2}
    item
    xs={10}
    className="gridContainer"
    style={{ pointerEvents: openSnackbar ? 'all' : 'none', zIndex: 1000000 }}
  >
    <Snackbar
      open={openSnackbar}
      TransitionComponent={transitionUp}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert
        variant="filled"
        severity={type}
        onClose={onClose}
        className="alertStyle"
        action={action}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  </Grid>
);

export default HauxSnackbar;


HauxSnackbar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  openSnackbar: PropTypes.bool,
  action: PropTypes.node,
};

HauxSnackbar.defaultProps = {
  type: '',
  message: '',
  onClose: () => {},
  openSnackbar: false,
  action: null,
};
