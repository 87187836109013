import moment from 'moment';
import _ from 'lodash';
import strings from '../assets/strings.json';

const {
  CONSULTS_LABELS,
  CONSULTS_STATUS,
  PROFESSIONALS_SCHEDULE,
  DATE_FORMATS,
} = strings;

export const isAuthenticated = () => !!localStorage.getItem('TOKEN_KEY');

export const getCurrentUser = () => JSON.parse(localStorage.getItem('currentUser'));

export const isPermission = (props) => {
  const user = getCurrentUser();

  if (user.profileType === 'Admin') return true;
  if (
    (props.location.pathname === '/client/newClient'
      || props.match.path === '/client/newNursingAnamnesis')
    && !(user.profileType === 'Enfermagem' || user.profileType === 'Coordenador')
  ) { return false; }

  if (
    props.match.path === '/client/physicalEducationAnamnesis'
    && !user.profileType === 'Educação Física'
  ) { return false; }

  if (
    props.match.path === '/client/nutritionAnamnesis'
    && !user.profileType === 'Nutrição'
  ) { return false; }

  return true;
};

export const updateUser = (user) => {
  localStorage.setItem('currentUser', JSON.stringify(user));
};

export const cleanUserAndToken = () => {
  localStorage.clear();
};

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validateBirthDate(date) {
  return moment(date, DATE_FORMATS.NORMAL).isBefore(moment().subtract(17, 'y'));
}

export const formatTextToFloat = (text) => {
  try {
    const splittedText = text.split(',');
    const floatText = parseFloat(splittedText.join('.'));
    return Number(floatText.toFixed(2));
  } catch (e) {
    return text;
  }
};

export const statesArray = [
  { value: 'AC' },
  { value: 'AL' },
  { value: 'AP' },
  { value: 'AM' },
  { value: 'BA' },
  { value: 'CE' },
  { value: 'DF' },
  { value: 'ES' },
  { value: 'GO' },
  { value: 'MA' },
  { value: 'MT' },
  { value: 'MS' },
  { value: 'MG' },
  { value: 'PA' },
  { value: 'PR' },
  { value: 'PE' },
  { value: 'PI' },
  { value: 'RJ' },
  { value: 'RN' },
  { value: 'RS' },
  { value: 'RO' },
  { value: 'RR' },
  { value: 'SC' },
  { value: 'SP' },
  { value: 'SE' },
  { value: 'TO' },
];

export const sourceChannelArray = [
  { value: 'Site' },
  { value: 'Mídias Sociais' },
  { value: 'Telefone' },
  { value: 'Indicação de Cliente' },
  { value: 'Indicação de Funcionário' },
  { value: 'Indicação de Médico' },
  { value: 'Presencial' },
  { value: 'Outbound/Comercial' },
];

export const physicalActivitiesArray = [
  'Musculação',
  'Ginástica',
  'Corrida',
  'Crossfit',
  'Natação',
  'Hidroginástica',
  'Bicicleta',
];

export const actualLevelPhysicalActivityArray = [
  'Iniciante',
  'Intermediário',
  'Intermediário 2',
  'Avançado',
];

export const musclePainsArray = [
  'Lombar',
  'Cervical',
  'Torácica',
  'Tornozelo',
  'Joelho',
  'Ombro',
  'Cotovelo',
];

export const difficultyMovingArray = [
  'Agachar-se',
  'Calçar tênis',
  'Pegar ou colocar objetos acima da cabeça',
  'Levantar da cama',
  'Levantar da posição sentada',
  'Entrar e sair do carro',
];

export const foodFrequencyArray = [
  { evaluation: 'Salada', frequency: '', period: '' },
  { evaluation: 'Frutas', frequency: '', period: '' },
  { evaluation: 'Peixes', frequency: '', period: '' },
  { evaluation: 'Doces', frequency: '', period: '' },
  { evaluation: 'Frituras', frequency: '', period: '' },
  { evaluation: 'Embutidos', frequency: '', period: '' },
  { evaluation: 'Temperos prontos', frequency: '', period: '' },
];

export const foodLiquids = [
  { evaluation: 'Água', frequency: '', period: '' },
  { evaluation: 'Sucos artificiais', frequency: '', period: '' },
  { evaluation: 'Refrigerantes', frequency: '', period: '' },
  { evaluation: 'Chás', frequency: '', period: '' },
];

export const frequencyDropdownItems = [
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x',
];

export const exportItems = [
  'Clientes selecionados',
  'Clientes ativos',
  'Clientes inativos',
  'Todos os clientes',
];

export const registerTypes = ['Cliente', 'Prospect'];

export const franchises = ['Clientes Haux Caxias', 'Clientes Haux At Home'];

export const exportProspectItems = [
  'Somente selecionados',
  'Todos agendados na Haux Tour',
  'Todos não agendados na Haux Tour',
  'Todos os cadastros prospecção',
];

export const evaluationQuestions = [
  {
    question:
      'Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?',
    value: '',
  },
  {
    question: 'Você sente dores no peito quando pratica atividades físicas?',
    value: '',
  },
  {
    question:
      'No último mês, você sentiu dores no peito quando praticou atividade física?',
    value: '',
  },
  {
    question:
      'Você apresenta desequilíbrio devido à tontura e/ou perda de consciência?',
    value: '',
  },
  {
    question:
      'Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?',
    value: '',
  },
  {
    question:
      'Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?',
    value: '',
  },
  {
    question:
      'Sabe de alguma outra razão pela qual você não deve praticar atividade física?',
    value: '',
  },
];

export const foodQuestions = [
  {
    question: 'Utiliza saleiro na mesa?',
    value: '',
  },
  {
    question: 'Utiliza açúcar?',
    value: '',
  },
  {
    question: 'Utiliza adoçante?',
    value: '',
  },
];

export const clientHealth = [
  'Cardio',
  'Diabete s/ insulina',
  'Hipertensão arterial',
];

export const getNutritionistForm = (form, client) => {
  const nutriAnswers = [];
  const foodInquerit = [];
  const liquids = [];
  const foods = [];
  const restAnswers = [];
  const foodSurveyAnswers = [];
  const {
    digestionAndFoodFunction,
    foodSection,
    liquidIngestion,
    foodEvaluation,
    professional,
    rest,
    foodSurvey,
  } = form;
  if (digestionAndFoodFunction.foodFast) {
    nutriAnswers.push({
      title: 'Considera sua mastigação: ',
      answer: digestionAndFoodFunction.foodFast,
    });
  }
  if (digestionAndFoodFunction.evaluationFrequency) {
    nutriAnswers.push({
      title: 'Frequência Evacuatória: ',
      answer: digestionAndFoodFunction.evaluationFrequency,
    });
  }
  if (digestionAndFoodFunction.intestinalFunction) {
    nutriAnswers.push({
      title: 'Considera sua função intestinal: ',
      answer: digestionAndFoodFunction.intestinalFunction,
    });
  }
  digestionAndFoodFunction.symptoms.map((symptom) => nutriAnswers.push({ title: `${symptom}: `, answer: 'Sim' }));

  foodSection.foodIntolerances.map((item) => foodInquerit.push({ title: `${item}: `, answer: 'Sim' }));
  if (foodSection.numberOfMealsDay) {
    foodInquerit.push({
      title: 'Número de refeições por dia: ',
      answer: foodSection.numberOfMealsDay,
    });
  }
  if (foodSection.foodPreference) {
    foodInquerit.push({
      title: 'Preferência alimentar: ',
      answer: foodSection.foodPreference,
    });
  }

  liquidIngestion.map((item) => liquids.push({
    title: `${item.evaluation}: `,
    answer:
        item.evaluation === 'Bebida alcoólica'
          ? `${item.frequency}ML ${item.period} / ${item.whichAlcoholicBeverages}`
          : `${item.frequency}ML ${item.period}`,
  }));

  foodEvaluation.map((item) => foods.push({
    title: `${item.evaluation}: `,
    answer: `${item.frequency} ${item.period}`,
  }));

  if (foodSurvey) {
    foodSurveyAnswers.push({
      title: 'Utiliza saleiro na mesa? ',
      answer: foodSurvey.useSalt ? 'Sim' : 'Não',
    });
    foodSurveyAnswers.push({
      title: 'Utiliza açúcar? ',
      answer: foodSurvey.useSugar ? 'Sim' : 'Não',
    });
    foodSurveyAnswers.push({
      title: 'Utiliza adoçante? ',
      answer: foodSurvey.useSweetener ? 'Sim' : 'Não',
    });
    foodSurveyAnswers.push({
      title: 'Possui alguma alergia alimentar? ',
      answer: !_.isEmpty(foodSurvey.haveFoodAllergies)
        ? `Sim, ${foodSurvey.haveFoodAllergies}`
        : 'Não',
    });
    foodSurveyAnswers.push({
      title: 'Já fez dieta? ',
      answer: !_.isEmpty(foodSurvey.haveDieted)
        ? `Sim, ${foodSurvey.haveDieted}`
        : 'Não',
    });
    if (!_.isEmpty(foodSurvey.middleOfTheNightToEat)) {
      foodSurveyAnswers.push({
        title: 'Quem cozinha em sua casa? ',
        answer: foodSurvey.whoCooksInYourHouse,
      });
    }
  }

  if (rest) {
    if (rest.wakeUp) { restAnswers.push({ title: 'Acorda às: ', answer: rest.wakeUp }); }
    if (rest.sleepIn) { restAnswers.push({ title: 'Dorme às: ', answer: rest.sleepIn }); }
    if (rest.sleepHours) {
      restAnswers.push({
        title: 'Horas de sono durante a semana: ',
        answer: rest.sleepHours,
      });
    }
    if (rest.sleepHoursInWeekend) {
      restAnswers.push({
        title: 'Horas de sono em finais de semana: ',
        answer: rest.sleepHoursInWeekend,
      });
    }
    if (rest.sleepDescription) {
      restAnswers.push({
        title: 'Como costuma ser seu sono? ',
        answer: rest.sleepDescription,
      });
    }
    restAnswers.push({
      title: 'Costuma acordar no meio da noite? ',
      answer: rest.middleOfTheNight ? 'Sim' : 'Não',
    });
    restAnswers.push({
      title: 'Costuma acordar no meio da noite para comer? ',
      answer: rest.middleOfTheNightToEat ? 'Sim' : 'Não',
    });
  }

  return {
    title: 'Anamnese Nutricional',
    clientName: client.name,
    date: moment(form.date).format(DATE_FORMATS.NORMAL),
    professionalName: professional,
    sections: [
      foodInquerit.length
        ? {
          answers: foodInquerit,
        }
        : null,
      nutriAnswers.length
        ? {
          name: 'Digestão e função alimentar',
          answers: nutriAnswers,
        }
        : null,
      foodSurveyAnswers.length
        ? {
          name: 'Inquérito alimentar',
          answers: foodSurveyAnswers,
        }
        : null,
      foods.length
        ? {
          name: 'Frequência alimentar',
          answers: foods,
        }
        : null,
      liquids.length
        ? {
          name: 'Ingestão de líquidos',
          answers: liquids,
        }
        : null,
      restAnswers.length
        ? {
          name: 'Descanso',
          answers: restAnswers,
        }
        : null,
    ],
  };
};

const getLeisureTimeActivityText = (activity) => {
  const items = ['Muito leve', 'Leve', 'Moderada', 'Intensa', 'Muito intensa'];

  return items[activity];
};

const joinFormatter = (item) => item.join(', ').replace(/, ([^,]*)$/, ' e $1');

export const getNurseForm = (form, client) => {
  const anamnesisArray = [];
  const measurementsArray = [];
  const bodyCompositionArray = [];

  const {
    leisureTimeActivity,
    workActivity,
    healthProblems,
    bloodPressure,
    abdominalCircumference,
    armCircumference,
    thighCircumference,
    calfCircumference,
    height,
    imc,
    freeMass,
    leanMass,
    bodyFat,
    muscleMass,
    professional,
    weight,
    handGrip,
    muscleMassIndex,
  } = form;

  if (leisureTimeActivity) {
    anamnesisArray.push({
      title: 'Nível de atividade física no lazer: ',
      answer: getLeisureTimeActivityText(leisureTimeActivity - 1),
    });
  }
  if (workActivity) {
    anamnesisArray.push({
      title: 'Nível de atividade física no trabalho ou escola: ',
      answer: getLeisureTimeActivityText(workActivity - 1),
    });
  }
  if (healthProblems.length) {
    anamnesisArray.push({
      title: 'Problemas de saúde: ',
      answer: joinFormatter(healthProblems),
    });
  }

  if (bloodPressure) {
    measurementsArray.push({
      title: 'Pressão arterial: ',
      answer: `${bloodPressure}mmHg`,
    });
  }
  if (bloodPressure) {
    measurementsArray.push({
      title: 'Estatura: ',
      answer: `${height.toFixed(2).replace('.', ',')}m`,
    });
  }
  if (abdominalCircumference) {
    measurementsArray.push({
      title: 'Circunferência abdominal: ',
      answer: `${abdominalCircumference.toFixed(2).replace('.', ',')}cm`,
    });
  }
  if (armCircumference) {
    measurementsArray.push({
      title: 'Circunferência do braço: ',
      answer: `${armCircumference.toFixed(2).replace('.', ',')}cm`,
    });
  }
  if (thighCircumference) {
    measurementsArray.push({
      title: 'Circunferência da coxa: ',
      answer: `${thighCircumference.toFixed(2).replace('.', ',')}cm`,
    });
  }
  if (calfCircumference) {
    measurementsArray.push({
      title: 'Circunferência da panturrilha: ',
      answer: `${calfCircumference.toFixed(2).replace('.', ',')}cm`,
    });
  }
  if (handGrip) {
    measurementsArray.push({
      title: 'Hand Grip: ',
      answer: `${handGrip.toFixed(2).replace('.', ',')}kg`,
    });
  }
  if (muscleMassIndex) {
    measurementsArray.push({
      title: 'Índice de massa muscular esquelética: ',
      answer: `${muscleMassIndex.toFixed(2).replace('.', ',')}kg/m2`,
    });
  }

  if (height) {
    bodyCompositionArray.push({
      title: 'Peso atual: ',
      answer: `${weight.toFixed(2).replace('.', ',')}kg`,
    });
  }
  if (imc) {
    bodyCompositionArray.push({
      title: 'IMC atual: ',
      answer: `${imc.toFixed(2).replace('.', ',')}kg/m2`,
    });
  }
  if (leanMass) {
    bodyCompositionArray.push({
      title: 'Massa muscular: ',
      answer: `${leanMass.toFixed(2).replace('.', ',')}kg`,
    });
  }
  if (freeMass) {
    bodyCompositionArray.push({
      title: 'Massa livre de gordura: ',
      answer: `${freeMass.toFixed(2).replace('.', ',')}kg`,
    });
  }
  if (bodyFat) {
    bodyCompositionArray.push({
      title: 'Gordura corporal: ',
      answer: `${bodyFat.toFixed(2).replace('.', ',')}%`,
    });
  }
  if (muscleMass) {
    bodyCompositionArray.push({
      title: 'Massa muscular esquelética: ',
      answer: `${muscleMass.toFixed(2).replace('.', ',')}kg`,
    });
  }

  return {
    title: 'Anamnese Clínica',
    clientName: client.name,
    date: moment(form.date).format(DATE_FORMATS.NORMAL),
    professionalName: professional,
    sections: [
      {
        name: '',
        answers: anamnesisArray,
      },
      {
        name: 'Antropometria',
        answers: measurementsArray,
      },
      {
        name: 'Composição corporal',
        answers: bodyCompositionArray,
      },
    ],
  };
};

const mountingParqQuiz = (parqQuiz) => {
  const parqArray = [];
  parqArray.push({
    title:
      'Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde? ',
    answer: parqQuiz[0].heartProblem ? 'Sim' : 'Não',
  });
  parqArray.push({
    title: 'Você sente dores no peito quando pratica atividades físicas? ',
    answer: parqQuiz[0].chestPains ? 'Sim' : 'Não',
  });
  parqArray.push({
    title:
      'No último mês, você sentiu dores no peito quando praticou atividade física? ',
    answer: parqQuiz[0].chestPainsInTheLastMonth ? 'Sim' : 'Não',
  });
  parqArray.push({
    title:
      'Você apresenta desequilibrio devido à tontura e/ou perda de consciência? ',
    answer: parqQuiz[0].haveImbalance ? 'Sim' : 'Não',
  });
  parqArray.push({
    title:
      'Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física? ',
    answer: parqQuiz[0].boneOrJointProblem ? 'Sim' : 'Não',
  });
  parqArray.push({
    title:
      'Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração? ',
    answer: parqQuiz[0].takeSomeMedicine ? 'Sim' : 'Não',
  });
  parqArray.push({
    title:
      'Sabe de alguma outra razão pela qual você não deve praticar atividade física? ',
    answer: parqQuiz[0].otherReason === 'true' ? 'Sim' : 'Não',
  });

  return parqArray;
};

export const getPhysicalEducatorForm = (form, client) => {
  const succinctAnamnesis = [];
  let parqArray = [];
  const {
    yourActivities,
    levelYourCurrentActivities,
    musclePains,
    stressLevel,
    difficultiesInMoving,
    parqQuiz,
    professional,
    weeklyAerobicMinutes,
    weeklyForceTimes,
  } = form;
  const newYourActivities = yourActivities.slice(1, 2);
  const newMusclePains = musclePains.slice(1, 2);
  if (yourActivities.length) {
    succinctAnamnesis.push({
      title: 'Atividades físicas que pratica ou já praticou: ',
      answer: yourActivities.indexOf('Outras')
        ? joinFormatter(yourActivities)
        : joinFormatter(newYourActivities),
    });
  }
  if (levelYourCurrentActivities) {
    succinctAnamnesis.push({
      title: 'Nível de atividade física atual: ',
      answer: levelYourCurrentActivities,
    });
  }
  if (musclePains.length) {
    succinctAnamnesis.push({
      title: 'Dores articulares ou musculares frequentes: ',
      answer: musclePains.indexOf('Outras')
        ? joinFormatter(musclePains)
        : joinFormatter(newMusclePains),
    });
  }
  if (stressLevel || stressLevel === 0) {
    succinctAnamnesis.push({
      title: 'Nível de estresse (0-10): ',
      answer: stressLevel,
    });
  }
  if (difficultiesInMoving.length) {
    succinctAnamnesis.push({
      title: 'Dificuldades em se movimentar: ',
      answer: joinFormatter(difficultiesInMoving),
    });
  }
  if (weeklyAerobicMinutes || weeklyAerobicMinutes === 0) {
    succinctAnamnesis.push({
      title: 'Atividade aeróbica semanal: ',
      answer: `${weeklyAerobicMinutes} minutos`,
    });
  }
  if (weeklyForceTimes || weeklyForceTimes === 0) {
    succinctAnamnesis.push({
      title: 'Atividade de força semanal: ',
      answer: `${weeklyForceTimes} vezes`,
    });
  }

  parqArray = mountingParqQuiz(parqQuiz);

  return {
    title: 'Avaliação Condicionamento Físico',
    clientName: client.name,
    date: moment(form.date).format(DATE_FORMATS.NORMAL),
    professionalName: professional,
    sections: [
      {
        name: 'Anamnese Sucinta',
        answers: succinctAnamnesis,
      },
      {
        name: 'Checando o Par-q',
        answers: parqArray,
      },
    ],
  };
};

export const stringFormat = (text) => text.toFixed(2).replace('.', ',');

export const setPayload = (type, payload) => ({
  type,
  payload,
});

export const moreLifeMeetings = [
  {
    number: '1',
    theme: 'Quebrando barreiras para adesão a um estilo de vida mais saudável',
  },
  {
    number: '2',
    theme: 'Gerenciamento do Stress',
  },
  {
    number: '3',
    theme: 'Resolução de problemas',
  },
  {
    number: '4',
    theme: 'Como agir em situações de risco',
  },
  {
    number: '5',
    theme: 'Tornando positivos os pensamentos negativos',
  },
  {
    number: '6',
    theme: 'Gatilhos psicológicos para os maus hábitos',
  },
  {
    number: '7',
    theme: 'Sono saudável de A a Zzzzz',
  },
  {
    number: '8',
    theme: 'Obtendo apoio e suporte familiar e social',
  },
  {
    number: '9',
    theme: 'Deslizes e recidivas',
  },
];

export const converEventToBaseContult = (event, client) => {
  function selectLabel(professional) {
    switch (professional.type) {
      case PROFESSIONALS_SCHEDULE.NURSE:
        return CONSULTS_LABELS.NURSING;
      case PROFESSIONALS_SCHEDULE.NUTRITIONIST:
        return CONSULTS_LABELS.NUTRITIONIST;
      case PROFESSIONALS_SCHEDULE.PHYSICAL_EDUCATOR:
        return CONSULTS_LABELS.PHYSICAL_EDUCATION;
      default:
        return CONSULTS_LABELS.MORE_LIFE;
    }
  }

  function selectStatus(status, professional) {
    if (event.professional.type === PROFESSIONALS_SCHEDULE.PSYCHOLOGIST) {
      const participant = event.participantsStatus.find(
        (x) => x.client === client,
      );
      if (participant) {
        switch (participant.status) {
          case 'Não confirmado':
            return CONSULTS_STATUS.NOT_CONFIRMED;
          case 'Presente':
            return CONSULTS_STATUS.PRESENT;
          default:
            return CONSULTS_STATUS.ABSENT;
        }
      } else {
        return CONSULTS_STATUS.NOT_CONFIRMED;
      }
    } else {
      switch (event.confirmationStatus) {
        case 'Não confirmado':
          return CONSULTS_STATUS.NOT_CONFIRMED;
        case 'Presente':
          return CONSULTS_STATUS.PRESENT;
        default:
          return CONSULTS_STATUS.ABSENT;
      }
    }
  }

  function selectConsultNumber(number, professionalType) {
    if (Number(number)) {
      return number;
    }

    if (professionalType === PROFESSIONALS_SCHEDULE.PSYCHOLOGIST) {
      return moreLifeMeetings.find((meeting) => meeting.theme === number)
        .number;
    }

    return 'Extra';
  }

  return {
    id: event._id,
    date: moment(event.start).valueOf(),
    label: selectLabel(event.professional),
    status: selectStatus(),
    consultNumber: selectConsultNumber(
      event.consultNumber,
      event.professional.type,
    ),
  };
};

export const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const getUserFranchises = () => {
  const { franchises } = getCurrentUser();

  return franchises;
};
