/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
} from "react";
import moment from "moment";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AnalyticsManager from "../../../../AnalyticsManager";
import HauxCSV from "../../../../components/HauxCSV";
import { ReactComponent as AcFilter } from "../../../../assets/svgs/ac-filter.svg";
import {
  HauxButton,
  HauxFilterDropdown,
  Grid,
  Dialog,
} from "../../../../components";
import Form from "./create";
import ConfirmForm from "./confirm";
import TableOfFinances from "./list";
import { HauxInput } from "../../../../components";
import useStyles from "./list/styles";
import { formatAsMoney } from "./helper";
import FinanceService from "../../../../service/FinanceService";
import ViewContext from "../../../../ViewContext";
import FILTER_OPTIONS from "./constants";
import SelectFranchise from "./components/selectFranchise";
import DateRangeFilter from "./components/dateRangeFilter";
import { formatAsDate, formatAsDecimal } from "./helper";
import { reducer, initialState } from "../../reducers";
import { setPayload } from "../../../../utils/Utils";

const columns = [
  "Entrada/Saída",
  "Data prevista",
  "Descrição",
  "Valor previsto",
  "Valor realizado",
  "Data realizada	",
  "Forma de pagamento",
  "Número NF",
];

const initialFilter = {
  status: {
    future: true,
    realized: true,
    late: true,
  },
  startDate: moment().startOf("month").valueOf(),
  endDate: moment().endOf("month").valueOf(),
};

const Finances = () => {
  const classes = useStyles();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState({
    open: false,
    selectedPayment: {},
  });
  const [finances, setFinances] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalPaidValue, setTotalPaidValue] = useState(0);
  const [totalInstallmentValue, setTotalInstallmentValue] = useState(0);
  const [filter, setFilter] = useState(initialFilter);
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbarOption, handleRequestsErrors } = useContext(ViewContext);
  const [franchise, setFranchise] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { exportSelected, exportFinancesData } = state;

  useEffect(() => {
    const paidValue = finances.reduce((acc, item) => {
      if (item.type.type === "entry") {
        return acc + (item.paidValue || 0);
      }

      return acc - (item.paidValue || 0);
    }, 0);

    const installmentValue = finances.reduce((acc, item) => {
      if (item.type.type === "entry") {
        return acc + item.installmentValue;
      }

      return acc - item.installmentValue;
    }, 0);

    setTotalPaidValue(paidValue);
    setTotalInstallmentValue(installmentValue);
  }, [finances]);

  useEffect(() => {
    const load = () => {
      setIsLoading(true);
      const { startDate, endDate, status } = filter;

      FinanceService.getFinances(
        franchise,
        startDate,
        endDate,
        status,
        searchText
      )
        .then(setFinances)
        .catch(handleRequestsErrors)
        .finally(() => setIsLoading(false));
    };

    if (franchise && filter.startDate && filter.endDate && !openCreateDialog) {
      setIsLoading(true);
      load();
    }
  }, [franchise, filter, handleRequestsErrors, openCreateDialog, searchText]);

  const handleStatus = ({ target }) =>
    setFilter((ps) => {
      AnalyticsManager.track("Clinic_finances button_filter_by clicked");
      const { name, checked } = target;
      const status = { ...ps.status, [name]: checked };

      return { ...filter, status };
    });

  const updateFinanceData = (updatedFinance) => {
    const newFinances = [...finances];
    const indexToUpdate = newFinances.findIndex(
      (f) => f._id.toString() === updatedFinance._id.toString()
    );
    newFinances.splice(indexToUpdate, 1, updatedFinance);
    setFinances(newFinances);
  };

  const filterTypeEntry = finances.filter(
    (finance) => (finance.type || {}).type === "entry"
  );
  const getTotalEntry = () =>
    filterTypeEntry.reduce((sum, item) => sum + (item.paidValue || 0), 0);

  const filterTypeExit = finances.filter(
    (finance) => (finance.type || {}).type === "exit"
  );
  const getTotalExit = () =>
    filterTypeExit.reduce((sum, item) => sum + (item.paidValue || 0), 0);

  const balance = () => getTotalEntry() - getTotalExit();

  const renderBottomSection = () => (
    <Grid container alignItems="center">
      <Grid item xs={6} />
      <Grid item xs={1}>
        <div style={{ marginLeft: -20, whiteSpace: "nowrap" }}>
          <span className="h6NormalStyle">
            Previsto:
            <br />
            {formatAsMoney(totalInstallmentValue)}
          </span>
        </div>
      </Grid>
      <Grid item xs={1} style={{ marginLeft: 48, whiteSpace: "nowrap" }}>
        <span className="h6NormalStyle">
          Realizado:
          <br />
          {formatAsMoney(totalPaidValue)}
        </span>
      </Grid>
    </Grid>
  );

  const handleExport = useCallback(() => {
    const financesExport = [];

    finances.forEach((finance) => {
      financesExport.push([
        finance.type.type === "entry" ? "Entrada" : "Saída",
        formatAsDate(finance.installmentDate),
        finance.description || finance.comments,
        formatAsDecimal(
          finance.installmentValue,
          finance.type.type === "entry"
        ),
        formatAsDecimal(finance.paidValue || 0, finance.type.type === "entry"),
        finance.isCancelled
          ? "Cancelado"
          : finance.paymentDate
          ? formatAsDate(finance.paymentDate)
          : finance.installmentDate < moment().valueOf()
          ? "Atrasado"
          : "Não realizado",
        finance.paymentType,
        finance.nf,
      ]);
    });
    const dataSet = [
      {
        columns,
        data: financesExport,
      },
    ];

    dispatch(setPayload("EXPORT_SELECTED", "finances"));
    dispatch(setPayload("EXPORT_FINANCES_DATA", dataSet));
  }, [finances]);

  const renderCreateDialog = () => (
    <Dialog
      open={openCreateDialog}
      onClose={() => setOpenCreateDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <Form
        franchise={franchise}
        handleRequestsErrors={handleRequestsErrors}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={() => {
          setSnackbarOption({
            type: "success",
            message: "Lançamento salvo com successo!",
            open: true,
          });
          setOpenCreateDialog(false);
          setFilter(initialFilter);
        }}
        onError={(e) => {
          handleRequestsErrors(e, {
            message: "Erro ao salvar lançamento",
          });
        }}
      />
    </Dialog>
  );

  const renderConfirmPaymentDialog = () => (
    <Dialog
      open={confirmDialogData.open}
      onClose={() => setConfirmDialogData((s) => ({ ...s, open: false }))}
      maxWidth="lg"
      scroll="body"
    >
      <ConfirmForm
        selectedPayment={confirmDialogData.selectedPayment}
        onClose={() => setConfirmDialogData((s) => ({ ...s, open: false }))}
        onFinanceUpdate={updateFinanceData}
        handleRequestsErrors={handleRequestsErrors}
      />
    </Dialog>
  );

  return (
    <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid container item xs={12}>
        <span style={{ marginLeft: 5 }} className="h6StylePurple">
          Fluxo de caixa
        </span>
      </Grid>

      <Grid item xs container direction="row" alignItems="flex-end">
        <Grid item xs={3}>
          <SelectFranchise franchise={franchise} setFranchise={setFranchise} />
        </Grid>
        <Grid item xs={3} style={{ paddingRight: 50 }}>
          <HauxInput
            fullWidth
            value={searchText}
            onChange={({ target }) => setSearchText(target.value)}
            placeholder="Buscar por descrição"
          />
        </Grid>
        <Grid item xs={6}>
          <DateRangeFilter filter={filter} setFilter={setFilter} />
        </Grid>
      </Grid>
      <Grid className={classes.totalContainer}>
        <Grid className={classes.totalContent}>
          <ArrowDownwardIcon
            style={{ color: "#685DA8", marginBottom: -50, marginTop: -10 }}
          />
          <span style={{ marginBottom: -15 }} className="h6NormalStyle">
            Total de entradas:
          </span>
          <span className="h6NormalStyle">
            {formatAsMoney(getTotalEntry())}
          </span>
        </Grid>
        <Grid className={classes.totalContent}>
          <ArrowUpwardIcon
            style={{ color: "#685DA8", marginBottom: -50, marginTop: -10 }}
          />
          <span style={{ marginBottom: -15 }} className="h6NormalStyle">
            Total de saídas:
          </span>
          <span className="h6NormalStyle">{formatAsMoney(getTotalExit())}</span>
        </Grid>
        <Grid className={classes.totalContent}>
          <AttachMoneyIcon
            style={{ color: "#685DA8", marginBottom: -50, marginTop: -10 }}
          />
          <span style={{ marginBottom: -15 }} className="h6NormalStyle">
            Saldo:
          </span>
          <span className="h6NormalStyle">{formatAsMoney(balance())}</span>
        </Grid>
      </Grid>

      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Grid container direction="column">
          <Grid item xs={12} container direction="row" justify="space-between">
            <Grid
              item
              xs
              container
              justify="flex-end"
              alignItems="flex-end"
              style={{ marginBottom: 20 }}
            >
              <Grid item>
                <HauxFilterDropdown
                  options={FILTER_OPTIONS}
                  Icon={AcFilter}
                  label="Filtrar por"
                  filters={filter.status}
                  onChange={handleStatus}
                />
              </Grid>
              <Grid item>
                <HauxButton
                  text="Exportar Dados"
                  type="secondary"
                  onClick={() => {
                    AnalyticsManager.track(
                      "Clinic_finances button_export_data clicked"
                    );
                    handleExport();
                  }}
                />
              </Grid>
              <Grid item>
                <HauxButton
                  text="Novo lançamento"
                  onClick={() => {
                    AnalyticsManager.track(
                      "Clinic_finances button_new_release clicked"
                    );
                    setOpenCreateDialog(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            {
              <TableOfFinances
                data={finances}
                setConfirmDialogData={setConfirmDialogData}
                isLoading={isLoading}
              />
            }
            {renderBottomSection()}
          </Grid>
          {renderCreateDialog()}
          {renderConfirmPaymentDialog()}
        </Grid>
      </div>
      {exportFinancesData.length && exportSelected ? (
        <HauxCSV
          data={exportFinancesData}
          exportCallback={() => dispatch(setPayload("EXPORT_SELECTED", ""))}
        />
      ) : null}
    </Grid>
  );
};

export default Finances;
