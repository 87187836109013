/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import EventPropTypes from './eventPropsTypes';
import DayEventCell from './dayEventCell';
import MonthEventCell from './monthEventCell';
import EventColors from './eventColors';
import './hauxCalendar.scss';
import { setPayload } from '../../utils/Utils';

moment.locale('pt-br');
const Localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const messages = {
  allDay: 'Dia inteiro',
  previous: '<',
  next: '>',
  today: 'Hoje',
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'Nenhum evento encontrado neste período.',
  showMore: (total) => `+ Mais (${total})`,
};

const ToggleButton = withStyles(() => ({
  root: {
    fontFamily: 'GraviolaSoft',
    fontSize: 14,
    color: '#685DA8',
    borderColor: '#685DA8',
    backgroundColor: 'white',
    '&.active': {
      backgroundColor: '#685DA8',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#685DA8',
      color: 'white',
    },
  },
}))(Button);

export default function HauxCalendar({
  initialDate,
  events,
  step,
  onRangeChange,
  onSelectEvent,
  onSelectSlot,
  isTraining,
}) {
  const [calendarView, setCalendarView] = useState('day');
  const [calendarDate, setCalendarDate] = useState(initialDate);
  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    setCalendarDate(initialDate);
  }, [initialDate]);

  useEffect(() => {
    const range = {};
    const startDate = moment(calendarDate);
    startDate.set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    });

    let endDate = moment(startDate.toDate());

    if (calendarView === 'day') {
      endDate.add(1, 'days').subtract(1, 'seconds');
    } else if (calendarView === 'month' || calendarView === 'agenda') {
      startDate.startOf('month');
      endDate = moment(startDate.toDate());
      endDate.add(1, 'months').subtract(1, 'seconds');
    } else if (calendarView === 'week') {
      startDate.startOf('week');
      endDate = moment(startDate.toDate());
      endDate.add(1, 'weeks').add(1, 'seconds');
    }

    range.startStr = startDate.format();
    range.endStr = endDate.format();
    range.start = startDate.toDate();
    range.end = endDate.toDate();

    onRangeChange(setPayload('DATE_RANGE', range));

    setTimeout(() => {
      try {
        const scrollContainer = document.getElementsByClassName('rbc-time-content')[0];
        const container = document.getElementsByClassName('rbc-events-container')[0];
        const eventTime = localStorage.getItem('last_event_time');
        localStorage.removeItem('last_event_time');
        let element = null;
        container.childNodes.forEach((e) => {
          if (e.innerHTML.includes(`HOUR: ${eventTime}`)) {
            element = e;
          }
        });
        if (element) {
          const childPos = element.offsetTop;
          const parentPos = container.offsetTop;
          const childOffset = {
            top: childPos - parentPos,
            behavior: 'smooth',
          };

          scrollContainer.scroll(childOffset);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }, 1000);
  }, [onRangeChange, calendarDate, calendarView]);

  const getMin = () => moment(calendarDate).set({
    hour: isTraining ? 7 : 8, minute: 0, second: 0, millisecond: 0,
  }).toDate();

  const getMax = () => {
    const m = moment(calendarDate);
    if (m.day() === 6) {
      return m.set({
        hour: 12, minute: 0, second: 0, millisecond: 0,
      }).toDate();
    }

    return m.set({
      hour: 20, minute: 0, second: 0, millisecond: 0,
    }).toDate();
  };

  const Toolbar = (props) => {
    const { views, view, label } = props;

    const addToDate = (count) => {
      let calendarComponent = `${view}s`;
      if (view === 'agenda') {
        calendarComponent = 'months';
      }

      setCalendarDate(
        moment(calendarDate)
          .add(count, calendarComponent),
      );
    };

    return (
      <div className="toolbarContainer">
        <ButtonGroup variant="outlined">
          <ToggleButton
            variant="outlined"
            onClick={() => setCalendarDate(moment())}
          >
            HOJE
          </ToggleButton>
          <ToggleButton
            variant="outlined"
            onClick={() => {
              if (calendarDate.day() === 1) {
                addToDate(-2);
              } else {
                addToDate(-1);
              }
            }}
          >
            {'<'}
          </ToggleButton>
          <ToggleButton
            variant="outlined"
            onClick={() => {
              if (calendarDate.day() === 6) {
                addToDate(2);
              } else {
                addToDate(1);
              }
            }}
          >
            {'>'}
          </ToggleButton>
        </ButtonGroup>
        <div className="toolbarCentralContainer">
          <ToggleButton
            style={{ position: 'absolute', top: 0, height: 36 }}
            onClick={() => setCalendarOpen(true)}
          >
            {label}
          </ToggleButton>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              style={{ display: 'none' }}
              open={calendarOpen}
              onClose={() => setCalendarOpen(false)}
              value={calendarDate}
              onChange={(m) => setCalendarDate(m)}
              views={
                view === 'month' || view === 'agenda'
                  ? ['month', 'year']
                  : ['date']
              }
            />
          </MuiPickersUtilsProvider>
        </div>
        <ButtonGroup variant="outlined">
          {views.map((v) => (
            <ToggleButton
              key={v}
              variant="outlined"
              className={v === view ? 'active' : ''}
              onClick={() => setCalendarView(v)}
            >
              {messages[v]}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  return (
    <DragAndDropCalendar
      key={`${calendarOpen}-${calendarView}-${calendarDate}`}
      messages={messages}
      localizer={Localizer}
      defaultView={calendarView}
      defaultDate={calendarDate.toDate()}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      views={['month', 'week', 'day']}
      min={getMin()}
      max={getMax()}
      components={{
        toolbar: Toolbar,
        day: {
          event: DayEventCell,
        },
        month: {
          event: MonthEventCell,
        },
        week: {
          event: MonthEventCell,
        },
        agenda: {
          event: DayEventCell,
        },
      }}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{
        height: 480,
      }}
      eventPropGetter={(event) => ({
        style: {
          borderColor: 'transparent',
          backgroundColor: EventColors[event.room],
          color: 'white',
        },
      })}
      resizable
      selectable
      showMultiDayTimes
      step={step}
      timeslots={1}
      formats={{
        eventTimeRangeFormat: () => null,
        agendaHeaderFormat: (range) => moment(range.start).format('MMMM YYYY'),
      }}
      dayLayoutAlgorithm="no-overlap"
    />
  );
}

HauxCalendar.defaultProps = {
  step: 30,
};

HauxCalendar.propTypes = {
  initialDate: PropTypes.instanceOf(moment),
  onRangeChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  events: PropTypes.arrayOf(PropTypes.shape(EventPropTypes)),
  onSelectEvent: PropTypes.func,
  onSelectSlot: PropTypes.func,
  isTraining: PropTypes.bool,
};

HauxCalendar.defaultProps = {
  initialDate: moment(),
  events: [],
  onSelectEvent: () => {},
  onSelectSlot: () => {},
  isTraining: false,
};
