/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as IcArrowDropdown } from '../../assets/svgs/ic-arrow-dropdown.svg';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
  },
  imgStyle: {
    marginRight: 8,
  },
  arrowStyle: {
    marginLeft: 8,
  },
  option: {
    color: '#737373',
  },
}));

const FilterCheckbox = withStyles({
  root: {
    color: '#737373',
    '&$checked': {
      color: '#F57A3D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const FilterDropdown = ({
  label, options, Icon,
  filters, onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={classes.root}
    >
      <Button
        className="CTASecondaryButton"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon className={classes.imgStyle} />
        <span className="textStyle">{label}</span>
        <IcArrowDropdown className={classes.arrowStyle} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
          >
            <FormGroup row>
              <FormControlLabel
                control={(
                  <FilterCheckbox
                    size="small"
                    checked={filters[option.name]}
                    name={option.name}
                    onChange={onChange}
                  />
                )}
                label={option.label}
                className={classes.option}
              />
            </FormGroup>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

FilterDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]),
  filters: PropTypes.shape({
    active: PropTypes.bool,
    inactive: PropTypes.bool,
    next: PropTypes.bool,
    recent: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

FilterDropdown.defaultProps = {
  label: '',
  options: [],
  Icon: <></>,
};

export default React.memo(FilterDropdown);
