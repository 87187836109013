/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  Grid, Tooltip,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ViewContext from '../../../../ViewContext';
import icMoreLife from '../../../../assets/svgs/ic-mais-vida.svg';
import strings from '../../../../assets/strings.json';
import HauxButton from '../../../../components/HauxButton';
import '../sheduleCard.scss';

const {
  DATE_FORMATS,
} = strings;

function HauxMoreLifeCard({
  contentMeetings,
}) {
  const history = useHistory();
  const { setSelected } = useContext(ViewContext);

  return (
    <Paper>
      <Grid container direction="column" className="cardContainer">
        <div className="titleCardContainer">
          <img src={icMoreLife} alt="" style={{ height: 25 }} />
          <span className="h6StylePurple spaceLeftTitle">Haux Mais Vida</span>
          <div className="positionRoom">
            <span className="cardRoom">Sala Multispace</span>
          </div>
        </div>
        <Grid container direction="row">
          {contentMeetings.map((
            item, index,
          ) => (
              <React.Fragment key={index.toString()}>
                {item.consultNumber.length >= 52
                  ? (
                    <>
                      <div className="spaceCardSubtitleMoreLife">
                        <span className="paragraph meetingTitle">{`${item.consultNumber.substr(0, 55)}`}</span>
                        <Tooltip title={`${item.consultNumber}`} enterDelay={500} leaveDelay={200}>
                          <span className="paragraph spaceMoreText">[...]</span>
                        </Tooltip>
                        <div className="textCenter">
                          <span className="paragraph spanBlock">{moment(item.start).format(DATE_FORMATS.DAYMONTH)}</span>
                          <span className="hourText spanBlock">{moment(item.start).format(DATE_FORMATS.HOUR)}</span>
                        </div>
                      </div>
                      <span className="description quantityClients">{`${item.participants.length} cliente(s) agendado(s)`}</span>
                      <div className="borderCard" />
                    </>
                  )
                  : (
                    <>
                      <div className="spaceCardSubtitleMoreLifeWithoutAlign">
                        <span className="paragraph meetingTitle">{`${item.consultNumber}`}</span>
                        <div className="textCenter">
                          <span className="paragraph spanBlock">{moment(item.start).format(DATE_FORMATS.DAYMONTH)}</span>
                          <span className="hourText spanBlock">{moment(item.start).format(DATE_FORMATS.HOUR)}</span>
                        </div>
                      </div>
                      <span className="description quantityClients">{`${item.participants.length} cliente(s) agendado(s)`}</span>
                      <div className="borderCard" />
                    </>
                  )}
              </React.Fragment>
            ))}
        </Grid>
        <div className="buttonCardContainer">
          <HauxButton
            type="secondary"
            text="Ver agenda"
            onClick={() => {
              history.push('/schedule/moreLife');
              setSelected(1);
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}

HauxMoreLifeCard.propTypes = {
  contentMeetings: PropTypes.array,
};
HauxMoreLifeCard.defaultProps = {
  contentMeetings: [],
};

export default HauxMoreLifeCard;
