import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import {
  HauxInput, HauxDropdown, HauxRadio, HauxButton,
} from '../../../../../../components';
import HauxSwitch from '../../../../../../components/HauxSwitch';
import HauxConfirmDialog from '../../../../../../components/HauxConfirmDialog';
import FinanceService from '../../../../../../service/FinanceService';
import AnalyticsManager from '../../../../../../AnalyticsManager';
import './hauxRegisterReleaseDialog.scss';


const typeOptions = [
  { label: 'Entrada', value: 'entry' },
  { label: 'Saída', value: 'exit' },
];

const useStyles = makeStyles(() => ({
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '100vw',
    },
  },
  title: {
    fontFamily: 'Graviola',
    '& .MuiTypography-root': {
      fontFamily: 'Graviola',
      fontWeight: 'bold',
      color: '#737373',
      fontSize: '20px',
    },
  },
}));

function ItemActive({ item }) {
  const [active, setActive] = useState(item.active);

  useEffect(() => {
    async function updateStatusFinanceType() {
      await FinanceService.updateStatus(item._id, active);
    }
    if (item.active !== active) {
      updateStatusFinanceType();
    }
  }, [item._id, active, item.active]);

  return (
    <div
      key={item._id}
      className="itensSummaryContainer"
    >
      <span className="h6NormalStyle">{`${item.identifier} - ${item.title}`}</span>
      <span className="h6NormalStyle">{`${item.mother.title}`}</span>
      <div className="activeSwitch">
        <HauxSwitch
          checked={active}
          onChange={() => setActive(!active)}
        />
      </div>
    </div>
  );
}

function RegisterRelease({ open, onClose, handleRequestsErrors }) {
  const [financeTypes, setFinanceTypes] = useState([]);
  const [type, setType] = useState('entry');
  const [motherTypes, setMotherTypes] = useState([]);
  const [mother, setMother] = useState('');
  const [typeSon, setTypeSon] = useState('');
  const [typeSonError, setTypeSonError] = useState({ error: false, message: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      FinanceService.getAllFinanceTypes()
        .then((response) => {
          setFinanceTypes(response);
        });
      FinanceService.getMotherTypes()
        .then((response) => {
          setMotherTypes(response);
        });
    }
  }, [open]);

  useEffect(() => {
    setMother('');
  }, [type]);

  const save = async () => {
    const newMother = motherTypes.find((m) => m.identifier.toString() === mother.substr(0, 2));
    try {
      const { data } = await FinanceService.postFinanceType({
        type,
        mother: newMother._id,
        motherIdentifier: newMother.identifier,
        typeSon,
      });
      setFinanceTypes([...financeTypes, data]);
    } catch (e) {
      handleRequestsErrors(e);
    }
  };

  const getFilteredMothers = () => motherTypes.filter((m) => m.type === type).map((m) => `${m.identifier} - ${m.title}`);

  function verifyFields() {
    return !(
      type
      && mother
      && typeSon
    );
  }

  function initialValues() {
    return (
      setType(''),
      setMother(''),
      setTypeSon('')
    );
  }

  const renderTypes = (typeToRender) => {
    let items;
    if (typeToRender === 'entry') {
      items = financeTypes.filter((f) => f.type === 'entry').sort((a, b) => a.identifier - b.identifier);
    } else {
      items = financeTypes.filter((f) => f.type === 'exit').sort((a, b) => a.identifier - b.identifier);
    }

    return items.map((item, index) => (
      <ItemActive
        key={index.toString()}
        item={item}
      />
    ));
  };

  return (
    <Dialog
      open={open}
      className={classes.dialog}
    >
      <div className="financesContainer">
        <DialogTitle className={classes.title}>Cadastrar tipos de lançamento</DialogTitle>
        <DialogContent>
          <div className={classes.title}>
            <div className="typesRegisterContainer">
              <span className="h6NormalStyle">
                <strong>Tipo de lançamento </strong>
                {typeOptions.map((option) => (
                  <HauxRadio
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    checked={
                      type === option.value
                    }
                    handleChange={() => setType(option.value)}
                  />
                ))}
              </span>
              <br />
              <span className="h6NormalStyle">
                <div className="typeContainer">
                  <strong className="alignTitle">Tipo mãe </strong>
                  <HauxDropdown
                    options={getFilteredMothers()}
                    value={mother}
                    handleChange={(e) => setMother(e.target.value)}
                    fullWidth
                  />
                </div>
              </span>
              <br />
              <span className="h6NormalStyle">
                <div className="typeContainer">
                  <strong className="alignTitle">Tipo filho </strong>
                  <HauxInput
                    value={typeSon}
                    onChange={(e) => setTypeSon(e.target.value)}
                    fullWidth
                    onBlur={() => {
                      if (!typeSon) {
                        setTypeSonError({ error: true, message: 'Campo obrigatório' });
                      }
                    }}
                    onFocus={() => {
                      if (typeSonError.error) {
                        setTypeSonError({ error: false, message: '' });
                      }
                    }}
                  />
                </div>
              </span>
            </div>
            <div className="line" />
            <span className="h6NormalStyle">
              <div className="summaryTitleContainer">
                <strong>Entradas</strong>
                <strong>Tipo mãe</strong>
                <strong style={{ textAlign: 'center' }}>Ativo/Inativo</strong>
              </div>
            </span>
            {renderTypes('entry')}
            <div className="spaceItens" />
            <span className="h6NormalStyle">
              <div className="summaryTitleContainer">
                <strong>Saídas</strong>
                <strong>Tipo mãe</strong>
                <strong style={{ textAlign: 'center' }}>Ativo/Inativo</strong>
              </div>
            </span>
            {renderTypes('exit')}
          </div>

          <HauxConfirmDialog
            title="Salvar tipo de lançamento"
            message="Tem certeza que deseja salvar o tipo de lançamento?"
            open={showConfirmation}
            handleClose={() => {
              AnalyticsManager.track('Company finances button_save_yes clicked');
              setShowConfirmation(false);
            }}
            handleConfirm={() => {
              AnalyticsManager.track('Company finances button_save_not clicked');
              setShowConfirmation(false);
              initialValues();
              save();
            }}
          />
        </DialogContent>
        <DialogActions className={classes.spaceBetweenRow}>
          <HauxButton
            text="Fechar"
            type="secondary"
            onClick={() => {
              AnalyticsManager.track('Company finances button_close clicked');
              onClose();
            }}
          />
          <HauxButton
            text="Salvar"
            disabled={verifyFields()}
            onClick={() => {
              AnalyticsManager.track('Company finances button_save clicked');
              setShowConfirmation(true);
            }}
            type="primary"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

ItemActive.propTypes = {
  item: PropTypes.shape({
    active: PropTypes.bool,
    _id: PropTypes.string,
    mother: PropTypes.object,
    title: PropTypes.string,
    identifier: PropTypes.number,
  }).isRequired,
};

RegisterRelease.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleRequestsErrors: PropTypes.func.isRequired,
};

RegisterRelease.defaultProps = {
  open: false,
  onClose: () => { },
};

export default RegisterRelease;
