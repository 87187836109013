import moment from "moment";

export const initialState = {
  tab: 0,
  financeType: {},
  description: "",
  competencyDate: moment(),
  paymentType: "",
  value: 0,
  discount: 0,
  finalValue: 0,
  installments: 1,
  installmentValue: 0,
  installmentDate: moment(),
  comments: "",
  isRecurring: false,
  errors: {},
  nf: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TAB":
      return {
        ...state,
        tab: action.payload,
      };
    case "SET_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
