import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  clientStatus: {
    borderRadius: 50,
    backgroundColor: '#E0E0E0',
    padding: '4px 8px 4px 8px',
    width: 98,
    display: 'flex',
    justifyContent: 'center',
  },
  clientActive: {
    backgroundColor: '#4ED25D',
  },
  clientInactive: {
    backgroundColor: '#DB8D8D',
  },
  optionContainer: {
    borderWidth: 2,
    display: 'flex',
  },
}));
