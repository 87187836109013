import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router-dom";
import strings from "../../../assets/strings.json";
import HAUXButton from "../../../components/HauxButton";
import ProspectService from "../../../service/ProspectService";
import AnalyticsManager from "../../../AnalyticsManager";
import { setPayload } from "../../../utils/Utils";

const {
  UPDATE_PROSPECT_SUCCESS,
  CLIENT_CPF_ALREADY_REGISTERED,
  REGISTER_PROSPECT_SUCCESS,
  REGISTER_PROSPECT_FAIL,
} = strings;

export default function ProspectForm({
  state,
  dispatch,
  renderBasicInfo,
  renderConfirmation,
  handleRequestsErrors,
  setSnackbarOption,
  setLoading,
}) {
  const history = useHistory();
  const location = useLocation();
  const { clientData, biologicalSex } = state;
  function validateFormProspect() {
    return (
      !clientData.name ||
      !clientData.phone ||
      !clientData.email ||
      !clientData.sourceChannel ||
      state.cpfError
    );
  }

  async function createProspect() {
    AnalyticsManager.track("NewProspectForm button_save_prospect clicked");
    setLoading(true);
    await ProspectService.postProspect(clientData, biologicalSex)
      .then(() => {
        AnalyticsManager.track("CreateProspect success");
        setSnackbarOption({
          type: "success",
          open: true,
          message:
            location.state && location.state.prospect
              ? UPDATE_PROSPECT_SUCCESS
              : REGISTER_PROSPECT_SUCCESS,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch((err) => {
        AnalyticsManager.track("CreateProspect failed");
        handleRequestsErrors(err, {
          message:
            err.message.substr(-3) === "422"
              ? CLIENT_CPF_ALREADY_REGISTERED
              : REGISTER_PROSPECT_FAIL,
        });
      });
    setLoading(false);
  }

  return (
    <Grid container spacing={2} className="formGridContainer">
      <Grid item xs={12}>
        {renderBasicInfo()}
        <Grid container spacing={2} className="buttonsContainer">
          <Grid item xs={6} className="saveButton">
            <HAUXButton
              text="Salvar"
              type="primary"
              onClick={() =>
                location.state && location.state.prospect
                  ? dispatch(setPayload("SAVE_FORM_DIALOG", true))
                  : createProspect()
              }
              disabled={validateFormProspect()}
            />
          </Grid>
          <Grid item xs={6}>
            <HAUXButton
              text="Voltar"
              type="secondary"
              onClick={() => {
                AnalyticsManager.track("NewProspectForm button_back clicked");
                if (Object.keys(clientData).length !== 0) {
                  dispatch(setPayload("EXIT_FORM_DIALOG", true));
                } else {
                  history.goBack();
                }
              }}
            />
          </Grid>
        </Grid>
        {renderConfirmation()}
      </Grid>
    </Grid>
  );
}

ProspectForm.propTypes = {
  renderBasicInfo: PropTypes.func.isRequired,
  renderConfirmation: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleRequestsErrors: PropTypes.func.isRequired,
  setSnackbarOption: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  state: PropTypes.shape({
    biologicalSex: PropTypes.string,
    clientData: PropTypes.shape({
      name: PropTypes.string,
      cpf: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      sourceChannel: PropTypes.string,
    }),
    cpfError: PropTypes.bool,
  }).isRequired,
};
