import axios, { reloadAxiosInstance } from '../middlewares/axios';
import { updateUser } from '../utils/Utils';
import AnalyticsManager from '../AnalyticsManager';

class AuthService {
  static signIn(username, password) {
    const url = '/user/login';
    return axios.post(url, { username, password })
      .then((res) => {
        localStorage.setItem('TOKEN_KEY', res.data.token);
        updateUser(res.data.user);
        AnalyticsManager.identify(res.data.user.userId);
        AnalyticsManager.people.set({
          $userId: res.data.user.userId,
          name: res.data.user.name,
          username: res.data.user.username,
          profileType: res.data.user.profileType,
        });
        reloadAxiosInstance();
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default AuthService;
