import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import HauxTable from "../../../components/HauxTable";
import HauxConfirmDialog from "../../../components/HauxConfirmDialog";
import { HauxInput, HauxButton } from "../../../components";
import ViewContext from "../../../ViewContext";
import AnalyticsManager from "../../../AnalyticsManager";
import PlansService from "../../../service/PlanService";
import "./plansTable.scss";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Valor",
    format: (row) => {
      return `R$ ${row.value}`;
    },
  },
];

const Table = () => {
  const history = useHistory();
  const timeoutRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 0,
    order: "asc",
    orderBy: "name",
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const {
    setLoading: setViewLoading,
    setSnackbarOption,
    setRouteProperties,
  } = useContext(ViewContext);

  const search = async () => {
    timeoutRef.current = null;
    setLoading(true);
    try {
      const { page, order, orderBy } = pagination;
      const response = await PlansService.getPaginatedPlans(
        page,
        searchText,
        orderBy,
        order
      );
      setItems(response.contents);
      setTotalPages(response.totalPages);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setLoading(false);
  };

  const debouncedSearch = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(search, 500);
  };

  useEffect(() => {
    debouncedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, searchText]);

  const edit = (planId) => {
    history.push("/plans/create", { planId });
  };

  const deletePlan = async (planId) => {
    setViewLoading(true);
    try {
      await PlansService.deletePlan(planId);
      setSnackbarOption({
        message: "Plano excluído com sucesso!",
        type: "success",
        open: true,
      });
    } catch (error) {
      setSnackbarOption({
        message: "Erro ao excluir plano.",
        type: "error",
        open: true,
      });
    }
    setViewLoading(false);
    search();
  };

  useEffect(() => {
    setRouteProperties({
      pageTitle: "Planos",
    });
  }, [setRouteProperties]);

  return (
    <>
      <Grid container direction="column" justify="center" id="plans-table">
        <Grid item xs>
          <Paper elevation={2} className="paper">
            <div className="filterDiv">
              <HauxInput
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                placeholder="Buscar por nome"
              />
              <HauxButton
                text="Novo plano"
                type="primary"
                onClick={() => {
                  AnalyticsManager.track("Plans button_new_plan clicked");
                  history.push("/plans/create");
                }}
              />
            </div>
            <HauxTable
              headCells={headCells}
              items={items}
              itemIdKey="_id"
              order={pagination.order}
              orderBy={pagination.orderBy}
              onSort={(orderBy, order) =>
                setPagination({ ...pagination, orderBy, order })
              }
              page={pagination.page}
              totalPages={totalPages}
              onChangePage={(page) => setPagination({ ...pagination, page })}
              loading={loading}
              onDelete={setShowConfirmDialog}
              onEdit={edit}
            />

            <HauxConfirmDialog
              isDestructive
              handleClose={() => setShowConfirmDialog(null)}
              handleConfirm={() => {
                deletePlan(showConfirmDialog);
                setShowConfirmDialog(null);
              }}
              open={!!showConfirmDialog}
              title="Excluir plano"
              message="Esta ação irá apagar este plano. Deseja continuar?"
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Table;
