import axios from '../middlewares/axios';

class ProfessionalService {
  static getProfessionals() {
    const url = '/professional/professionals';
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }
}

export default ProfessionalService;
