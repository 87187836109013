import React, {
  useState, useEffect, useCallback, useRef, useContext,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import HauxButton from '../../../components/HauxButton';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';
import Steps from '../components/steps';
import Guides from '../components/Guides';
import '../training.scss';

import ScheduleService from '../../../service/ScheduleService';
import MeetingService from '../../../service/MeetingService';

import InputSearch from './components/inputSearch';
import strings from '../../../assets/strings.json';
import EditModal from './components/edit';

import ViewContext from '../../../ViewContext';
import { HauxSnackbar, Grid } from '../../../components';

const {
  HAUX_TRAINING,
  DATE_FORMATS,
} = strings;

const initialSnackbar = {
  type: null,
  message: null,
  show: false,
};

const HauxTraining = () => {
  const history = useHistory();
  const stepRef = useRef();
  const [trainings, setTrainings] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const { setLoading, handleRequestsErrors } = useContext(ViewContext);
  const [selectedClient, setSelectedClient] = useState(null);
  const [snackbar, setSnackbar] = useState(initialSnackbar);

  const loadMeetings = useCallback(async () => {
    setLoading(true);
    try {
      const response = await MeetingService.getTrainingMeetings(trainings.map((t) => t._id));
      setMeetings(response);
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }, [trainings, handleRequestsErrors, setLoading]);

  const loadTrainings = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ScheduleService.getEvents(moment().startOf('day').format(), moment().endOf('day').format());
      setTrainings(response.filter((e) => e.room === HAUX_TRAINING));
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }, [handleRequestsErrors, setLoading]);

  const startTraining = async (training) => {
    history.push('training/followUp', {
      participants: training.participants,
      idMeeting: meetings._id,
    });
  };

  const saveAndNext = async () => {
    // await MeetingService.putTrainingMeeting(meeting._id, { step: meeting.step + 1 });
    loadMeetings();
    // stepRef.current.next();
  };

  const getTrainingMeeting = (training) => _.find(meetings, { event: training._id });

  useEffect(() => {
    loadTrainings();
  }, [loadTrainings]);

  useEffect(() => {
    if (trainings.length > 0) {
      loadMeetings();
    }
  }, [loadMeetings, trainings]);


  const renderTraining = (training, meeting) => (
    <div style={{ width: '100%' }}>
      {_.isEmpty(meeting) && (
        <>
          <span className="h6StylePurple">
            {training.participants.length === 0 ? 'Nenhum cliente agendado' : 'Clientes agendados'}
          </span>
          {training.participants.map((participant, index) => (

            <ListItem
              divider
              style={{
                marginTop: index === 0 ? 10 : 0, paddingTop: 20, paddingBottom: 20, width: '100%',
              }}
              key={participant._id}
            >
              <span className="h6NormalStyle">{participant.name}</span>
            </ListItem>
          ))}
        </>
      )}
      {!_.isEmpty(meeting) && (
        <Steps key={`${meeting._id} = ${meeting.step}`} ref={stepRef} initialStep={meeting.step} />
      )}
      <div className="trainingFooter">
        {_.isEmpty(meeting) ? <div /> : (
          <HauxButton
            text="Voltar"
            type="secondary"
            onClick={() => stepRef.current.back()}
          />
        )}
        {_.isEmpty(meeting)
          ? (
            <HauxButton
              disabled={_.isEmpty(training.participants)}
              text="Iniciar aula"
              onClick={() => startTraining(training)}
            />
          )
          : (
            <HauxButton
              disabled={_.isEmpty(training.participants)}
              variant={meeting.step > 5 ? 'link' : 'contained'}
              text={meeting.step > 5 ? 'Aula concluída' : 'Concluir etapa'}
              endIcon={meeting.step > 5 ? <CheckIcon /> : <NavigateNextIcon />}
              onClick={meeting.step > 5 ? () => stepRef.current.next() : () => saveAndNext(meeting)}
            />
          )}

      </div>
    </div>
  );

  const renderEmptyState = () => (
    <Paper className="emptyStateContainer">
      <span className="h6NormalStyle guideTitle">Não há aulas marcadas para hoje.</span>
    </Paper>
  );

  const renderTrainingsToday = () => {
    if (trainings.length === 0) {
      return renderEmptyState();
    }

    return (
      trainings.map((training) => (
        <Grid 
          key={training._id}
          container
          className="trainingItem"
        >
          <HauxExpansionPanel
            items={[{
              name: `Aula ${moment(training.start).format(DATE_FORMATS.COMPLETE)}`,
              renderDetails: renderTraining(training, getTrainingMeeting(training)),
            }]}
          />
        </Grid>
      ))
    );
  };

  const handleClose = () => setSelectedClient(null);
  const handleSave = (trainingUpdated) => {
    setSelectedClient(null);
    setSnackbar({
      type: "success",
      message: `Ficha de acompanhamento do(a) cliente(a) ${trainingUpdated.client.name} atualizada!`,
      show: true,
    });
  }

  return (
    <>
      <HauxSnackbar
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => setSnackbar(ps => ({ ...ps, show: false }))}
        openSnackbar={snackbar.show}
      />
      {
        selectedClient && (
          <EditModal
            selectedClient={selectedClient}
            onClose={handleClose}
            onSave={handleSave}
          />
        )
      }
      <Grid
        container
        alignItems="center"
        className="trainingContainer"
      >
        <Grid
          container
          justify="center"
        >
          <InputSearch
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </Grid>
        <Guides />
        <Grid container>
          {renderTrainingsToday()}
        </Grid>
      </Grid>
    </>
  );
};

export default HauxTraining;
