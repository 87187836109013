import moment from 'moment';
import strings from '../../assets/strings.json';

const {
  ROOMS,
} = strings;

export const initialState = {
  filter: '',
  tab: 0,
  room: '',
  roomsToFilter: ROOMS,
  dateRange: 0,
  currentClient: {},
  clients: [],
  prospects: [],
  professionals: [],
  events: [],
  filteredEvents: [],
  remainingConsultations: {},
  initialDate: moment(),
  newEventDialogOpen: false,
  newEventDialogDate: 0,
  eventDialog: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    case 'TAB':
      return {
        ...state,
        tab: action.payload,
      };
    case 'ROOM':
      return {
        ...state,
        room: action.payload,
      };
    case 'ROOMS_TO_FILTER':
      return {
        ...state,
        roomsToFilter: action.payload,
      };
    case 'DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'CURRENT_CLIENT':
      return {
        ...state,
        currentClient: action.payload,
      };
    case 'CLIENTS':
      return {
        ...state,
        clients: action.payload,
      };
    case 'PROSPECTS':
      return {
        ...state,
        prospects: action.payload,
      };
    case 'PROFESSIONALS':
      return {
        ...state,
        professionals: action.payload,
      };
    case 'EVENTS':
      return {
        ...state,
        events: action.payload,
      };
    case 'FILTERED_EVENTS':
      return {
        ...state,
        filteredEvents: action.payload,
      };
    case 'REMAINING_CONSULTATIONS':
      return {
        ...state,
        remainingConsultations: action.payload,
      };
    case 'INITIAL_DATE':
      return {
        ...state,
        initialDate: action.payload,
      };
    case 'EVENT_DIALOG_OPEN':
      return {
        ...state,
        newEventDialogOpen: action.payload,
      };
    case 'EVENT_DIALOG_DATE':
      return {
        ...state,
        newEventDialogDate: action.payload,
      };
    case 'EVENT_DIALOG':
      return {
        ...state,
        eventDialog: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
