/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './hauxProgressIndicator.scss';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FOLLOWUP } from '../../assets/strings.json';

const { PHYSICAL_EDUCATION } = FOLLOWUP;
const { PHYSICAL_ACTIVITY_LEVELS } = PHYSICAL_EDUCATION;

const useStyles = makeStyles(() => ({
  chip: {
    fontFamily: 'Graviola',
    color: '#737373',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const HauxProgressIndicator = ({ indicator }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <div className="progressContainer">
        <div className="progressLine" />
        {PHYSICAL_ACTIVITY_LEVELS.map((level) => (
          <div key={level} className={`progressCircle ${indicator === level ? 'selectedCircle' : ''}`}>
            { indicator === level ? <span className="paragraph labelColor">{level}</span> : null }
          </div>
        ))}
      </div>
    </Grid>

  );
};

HauxProgressIndicator.propTypes = {
  indicator: PropTypes.number,
};

HauxProgressIndicator.defaultProps = {
  indicator: 0,
};

export default HauxProgressIndicator;
