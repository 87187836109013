export const initialState = {
  modalData: {},
  openModal: false,
  editable: -1,
  clientMeetings: [],
  notes: '',
  clientFeelings: '',
  tokenExpired: false,
  files: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEETINGS':
      return {
        ...state,
        clientMeetings: action.payload,
      };
    case 'SET_EDITABLE_DATA':
      return {
        ...state,
        notes: state.clientMeetings[action.payload].notes,
        clientFeelings: state.clientMeetings[action.payload].clientFeelings,
        stykuMeasure: state.clientMeetings[action.payload].stykuMeasure,
      };
    case 'SET_EDITABLE':
      return {
        ...state,
        editable: action.payload,
      };
    case 'RESET_STATE':
      return {
        ...state,
        notes: '',
        clientFeelings: '',
      };
    case 'MANAGE_MODAL':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload,
      };
    case 'SET_CLIENT_FEELINGS':
      return {
        ...state,
        clientFeelings: action.payload,
      };
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload,
      };
    case 'UPDATE_MEETING':
      return {
        ...state,
        clientMeetings: action.payload.clientMeetings,
        editable: -1,
      };
    default:
      return {
        ...state,
      };
  }
};
