import axios from '../middlewares/axios';
import { formatTextToFloat } from '../utils/Utils';

const defaultRoute = '/client/clients';

class ClientService {
  static getClients(active = 0, inactive = 0) {
    const url = `${defaultRoute}?active=${active}&inactive=${inactive}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static getPaginatedClients(page, searchText, showActive, showInactive, sort) {
    const url = `/client/clientsPaginated?page=${page}&search=${searchText}&active=${showActive}&inactive=${showInactive}&sort=${sort}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static postClient(clientData, biologicalSex, sendPassword) {
    const data = {
      newClient: {
        ...clientData,
        address: {
          cep: clientData.cep,
          complement: clientData.complement || '',
          street: clientData.street,
          number: clientData.number,
          district: clientData.district,
          city: clientData.city,
          state: clientData.uf,
        },
        biologicalSex,
      },
      sendPassword,
    };
    const url = defaultRoute;
    return axios.post(url, data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewPhysicalEducationForm(clientId, formData) {
    const url = '/client/newPhysicalEducationAnamnesis';
    return axios.post(url, { clientId, formData });
  }

  static postNewNutritionAnamnesis(clientId, consultData) {
    const url = '/client/newNutritionAnamnesis';
    return axios.post(url, { clientId, consultData });
  }

  static getClientById(id) {
    const url = `client/${id}`;
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getMaxMinCalories(data) {
    const url = 'client/maxMinCalories';
    return axios.get(url, { params: data })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewNursingAnamnesis(clientId, data, goal) {
    const json = {
      form: data,
      goal: goal ? {
        ...goal,
        weight: formatTextToFloat(goal.weight),
        imc: formatTextToFloat(goal.imc),
      } : null,
      clientId,
    };
    const url = '/client/newNursingAnamnesis';
    return axios.post(url, json)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewPendingNursingAnamnesis(clientId, data) {
    const json = {
      form: data,
      clientId,
    };
    const url = '/client/newPendingNursingAnamnesis';
    return axios.post(url, json)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getNewContract(id) {
    const url = `/client/contract/newContract?clientId=${id}`;
    return axios.get(url, { responseType: 'blob' })
      .then((res) => URL.createObjectURL(res.data))
      .catch((error) => {
        throw error;
      });
  }

  static getCurrentContract(id) {
    const url = `/client/contract/current?clientId=${id}`;
    return axios.get(url, { responseType: 'blob' })
      .then((res) => URL.createObjectURL(res.data))
      .catch((error) => {
        throw error;
      });
  }

  static getClientSchedule(id, training) {
    const url = `/client/pdf/schedule?id=${id}&training=${training}`;
    return axios.get(url, { responseType: 'blob' })
      .then((res) => URL.createObjectURL(res.data))
      .catch((error) => {
        throw error;
      });
  }

  static getClientReport(id) {
    const url = `/client/pdf/report?id=${id}`;
    return axios.get(url, { responseType: 'blob' })
      .then((res) => URL.createObjectURL(res.data))
      .catch((error) => {
        throw error;
      });
  }

  static getClientEvents(id) {
    const url = `/client/schedule?id=${id}`;
    return axios.get(url).then((res) => res.data);
  }

  static updateClientStatus(id, status) {
    const url = `/client/updateStatus?id=${id}&active=${status}`;
    return axios.post(url)
      .catch((error) => {
        throw error;
      });
  }

  static getClientAdditionalInfo(id) {
    const url = `/client/additionalInfo/${id}`;
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewClientAdditionalInfo(clientId, text) {
    const url = '/client/additionalInfo';
    const data = {
      clientId,
      text,
    };

    return axios.post(url, data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteClientAdditionalInfo(infoId) {
    const url = `/client/additionalInfo/${infoId}`;
    return axios.delete(url)
      .catch((error) => {
        throw error;
      });
  }

  static putClientAdditionalInfo(info, update) {
    const url = '/client/additionalInfo';
    const data = {
      ...info,
      ...{ text: update },
    };

    return axios.put(url, data)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static updateClientById(id, clientData) {
    const data = {
      clientId: id,
      update: {
        ...clientData,
        address: {
          cep: clientData.cep,
          complement: clientData.complement || '',
          street: clientData.street,
          number: clientData.number,
          district: clientData.district,
          city: clientData.city,
          state: clientData.uf,
        },
      },
    };
    const url = '/client/clients';
    return axios.put(url, data)
      .catch((error) => {
        throw error;
      });
  }

  static updateProspectToClient(prospectId, clientData, biologicalSex) {
    const data = {
      clientData: {
        ...clientData,
        address: {
          cep: clientData.cep,
          complement: clientData.complement || '',
          street: clientData.street,
          number: clientData.number,
          district: clientData.district,
          city: clientData.city,
          state: clientData.uf,
        },
        biologicalSex,
      },
      prospectId,
    };
    const url = '/client/updateProspectToClient';
    return axios.post(url, data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteCient(clientId) {
    const url = `/client/${clientId}`;
    return axios.delete(url)
      .catch((error) => {
        throw error;
      });
  }

  static getClientInfoWarning() {
    const url = '/client/info/warning';
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getClientsFilteredByName(name, franchises = 0) {
    const url = `/client?filter=${name}&franchises=${franchises}`;
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getClientsAndProspectsFilteredByName(name) {
    const url = `/client/clientsAndProspects?filter=${name}`;
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getClientSubscriptions(clientId) {
    const url = `/client/subscription/${clientId}`;
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static getClientsLigth() {
    const url = '/client/basicInfoOnly';
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default ClientService;
