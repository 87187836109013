import PropTypes from 'prop-types';

export const EventTypes = {
  _id: PropTypes.string,
  room: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  confirmationStatus: PropTypes.string,
  client: PropTypes.object,
  professional: PropTypes.object,
};

export default EventTypes;
