/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../../components';
import {
  formatAsDate,
  formatAsDecimal,
} from '../helper';
import useStyles from './styles';
import AnalyticsManager from '../../../../../AnalyticsManager';

const columns = [
  null, 'Data prevista', 'Tipo', 'Descrição', 'Valor previsto',
  'Valor realizado', 'Data realizada', null,
];

const sortFunc = (a, b) => a.installmentDate - b.installmentDate;

const TableOfFinances = ({ data, setConfirmDialogData }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {
              columns
                .map((cell, index) => (
                  <TableCell
                    key={index.toString()}
                    className={`${classes.tableHeadItem} ${classes.tableText}`}
                    align={cell === 'Valor previsto' || cell === 'Valor realizado' ? 'right' : 'left'}
                  >
                    {cell}
                  </TableCell>
                ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (
              data.length
              && data.sort(sortFunc)
                .map((finance) => (
                  <TableRow key={finance._id}>
                    <TableCell className={`${classes.tableText} ${finance.type.type === 'entry' ? classes.entryText : classes.exitText}`}>
                      {finance.type.type === 'entry' ? 'E' : 'S'}
                    </TableCell>
                    <TableCell className={classes.tableText}>
                      {formatAsDate(finance.installmentDate)}
                    </TableCell>
                    <TableCell className={classes.tableText}>{(finance.type.identifier || '')}</TableCell>
                    <TableCell className={classes.tableText}>
                      <button
                        onClick={() => {
                          AnalyticsManager.track('Clinic_finances button_list_description clicked');
                          setConfirmDialogData({ open: true, selectedPayment: finance });
                        }}
                        type="button"
                        className="clickableText"
                        style={{
                          textAlign: 'left',
                          whiteSpace: 'nowrap',
                          overflowX: 'hidden',
                        }}
                      >
                        {finance.description || finance.comments}
                      </button>
                    </TableCell>
                    <TableCell
                      className={classes.tableText}
                      align="right"
                    >
                      {formatAsDecimal(finance.installmentValue, finance.type.type === 'entry')}
                    </TableCell>
                    <TableCell
                      className={classes.tableText}
                      align="right"
                    >
                      {formatAsDecimal(finance.paidValue || 0, finance.type.type === 'entry')}
                    </TableCell>
                    <TableCell
                      className={classes.tableText}
                    >
                      {finance.isCancelled ? 'Cancelado'
                        : (finance.paymentDate
                          ? formatAsDate(finance.paymentDate)
                          : finance.installmentDate < moment().valueOf()
                            ? <span className={classes.tableTextRed}>Atrasado</span>
                            : 'Não realizado')}
                    </TableCell>
                    <TableCell
                      className={classes.tableText}
                    >
                      <button
                        onClick={() => {
                          AnalyticsManager.track('Clinic_finances button_list_view_more clicked');
                          setConfirmDialogData({ open: true, selectedPayment: finance });
                        }}
                        type="button"
                        className="clickableText"
                      >
                        Ver mais
                      </button>
                    </TableCell>
                  </TableRow>
                )))
            || (
              <TableRow>
                <TableCell colSpan={columns.length} className={classes.tableText} align="center">
                  Nenhum registro encontrado
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableOfFinances;

TableOfFinances.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setConfirmDialogData: PropTypes.func.isRequired,
};
