/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import PropTypes from 'prop-types';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ClientProvider } from './ClientContex';
import { ViewProvider } from './ViewContext';
import HauxView from './components/HauxView';
import Schedule from './pages/schedule';
import NewClient from './pages/newClient';
import Dashboard from './pages/dashboard';
import PhysicalEducationForm from './pages/physicalEducationForm';
import NursingForm from './pages/nursingForm';
import PsychologyForm from './pages/psychologyForm';
import NutritionForm from './pages/nutritionForm';
import Login from './pages/login';
import Clinic from './pages/clinic';
import NewContent from './pages/company/newContent';
import Company from './pages/company';
import Meetings from './pages/meetings';
import Users from './pages/users';
import CreateUser from './pages/users/create';
import NutritionMeetings from './pages/meetings/meetingsPages/nutritionMeetings';
import PhysicalEducationMeetings from './pages/meetings/meetingsPages/physicalEducationMeetings/physicalEducationMeetings';
import NursingMeetings from './pages/meetings/meetingsPages/nursingMeetings';
import Plans from './pages/plans';
import PlansCreate from './pages/plansPages/create';
import PlansTable from './pages/plansPages';
import Reception from './pages/reception';
import HauxMoreLife from './pages/hauxMoreLife';
import Training from './pages/training';
import TherapyMeeting from './pages/therapyMeeting';
import Chat from './pages/chat';
import CreateMeal from './pages/company/tabs/meals/create';
import CreatePartners from './pages/company/tabs/partners/create';
import { isAuthenticated, isPermission, getCurrentUser } from './utils/Utils';

const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#3E2782',
      main: '#685DA8',
      light: '#CBC3F7',
    },
    typography: {
      button: {
        fontSize: '2rem',
      },
      fontFamily: ['Graviola', 'GraviolaSoft', 'Roboto'].join(','),
    },
  },
});

const HomePage = () => {
  const user = getCurrentUser();
  if (user) {
    if (user.profileType === 'Admin' || user.profileType === 'Recepcionista') {
      return <Redirect to="/reception" />;
    }
    if (user.profileType === 'Coordenador') {
      return <Redirect to="/clinic/clients" />;
    }
    if (user.profileType === 'Psicólogo') {
      return <Redirect to="/hauxMoreLife" />;
    }

    return <Redirect to="/meetings" />;
  }

  return <Redirect to="/login" />;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    onUpdate={() => new Array(...document.getElementsByTagName('div')).forEach((e) => e.scrollTo(0, 0))}
    render={(props) => {
      new Array(...document.getElementsByTagName('div')).forEach((e) => e.scrollTo(0, 0));
      if (props.match.path === '/') {
        return HomePage();
      }
      return isAuthenticated() ? (
        isPermission(props) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      );
    }}
  />
);

function App() {
  return (
    <div className="appBackground">
      <ClientProvider>
        <ViewProvider>
          <ThemeProvider theme={theme}>
            <HauxView>
              <Switch>
                <PrivateRoute path="/" exact component={Reception} />
                <Route path="/login" exact component={Login} />
                <PrivateRoute path="/reception" exact component={Reception} />
                <PrivateRoute path="/client/newClient" component={NewClient} />
                <PrivateRoute path="/client/dashboard" component={Dashboard} />
                <PrivateRoute
                  path="/client/physicalEducationConsult"
                  component={PhysicalEducationForm}
                />
                <PrivateRoute
                  path="/client/nutritionConsult"
                  component={NutritionForm}
                />
                <PrivateRoute
                  path="/client/nursingConsult"
                  component={NursingForm}
                />
                <PrivateRoute
                  path="/client/psychologyConsult"
                  component={PsychologyForm}
                />
                <PrivateRoute path="/schedule" component={Schedule} />
                <PrivateRoute path="/newContent" component={NewContent} />
                <PrivateRoute path="/company" component={Company} />
                <PrivateRoute path="/clinic" component={Clinic} />
                <PrivateRoute path="/meetings" exact component={Meetings} />
                <PrivateRoute
                  path="/meetings/nutrition"
                  component={NutritionMeetings}
                />
                <PrivateRoute
                  path="/meetings/physicalEducation"
                  component={PhysicalEducationMeetings}
                />
                <PrivateRoute
                  path="/meetings/nursing"
                  component={NursingMeetings}
                />
                <PrivateRoute path="/plans/create" component={PlansCreate} />
                <PrivateRoute path="/plans/table" component={PlansTable} />
                <PrivateRoute path="/plans" component={Plans} />
                <PrivateRoute path="/hauxMoreLife" component={HauxMoreLife} />
                <PrivateRoute path="/training" component={Training} />
                <PrivateRoute
                  path="/therapyMeeting"
                  component={TherapyMeeting}
                />
                <PrivateRoute path="/meals/create" component={CreateMeal} />
                <PrivateRoute
                  path="/partners/create"
                  component={CreatePartners}
                />
                <PrivateRoute path="/users/create" component={CreateUser} />
                <PrivateRoute path="/users" component={Users} />
                <Route path="/chat/:encodedId" component={Chat} />
              </Switch>
            </HauxView>
          </ThemeProvider>
        </ViewProvider>
      </ClientProvider>
    </div>
  );
}

export default App;

PrivateRoute.propTypes = {
  Component: PropTypes.func,
};

PrivateRoute.defaultProps = {
  Component: () => <></>,
};
