/* eslint-disable react/forbid-prop-types */
import React from 'react';
import './hauxModal.scss';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import icClose from '../../assets/svgs/ic-close.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxWidth: 768,
    minWidth: 768,
    maxHeight: 500,
    minHeight: 500,
    boxShadow: theme.shadows[5],
    padding: 32,
  },
  titleModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    marginTop: 16,
  },
  boxSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));

const HauxModal = ({
  open, handleClose, title, subtitle, children,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <div className={classes.iconContainer}>
              <Button onClick={handleClose}>
                <img src={icClose} alt="" />
              </Button>
            </div>
            <Grid className={classes.titleModal}>
              <span className="h4Style">{title}</span>
              <span className="paragraph">
                {subtitle}
              </span>
            </Grid>
            { children }
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

HauxModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

HauxModal.defaultProps = {
  open: false,
  handleClose: () => {},
  title: '',
  subtitle: '',
  children: <></>,
};

export default HauxModal;
