import pdfs from '../../../../assets/pdfs';

const {
  edFisicoPdf1, edFisicoPdf2, edFisicoPdf3, edFisicoPdf4,
  edFisicoPdf5, edFisicoPdf6, edFisicoPdf7,
  edFisicoPdf8, edFisicoPdf9,
} = pdfs;
const COMPOSICAO_COMPORAL = 'Composição Corporal';

const MEETINGS = [
  { number: 1, name: 'Avaliações e Metas', pdf: edFisicoPdf1 },
  { number: 2, name: 'Resolução de Problemas', pdf: edFisicoPdf2 },
  { number: 3, name: COMPOSICAO_COMPORAL, pdf: edFisicoPdf3 },
  { number: 4, name: 'Reduzindo o tempo sedentário', pdf: edFisicoPdf4 },
  { number: 5, name: COMPOSICAO_COMPORAL, pdf: edFisicoPdf5 },
  { number: 6, name: 'O músculo é a nossa maior glândula', pdf: edFisicoPdf6 },
  { number: 7, name: COMPOSICAO_COMPORAL, pdf: edFisicoPdf7 },
  { number: 8, name: COMPOSICAO_COMPORAL, pdf: edFisicoPdf8 },
  { number: 9, name: COMPOSICAO_COMPORAL, pdf: edFisicoPdf9 },
];

export default MEETINGS;
