/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Clear";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { path } from "ramda";
import _ from "lodash";
import { HAUXTextField } from "../../../components/HauxInput";
import HAUXButton from "../../../components/HauxButton";
import ScheduleService from "../../../service/ScheduleService";
import AnalyticsManager from "../../../AnalyticsManager";
import { getCurrentUser, moreLifeMeetings } from "../../../utils/Utils";
import strings from "../../../assets/strings.json";
import ViewContext from "../../../ViewContext";

const {
  ARE_YOU_SURE,
  ACTION_DELETE_THE_SELECTED_EVENT,
  HAUX_TRAINING,
  HAUX_MULTISPACE,
  HAUX_TOUR,
  FIELD_REQUIRED,
  PROFESSIONALS_SCHEDULE,
} = strings;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Graviola",
    "& .MuiTypography-root": {
      fontFamily: "Graviola",
    },
  },
  vagas: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  picker: {
    marginTop: 10,
    "& input": {
      fontFamily: "Graviola",
      color: "#737373",
      "&::placeholder": {
        fontFamily: "Graviola",
      },
    },
    "& label": {
      fontFamily: "Graviola",
      fontSize: 16,
    },
    "& label.Mui-focused": {
      color: "#685DA8",
    },
  },
  option: {
    fontFamily: "Graviola",
    fontSize: 14,
    color: "#737373",
    mixBlendMode: "normal",
  },
}));

const NewEvent = ({
  open,
  onCloseClick,
  isTraining,
  isTour,
  isMoreLife,
  clients,
  prospects,
  professionals,
  client,
  multiple,
  date,
  event,
  onDateChange,
  reloadClients,
}) => {
  const { setSelected, setSnackbarOption, handleRequestsErrors } = useContext(
    ViewContext
  );
  const history = useHistory();
  const classes = useStyles();
  const user = getCurrentUser();
  const theme = useTheme();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [saving, setSaving] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [currentTime, setCurrentTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());
  const [currentClient, setCurrentClient] = useState("");
  const [currentFranchise, setCurrentFranchise] = useState("");
  const [franchiseList, setFranchiseList] = useState([]);
  const [currentSpecialty, setCurrentSpecialty] = useState("");
  const [observations, setObservations] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [consultNumber, setConsultNumber] = useState("");
  const [currentProfessional, setCurrentProfessional] = useState("");
  const [errors, setErrors] = useState({});
  const [remaining, setRemaining] = useState({});
  const SALAS_ATENDIMENTO = {
    Nutricionista: "HAUX Nutri",
    "Educadora(o) Físico": "HAUX Fit",
    "Psicologia Cognitiva": "HAUX Multispace",
    "Enfermeira(o)": "HAUX Tech",
  };

  const validateClient = () => {
    if (multiple) {
      return true;
    }
    let clientErrorState = null;
    let result = true;
    if (!currentClient) {
      result = false;
      clientErrorState = FIELD_REQUIRED;
    }
    setErrors({
      ...errors,
      client: clientErrorState,
    });

    return result;
  };

  const validateFranchise = () => {
    let franchiseErrorState = null;
    let result = true;
    if (_.isEmpty(currentFranchise)) {
      result = false;
      franchiseErrorState = FIELD_REQUIRED;
    }
    setErrors({
      ...errors,
      franchise: franchiseErrorState,
    });

    return result;
  };

  const validateSpecialty = () => {
    let specialtyErrorState = null;
    let result = true;
    if (!currentSpecialty) {
      result = false;
      specialtyErrorState = FIELD_REQUIRED;
    }
    setErrors({
      ...errors,
      specialty: specialtyErrorState,
    });

    return result;
  };

  const validateDate = () => {
    const today = moment();
    today.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let dateErrorState = null;
    let result = true;
    if (!currentDate) {
      result = false;
      dateErrorState = FIELD_REQUIRED;
    } else if (moment(currentDate).isBefore(today)) {
      dateErrorState = "Data inválida";
      result = false;
    }

    setErrors({
      ...errors,
      date: dateErrorState,
    });

    return result;
  };

  const validateTime = (type) => {
    const now = moment();
    let resultTime = null;
    let timeErrorState = null;
    let result = true;
    if (type === "end") {
      resultTime = endTime;
    } else {
      resultTime = currentTime;
    }
    if (!resultTime) {
      result = false;
      timeErrorState = FIELD_REQUIRED;
    } else {
      const selectedDate = moment(currentDate);
      const time = moment(resultTime);

      selectedDate.set({
        hour: time.hour(),
        minute: time.minute(),
        second: 0,
        millisecond: 0,
      });

      if (selectedDate.isBefore(now)) {
        timeErrorState = "Hora inválida";
        result = false;
      }

      if (
        endTime &&
        endTime.hour &&
        ((endTime.hour() === currentTime.hour() &&
          endTime.minute() <= currentTime.minute()) ||
          endTime.hour() < currentTime.hour())
      ) {
        timeErrorState = "Hora de término deve ser maior que a hora de início";
        result = false;
      }
    }

    setErrors({
      ...errors,
      endTime: timeErrorState,
    });

    return result;
  };

  const validateConsultNumber = () => {
    let consultNumberError = null;
    let result = true;
    if (!consultNumber) {
      result = false;
      consultNumberError = FIELD_REQUIRED;
    }
    setErrors({
      ...errors,
      consultNumber: consultNumberError,
    });

    return result;
  };

  const schedule = async () => {
    AnalyticsManager.track("ScheduleNewEvent button_create clicked");
    let validForm = true;
    validForm = validForm && validateClient();
    if (!isTour) {
      validForm = validForm && validateFranchise();

      if (!isTraining) {
        validForm = validForm && validateSpecialty() && validateConsultNumber();
      }
    }
    validForm = validForm && validateDate();
    validForm = validForm && validateTime();

    if (validForm) {
      setSaving(true);

      try {
        const start = moment(currentDate);
        const end = moment(currentDate);
        const startTime = moment(currentTime);
        const endTimeSelected = moment(endTime);

        start.set({
          hour: startTime.hour(),
          minute: startTime.minute(),
          second: 0,
          millisecond: 0,
        });
        end.set({
          hour: endTimeSelected.hour(),
          minute: endTimeSelected.minute(),
          second: 0,
          millisecond: 0,
        });
        const clientId = currentClient
          ? !isTour
            ? currentClient.clientId
              ? currentClient.clientId
              : currentClient._id
            : currentClient.prospectId
            ? currentClient.prospectId
            : currentClient._id
          : undefined;
        let room = SALAS_ATENDIMENTO[currentSpecialty];
        if (isTraining) {
          room = HAUX_TRAINING;
        } else if (isTour) {
          room = HAUX_MULTISPACE;
        }

        localStorage.setItem("last_event_time", start.format("HH:mm"));
        await ScheduleService.postNewEvent({
          clientId,
          participants: multiple
            ? (currentClient || []).map((c) => c.clientId)
            : null,
          room,
          start: start.format(),
          end: end.format(),
          isTour,
          consultNumber,
          observations,
          franchise: currentFranchise._id,
          professionalId: currentProfessional
            ? currentProfessional._id
            : undefined,
        });
        AnalyticsManager.track("Create EventInSchedule");
        setConsultNumber("");
        setSaving(false);
        setSnackbarOption({
          open: true,
          type: "success",
          message: "Agendamento efetuado com sucesso",
        });
        onDateChange(currentDate, clientId, room);
        await reloadClients();
      } catch (error) {
        localStorage.removeItem("last_event_time");
        let errorMessage = "Erro ao efetuar agendamento";
        let action = null;

        const reason = path(["response", "data", "reasons"], error);

        switch (reason) {
          case "no.events.remaining":
            errorMessage = `O Cliente ${
              currentClient ? currentClient.name || "" : ""
            } já possui todos os agendamentos para esta especialidade.`;
            action = (
              <HAUXButton
                text="Comprar encontros"
                type="snackbarButton"
                onClick={() => {
                  setSelected(4);
                  history.push("/plans");
                  setSnackbarOption({ open: false });
                }}
              />
            );
            break;

          case "client.already.has.event":
            errorMessage = `O Cliente ${
              currentClient ? currentClient.name || "" : ""
            } já possui agendamento na data e hora selecionadas`;
            break;

          case "professional.already.has.event":
            errorMessage =
              "O profissional já possui agendamento na data e hora selecionadas";
            break;

          case "room.already.has.event":
            errorMessage =
              "A sala já possui agendamento na data e hora selecionadas";
            break;

          case "full.schedule":
            errorMessage = "Capacidade máxima da sala atingida neste horário";
            break;

          default:
            break;
        }

        setSaving(false);
        handleRequestsErrors(error, {
          message: errorMessage,
          action,
        });
      }
    }
  };

  const confirmationEvent = async (clientId, status) => {
    setSaving(true);
    try {
      localStorage.setItem("last_event_time", currentTime.format("HH:mm"));
      await ScheduleService.postConfirmStatus(event._id, clientId, status);
      setSnackbarOption({
        open: true,
        type: "success",
        message: `${
          status === "Presente" ? "Presença" : "Ausência"
        } confirmada com sucesso!`,
      });
      onDateChange(currentDate);
    } catch (e) {
      localStorage.removeItem("last_event_time");
      handleRequestsErrors(e, {
        message: event._id
          ? `Erro ao confirmar ${
              status === "Presente" ? "presença" : "ausência"
            }`
          : `Salve o evento antes de confirmar as ${
              status === "Presente" ? "presenças" : "ausências"
            }`,
      });
    }
    setSaving(false);
  };

  const changeParticipants = async () => {
    setSaving(true);
    try {
      localStorage.setItem("last_event_time", currentTime.format("HH:mm"));
      await ScheduleService.postParticipants(
        event._id,
        currentClient.map((p) => p.clientId || p._id)
      );
      setSnackbarOption({
        open: true,
        type: "success",
        message: "Participantes atualizados com sucesso!",
      });
      onDateChange(currentDate);
    } catch (e) {
      localStorage.removeItem("last_event_time");
      handleRequestsErrors({
        message: "Erro ao atualizar participantes!",
      });
    }
    setSaving(false);
  };

  const deleteEvent = async () => {
    setOpenDeleteDialog(false);
    setSaving(true);
    let error = false;
    try {
      await ScheduleService.deleteEvent(event._id);
    } catch (e) {
      error = true;
    }
    onDateChange(currentDate);
    setSaving(false);
    setSnackbarOption({
      open: true,
      type: error ? "error" : "success",
      message: error
        ? "Erro ao deletar evento"
        : "Evento deletado com sucesso.",
    });
    await reloadClients();
  };

  useEffect(() => {
    setCurrentSpecialty(null);
    setCurrentProfessional(null);
    setOpenDeleteDialog(false);
    if (multiple) {
      setCurrentClient(null);
      setCurrentFranchise("");
    }
  }, [open]);

  useEffect(() => {
    if (!multiple) {
      setCurrentClient(client);
    }
  }, [client]);

  useEffect(() => {
    const newFranchises = multiple ? user.franchises : [];
    try {
      if (!multiple && currentClient && currentClient.franchises) {
        currentClient.franchises.forEach((franchise) => {
          if (
            user.franchises.filter(
              (f) => f === franchise._id || f._id === franchise._id
            ).length > 0
          ) {
            newFranchises.push(franchise);
          }
        });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    setFranchiseList(newFranchises);
    if (event && event._id) {
      setCurrentFranchise(event.franchise);
    } else if (newFranchises.length === 1) {
      setCurrentFranchise(newFranchises[0]);
    } else if (!multiple) {
      setCurrentFranchise("");
    }

    if (!_.isEmpty(currentClient)) {
      const clientId = _.isArray(currentClient)
        ? currentClient[currentClient.length - 1].clientId
        : currentClient.clientId;
      ScheduleService.getRemainingClientConsultations(clientId)
        .then((data) => {
          setRemaining(data);
        })
        .catch(() => {
          setRemaining({});
        });
    }
  }, [currentClient]);

  useEffect(() => {
    setCurrentDate(date);
    setCurrentTime(date);
    if (event._id) {
      setEndTime(event.end);
    } else {
      const end = moment(date);
      end.add(isTraining ? 60 : 30, "minutes").format();
      setEndTime(end);
    }
  }, [date]);

  useEffect(() => {
    if (event.client || event.participants) {
      setCurrentClient(event.client || event.participants);
    }
    setCurrentSpecialty(
      event.professional
        ? event.professional.type
        : isMoreLife
        ? "Psicologia Cognitiva"
        : null
    );
    setConsultNumber(event.consultNumber ? event.consultNumber : null);
    setCurrentProfessional(event.professional);
    if (event.observations) {
      setObservations(event.observations);
    }
  }, [event]);

  useEffect(() => {
    if (isTraining) {
      setSpecialties(["Educadora(o) Físico"]);
      return;
    }

    if (isTour) {
      setSpecialties(["Psicologia Cognitiva"]);
      return;
    }

    let filteredSpecialties = professionals
      .filter(
        (p) =>
          !(
            p.serviceRooms.length === 1 &&
            (p.serviceRooms.includes(HAUX_TOUR) ||
              p.serviceRooms.includes(HAUX_MULTISPACE))
          )
      )
      .map((p) => p.type);

    filteredSpecialties = [...new Set(filteredSpecialties)];

    setSpecialties(filteredSpecialties);
  }, [professionals, isTraining, isTour]);

  useEffect(() => {
    validateDate();
    validateTime();
  }, [currentDate, endTime]);

  const removeClient = (clientId) => {
    setCurrentClient(
      currentClient.filter((c) => clientId !== (c.clientId || c._id))
    );
  };

  const filteredClients = useCallback(() => {
    try {
      if (multiple && currentClient) {
        return (clients || []).filter(
          (c) =>
            currentClient.filter((ct) => c.clientId === (ct.clientId || ct._id))
              .length === 0
        );
      }

      return clients;
    } catch (e) {
      return [];
    }
  }, [clients, currentClient]);

  const getAvailablesProfessionails = useCallback(() => {
    if (currentSpecialty) {
      return professionals.filter((p) => p.type === currentSpecialty);
    }

    return [];
  }, [professionals, currentSpecialty]);

  const getParticipantStatus = (participant, e) => {
    const participantsStatus = e.participantsStatus || [];
    const filteredStatus = participantsStatus.filter(
      (s) => s.client === (participant.clientId || participant._id)
    );

    return filteredStatus.length === 0
      ? "Não confirmado"
      : filteredStatus[0].status;
  };

  const filterPossibleConsults = () => {
    const OTHER_NAME = "Outro";
    const EVALUATION = "Avaliação";
    if (currentClient) {
      switch (currentSpecialty) {
        case PROFESSIONALS_SCHEDULE.NURSE:
          return [
            ...(currentClient.possibleNursingConsults || []).filter(
              (p) => p !== OTHER_NAME
            ),
            OTHER_NAME,
            EVALUATION,
          ];
        case PROFESSIONALS_SCHEDULE.NUTRITIONIST:
          return [
            ...(currentClient.possibleNutritionistConsults || []).filter(
              (p) => p !== OTHER_NAME
            ),
            OTHER_NAME,
          ];
        case PROFESSIONALS_SCHEDULE.PHYSICAL_EDUCATOR:
          return [
            ...(currentClient.possiblePhysicalEducatorConsults || []).filter(
              (p) => p !== OTHER_NAME
            ),
            OTHER_NAME,
          ];
        default:
          return [
            ...(currentClient.possiblePsycologistConsults || []).filter(
              (p) => p !== OTHER_NAME
            ),
            OTHER_NAME,
          ];
      }
    }
    return [];
  };

  const renderClientInput = () => (
    <div>
      <Autocomplete
        multiple={multiple}
        options={!isTour ? filteredClients() : prospects}
        getOptionLabel={(option) => option.name || ""}
        id="clientes"
        classes={{
          margin: 0,
          option: classes.option,
        }}
        disabled={
          (!!event._id && !isMoreLife && !isTraining) ||
          (isMoreLife && (currentClient || []).length >= 15) ||
          (isTraining && (currentClient || []).length >= 10)
        }
        value={multiple && !currentClient ? [] : multiple ? [] : currentClient}
        onChange={(e, newValue) => {
          if (multiple) {
            setCurrentClient([...newValue, ...(currentClient || [])]);
          } else {
            setCurrentClient(newValue);
          }
        }}
        renderInput={(params) => (
          <HAUXTextField
            {...params}
            label={`Selecione o ${isTour ? "prospect" : "cliente"}`}
            fullWidth
            error={errors.client && !event._id}
            helperText={!event._id ? errors.client : ""}
            onBlur={() => validateClient()}
          />
        )}
      />
      <List>
        {multiple &&
          !_.isEmpty(currentClient) &&
          currentClient.map((participant, index) => {
            const status = getParticipantStatus(participant, event);
            return (
              <ListItem
                divider
                className={classes.footer}
                style={{ marginTop: index === 0 ? 40 : 0, padding: 0 }}
                key={participant.clientId}
              >
                <span className="h6NormalStyle">{participant.name}</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {status !== "Ausente" && (
                    <HAUXButton
                      text={
                        status === "Presente"
                          ? "Presença confirmada"
                          : "Confirmar presença"
                      }
                      disabled={status === "Presente"}
                      onClick={() => {
                        AnalyticsManager.track(
                          "ScheduleNewEvent button_confirm_presence clicked"
                        );
                        confirmationEvent(
                          participant.clientId || participant._id,
                          "Presente"
                        );
                      }}
                    />
                  )}
                  {status !== "Presente" && (
                    <HAUXButton
                      text={
                        status === "Ausente"
                          ? "Ausência confirmada"
                          : "Confirmar ausência"
                      }
                      disabled={status === "Ausente"}
                      className="destructive"
                      type="secondary"
                      style={{ margin: 10 }}
                      onClick={() => {
                        AnalyticsManager.track(
                          "ScheduleNewEvent button_confirm_absence clicked"
                        );
                        confirmationEvent(
                          participant.clientId || participant._id,
                          "Ausente"
                        );
                      }}
                    />
                  )}
                  <IconButton
                    style={{ width: 50, height: 50 }}
                    onClick={() =>
                      removeClient(participant.clientId || participant._id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </ListItem>
            );
          })}
      </List>
    </div>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseClick}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        aria-labelledby="new-event-dialog-title"
      >
        <DialogTitle id="new-event-dialog-title" className={classes.title}>
          {event._id ? "Agendamento" : "Novo Agendamento"}
        </DialogTitle>
        {(event.room === HAUX_MULTISPACE || event.room === HAUX_TRAINING) &&
          !isTour && (
            <DialogTitle className={[classes.title, classes.vagas]}>
              {`${Math.max(
                0,
                (event.room === HAUX_TRAINING ? 10 : 15) -
                  (currentClient || []).length
              )} vagas restantes`}
            </DialogTitle>
          )}
        <DialogContent>
          <Grid container direction="column">
            {!multiple && <Grid item>{renderClientInput()}</Grid>}
            {(currentClient || multiple) && franchiseList.length > 1 && (
              <Grid container direction="column">
                <Grid item>
                  <Autocomplete
                    options={franchiseList}
                    getOptionLabel={(option) => option.name || ""}
                    id="franchise"
                    classes={{
                      margin: 0,
                      option: classes.option,
                    }}
                    disabled={!!event._id}
                    value={currentFranchise}
                    onChange={(e, newValue) => setCurrentFranchise(newValue)}
                    renderInput={(params) => (
                      <HAUXTextField
                        {...params}
                        label="Selecione a franquia"
                        fullWidth
                        error={errors.franchise && !event._id}
                        helperText={!event._id ? errors.client : ""}
                        onBlur={() => validateFranchise()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={1}>
              <Grid item xs>
                {!isTraining && !isTour && !isMoreLife ? (
                  <Autocomplete
                    options={specialties}
                    getOptionLabel={(option) => option || ""}
                    id="especialidades"
                    classes={{
                      margin: 0,
                      option: classes.option,
                    }}
                    disabled={!!event._id}
                    value={currentSpecialty}
                    onChange={(e, newValue) => setCurrentSpecialty(newValue)}
                    renderInput={(params) => (
                      <HAUXTextField
                        {...params}
                        label="Selecione a especialidade"
                        fullWidth
                        error={errors.specialty && !event._id}
                        helperText={!event._id ? errors.specialty : ""}
                        onBlur={() => validateSpecialty()}
                      />
                    )}
                  />
                ) : null}
                {!_.isEmpty(currentClient) &&
                  !_.isEmpty(currentSpecialty) &&
                  !event._id && (
                    <Grid item xs>
                      <span className="hintText">
                        {`${
                          remaining[SALAS_ATENDIMENTO[currentSpecialty]]
                        } agendamentos restantes para ${currentSpecialty.toLowerCase()}`}
                      </span>
                    </Grid>
                  )}
              </Grid>
              <Grid item xs={isMoreLife ? 12 : 3}>
                {!isTraining && !isTour ? (
                  <Autocomplete
                    options={
                      isMoreLife
                        ? moreLifeMeetings.map((m) => m.theme)
                        : filterPossibleConsults()
                    }
                    getOptionLabel={(option) => option || ""}
                    id="numero da consulta"
                    classes={{
                      margin: 0,
                      option: classes.option,
                    }}
                    disabled={!currentSpecialty || !!event._id}
                    value={consultNumber}
                    onChange={(e, newValue) => setConsultNumber(newValue)}
                    renderInput={(params) => (
                      <HAUXTextField
                        {...params}
                        label="Encontro"
                        error={errors.consultNumber && !event._id}
                        helperText={!event._id ? errors.consultNumber : ""}
                        onBlur={() => validateConsultNumber()}
                        fullWidth
                      />
                    )}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              {getAvailablesProfessionails().length > 1 && (
                <Autocomplete
                  options={getAvailablesProfessionails()}
                  getOptionLabel={(option) => option.name || ""}
                  id="professionals"
                  classes={{
                    margin: 0,
                    option: classes.option,
                  }}
                  value={currentProfessional}
                  disabled={!!event._id}
                  onChange={(e, newValue) => {
                    setCurrentProfessional(newValue);
                  }}
                  renderInput={(params) => (
                    <HAUXTextField
                      {...params}
                      label="Selecione o profissional"
                      fullWidth
                      onBlur={() => validateClient()}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justify="space-around" spacing={2}>
                  <Grid item xs={4}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      label="Data"
                      format="DD/MM/YYYY"
                      InputAdornmentProps={{ position: "end" }}
                      fullWidth
                      disabled={!!event._id}
                      value={currentDate}
                      onChange={(d) => setCurrentDate(moment(d))}
                      className={classes.picker}
                      error={errors.date && !event._id}
                      helperText={!event._id ? errors.date : ""}
                      onBlur={() => {
                        validateDate();
                        validateTime();
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePicker
                      label="Hora de início"
                      mask="__:__"
                      ampm={false}
                      disabled={!!event._id}
                      value={currentTime}
                      onChange={(d) => setCurrentTime(moment(d))}
                      fullWidth
                      className={classes.picker}
                      error={errors.time && !event._id}
                      helperText={!event._id ? errors.time : ""}
                      onBlur={() => validateTime()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePicker
                      label="Hora de término"
                      mask="__:__"
                      ampm={false}
                      disabled={!!event._id}
                      value={endTime}
                      onChange={(d) => setEndTime(moment(d))}
                      fullWidth
                      className={classes.picker}
                      error={errors.endTime && !event._id}
                      helperText={!event._id ? errors.endTime : ""}
                      onBlur={() => validateTime("end")}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item style={{ marginBottom: 10, marginTop: 10 }} spacing={2}>
              <TextField
                label="Observações"
                value={observations}
                multiline
                disabled={!!event._id}
                onChange={(event) => setObservations(event.target.value)}
                fullWidth
              />
            </Grid>
            {multiple && <Grid item>{renderClientInput()}</Grid>}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <HAUXButton
            text={event._id ? "Fechar" : "Cancelar"}
            variant="outlined"
            onClick={onCloseClick}
          />
          {event._id ? (
            <div style={{ display: "flex" }}>
              <HAUXButton
                text={`Excluir${isMoreLife ? " encontro" : ""}`}
                className="destructive"
                type="tertiary"
                onClick={() => {
                  AnalyticsManager.track(
                    "ScheduleNewEvent button_delete_event clicked"
                  );
                  setOpenDeleteDialog(true);
                }}
              />
              {!multiple && event.confirmationStatus !== "Presente" && (
                <HAUXButton
                  text={
                    event.confirmationStatus === "Ausente"
                      ? "Ausência confirmada"
                      : "Confirmar ausência"
                  }
                  disabled={event.confirmationStatus === "Ausente"}
                  className="destructive"
                  type="secondary"
                  style={{ margin: 10 }}
                  onClick={() => {
                    AnalyticsManager.track(
                      "ScheduleNewEvent button_confirm_absence clicked"
                    );
                    confirmationEvent(
                      currentClient.clientId || currentClient._id,
                      "Ausente"
                    );
                  }}
                />
              )}
              {!multiple && event.confirmationStatus !== "Ausente" && (
                <HAUXButton
                  text={
                    event.confirmationStatus === "Presente"
                      ? "Presença confirmada"
                      : "Confirmar presença"
                  }
                  disabled={event.confirmationStatus === "Presente"}
                  onClick={() => {
                    AnalyticsManager.track(
                      "ScheduleNewEvent button_confirm_presence clicked"
                    );
                    confirmationEvent(
                      currentClient.clientId || currentClient._id,
                      "Presente"
                    );
                  }}
                />
              )}
              {multiple && (
                <HAUXButton
                  text="Salvar"
                  onClick={() => {
                    AnalyticsManager.track(
                      "ScheduleNewEvent button_save clicked"
                    );
                    changeParticipants();
                  }}
                />
              )}
            </div>
          ) : (
            <HAUXButton text="Agendar" onClick={schedule} />
          )}
        </DialogActions>
        <Backdrop className={classes.backdrop} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog open={openDeleteDialog}>
          <DialogTitle className={classes.title}>{ARE_YOU_SURE}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.title}>
              {ACTION_DELETE_THE_SELECTED_EVENT}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.footer}>
            <HAUXButton
              type="secondary"
              text="Não"
              onClick={() => setOpenDeleteDialog(false)}
            />
            <HAUXButton
              type="primary"
              text="Sim, excluir"
              onClick={deleteEvent}
            />
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
};

export default NewEvent;

NewEvent.propTypes = {
  date: PropTypes.number,
  event: PropTypes.object,
  client: PropTypes.object,
  clients: PropTypes.array,
  prospects: PropTypes.array,
  professionals: PropTypes.array,
  isTraining: PropTypes.bool,
  isTour: PropTypes.bool,
  isMoreLife: PropTypes.bool,
  open: PropTypes.bool,
  multiple: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onDateChange: PropTypes.func,
  reloadClients: PropTypes.func.isRequired,
};

NewEvent.defaultProps = {
  date: 0,
  event: null,
  client: null,
  clients: [],
  prospects: [],
  professionals: [],
  isTraining: false,
  isTour: false,
  isMoreLife: false,
  open: false,
  multiple: false,
  onCloseClick: () => {},
  onDateChange: () => {},
};
