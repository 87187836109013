import axios from "../middlewares/axios";

class PlanService {
  static getPlans() {
    const url = "plan/plans";
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static subscribePlans(clientId, items, paymentOptions) {
    const url = "plan/subscribe";
    return axios
      .post(url, {
        clientId,
        items,
        paymentOptions,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static unsubscribe(subscriptionId) {
    const url = `plan/unsubscribe/${subscriptionId}`;
    return axios
      .post(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getPaginatedPlans(page, searchText, orderBy, order) {
    const url = `plan/plansPaginated?page=${page}&filter=${searchText}&orderBy=${orderBy}&order=${order}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static putPlan(plan) {
    const url = `plan/plan`;
    return axios
      .put(url, plan)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postPlan(newPlan) {
    const url = `plan/plan`;
    return axios
      .post(url, newPlan)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getPlan(planId) {
    const url = `plan/plan/${planId}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static deletePlan(planId) {
    const url = `plan/plan/${planId}`;
    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default PlanService;
