import React, { useContext, useEffect, useCallback } from 'react';
import {
  Route, Switch, useRouteMatch, useLocation,
} from 'react-router-dom';
import ViewContext from '../../ViewContext';
import TrainingMeetings from './trainingMeetings';
import FollowUp from './followUp';
import Gallery from './gallery';
import AnalyticsManager from '../../AnalyticsManager';

const Training = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const { setRouteProperties } = useContext(ViewContext);

  useEffect(() => {
    AnalyticsManager.track('View Haux Training');
  }, []);

  const getTitle = useCallback(() => {
    if (location.pathname.includes('/gallery')) {
      return 'Galeria de Vídeos';
    }
    if (location.pathname.includes('/followUp')) {
      return 'Acompanhamento de Treinos';
    }
    return 'Haux Training';
  }, [location.pathname]);

  useEffect(() => {
    setRouteProperties({
      pageTitle: getTitle(),
      breadcrumbs: location.pathname === '/training' ? undefined : 'Haux Training',
    });
  }, [setRouteProperties, getTitle, location.pathname]);

  return (
    <Switch>
      <Route path={`${match.url}`} exact component={TrainingMeetings} />
      <Route path={`${match.url}/gallery`} component={Gallery} />
      <Route path={`${match.url}/followUp`} component={FollowUp} />
    </Switch>
  );
};

export default Training;
