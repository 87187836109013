import { foodQuestions } from '../../utils/Utils';

export const initialState = {
  exitFormDialog: false,
  rerender: false,
  numberMeal: '4',
  foodIntolerance: [],
  foodPreference: '',
  foodSpeed: '',
  intestinalFunction: '',
  symptoms: [],
  foodAlergie: '',
  foodDiet: '',
  wakeUp: '',
  wakeUpToEat: '',
  foodFrequency: [],
  foodLiquid: [],
  wakeUpInput: '',
  sleepIn: '',
  hoursOfSleep: '',
  hoursOfSleepInWeekend: '',
  sleepDescription: '',
  evaluationFrequency: '',
  questions: foodQuestions,
  alergieDescription: '',
  alergieDescriptionError: false,
  alergieDescriptionMsg: '',
  dietDescription: '',
  dietDescriptionError: false,
  dietDescriptionMsg: '',
  alcoholicBeverages: '',
  saveFormClicked: false,
  whoCooksAtHome: '',
  stomachacheWhatFoods: '',
  formCompleted: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EXIT_FORM_DIALOG':
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case 'RERENDER':
      return {
        ...state,
        rerender: action.payload,
      };
    case 'NUMBER_MEAL':
      return {
        ...state,
        numberMeal: action.payload,
      };
    case 'FOOD_INTOLERANCE':
      return {
        ...state,
        foodIntolerance: action.payload,
      };
    case 'FOOD_PREFERENCE':
      return {
        ...state,
        foodPreference: action.payload,
      };
    case 'FOOD_SPEED':
      return {
        ...state,
        foodSpeed: action.payload,
      };
    case 'INTESTINAL_FUNCTION':
      return {
        ...state,
        intestinalFunction: action.payload,
      };
    case 'SYMPTOMS':
      return {
        ...state,
        symptoms: action.payload,
      };
    case 'FOOD_ALERGIE':
      return {
        ...state,
        foodAlergie: action.payload,
      };
    case 'FOOD_DIET':
      return {
        ...state,
        foodDiet: action.payload,
      };
    case 'WAKE_UP':
      return {
        ...state,
        wakeUp: action.payload,
      };
    case 'WAKE_UP_TO_EAT':
      return {
        ...state,
        wakeUpToEat: action.payload,
      };
    case 'FOOD_FREQUENCY':
      return {
        ...state,
        foodFrequency: action.payload,
      };
    case 'FOOD_LIQUID':
      return {
        ...state,
        foodLiquid: action.payload,
      };
    case 'WAKE_UP_INPUT':
      return {
        ...state,
        wakeUpInput: action.payload,
      };
    case 'SLEEP_IN':
      return {
        ...state,
        sleepIn: action.payload,
      };
    case 'HOURS_OF_SLEEP':
      return {
        ...state,
        hoursOfSleep: action.payload,
      };
    case 'HOURS_OF_SLEEP_IN_WEEKEND':
      return {
        ...state,
        hoursOfSleepInWeekend: action.payload,
      };
    case 'SLEEP_DESCRIPTION':
      return {
        ...state,
        sleepDescription: action.payload,
      };
    case 'EVALUATION_FREQUENCY':
      return {
        ...state,
        evaluationFrequency: action.payload,
      };
    case 'QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'ALERGIE_DESCRIPTION':
      return {
        ...state,
        alergieDescription: action.payload,
      };
    case 'ALERGIE_DESCRIPTION_ERROR':
      return {
        ...state,
        alergieDescriptionError: action.payload,
      };
    case 'ALERGIE_DESCRIPTION_MSG':
      return {
        ...state,
        alergieDescriptionMsg: action.payload,
      };
    case 'DIET_DESCRIPTION':
      return {
        ...state,
        dietDescription: action.payload,
      };
    case 'DIET_DESCRIPTION_ERROR':
      return {
        ...state,
        dietDescriptionError: action.payload,
      };
    case 'DIET_DESCRIPTION_MSG':
      return {
        ...state,
        dietDescriptionMsg: action.payload,
      };
    case 'ALCOHOLIC_BEVERAGES':
      return {
        ...state,
        alcoholicBeverages: action.payload,
      };
    case 'SAVE_FORM_CLICKED':
      return {
        ...state,
        saveFormClicked: action.payload,
      };
    case 'WHO_COOKS_AT_HOME':
      return {
        ...state,
        whoCooksAtHome: action.payload,
      };
    case 'STOMACHACHE_WHAT_FOODS':
      return {
        ...state,
        stomachacheWhatFoods: action.payload,
      };
    case 'FORM_COMPLETED':
      return {
        ...state,
        formCompleted: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
