/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import strings from '../../../assets/strings.json';
import { ReactComponent as AcAddCircle } from '../../../assets/svgs/ac-add-circle.svg';
import { ReactComponent as AcExportXls } from '../../../assets/svgs/ac-export-xls.svg';
import HauxDropdownButton from '../../../components/HauxDropdownButton';
import HauxCheckbox from '../../../components/HauxCheckbox';
import HAUXInput from '../../../components/HauxInput';
import HauxSearchList from '../../../components/HauxSearchList';
import ProspectService from '../../../service/ProspectService';
import AnalyticsManager from '../../../AnalyticsManager';
import { setPayload, exportProspectItems } from '../../../utils/Utils';

const {
  DELETE_PROSPECT_SUCCESS,
  DELETE_PROSPECT_FAIL,
  DATE_FORMATS,
} = strings;

const newButtonOptions = ['Prospect'];

const useStyles = makeStyles(() => ({
  headerBottonContainer: {
    marginLeft: -10,
    marginRight: -10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientsContainer: {
    flexDirection: 'column',
    padding: '0px 32px',
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  leftSpace: {
    marginLeft: 16,
  },
  exportContainer: {
    marginBottom: 16,
    marginRight: -10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ProspectsTab({
  state, dispatch, handleChangeRegister,
  openCurrentContractPDF, handleRequestErrors,
  handleSnackbar,
}) {
  const classes = useStyles();
  const [searchTextProspect, setSearchTextProspect] = useState('');
  const [showWithoutScheduling, setShowWithoutScheduling] = useState(false);
  const {
    prospectsPage, prospects, loading,
    prospectsCount, selectedProspects,
    allProspects,
  } = state;

  const getProspects = useCallback(async (sort) => {
    try {
      dispatch(setPayload('SET_LOADING', true));
      if (searchTextProspect) {
        dispatch(setPayload('PROSPECTS_PAGE', 0));
      }
      const loadedProspects = await ProspectService.getPaginatedProspects(
        prospectsPage, searchTextProspect, showWithoutScheduling, sort,
      );

      dispatch(setPayload('PROSPECTS', loadedProspects));
    } catch (error) {
      handleRequestErrors(error);
    } finally {
      dispatch(setPayload('SET_LOADING', false));
    }
  }, [prospectsPage, searchTextProspect, showWithoutScheduling, dispatch, handleRequestErrors]);

  const deleteProspectInfo = useCallback(async (id) => {
    try {
      await ProspectService.deleteProspect(id);
      getProspects(1);
      handleSnackbar({ type: 'success', message: DELETE_PROSPECT_SUCCESS });
    } catch (error) {
      handleRequestErrors(error, { type: 'error', message: DELETE_PROSPECT_FAIL });
    }
  }, [handleRequestErrors, getProspects, handleSnackbar]);

  useEffect(() => {
    getProspects(1);
  }, [getProspects]);

  useEffect(() => {
    ProspectService.getProspects()
      .then((response) => {
        dispatch(setPayload('ALL_PROSPECTS', response));
        if (searchTextProspect) {
          dispatch(setPayload('PROSPECTS_COUNT', prospects.length));
        } else {
          dispatch(setPayload('PROSPECTS_COUNT', response.length));
        }
      });
  }, [prospects, showWithoutScheduling, searchTextProspect, dispatch]);

  const handleChangeProspectsExport = (option) => {
    AnalyticsManager.track('Prospect button_export_data clicked');
    const prospectsExport = [];
    let prospectsByFilter = [];
    if (option === 'Todos os cadastros prospecção') {
      prospectsByFilter = allProspects;
    } else if (option === 'Todos agendados na Haux Tour') {
      prospectsByFilter = allProspects.filter(
        (prospect) => prospect.events
          && prospect.events.length
          && moment().isBefore(prospect.events[prospect.events.length - 1].start),
      );
    } else if (option === 'Todos não agendados na Haux Tour') {
      prospectsByFilter = allProspects.filter(
        (prospect) => (!(prospect.events && prospect.events.length)
          || moment().isAfter(prospect.events[prospect.events.length - 1].start)),
      );
    } else {
      prospectsByFilter = selectedProspects;
    }
    prospectsByFilter.forEach((prospect) => {
      const prospectEvent = prospect.events && prospect.events.length
        ? (
          moment().isBefore(prospect.events[prospect.events.length - 1].start)
            ? moment(prospect.events[prospect.events.length - 1].start).format(DATE_FORMATS.NORMAL)
            : 'Não agendado'
        )
        : 'Não agendado';
      const prospectEventConfirmation = prospect.events && prospect.events.length ? prospect.events[prospect.events.length - 1].confirmationStatus : '';
      prospectsExport.push([
        prospect.name,
        moment(prospect.createdAt).format(DATE_FORMATS.NORMAL),
        prospect.phone,
        prospect.email,
        prospect.sourceChannel,
        prospect.birthDate ? prospect.birthDate : '',
        prospectEvent,
        prospectEventConfirmation,
      ]);
    });
    const dataSet = [
      {
        columns: ['Nome', 'Data cadastro', 'Telefone', 'E-mail', 'Canal', 'Data de nascimento', 'Data Haux Tour', 'Presença ou ausência na Haux Tour'],
        data: prospectsExport,
      },
    ];
    dispatch(setPayload('EXPORT_SELECTED', option));
    dispatch(setPayload('EXPORT_CLIENTS_DATA', dataSet));
  };

  return (
    <>
      <Grid container className={classes.clientsContainer}>
        <Grid item className={classes.headerBottonContainer}>
          <Grid container justify="flex-end">
            <HauxDropdownButton
              label="NOVO"
              options={newButtonOptions}
              Icon={AcAddCircle}
              handleChange={handleChangeRegister}
              className="CTAPrimaryButton"
            />
          </Grid>
        </Grid>
        <Grid item className={classes.headerContainer}>
          <Grid item xs={8} style={{ marginRight: 30 }}>
            <HAUXInput
              label="Buscar cadastro"
              fullWidth
              value={searchTextProspect}
              onChange={(e) => setSearchTextProspect(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} className={`${classes.checkBox} ${classes.leftSpace}`}>
            <HauxCheckbox
              checked={showWithoutScheduling}
              handleChange={() => setShowWithoutScheduling(!showWithoutScheduling)}
              size="small"
            />
            <span className="paragraph">Haux Tour não agendada</span>
          </Grid>
          <Grid container className={classes.exportContainer}>
            <HauxDropdownButton
              label="Exportar dados"
              options={exportProspectItems}
              handleChange={handleChangeProspectsExport}
              Icon={AcExportXls}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <HauxSearchList
          data={prospects}
          page={prospectsPage}
          loading={loading}
          dispatch={dispatch}
          getClients={getProspects}
          openCurrentContractPDF={openCurrentContractPDF}
          type={2}
          totalItems={prospectsCount}
          deleteProspect={deleteProspectInfo}
          selectedProspects={selectedProspects}
          allProspects={allProspects}
        />
      </Grid>
    </>
  );
}

ProspectsTab.propTypes = {
  state: PropTypes.shape({
    prospectsPage: PropTypes.number,
    prospects: PropTypes.array,
    loading: PropTypes.bool,
    prospectsCount: PropTypes.number,
    selectedProspects: PropTypes.array,
    allProspects: PropTypes.array,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChangeRegister: PropTypes.func.isRequired,
  openCurrentContractPDF: PropTypes.func.isRequired,
  handleRequestErrors: PropTypes.func.isRequired,
  handleSnackbar: PropTypes.func.isRequired,
};
