import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Delete from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  Paper,
  IconButton,
  Grid,
  HauxButton,
  HauxDropdown,
} from '..';
import icEnfermagem from '../../assets/svgs/ic-enfermagem.svg';
import icEnfermagemMin from '../../assets/svgs/ic-enfermagem-min.svg';
import icHauxTraining from '../../assets/svgs/ic-haux-training.svg';
import icHauxTrainingMin from '../../assets/svgs/ic-haux-training-min.svg';
import icNutricao from '../../assets/svgs/ic-nutricao.svg';
import icNutricaoMin from '../../assets/svgs/ic-nutricao-min.svg';
import icEducacaoFisica from '../../assets/svgs/ic-educacao-fisica.svg';
import icEducacaoFisicaMin from '../../assets/svgs/ic-educacao-fisica-min.svg';
import icMaisVida from '../../assets/svgs/ic-mais-vida.svg';
import icMaisVidaMin from '../../assets/svgs/ic-mais-vida-min.svg';
import './hauxPlanItem.scss';

const getIcon = (mettings, min) => {
  if (Object.keys(mettings).length > 1) {
    return min ? icEnfermagemMin : icEnfermagem;
  }

  if (mettings.nursing > 0) {
    return min ? icEnfermagemMin : icEnfermagem;
  }

  if (mettings.training > 0) {
    return min ? icHauxTrainingMin : icHauxTraining;
  }

  if (mettings.nutrition > 0) {
    return min ? icNutricaoMin : icNutricao;
  }

  if (mettings.physicalEducation > 0) {
    return min ? icEducacaoFisicaMin : icEducacaoFisica;
  }

  if (mettings.hauxMoreLife > 0) {
    return min ? icMaisVidaMin : icMaisVida;
  }

  return min ? icEnfermagemMin : icEnfermagem;
};

export const HauxPlanItemCart = ({
  plan,
  quantity,
  onAddClick,
  onSubtractClick,
  onRemoveClick,
}) => {
  const { name, value, meetings } = plan;
  return (
    <div className="cartItemContainer">
      <img src={getIcon(meetings, true)} alt="" />
      <span className="h6StylePurple planTitle">{name}</span>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={onSubtractClick}
        disabled={quantity <= 1}
      >
        <RemoveCircle style={{ color: '#B3B3B3' }} />
      </IconButton>
      <span className="h6NormalStyle quantityLabel">{quantity}</span>
      <IconButton color="primary" aria-label="upload picture" component="span" onClick={onAddClick}>
        <AddCircle style={{ color: '#B3B3B3' }} />
      </IconButton>
      <span className="h6NormalStyle priceLabel">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value * quantity)}</span>
      <IconButton color="primary" aria-label="upload picture" component="span" onClick={onRemoveClick}>
        <Delete style={{ color: '#B3B3B3' }} />
      </IconButton>
    </div>
  );
};

const formatAsMoney = (value) => Intl
  .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
  .format(value);

const formatLabel = (installmentNumber, value) => (installmentNumber === 1
  ? `À VISTA = R$ ${formatAsMoney(value)}`
  : `${installmentNumber}x ${formatAsMoney(value / installmentNumber)}`);

const formatOption = ({ installmentNumber, value }) => ({
  label: formatLabel(installmentNumber, value),
  value: installmentNumber,
});

function HauxPlanItem({
  plan,
  onAddClick,
}) {
  const {
    name,
    period,
    subtitle,
    descriptions,
    installment,
    installmentOptions,
    meetings,
  } = plan;
  const [state, setState] = useState({
    installmentSelected: installment ? 6 : 1,
    installmentText: '',
    value: 0,
  });

  useEffect(() => {
    if (installmentOptions.length) {
      const { installmentNumber, value } = installmentOptions
        .find(({ installmentNumber }) => installmentNumber === state.installmentSelected);

      const installmentText = formatLabel(installmentNumber, value);

      setState((ps) => ({
        ...ps,
        installmentText,
        value,
      }));
    }
  }, [installmentOptions, state.installmentSelected]);

  return (
    <Paper>
      <div className="planContainer">
        <div className="titleContainer">
          <div className="iconContainer">
            <img src={getIcon(meetings, false)} alt="" />
            <span className="h6StylePurple">
              {name}
              {period === 3 ? ' TRIMESTRAL' : ''}
            </span>
            <span className="h6StylePurple" style={{ fontSize: 14 }}>{subtitle}</span>
            {meetings && Object.keys(meetings).length > 1
              && (
                <div className="minIconContainer">
                  {meetings.nursing > 0 && <img src={icEnfermagemMin} alt="Enfermagem" />}
                  {meetings.training > 0 && <img src={icHauxTrainingMin} alt="Haux Training" />}
                  {meetings.nutrition > 0 && <img src={icNutricaoMin} alt="Nutrição" />}
                  {meetings.physicalEducation > 0 && <img src={icEducacaoFisicaMin} alt="Educação Física" />}
                  {meetings.hauxMoreLife > 0 && <img src={icMaisVidaMin} alt="Mais Vida" />}
                </div>
              )}
          </div>
          <div className="descriptionContainer">
            <div className="list">
              {descriptions.map((description) => (
                <div key={description}>
                  <span className="h6NormalStyle">{description}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Grid container direction="row">
          <Grid item xs={6} container direction="column">
            {
              state.value > 230 && installmentOptions.length > 0
              && (
                <HauxDropdown
                  fullWidth
                  label="Opções de pagamento"
                  value={state.installmentSelected}
                  options={installmentOptions.map((option) => formatOption(option))}
                  handleChange={({ target }) => setState((ps) => ({ ...ps, installmentSelected: target.value }))}
                  disabled={installmentOptions && installmentOptions.length === 1}
                />
              )
            }
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            alignItems="center"
          >
            <Grid item>
              {installment && (
                <span className="h6StylePurple">
                  {state.installmentText}
                </span>
              )}
            </Grid>
            <Grid item>
              <span className="h6NormalStyle">
                {`Total: ${formatAsMoney(state.value)}`}
              </span>
            </Grid>
            <Grid item>
              <HauxButton
                text="Adicionar"
                onClick={() => onAddClick({
                  ...plan,
                  value: state.value,
                  installmentSelected: state.installmentSelected,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

const PlanProps = PropTypes.shape({
  name: PropTypes.string,
  subtitle: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  installment: PropTypes.bool,
  installmentOptions: PropTypes.arrayOf(PropTypes.object),
  maximumInstallment: PropTypes.number,
  meetings: PropTypes.shape({
    nursing: PropTypes.number,
    nutrition: PropTypes.number,
    physicalEducation: PropTypes.number,
    hauxMoreLife: PropTypes.number,
    training: PropTypes.number,
  }),
}).isRequired;

HauxPlanItem.propTypes = {
  onAddClick: PropTypes.func,
  plan: PlanProps,
};
HauxPlanItem.defaultProps = {
  onAddClick: () => { },
  plan: {},
};

HauxPlanItemCart.propTypes = {
  onAddClick: PropTypes.func,
  onSubtractClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  quantity: PropTypes.number,
  plan: PlanProps,
};
HauxPlanItemCart.defaultProps = {
  onAddClick: () => { },
  onSubtractClick: () => { },
  onRemoveClick: () => { },
  quantity: 0,
  plan: {},
};

export default HauxPlanItem;
