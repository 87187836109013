import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { Grid, Paper, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { Input, Select } from "../../../components/Form";
import ViewContext from "../../../ViewContext";
import UserService from "../../../service/UserService";
import FranchiseService from "../../../service/FranchiseService";

import "./usersCreate.scss";

const TYPES = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Coordenador",
    label: "Coordenador",
  },
  {
    value: "Educação Física",
    label: "Educação Física",
  },
  {
    value: "Enfermagem",
    label: "Enfermagem",
  },
  {
    value: "Nutrição",
    label: "Nutrição",
  },
  {
    value: "Psicologia",
    label: "Psicologia",
  },
  {
    value: "Recepcionista",
    label: "Recepcionista",
  },
];

const REQUIRED_MSG = "Campo obrigatório";

const UsersFormSchema = Yup.object().shape({
  name: Yup.string().min(2, "Muito curto").required(REQUIRED_MSG),
  email: Yup.string().email("Email inválida").required(REQUIRED_MSG),
  franchises: Yup.array().required(REQUIRED_MSG),
  profileType: Yup.string().required(REQUIRED_MSG),
  username: Yup.string().required(REQUIRED_MSG),
  password: Yup.string().required(REQUIRED_MSG),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Senhas devem ser iguais")
    .required(REQUIRED_MSG),
});

const CreateUser = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    setRouteProperties,
    setLoading,
    loading,
    setSnackbarOption,
  } = useContext(ViewContext);
  const [initialValues, setInitialValues] = useState({});
  const [franchises, setFranchises] = useState([]);

  const findUser = async (userId) => {
    setLoading(true);
    const user = await UserService.getUser(userId);
    setInitialValues({
      ...user,
      franchises: user.franchises,
      passwordConfirm: user.password,
    });
    setLoading(false);
  };

  const getFranchises = async () => {
    const values = await FranchiseService.getAllFranchise();
    setFranchises(values);
  };

  const save = async (values) => {
    setLoading(true);
    const saveValues = values;
    setInitialValues({
      ...values,
      franchises: values.franchises,
    });
    saveValues.franchises = saveValues.franchises.map((item) => item._id);

    try {
      const SaveAction = values._id
        ? UserService.putUser
        : UserService.postUser;
      await SaveAction(saveValues);
      setSnackbarOption({
        message: `Usuário ${values._id ? "alterado" : "salvo"} com sucesso!`,
        type: "success",
        open: true,
        onClose: () => {
          history.push("/users");
          setSnackbarOption((s) => ({
            ...s,
            open: false,
          }));
        },
      });
    } catch (error) {
      const { response } = error;
      setSnackbarOption({
        message: response.data.error,
        type: "error",
        open: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setRouteProperties({
      pageTitle: "Novo usuário",
      breadcrumbs: "Usuários",
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (location.state) {
      const { userId } = location.state;
      findUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getFranchises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      key={loading}
      initialValues={initialValues}
      validationSchema={UsersFormSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          save(values);
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      {({ setFieldValue, values }) => (
        <Grid
          container
          direction="column"
          justify="center"
          className="userNewContainer"
        >
          <Form style={{ width: "100%" }}>
            <Grid item xs>
              <Paper className="paperContainer" elevation={2}>
                <Grid
                  className="userForm"
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Input label="Nome" name="name" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <Input label="Email" name="email" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Input label="Username" fullWidth name="username" />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        label="Perfil"
                        fullWidth
                        name="profileType"
                        options={TYPES}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Autocomplete
                        multiple
                        name="franchises"
                        options={franchises}
                        onChange={(e, value) => {
                          setFieldValue("franchises", value);
                        }}
                        defaultValue={values.franchises}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="standard"
                            label="Clínica"
                            placeholder="Clínica"
                            name="franchises"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Input label="Senha" fullWidth name="password" />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Confirmar Senha"
                      fullWidth
                      name="passwordConfirm"
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={2}
                style={{ padding: 5, paddingTop: 20, paddingBottom: 40 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/users")}
                >
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  );
};

export default CreateUser;
