import React, {
  useState, useEffect, useContext,
} from 'react';
import { object, func } from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClientService from '../../../../../service/ClientService';
import { debounce } from 'debounce';
import { HauxInputSearch, Grid } from '../../../../../components';
import strings from '../../../../../assets/strings.json';
import ViewContext from '../../../../../ViewContext';
import useStyles from './styles';

const {
  NO_CLIENTS_FOUND, MINIMUN_SEARCH_LENGTH,
} = strings;

const InputSearch = ({ selectedClient, setSelectedClient }) => {
  const { handleRequestsErrors } = useContext(ViewContext);
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [searchClient, setSearchClient] = useState('');
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (!selectedClient) {
      setSearchClient('');
      setSearched(false);
      setClients([]);
    }
  }, [selectedClient]);

  const debouncedSearch = debounce((name) => {
    ClientService.getClientsFilteredByName(name, 1)
      .then((response) => {
        if (name.length >= 3) {
          setClients(response);
          setSearched(true);
        }
      })
      .catch(handleRequestsErrors);
  }, 750);

  const handleSearchInputChange = (event, selectedEvent) => {
    if (selectedEvent && selectedEvent.name) {
      setSearchClient(selectedEvent.name);
    } else {
      setSearchClient(event.target.value);
    }
    if (searchClient && event.target.value) {
      if (event.target.value.length >= 3) {
        debouncedSearch(event.target.value);
      } else if (searchClient && event.target.value.length < 3 && searched) {
        setClients([]);
        setSearched(false);
      }
    }
  };
  return (
    <Grid item xs={8}>
      <HauxInputSearch
        placeholder="Buscar cliente"
        noOptionsText={searched ? NO_CLIENTS_FOUND : MINIMUN_SEARCH_LENGTH}
        rightIcon={() => <ArrowDropDownIcon />}
        value={searchClient}
        onChange={handleSearchInputChange}
        onSelect={(client) => setSelectedClient(client)}
        fullWidth
        autocomplete
        autocompleteProps={{
          options: clients,
          getOptionLabel: (option) => option.name || '',
        }}
        renderOption={(option) => (
          <Grid container>
            <Grid item xs={12} className={classes.optionContainer}>
              <Grid item xs={2}>
                {
                  option.active
                    ? (
                      <Grid item className={`${classes.clientStatus} ${classes.clientActive}`}>
                        <span className="descriptionWhite">Cliente Ativo</span>
                      </Grid>
                    )
                    : (
                      <Grid
                        item
                        className={`${classes.clientStatus} ${classes.clientInactive}`}
                      >
                        <span className="descriptionWhite">Cliente Inativo</span>
                      </Grid>
                    )
                }
              </Grid>
              <Grid item xs={4}>
                <span className="paragraph">{option.name}</span>
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

InputSearch.propTypes = {
  selectedClient: object,
  setSelectedClient: func,
}


export default InputSearch;
