import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const CustomForm = withStyles({
  root: {
    fontFamily: 'Graviola',
    color: '#685DA8',
    lineHeight: 20,
    fontStyle: 'normal',
    fontWeight: 'normal',
    '& label, & .MuiInput-root': {
      color: '#737373',
      fontFamily: 'Graviola',
    },
    '& label': {
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: '#685DA8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#685DA8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#685DA8',
      },
    },
  },
})(FormControl);

const CustomMenuItem = withStyles({
  root: {
    fontFamily: 'Graviola',
    color: '#737373',
  },
})(MenuItem);

const HauxSelect = ({
  label,
  options,
  value,
  onChange,
  disabled,
}) => (
  <CustomForm style={{ width: '100%' }}>
    <InputLabel id={`${label}`} style={value ? { color: '#685DA8' } : {}}>{label}</InputLabel>
    <Select
      labelId={`${label}`}
      id="filter-select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      {options.map((op) => (
        <CustomMenuItem key={op.value} value={op.value}>{op.label}</CustomMenuItem>
      ))}
    </Select>
  </CustomForm>
);

export default HauxSelect;

HauxSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

HauxSelect.defaultProps = {
  label: '',
  options: [],
  value: '',
  onChange: () => <></>,
  disabled: false,
};
