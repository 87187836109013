import React, {
  useReducer, useEffect, useLayoutEffect, useContext,
} from 'react';
import moment from 'moment';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import pdfs from '../../assets/pdfs';
import HauxCheckbox from '../../components/HauxCheckbox';
import HAUXInput from '../../components/HauxInput';
import HauxSlider from '../../components/HauxSlider';
import {
  physicalActivitiesArray, setPayload,
  musclePainsArray, difficultyMovingArray,
} from '../../utils/Utils';
import HauxQuiz from '../../components/HauxQuiz';
import ClientService from '../../service/ClientService';
import HAUXButton from '../../components/HauxButton';
import HauxConfirmDialog from '../../components/HauxConfirmDialog';
import AnalyticsManager from '../../AnalyticsManager';
import strings from '../../assets/strings.json';
import { reducer, initialState } from './reducers';
import ClientContext from '../../ClientContex';
import ViewContext from '../../ViewContext';
import './physicalEducationForm.scss';

const {
  OTHER, SUCCESS_FIRST_CONSULT, FAIL_FIRST_CONSULT, PHYSICAL_CONDITION_EVALUATION,
  SUCINT_ANAMNESIS, PHYSICAL_ACTIVITIES_YOU_PRACTICE, HAVE_FREQUENT_MUSCLE_PAIN, STRESS_LEVEL,
  FIELD_REQUIRED, DIFFICULTIES_IN_MOVING, PARQ_CHECK, PARQ_MESSAGE, CONFIRM_PAGE_EXIT,
} = strings;

const {
  responsibilityTermPhysical,
} = pdfs;

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainer: {
    borderRadius: 8,
    paddingTop: 48,
    paddingBottom: 48,
    marginBottom: 16,
  },
  headerInfo: {
    marginBottom: 60,
  },
  maxWidthStyle: {
    maxWidth: 1156,
  },
  itemContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  spaceItems: {
    justifyContent: 'space-between',
  },
  stressLevel: {
    display: 'flex',
    justifyContent: 'center',
  },

}));

const PhysicalEducationAnamnesis = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { client, loadClient } = useContext(ClientContext);
  const {
    setRouteProperties, handleRequestsErrors, setSnackbarOption, setLoading,
  } = useContext(ViewContext);
  const { professional } = location.state;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    physicalActivities, musclePains, difficultyMoving, questions,
    rerender, stressLevel, exitFormDialog, renderOtherMuscleField, renderOtherActivityField,
    otherPain, otherPainError, otherPainErrorMsg, otherActivity, otherActivityError,
    otherActivityErrorMsg, formCompleted, saveFormClicked,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'acompanhamento de condicionamento físico',
      breadcrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    AnalyticsManager.track('View PhysicalEducationAnamnesis');
    dispatch(setPayload('RESET_QUESTIONS', []));
  }, []);

  useEffect(() => {
    if (rerender) dispatch(setPayload('RERENDER', !rerender));
  }, [rerender]);

  useLayoutEffect(() => {
    if (musclePains.includes(OTHER)) {
      dispatch(setPayload('RENDER_OTHER_MUSCLE_FIELD', true));
    } else {
      dispatch(setPayload('RENDER_OTHER_MUSCLE_FIELD', false));
    }
  }, [musclePains]);

  useLayoutEffect(() => {
    if (physicalActivities.includes(OTHER)) {
      dispatch(setPayload('RENDER_OTHER_ACTIVITY_FIELD', true));
    } else {
      dispatch(setPayload('RENDER_OTHER_ACTIVITY_FIELD', false));
    }
  }, [physicalActivities]);


  const verifyFields = () => !(!questions.find((quest) => quest.value === ''));

  const savePhysicalEducationAnamnesis = async () => {
    try {
      AnalyticsManager.track('PhysicalEducatorForm button_save_form clicked');
      setLoading(true);
      const yourActivities = physicalActivities;
      const yourPains = musclePains;
      if (otherPain) {
        musclePains.push(otherPain);
      }
      if (otherActivity) {
        physicalActivities.push(otherActivity);
      }
      if (yourActivities.includes('Outras')) {
        const item = yourActivities.indexOf('Outras');
        yourActivities.splice(item, 1);
      }
      if (yourPains.includes('Outras')) {
        const item = yourPains.indexOf('Outras');
        yourPains.splice(item, 1);
      }
      const data = {
        date: moment(),
        professional,
        yourActivities,
        musclePains: yourPains,
        difficultiesInMoving: difficultyMoving,
        stressLevel,
        parqQuiz: {
          heartProblem: questions[0].value,
          chestPains: questions[1].value,
          chestPainsInTheLastMonth: questions[2].value,
          haveImbalance: questions[3].value,
          boneOrJointProblem: questions[4].value,
          takeSomeMedicine: questions[5].value,
          otherReason: questions[6].value,
        },
      };
      await ClientService.postNewPhysicalEducationForm(client.clientId, data);
      AnalyticsManager.track('Create PhysicalEducator Form');
      dispatch(setPayload('FORM_COMPLETED', true));
      setSnackbarOption({
        open: true,
        message: SUCCESS_FIRST_CONSULT,
        type: 'success',
      });
      await loadClient();
      setTimeout(() => {
        history.push('/client/dashboard');
      }, 3000);
    } catch (error) {
      handleRequestsErrors(error, { message: FAIL_FIRST_CONSULT });
    } finally {
      setLoading(false);
    }
  };

  const exitForm = () => dispatch(setPayload('EXIT_FORM_DIALOG', true));

  const handleLevelStressChange = (event, newValue) => {
    dispatch(setPayload('STRESS_LEVEL', newValue));
  };

  const handleChange = (item, value) => {
    const alterQuestions = questions;
    const findItem = alterQuestions.find((qt) => item.question === qt.question);
    if (findItem) findItem.value = value;

    dispatch(setPayload('QUESTIONS', alterQuestions));
    dispatch(setPayload('RERENDER', !rerender));
  };

  const handleSelectPhysicalActivity = (value) => {
    let newActivities = null;
    if (physicalActivities.includes(value)) {
      newActivities = physicalActivities.filter((activity) => activity !== value);
    } else {
      newActivities = physicalActivities.concat(value);
    }
    dispatch(setPayload('PHYSICAL_ACTIVITIES', newActivities));
  };

  const handleSelectMusclePains = (value) => {
    let pains = null;
    if (musclePains.includes(value)) {
      pains = musclePains.filter((pain) => pain !== value);
    } else {
      pains = musclePains.concat(value);
    }
    dispatch(setPayload('MUSCLE_PAINS', pains));
  };

  const handleSelectDifficultyMoving = (value) => {
    let movings = null;
    if (difficultyMoving.includes(value)) {
      movings = difficultyMoving.filter((moving) => moving !== value);
    } else {
      movings = difficultyMoving.concat(value);
    }
    dispatch(setPayload('DIFFICULTY_MOVING', movings));
  };

  const renderPhysicalActivities = () => physicalActivitiesArray
    .map((activity) => (
      <div key={activity}>
        <HauxCheckbox
          checked={physicalActivities.includes(activity)}
          handleChange={() => handleSelectPhysicalActivity(activity)}
          value={activity}
        />
      </div>
    ));

  const renderMusclePains = () => musclePainsArray
    .map((pain) => (
      <div key={pain}>
        <HauxCheckbox
          checked={musclePains.includes(pain)}
          handleChange={() => handleSelectMusclePains(pain)}
          value={pain}
        />
      </div>
    ));

  const renderDifficultyMoving = () => difficultyMovingArray
    .map((moving) => (
      <div key={moving}>
        <HauxCheckbox
          checked={difficultyMoving.includes(moving)}
          handleChange={() => handleSelectDifficultyMoving(moving)}
          value={moving}
        />
      </div>
    ));

  const verifyAnyField = () => !!(
    (questions.find((quest) => quest.value === null)
      && questions.find((quest) => quest.value !== null))
    || physicalActivities.length
    || musclePains.length
    || difficultyMoving.length
    || stressLevel
    || renderOtherMuscleField
    || renderOtherActivityField
    || otherPain
    || otherActivity
  );

  const renderPrompt = () => (
    <Prompt
      when={verifyAnyField() && !exitFormDialog && !formCompleted}
      message={CONFIRM_PAGE_EXIT}
    />
  );

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={10} className={classes.maxWidthStyle}>
        {renderPrompt()}
        <Grid item xs={12} className={classes.headerInfo}>
          <div>
            <h4 className="title">{PHYSICAL_CONDITION_EVALUATION}</h4>
            <span className="paragraph fontSmall">{client.name}</span>
          </div>
          <span className="paragraph">
            Data da avaliação:
            {' '}
            {moment().format('DD/MM/YYYY')}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10}>
                <div>
                  <h4 className="title">{SUCINT_ANAMNESIS}</h4>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2} className={classes.spaceItems}>
                  <Grid item xs={5}>
                    <span className="paragraph fontSmall">{PHYSICAL_ACTIVITIES_YOU_PRACTICE}</span>
                  </Grid>
                  <Grid item xs={5}>
                    <span className="paragraph fontSmall">{STRESS_LEVEL}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2} className={classes.spaceItems}>
                  <Grid item xs={5} className={classes.itemContainer}>
                    {renderPhysicalActivities()}
                    <div>
                      <HauxCheckbox
                        checked={physicalActivities.includes(OTHER)}
                        handleChange={() => handleSelectPhysicalActivity(OTHER)}
                        value={OTHER}
                      />
                    </div>
                    {renderOtherActivityField
                      ? (
                        <div className={classes.stressLevel}>
                          <HAUXInput
                            label="Quais?"
                            value={otherActivity}
                            onChange={(event) => dispatch(setPayload('OTHER_ACTIVITY', event.target.value))}
                            onFocus={() => {
                              dispatch(setPayload('OTHER_ACTIVITY_ERROR', false));
                              dispatch(setPayload('OTHER_ACTIVITY_ERROR_MSG', ''));
                            }}
                            onBlur={() => {
                              if (!otherActivity) {
                                dispatch(setPayload('OTHER_ACTIVITY_ERROR', true));
                                dispatch(setPayload('OTHER_ACTIVITY_ERROR_MSG', FIELD_REQUIRED));
                              }
                            }}
                            error={otherActivityError}
                            errorMsg={otherActivityErrorMsg}
                          />
                        </div>
                      )
                      : null}
                  </Grid>
                  <Grid item xs={6} className={classes.stressLevel}>
                    <HauxSlider value={stressLevel} handleChange={handleLevelStressChange} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={5} className="marginTp">
                    <span className="paragraph fontSmall">{HAVE_FREQUENT_MUSCLE_PAIN}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={5} className={classes.itemContainer}>
                    {renderMusclePains()}
                    <div>
                      <HauxCheckbox
                        checked={musclePains.includes(OTHER)}
                        handleChange={() => handleSelectMusclePains(OTHER)}
                        value={OTHER}
                      />
                    </div>
                    {renderOtherMuscleField
                      ? (
                        <HAUXInput
                          label="Quais?"
                          value={otherPain}
                          onChange={(event) => dispatch(setPayload('OTHER_PAIN', event.target.value))}
                          onFocus={() => {
                            dispatch(setPayload('OTHER_PAIN_ERROR', false));
                            dispatch(setPayload('OTHER_PAIN_ERROR_MSG', ''));
                          }}
                          onBlur={() => {
                            if (!otherPain) {
                              dispatch(setPayload('OTHER_PAIN_ERROR', true));
                              dispatch(setPayload('OTHER_PAIN_ERROR_MSG', FIELD_REQUIRED));
                            }
                          }}
                          error={otherPainError}
                          errorMsg={otherPainErrorMsg}
                        />
                      )
                      : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={5} className="marginTp">
                    <span className="paragraph fontSmall">{DIFFICULTIES_IN_MOVING}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    {renderDifficultyMoving()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10}>
                <div>
                  <h4 className="title">{PARQ_CHECK}</h4>
                  <span className="paragraph marginTp">{PARQ_MESSAGE}</span>
                </div>
              </Grid>
              <Grid item xs={10} className="marginTp">
                <HauxQuiz questions={questions} handleChange={handleChange} isRowItems />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} className="buttonsContainer">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <HAUXButton text="Voltar" type="secondary" onClick={() => exitForm()} />
            </Grid>
            <Grid item xs={6} className="saveButton displayButton">
              <div className="marginLeftButton">
                <HAUXButton
                  type="secondary"
                  text="Termo de responsabilidade"
                  disabled={!(questions.find((quest) => quest.value === true))}
                  onClick={() => {
                    AnalyticsManager.track('PhysicalEducation term clicked');
                    window.open(responsibilityTermPhysical);
                  }}
                />
              </div>
              <div className="marginLeftButton">
                <HAUXButton text="Salvar" type="primary" onClick={() => dispatch(setPayload('SAVE_FORM_CLICKED', true))} disabled={verifyFields()} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <HauxConfirmDialog
          title="Salvar formulário"
          message="Após salvar este documento, você não poderá realizar edições. Deseja continuar?"
          open={saveFormClicked}
          handleClose={() => dispatch(setPayload('SAVE_FORM_CLICKED', false))}
          handleConfirm={() => {
            AnalyticsManager.track('NewNutritionistForm button_save_form clicked');
            dispatch(setPayload('SAVE_FORM_CLICKED', false));
            savePhysicalEducationAnamnesis();
          }}
        />
        <HauxConfirmDialog
          title="Sair do formulário"
          message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
          open={exitFormDialog}
          handleClose={() => dispatch(setPayload('EXIT_FORM_DIALOG', false))}
          handleConfirm={() => {
            history.goBack();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PhysicalEducationAnamnesis;
