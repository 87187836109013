import React from 'react';

import Grid from '@material-ui/core/Grid';

function Mobilidade() {
  return (
    <Grid justify="center" container>
      <span className="h3Style">Mobilidade</span>
    </Grid>
  );
}

export default Mobilidade;
