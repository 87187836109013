import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles({
  root: {
    color: '#685DA8',
  },
})(CircularProgress);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    height: '100%',
    width: '100%',
    zIndex: 1000000,
  },
  progress: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '50%',
    top: '50%',
  },
}));

export default function HauxLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <ColorCircularProgress size={50} thickness={3} />
      </div>
    </div>
  );
}
