/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import icClose from "../../assets/svgs/ic-close.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxWidth: 768,
    minWidth: 768,
    maxHeight: 500,
    minHeight: 500,
    boxShadow: theme.shadows[5],
    padding: 32,
  },
  titleModal: {
    display: "flex",
    flexDirection: "column",
  },
  section: {
    marginTop: 16,
  },
  boxSection: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  iconContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

const MeetingModal = ({ open, handleClose, data }) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <div className={classes.iconContainer}>
              <Button onClick={handleClose}>
                <img src={icClose} alt="" />
              </Button>
            </div>
            <Grid className={classes.titleModal}>
              <span className="h4Style titleSpace">{data.theme}</span>
              <span className="paragraph itemSpace">
                {moment(data.date).format("DD/MM/YYYY")} -{" "}
                {moment(data.hour).format("HH:mm")}
              </span>
              <span className="paragraph itemSpace">
                Profissional: {data.professional}
              </span>
              <span className="paragraph titleSpace">
                Nº de participantes: {data.numberOfParticipants}
              </span>
            </Grid>
            {data.positivePoints ? (
              <Grid className={classes.section} border={1}>
                <Box
                  border={1}
                  borderColor="#E0E0E0"
                  className={classes.boxSection}
                >
                  <div>
                    <span className="paragraph mainColor">
                      Pontos positivos:{" "}
                    </span>
                    <span className="paragraph">{data.positivePoints}</span>
                  </div>
                </Box>
              </Grid>
            ) : null}
            {data.negativePoints ? (
              <Grid className={classes.section} border={1}>
                <Box
                  border={1}
                  borderColor="#E0E0E0"
                  className={classes.boxSection}
                >
                  <div>
                    <span className="paragraph mainColor">
                      Pontos negativos:{" "}
                    </span>
                    <span className="paragraph">{data.negativePoints}</span>
                  </div>
                </Box>
              </Grid>
            ) : null}
            {data.difficulties ? (
              <Grid className={classes.section} border={1}>
                <Box
                  border={1}
                  borderColor="#E0E0E0"
                  className={classes.boxSection}
                >
                  <div>
                    <span className="paragraph mainColor">Dificuldades: </span>
                    <span className="paragraph">{data.difficulties}</span>
                  </div>
                </Box>
              </Grid>
            ) : null}
            {data.reviews ? (
              <Grid className={classes.section} border={1}>
                <Box
                  border={1}
                  borderColor="#E0E0E0"
                  className={classes.boxSection}
                >
                  <div>
                    <span className="paragraph mainColor">Críticas: </span>
                    <span className="paragraph">{data.reviews}</span>
                  </div>
                </Box>
              </Grid>
            ) : null}
            {data.suggestions ? (
              <Grid className={classes.section} border={1}>
                <Box
                  border={1}
                  borderColor="#E0E0E0"
                  className={classes.boxSection}
                >
                  <div>
                    <span className="paragraph mainColor">Sugestões: </span>
                    <span className="paragraph">{data.suggestions}</span>
                  </div>
                </Box>
              </Grid>
            ) : null}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

MeetingModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

MeetingModal.defaultProps = {
  open: false,
  handleClose: () => {},
  data: {},
};

export default MeetingModal;
