import React, {
  useReducer, useEffect, useContext,
  useCallback,
} from 'react';
import './clinic.scss';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FinancesTab from './tabs/finances';
import HauxCSV from '../../components/HauxCSV';
import ClinicTabs from './components/clinicTabs';
import ClientsTab from './tabs/clients';
import ProspecstTab from './tabs/prospects';
import ClientService from '../../service/ClientService';
import AnalyticsManager from '../../AnalyticsManager';
import ViewContext from '../../ViewContext';
import { setPayload } from '../../utils/Utils';
import { reducer, initialState } from './reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerBottonContainer: {
    marginLeft: -10,
    marginRight: -10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientsContainer: {
    flexDirection: 'column',
    padding: '0px 32px',
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  leftSpace: {
    marginLeft: 16,
  },
  rightSpace: {
    marginRight: 10,
  },
  maxWidthStyle: {
    maxWidth: 1156,
    backgroundColor: '#ffffff',
    marginTop: -32,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  exportContainer: {
    marginBottom: 16,
    marginRight: -10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ctaContainer: {
    marginBottom: 16,
    marginRight: -10,
  },
}));

const ClinicScreen = React.memo(({ tab }) => {
  const history = useHistory();
  const { setSnackbarOption, handleRequestsErrors } = useContext(ViewContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    exportSelected, exportClientsData, exportProspectsData,
  } = state;

  const handleChangeRegister = useCallback((option) => {
    if (option === 'Cliente') {
      history.push('/client/newClient/', { checkedA: true });
      AnalyticsManager.track('ClinicClient button_new_register clicked');
    } else if (option === 'Prospect') {
      history.push('/client/newClient/', { checkedA: false });
      AnalyticsManager.track('ClinicProspect button_new_register clicked');
    } else {
      history.push('/plans', { origin: 'Gestão da Clínica' });
      AnalyticsManager.track('ClinicProspect button_new_plan clicked');
    }
  }, [history]);

  const openCurrentContractPDF = async (id) => {
    AnalyticsManager.track('Clinic button_open_contract clicked');
    const pdfURL = await ClientService.getCurrentContract(id);
    window.open(pdfURL);
  };

  const handleClientChangeExportAction = useCallback((option, data) => {
    dispatch(setPayload('EXPORT_SELECTED', option));
    dispatch(setPayload('EXPORT_CLIENTS_DATA', data));
  }, []);

  const renderProspection = () => (
    <ProspecstTab
      dispatch={dispatch}
      handleChangeRegister={handleChangeRegister}
      openCurrentContractPDF={openCurrentContractPDF}
      handleRequestErrors={handleRequestsErrors}
      handleSnackbar={setSnackbarOption}
      state={state}
    />
  );

  const renderClients = () => (
    <ClientsTab
      handleChangeRegister={handleChangeRegister}
      openCurrentContractPDF={openCurrentContractPDF}
      handleRequestErrors={handleRequestsErrors}
      handleSnackbar={setSnackbarOption}
      handleChangeExportAction={handleClientChangeExportAction}
    />
  );

  const checkRender = () => {
    let render = <></>;
    if (tab === 2) {
      render = renderProspection();
    } else {
      render = renderClients();
    }
    return render;
  };

  return (
    <>
      {checkRender()}
      {exportClientsData.length && exportSelected
        ? <HauxCSV data={exportClientsData} exportCallback={() => dispatch(setPayload('EXPORT_SELECTED', ''))} />
        : null}
      {exportProspectsData.length && exportSelected
        ? <HauxCSV data={exportClientsData} exportCallback={() => dispatch(setPayload('EXPORT_SELECTED', ''))} />
        : null}
    </>
  );
});

const routes = ['clients', 'prospects', 'finances'];
const Clinic = ({ match, history }) => {
  const classes = useStyles();
  const location = useLocation();
  const { setRouteProperties } = useContext(ViewContext);

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Gestão da Clínica',
    });
  }, [setRouteProperties]);

  return (
    <>
      <Grid item container justify="center" alignItems="center" className={classes.maxWidthStyle}>
        <Grid container justify="center" className={classes.tabContainer}>
          <Grid item container justify="center">
            <ClinicTabs
              onChange={(index) => {
                AnalyticsManager.track(`Clinic tab_${routes[index]} clicked`);
                history.push(`${match.url}/${routes[index]}`);
              }}
              route={location.pathname.replace(`${match.url}/`, '')}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.tabContainer}>
          <Switch>
            <Route path={`${match.url}/clients`} component={() => <ClinicScreen tab={1} />} />
            <Route path={`${match.url}/prospects`} component={() => <ClinicScreen tab={2} />} />
            <Route path={`${match.url}/finances`} component={FinancesTab} />
          </Switch>
        </Grid>
      </Grid>
    </>
  );
};


ClinicScreen.propTypes = {
  tab: PropTypes.number,
};

ClinicScreen.defaultProps = {
  tab: 1,
};

Clinic.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Clinic;
