import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import HauxTable from '../../../../../components/HauxTable';
import HauxConfirmDialog from '../../../../../components/HauxConfirmDialog';
import {
  HauxInput, HauxButton,
} from '../../../../../components';
import ViewContext from '../../../../../ViewContext';
import AnalyticsManager from '../../../../../AnalyticsManager';
import PartnerService from '../../../../../service/PartnerService';
import './partnersTable.scss';

const headCells = [
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Nome',
  },
  {
    id: 'specialty', numeric: false, disablePadding: false, label: 'Especialidade',
  },
  {
    id: 'register', numeric: false, disablePadding: false, label: 'Registro',
  },
  {
    id: 'phone', numeric: false, disablePadding: false, label: 'Telefone',
  },
];

const Table = () => {
  const history = useHistory();
  const timeoutRef = useRef();
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 0,
    order: 'asc',
    orderBy: 'name',
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const { setLoading: setViewLoading, setSnackbarOption } = useContext(ViewContext);

  const search = async () => {
    timeoutRef.current = null;
    setLoading(true);
    try {
      const { page, order, orderBy } = pagination;
      const response = await PartnerService.getPaginatedPartners(page, searchText, orderBy, order);
      setItems(response.contents);
      setTotalPages(response.totalPages);
    // eslint-disable-next-line no-empty
    } catch (e) {}
    setLoading(false);
  };

  const debouncedSearch = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(search, 500);
  };

  useEffect(() => {
    debouncedSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, searchText]);

  const edit = (partnerId) => {
    history.push('/partners/create', { partnerId });
  };

  const deletePartner = async (partnerId) => {
    setViewLoading(true);
    try {
      await PartnerService.deletePartner(partnerId);
      setSnackbarOption({
        message: 'Parceiro excluído com sucesso!',
        type: 'success',
        open: true,
      });
    } catch (error) {
      setSnackbarOption({
        message: 'Erro ao excluir parceiro.',
        type: 'error',
        open: true,
      });
    }
    setViewLoading(false);
    search();
  };

  return (
    <>
      <div className="filterDiv">
        <HauxInput
          value={searchText}
          onChange={({ target }) => setSearchText(target.value)}
          placeholder="Buscar por nome, registro ou especialidade"
        />
        <HauxButton
          text="Novo parceiro"
          type="primary"
          onClick={() => {
            AnalyticsManager.track('Partner button_new_partner clicked');
            history.push('/partners/create');
          }}
        />
      </div>
      <HauxTable
        headCells={headCells}
        items={items}
        itemIdKey="_id"
        order={pagination.order}
        orderBy={pagination.orderBy}
        onSort={(orderBy, order) => setPagination({ ...pagination, orderBy, order })}
        page={pagination.page}
        totalPages={totalPages}
        onChangePage={(page) => setPagination({ ...pagination, page })}
        loading={loading}
        onDelete={setShowConfirmDialog}
        onEdit={edit}
      />

      <HauxConfirmDialog
        isDestructive
        handleClose={() => setShowConfirmDialog(null)}
        handleConfirm={() => {
          deletePartner(showConfirmDialog);
          setShowConfirmDialog(null);
        }}
        open={!!showConfirmDialog}
        title="Excluir parceiro"
        message="Esta ação irá apagar este parceiro. Deseja continuar?"
      />
    </>
  );
};

export default Table;
