export const initialState = {
  tab: 1,
  prospectsPage: 0,
  prospects: [],
  prospectsCount: 0,
  exportSelected: "",
  exportClientsData: [],
  exportProspectsData: [],
  selectedProspects: [],
  exportFinancesData: [],
  allProspects: [],
  loading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TAB":
      return {
        ...state,
        tab: action.payload,
      };
    case "PROSPECTS_PAGE":
      return {
        ...state,
        prospectsPage: action.payload,
      };
    case "CLIENTS_PAGE":
      return {
        ...state,
        clientsPage: action.payload,
      };
    case "PROSPECTS":
      return {
        ...state,
        prospects: action.payload,
      };
    case "PROSPECTS_COUNT":
      return {
        ...state,
        prospectsCount: action.payload,
      };
    case "EXPORT_SELECTED":
      return {
        ...state,
        exportSelected: action.payload,
      };
    case "EXPORT_CLIENTS_DATA":
      return {
        ...state,
        exportClientsData: action.payload,
      };
    case "SELECTED_CLIENTS":
      return {
        ...state,
        selectedClients: action.payload,
      };
    case "EXPORT_PROSPECTS_DATA":
      return {
        ...state,
        exportProspectsData: action.payload,
      };
    case "EXPORT_FINANCES_DATA":
      return {
        ...state,
        exportFinancesData: action.payload,
      };
    case "SELECTED_PROSPECTS":
      return {
        ...state,
        selectedProspects: action.payload,
      };
    case "ALL_PROSPECTS":
      return {
        ...state,
        allProspects: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        laoding: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
