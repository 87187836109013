import React, { useState, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import ClientService from './service/ClientService';

const ClientContext = createContext({});

function saveClientOnStorage(client) {
  localStorage.setItem('currentClient', JSON.stringify(client));
}

function loadClientFromStorage() {
  const loadedCliet = localStorage.getItem('currentClient');
  return loadedCliet ? JSON.parse(loadedCliet) : {};
}

export const ClientProvider = ({ children }) => {
  const [client, setClient] = useState(loadClientFromStorage());


  const loadClient = useCallback(async () => {
    const clientId = client.clientId || client._id;
    const loadedClient = await ClientService.getClientById(clientId);
    setClient(loadedClient);
    saveClientOnStorage(loadedClient);
  }, [client._id, client.clientId]);

  return (
    <ClientContext.Provider
      value={{
        client,
        loadClient,
        setClient: (newClient) => {
          setClient(newClient);
          saveClientOnStorage(newClient);
        },
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};


export default ClientContext;

ClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
