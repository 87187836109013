/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  useField,
  useFormikContext,
} from 'formik';
import './input.scss';

const Input = (props) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();
  const { unit } = props;


  let errors = {};

  if (meta.error && (meta.touched || form.submitCount > 0)) {
    errors = { error: true, helperText: meta.error };
  }

  return (
    <TextField
      {...field}
      {...props}
      {...errors}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
    />
  );
};

export default Input;
