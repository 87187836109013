import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  CircularProgress,
  Grid,
  HauxButton,
  HauxSnackbar,
  HauxCheck,
  HauxRadio,
  HauxInput,
  TextField,
  HauxPdfModal,
} from '../../../../components';
import AnalyticsManager from '../../../../AnalyticsManager';
import MeetingService from '../../../../service/MeetingService';
import useStyle from './styles';
import ViewContext from '../../../../ViewContext';
import ClientContext from '../../../../ClientContex';
import { getCurrentUser } from '../../../../utils/Utils';
import { FOLLOWUP } from '../../../../assets/strings.json';

const { PHYSICAL_EDUCATION } = FOLLOWUP;
const {
  GOALS,
  GOALS_LABEL,
  PHYSICAL_ACTIVITY_LEVELS,
  PHYSICAL_ACTIVITY_LEVELS_LABEL,
  NEXT_FORTNIGHT_GOALS_LABEL,
  WEEKLY_AEROBIC_MINUTES,
  WEEKLY_FORCE_TIMES,
  OTHER_NOTES,
} = PHYSICAL_EDUCATION;

const PhysicalEducationForm = ({
  initialMeeting, openChat, openPhysicalEvaluation,
}) => {
  const [snackbar, setSnackbar] = useState({});
  const [canEdit, setCanEdit] = useState(_.isEmpty(initialMeeting._id));
  const [saving, setSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [id, setId] = useState(initialMeeting._id || '');
  const [number] = useState(initialMeeting.number || '');
  const [goals, setGoals] = useState(initialMeeting.goals || []);
  const [physicalActivityLevel, setPhysicalActivityLevel] = useState(
    initialMeeting.physicalActivityLevel || '',
  );
  const [weeklyAerobicMinutes, setWeeklyAerobicMinutes] = useState(
    initialMeeting.weeklyAerobicMinutes || '',
  );
  const [weeklyForceTimes, setWeeklyForceTimes] = useState(
    initialMeeting.weeklyForceTimes || '',
  );
  const [weeklyAerobicMinutesError, setWeeklyAerobicMinutesError] = useState(
    { error: true, message: '' },
  );
  const [weeklyForceTimesError, setWeeklyForceTimesError] = useState(
    { error: true, message: '' },
  );
  const [notes, setNotes] = useState(initialMeeting.notes || '');
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const { setOpenChat } = useContext(ViewContext);
  const { client } = useContext(ClientContext);
  const user = getCurrentUser();
  const history = useHistory();
  const classes = useStyle();

  useEffect(() => {
    if (openChat) setOpenChat(true);
  }, [openChat, setOpenChat]);

  const save = () => {
    setSaving(true);

    const meetingToPost = {
      id,
      client: client.clientId,
      number,
      goals,
      physicalActivityLevel,
      weeklyAerobicMinutes,
      weeklyForceTimes,
      notes,
    };

    MeetingService.createNewPhysicalEducationMeeting(meetingToPost)
      .then(() => {
        setId('SAVED');
        setSaving(false);
        setSnackbar({
          visible: true,
          message: `Encontro ${number} salvo com sucesso!`,
          type: 'success',
        });
        setIsSaved(true);

        setTimeout(() => history.push('/client/dashboard'), 3000);
      }).catch(() => {
        setSaving(false);
        setSnackbar({
          visible: true,
          message: `Erro ao salvar encontro ${number}`,
          type: 'error',
        });
      });
  };

  const verifyFields = () => (
    !(weeklyForceTimes && weeklyAerobicMinutes && goals.length && physicalActivityLevel)
    || isSaved);

  const openMeetingPDF = async (pdfURL) => {
    AnalyticsManager.track('Dashboard button_physical_education_meeting_pdf clicked');
    setOpen(true);
    setLink(`${window.location.origin.toString()}${pdfURL}`);
  };

  const PhysicalEducationAnamnesisAndOpenPdfContainer = () => (
    <Grid container item xs className={classes.topButtonsContainer}>
      <HauxButton
        text="Ver avaliação física"
        type="secondary"
        onClick={() => {
          if (client.physicalEducationAnamnesis && client.physicalEducationAnamnesis.length > 0) {
            openPhysicalEvaluation();
          } else {
            setSnackbar({
              visible: true,
              message: 'Este cliente ainda não realizou nenhuma avaliação física!',
              type: 'error',
              action: (
                <button
                  className="reloadButton"
                  type="button"
                  onClick={() => history.push('/client/physicalEducationConsult', { professional: user.name })}
                >
                  Criar nova
                </button>
              ),
            });
          }
        }}
      />
      <HauxButton
        text="Guia de atendimento"
        onClick={() => openMeetingPDF(initialMeeting.pdf)}
      />
    </Grid>
  );

  const renderGoalsContainer = () => (
    <Grid container>
      <Grid item xs={12}>
        <span className="h6StylePurple">{GOALS_LABEL}</span>
      </Grid>
      {
        GOALS.map((goal) => (
          <Grid item xs={6} key={goal} className={classes.fixMarginLeft}>
            <HauxCheck
              label={goal}
              value={goal}
              checked={goals.includes(goal)}
              disabled={!canEdit}
              handleChange={() => {
                if (goals.includes(goal)) {
                  setGoals(goals.filter((g) => g !== goal));
                } else {
                  setGoals([...goals, goal]);
                }
              }}
            />
          </Grid>
        ))
      }
    </Grid>
  );

  const renderPhysicalActivityLevelsContainer = () => (
    <Grid container className="marginTp">
      <Grid item xs={12}>
        <span className="h6StylePurple">{PHYSICAL_ACTIVITY_LEVELS_LABEL}</span>
      </Grid>
      {
        PHYSICAL_ACTIVITY_LEVELS.map((level) => (
          <Grid item xs={3} key={level} className={classes.fixMarginLeft}>
            <HauxRadio
              label={level}
              value={level}
              disabled={!canEdit}
              checked={physicalActivityLevel === level}
              handleChange={() => setPhysicalActivityLevel(level)}
            />
          </Grid>
        ))
      }
    </Grid>
  );

  const renderNextFortnightGoalsContainer = () => (
    <Grid container className="marginTp">
      <Grid item xs={12}>
        <span className="h6StylePurple">{NEXT_FORTNIGHT_GOALS_LABEL}</span>
      </Grid>
      <Grid item xs={12} className={`${classes.weeklyContainer} ${classes.fixMarginLeft}`}>
        <span className="paragraph fontSmall">{WEEKLY_AEROBIC_MINUTES}</span>
        <HauxInput
          placeholder="Ex: 300"
          disabled={!canEdit}
          value={weeklyAerobicMinutes}
          onChange={(e) => {
            const { value } = e.target;
            const numericValue = Number(value);
            if (numericValue >= 0) {
              setWeeklyAerobicMinutes(Number.parseInt(numericValue, 10));
            }
          }}
          error={weeklyAerobicMinutesError.error}
          errorMsg={weeklyAerobicMinutesError.message}
          onBlur={() => {
            if (!weeklyAerobicMinutes) {
              setWeeklyAerobicMinutesError({ error: true, message: 'Campo obrigatório' });
            }
          }}
          onFocus={() => {
            if (weeklyAerobicMinutesError.error) {
              setWeeklyAerobicMinutesError({ error: false, message: '' });
            }
          }}
        />
        <span className="paragraph fontSmall">min</span>
      </Grid>
      <Grid item xs={12} className={`${classes.weeklyContainer} ${classes.fixMarginLeft}`}>
        <span className="paragraph fontSmall">{WEEKLY_FORCE_TIMES}</span>
        <HauxInput
          placeholder="Ex: 3"
          disabled={!canEdit}
          value={weeklyForceTimes.toString()}
          onChange={(e) => {
            const { value } = e.target;
            const numericValue = Number(value);
            if (numericValue >= 0) {
              setWeeklyForceTimes(Number.parseInt(numericValue, 10));
            }
          }}
          error={weeklyForceTimesError.error}
          errorMsg={weeklyForceTimesError.message}
          onBlur={() => {
            if (!weeklyForceTimes) {
              setWeeklyForceTimesError({ error: true, message: 'Campo obrigatório' });
            }
          }}
          onFocus={() => {
            if (weeklyForceTimesError.error) {
              setWeeklyForceTimesError({ error: false, message: '' });
            }
          }}
        />
        <span className="paragraph fontSmall">vezes por semana</span>
      </Grid>
    </Grid>
  );

  const renderOtherNotesContainer = () => (
    <Grid container className="marginTp">
      <Grid item xs={12}>
        <TextField
          label={OTHER_NOTES}
          multiline
          fullWidth
          value={notes}
          disabled={!canEdit}
          onChange={(e) => setNotes(e.target.value)}
          className={classes.contentText}
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
    </Grid>
  );

  const renderEditAndSaveButtonsContainer = () => (
    <Grid container className={classes.bottomButtonsContainer}>
      {saving && <CircularProgress className={classes.progress} />}
      {
        !_.isEmpty(id)
        && (
          <HauxButton
            text="Editar"
            onClick={() => setCanEdit(true)}
            type="secondary"
            disabled={isSaved}
          />
        )
      }
      <HauxButton
        text="Salvar"
        onClick={save}
        disabled={verifyFields()}
      />
    </Grid>
  );

  return (
    <div>
      <Grid container>
        {
          client.franchises[0].slug === 'at_home' && (
            <Grid item xs={4}>
              <HauxButton
                text="Tele atendimento"
                onClick={() => setOpenChat(true)}
              />
            </Grid>
          )
        }
        <PhysicalEducationAnamnesisAndOpenPdfContainer />
      </Grid>

      {renderGoalsContainer()}
      {renderPhysicalActivityLevelsContainer()}
      {renderNextFortnightGoalsContainer()}
      {renderOtherNotesContainer()}
      {renderEditAndSaveButtonsContainer()}

      <HauxSnackbar
        openSnackbar={snackbar.visible}
        message={snackbar.message}
        type={snackbar.type}
        action={snackbar.action}
        onClose={() => setSnackbar({ ...snackbar, visible: false })}
      />

      <HauxPdfModal open={open} link={link} handleClose={() => setOpen(false)} />
    </div>
  );
};

export default PhysicalEducationForm;

PhysicalEducationForm.propTypes = {
  initialMeeting: PropTypes.shape({
    _id: PropTypes.string,
    number: PropTypes.number,
    goals: PropTypes.array,
    physicalActivityLevel: PropTypes.string,
    weeklyAerobicMinutes: PropTypes.number,
    weeklyForceTimes: PropTypes.number,
    notes: PropTypes.string,
    pdf: PropTypes.string,
  }),
  openChat: PropTypes.bool,
  openPhysicalEvaluation: PropTypes.func,
};

PhysicalEducationForm.defaultProps = {
  initialMeeting: {
    _id: '',
    number: 1,
    goals: [],
    physicalActivityLevel: '',
    weeklyAerobicMinutes: 0,
    weeklyForceTimes: 0,
    notes: '',
    pdf: '',
  },
  openChat: false,
  openPhysicalEvaluation: null,
};
