import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { HauxDropdownButton } from '../../../../../../components';
import { getUserFranchises } from "../../../../../../utils/Utils";
import { ReactComponent as AcChange } from '../../../../../../assets/svgs/ac-change-local.svg';

const DEFAULT_OPTION_SLUG = 'caxias';

const SelectFranchise = ({ setFranchise }) => {
  const [options] = useState(() => getUserFranchises());
  const [optionSelected, setOptionSelected] = useState(0);

  useEffect(() => {
    if (!options || !options.length) return;

    if (options.length > 1) {
      const indexOfDefaultOption = options
        .findIndex(({ slug }) => DEFAULT_OPTION_SLUG === slug);
      
      setOptionSelected(indexOfDefaultOption);
    }
  }, [options]);

  useEffect(() => {
    if (options.length)
      setFranchise(options[optionSelected]._id);
  }, [optionSelected, options, setFranchise]);

  const handleChange = (_, index) => setOptionSelected(index);

  if (options.length > 1) return (
    <HauxDropdownButton
      label={options[optionSelected].name || "Clinica"}
      options={options.map(({ name }) => name)}
      handleChange={handleChange}
      Icon={AcChange}
    />
  );

  return null;
}

SelectFranchise.propTypes = {
  setFranchise: func,
}

export default SelectFranchise;