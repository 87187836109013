import { evaluationQuestions } from '../../utils/Utils';

const initialQuestions = [...evaluationQuestions];

export const initialState = {
  physicalActivities: [],
  musclePains: [],
  difficultyMoving: [],
  questions: initialQuestions,
  rerender: false,
  stressLevel: 0,
  exitFormDialog: false,
  renderOtherMuscleField: false,
  renderOtherActivityField: false,
  otherPain: '',
  otherPainError: false,
  otherPainErrorMsg: '',
  otherActivity: '',
  otherActivityError: false,
  otherActivityErrorMsg: '',
  formCompleted: false,
  saveFormClicked: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PHYSICAL_ACTIVITIES':
      return {
        ...state,
        physicalActivities: action.payload,
      };
    case 'MUSCLE_PAINS':
      return {
        ...state,
        musclePains: action.payload,
      };
    case 'DIFFICULTY_MOVING':
      return {
        ...state,
        difficultyMoving: action.payload,
      };
    case 'QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'RERENDER':
      return {
        ...state,
        rerender: action.payload,
      };
    case 'STRESS_LEVEL':
      return {
        ...state,
        stressLevel: action.payload,
      };
    case 'EXIT_FORM_DIALOG':
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case 'RENDER_OTHER_MUSCLE_FIELD':
      return {
        ...state,
        renderOtherMuscleField: action.payload,
      };
    case 'RENDER_OTHER_ACTIVITY_FIELD':
      return {
        ...state,
        renderOtherActivityField: action.payload,
      };
    case 'OTHER_PAIN':
      return {
        ...state,
        otherPain: action.payload,
      };
    case 'OTHER_PAIN_ERROR':
      return {
        ...state,
        otherPainError: action.payload,
      };
    case 'OTHER_PAIN_ERROR_MSG':
      return {
        ...state,
        otherPainErrorMsg: action.payload,
      };
    case 'OTHER_ACTIVITY_ERROR':
      return {
        ...state,
        otherActivityError: action.payload,
      };
    case 'OTHER_ACTIVITY_ERROR_MSG':
      return {
        ...state,
        otherActivityErrorMsg: action.payload,
      };
    case 'OTHER_ACTIVITY':
      return {
        ...state,
        otherActivity: action.payload,
      };
    case 'FORM_COMPLETED':
      return {
        ...state,
        formCompleted: action.payload,
      };
    case 'SAVE_FORM_CLICKED':
      return {
        ...state,
        saveFormClicked: action.payload,
      };
    case 'RESET_QUESTIONS':
      return {
        ...state,
        questions: [
          {
            question: 'Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?',
            value: '',
          },
          {
            question: 'Você sente dores no peito quando pratica atividades físicas?',
            value: '',
          },
          {
            question: 'No último mês, você sentiu dores no peito quando praticou atividade física?',
            value: '',
          },
          {
            question: 'Você apresenta desequilíbrio devido à tontura e/ou perda de consciência?',
            value: '',
          },
          {
            question: 'Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?',
            value: '',
          },
          {
            question: 'Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?',
            value: '',
          },
          {
            question: 'Sabe de alguma outra razão pela qual você não deve praticar atividade física?',
            value: '',
          },
        ],
      };
    default:
      return {
        ...state,
      };
  }
};
