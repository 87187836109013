import axios from "../middlewares/axios";

const defaultRoute = "/user/users";

class UserService {
  static getUsers() {
    const url = "/user/allUsers";
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getUser(userId) {
    const url = `${defaultRoute}/${userId}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getPaginatedUsers(page, searchText, orderBy, order) {
    const url = `${defaultRoute}?page=${page}&filter=${searchText}&orderBy=${orderBy}&order=${order}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static putUser(user) {
    const url = `${defaultRoute}`;
    return axios
      .put(url, user)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postUser(newUser) {
    const url = `${defaultRoute}`;
    return axios
      .post(url, newUser)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteUser(userId) {
    const url = `${defaultRoute}/${userId}`;
    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default UserService;
