export const initialState = {
  clients: [],
  clientsCount: 0,
  currentClinic: '',
  page: 1,
  selectedClients: [],
  userClinics: [],
  filters: {
    active: true,
    inactive: true,
    next: false,
    recent: false,
  },
  searchText: '',
  warnings: [],
  inactives: [],
  loading: true,
  totalPages: 1,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_PAGE_LOAD':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'CHANGE_FILTERS':
      return {
        ...state,
        filters: action.payload,
      };
    case 'CHANGE_CLINIC':
      return {
        ...state,
        currentClinic: state.userClinics[action.payload],
        selectedClients: [],
      };
    case 'SET_PAGINATION_RESULTS':
      return {
        ...state,
        clients: action.payload.clients,
        clientsCount: action.payload.count,
        totalPages: action.payload.pages,
      };
    case 'SET_SELECTED_CLIENTS':
      return {
        ...state,
        selectedClients: action.payload,
      };
    case 'UPDATE_CLIENTS_LIST':
      return {
        ...state,
        clients: action.payload,
      };
    case 'CHANGE_PAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
