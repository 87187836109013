import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontFamily: 'Graviola',
  },
}));

const HauxExpansionPanel = ({ items }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderItems = () => (
    items.map((item) => (
      <ExpansionPanel
        key={item.name}
        expanded={expanded === item.name}
        onChange={handleChange(item.name)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <span className="paragraph">{item.name}</span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {item.renderDetails}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ))
  );

  return (
    <div className={classes.root}>
      {renderItems()}
    </div>
  );
};

HauxExpansionPanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

HauxExpansionPanel.defaultProps = {
  items: [],
};

export default HauxExpansionPanel;
