/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import './hauxInputSearch.scss';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 50,
  },
  iconButton: {
    padding: 10,
  },
  clientStatus: {
    borderRadius: 50,
    backgroundColor: '#E0E0E0',
    padding: '4px 8px 4px 8px',
    width: 98,
    display: 'flex',
    justifyContent: 'center',
  },
  clientActive: {
    backgroundColor: '#4ED25D',
  },
  clientInactive: {
    backgroundColor: '#DB8D8D',
  },
  optionContainer: {
    borderWidth: 2,
    display: 'flex',
  },
}));

const inputBorderColor = 'rgba(0, 0, 0, 0.23)';

const HAUXTextField = withStyles({
  root: {
    '& input': {
      fontFamily: 'Graviola',
      color: '#737373',
      '&::placeholder': {
        fontFamily: 'Graviola',
      },
    },
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
      color: (props) => (props.value ? '#685DA8' : '#737373'),
    },
    '& label.Mui-focused': {
      color: '#685DA8',
      border: 'none',
    },
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: '#D64E0F',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 50,
      '& fieldset': {
        borderColor: inputBorderColor,
      },
      '&:hover fieldset': {
        borderColor: inputBorderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: inputBorderColor,
      },
    },
  },
})(TextField);

export { HAUXTextField };

const autocompleteFilter = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  stringify: (option) => option.name || option.value || option,
});

const HauxInputSearch = ({
  autocompleteProps,
  noOptionsText,
  label,
  placeholder,
  onChange,
  onSelect,
  value,
  renderOption,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      options={autocompleteProps.options.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }

        return 0;
      })}
      getOptionLabel={autocompleteProps.getOptionLabel
        ? autocompleteProps.getOptionLabel
        : (option) => option.value}
      noOptionsText={noOptionsText}
      id={label}
      classes={{
        option: classes.option,
      }}
      onChange={(event, onChangeValue, reason) => {
        onChange(event, onChangeValue, reason);
        if (onChangeValue) {
          onSelect(onChangeValue);
        }
      }}
      value={value}
      inputValue={value}
      filterOptions={autocompleteFilter}
      className={classes.autocomplete}
      renderInput={(params) => (
        <HAUXTextField
          {...params}
          className={classes.input}
          placeholder={placeholder}
          variant="outlined"
          value={value}
          onChange={onChange}
          fullWidth
        />
      )}
      renderOption={(option) => renderOption(option)}
    />
  );
};

export default HauxInputSearch;

HauxInputSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  autocomplete: PropTypes.bool,
  autocompleteProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  renderOption: PropTypes.func,
  disabled: PropTypes.bool,
};

HauxInputSearch.defaultProps = {
  label: '',
  placeholder: 'Buscar cadastro',
  noOptionsText: 'Não encontrado',
  error: false,
  errorMsg: '',
  fullWidth: false,
  autocomplete: false,
  autocompleteProps: {},
  value: '',
  onChange: () => <></>,
  onSelect: () => <></>,
  onBlur: () => <></>,
  onFocus: () => <></>,
  renderOption: () => <></>,
  disabled: false,
};
