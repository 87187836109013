import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { object, func } from 'prop-types';
import { isNull, isEqual } from 'lodash';

import Modal from '../modal';
import Form from '../../../components/form';
import ConfirmDialog from '../../../../../components/HauxConfirmDialog';

import MeetingService from '../../../../../service/MeetingService';
import ViewContext from '../../../../../ViewContext';

const EditModal = ({ selectedClient, onSave, onClose }) => {
  const { handleRequestsErrors } = useContext(ViewContext);
  const [training, setTraining] = useState(null);
  const [editedTraining, setEditedTraining] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const getTraining = useCallback(async (id) => {
    try {
      const data = await MeetingService
        .getTrainingMeetingsId([id]);

      if (data.length) {
        const {
          client,
          stretching,
          mobility,
          power,
          force,
          conditioning,
        } = data[0];

        const tng = {
          client: client._id,
          stretching,
          mobility,
          power,
          force,
          conditioning,
        }

        setEditedTraining(tng);
        setTraining(tng);
      }
    } catch (err) {
      handleRequestsErrors(err)
    }
  }, [handleRequestsErrors]);

  useEffect(() => {
    if (!isNull(selectedClient)) {
      getTraining(selectedClient.clientId);
    }
  }, [getTraining, selectedClient]);

  const handleSave = async () => {
    try {
      const data = await MeetingService
        .putTrainingMeeting(editedTraining.client, editedTraining);

      if (data) onSave(data);
    } catch (err) {
      handleRequestsErrors(err);
    }
  };

  const handleModalClose = () => {
    if (isEqual(training, editedTraining))
      onClose();
    else
      setShowDialog(true);
  };

  return (
    <Modal
      open={!isNull(training)}
      title={selectedClient.name}
      onClose={handleModalClose}
      onSave={handleSave}
    >
      <ConfirmDialog
        open={showDialog}
        title="Sair sem salvar?"
        message="Se você sair perderá suas modificações."
        handleClose={() => setShowDialog(false)}
        handleConfirm={onClose}
      />
      <Form
        training={editedTraining}
        setTraining={setEditedTraining}
      />
    </Modal>
  );
};

EditModal.propTypes = {
  selectedClient: object,
  onSave: func,
  onClose: func,
};

export default EditModal;
