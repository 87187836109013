/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Form, Formik } from 'formik';
import {
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { GpsFixed, SignalCellularAlt, CalendarToday } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import _, { isString } from 'lodash';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  formatTextToFloat,
  physicalActivitiesArray,
  musclePainsArray,
  difficultyMovingArray,
} from '../../utils/Utils';
import HAUXInput from '../../components/HauxInput';
import {
  MaskInput,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Input,
} from '../../components/Form';
import useStyles from './components/useStyles';
import icProfilePurple from '../../assets/svgs/ic-profile-purple.svg';
import icActivity from '../../assets/svgs/ic-activity.svg';
import icMetric from '../../assets/svgs/ic-metric.svg';
import icCalculator from '../../assets/svgs/ic-calculator.svg';
import icInfo from '../../assets/svgs/ic-info.svg';
import icAttention from '../../assets/svgs/ic-attention.svg';
import icHealth from '../../assets/svgs/ic-heart.svg';
import icUpload from '../../assets/svgs/ic-upload.svg';
import AnalyticsManager from '../../AnalyticsManager';
import {
  recreationActivityLevels,
  workActivityLevels,
  healthProblems,
  NursingFormSchema,
  getSarcopenicAlert,
  getFileConfig,
  saveStykeMeasures,
  saveInBodyMeasures,
  evaluationQuestions,
  getCadiovascularRiskClassification,
} from './utils/index';
import ViewContext from '../../ViewContext';
import ClientContext from '../../ClientContex';
import ClientService from '../../service/ClientService';
import MeetingService from '../../service/MeetingService';
import strings from '../../assets/strings.json';
import HauxSlider from '../../components/HauxSlider';
import pdfs from '../../assets/pdfs';

const { responsibilityTermPhysical } = pdfs;

const {
  NONE,
  TYPE_ONE_DIABETES,
  TYPE_TWO_DIABETES,
  DYSLIPIDEMIA,
  ARTERIAL_HYPERTENSION,
  CARDIOVASCULAR_DISEASES,
  PHYSICAL_ACTIVITIES_YOU_PRACTICE,
  SUCINT_ANAMNESIS,
  STRESS_LEVEL,
  OTHER,
  HAVE_FREQUENT_MUSCLE_PAIN,
  DIFFICULTIES_IN_MOVING,
  PARQ_CHECK,
  PARQ_MESSAGE,
  FOLLOWUP,
} = strings;

const { PHYSICAL_EDUCATION } = FOLLOWUP;
const {
  GOALS,
  GOALS_LABEL,
  PHYSICAL_ACTIVITY_LEVELS,
  PHYSICAL_ACTIVITY_LEVELS_LABEL,
  NEXT_FORTNIGHT_GOALS_LABEL,
  WEEKLY_AEROBIC_MINUTES,
  WEEKLY_FORCE_TIMES,
  OTHER_NOTES,
} = PHYSICAL_EDUCATION;

const TextInput = ({
  label, name, xs, showMinMax, showOptions, onChange, ...rest
}) => (
  <Grid item xs={xs} style={{ marginBottom: 10 }}>
    <MaskInput
      label={label}
      name={name}
      fullWidth
      decimalSeparator=","
      {...(onChange ? { onChange } : {})}
      {...rest}
    />
    {showMinMax && (
      <Grid spacing={2} style={{ display: 'flex', marginTop: 5 }}>
        <MaskInput
          placeholder="Min"
          name={`references.${name}.min`}
          fullWidth
          size="small"
          decimalSeparator=","
          style={{ marginRight: 2 }}
          {...rest}
        />
        <MaskInput
          placeholder="Max"
          name={`references.${name}.max`}
          fullWidth
          size="small"
          decimalSeparator=","
          style={{ marginLeft: 2 }}
          {...rest}
        />
      </Grid>
    )}
    {showOptions && (
      <Grid spacing={2} style={{ display: 'flex', marginTop: 5 }}>
        <Input
          label={`Classificação da ${label}`}
          name={`references.${name}`}
          fullWidth
          size="small"
          select
        >
          {showOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Input>
      </Grid>
    )}
  </Grid>
);

const NursingForm = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { client } = useContext(ClientContext);
  const { setRouteProperties, setLoading } = useContext(ViewContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [otherPhysical, setOtherPhysical] = useState(false);
  const [otherMuscle, setOtherMuscle] = useState(false);
  const [formPhysicalEducation, setFormPhysicalEducation] = useState(false);
  const [formMeetingPhysical, setFormMeetingPhysical] = useState(false);
  const [formAdditionalInfo, setFormAdditionalInfo] = useState(false);
  const [numberMeeting, setNumberMeeting] = useState(0);
  const { professional, pendingNursingAnamnesis } = location.state;
  const [savedData, setSavedData] = useState({});
  const [reload, setReload] = useState('');
  const formik = useRef();

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'acompanhamento clínico',
      breadCrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {

  }, [pendingNursingAnamnesis]);

  useEffect(() => {
    const getInit = async () => {
      const meetings = await MeetingService.getPhysicalEducationMeetings(
        client.clientId,
      );

      formik.current.setFieldValue(
        'meetingPhysicalEducation',
        meetings.data[meetings.data.length - 1],
      );

      setNumberMeeting(meetings.data.length + 1);


      if (client.nursingAnamnesis && client.nursingAnamnesis.length > 0) {
        const ref = (client.nursingAnamnesis[
          client.nursingAnamnesis.length - 1
        ].references ? client.nursingAnamnesis[
            client.nursingAnamnesis.length - 1
          ].references : {});
        const { muscleMass, ...references } = ref;

        formik.current.setFieldValue(
          'references',
          references,
        );
        formik.current.setFieldValue(
          'leisureTimeActivity',
          String(
            client.nursingAnamnesis[client.nursingAnamnesis.length - 1]
              .leisureTimeActivity,
          ),
        );
        formik.current.setFieldValue(
          'workActivity',
          String(
            client.nursingAnamnesis[client.nursingAnamnesis.length - 1]
              .workActivity,
          ),
        );
        formik.current.setFieldValue(
          'healthProblems',
          client.nursingAnamnesis[client.nursingAnamnesis.length - 1]
            .healthProblems || [],
        );
        setReload(moment().format());
      }

      if (
        client.physicalEducationAnamnesis
        && client.physicalEducationAnamnesis.length > 0
      ) {
        const physical = client.physicalEducationAnamnesis[
          client.physicalEducationAnamnesis.length - 1
        ];

        // eslint-disable-next-line prefer-destructuring
        physical.parqQuiz = physical.parqQuiz && physical.parqQuiz.length > 0
          ? physical.parqQuiz[0]
          : undefined;
        if (physical.parqQuiz && physical.parqQuiz._id) {
          delete physical.parqQuiz._id;
        }
        Object.keys(physical.parqQuiz || {}).forEach((key) => {
          physical.parqQuiz[key] = physical.parqQuiz[key] ? 'yes' : 'no';
        });

        formik.current.setFieldValue('physicalEducation', physical);
      }
    };

    const getPending = () => {
      console.log('Chamou');
      const { files, ...values } = pendingNursingAnamnesis;
      formik.current.setValues(values);
      setFormPhysicalEducation(pendingNursingAnamnesis.formPhysicalEducation);
      setFormMeetingPhysical(pendingNursingAnamnesis.formMeetingPhysical);
      setFormAdditionalInfo(pendingNursingAnamnesis.formAdditionalInfo);
      calculateIMC(
        pendingNursingAnamnesis.weight,
        pendingNursingAnamnesis.height,
      );
      calculateIMME(
        pendingNursingAnamnesis.muscleMass,
        pendingNursingAnamnesis.height,
      );
      setReload(moment().format());
    };

    if (formik.current && pendingNursingAnamnesis) {
      getPending();
    } else if (numberMeeting === 0) {
      getInit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingNursingAnamnesis]);

  const updateGoal = async (values) => {
    setLoading(true);

    await ClientService.postNewNursingAnamnesis(
      client.clientId,
      savedData,
      values.goal,
    );

    setLoading(false);
    history.push('/client/dashboard');
  };

  const submit = async (values, isPending = false) => {
    if (isPending) {
      setLoading(true);
      await ClientService.postNewPendingNursingAnamnesis(
        client.clientId,
        {
          ...values,
          formMeetingPhysical,
          formAdditionalInfo,
          formPhysicalEducation,
          date: new Date(),
        },
        null,
      );
      setLoading(false);
      history.push('/client/dashboard');
      return;
    }

    setLoading(true);
    AnalyticsManager.track('NurseForm button_save clicked');

    const healthProblemsValues = values.healthProblems || [];

    const diabetes = healthProblemsValues.includes(TYPE_ONE_DIABETES)
      || healthProblemsValues.includes(TYPE_TWO_DIABETES);

    const cardio = healthProblemsValues.includes(DYSLIPIDEMIA)
      || healthProblemsValues.includes(ARTERIAL_HYPERTENSION)
      || healthProblemsValues.includes(CARDIOVASCULAR_DISEASES);

    const valuesData = { references: {} };

    Object.keys(values).forEach((key) => {
      if (_.isString(values[key])) {
        valuesData[key] = values[key].replace(',', '.');
        if (values.references && values.references[key] && !isString(values.references[key])) {
          valuesData.references[key] = {
            min: values.references[key].min.replace(',', '.'),
            max: values.references[key].max.replace(',', '.'),
          };
        } else {
          valuesData.references[key] = values.references[key];
        }
      } else if (key !== 'references') {
        valuesData[key] = values[key];
      }
    });

    const stykuMeasure = await saveStykeMeasures(values.files);
    const inBodyMeasure = await saveInBodyMeasures(values.inbodyfiles[0]);

    const nursingAnamnesis = {
      ...valuesData,
      stykuMeasure,
      inBodyMeasure,
      restriction: {
        diabetes,
        cardio,
      },
      date: moment(),
    };

    if (nursingAnamnesis.physicalEducation) {
      delete nursingAnamnesis.physicalEducation;
    }

    if (nursingAnamnesis.additionalInfo) {
      delete nursingAnamnesis.additionalInfo;
    }

    if (nursingAnamnesis.meetingPhysicalEducation) {
      delete nursingAnamnesis.meetingPhysicalEducation;
    }

    if (pendingNursingAnamnesis) {
      nursingAnamnesis.pendingNursingAnamnesis = pendingNursingAnamnesis._id;
    }

    const data = await ClientService.postNewNursingAnamnesis(
      client.clientId,
      nursingAnamnesis,
      null,
    );

    setSavedData(nursingAnamnesis);

    if (formPhysicalEducation) {
      const form = values.physicalEducation;

      if (otherPhysical) {
        form.yourActivities.push(form.yourActivitiesOther);
      }

      if (otherMuscle) {
        form.musclePains.push(form.musclePainsOther);
      }

      Object.keys(form.parqQuiz || {}).forEach((key) => {
        form.parqQuiz[key] = form.parqQuiz[key] === 'yes';
      });

      form.professional = professional;
      form.date = moment();

      await ClientService.postNewPhysicalEducationForm(client.clientId, form);
    }

    if (formMeetingPhysical) {
      const meeting = values.meetingPhysicalEducation;

      await MeetingService.createNewPhysicalEducationMeeting({
        ...meeting,
        client: client.clientId,
        number: numberMeeting,
      });
    }

    if (formAdditionalInfo) {
      await ClientService.postNewClientAdditionalInfo(
        client.clientId,
        values.additionalInfo,
      );
    }

    formik.current.setFieldValue('goal', data.goal);
    setOpenDialog(true);
    setLoading(false);
  };

  const calculateIMC = (weight, height) => {
    if (formik.current) {
      const fixedWeight = parseFloat(String(weight || '').replace(',', '.'));
      const fixedHeight = parseFloat(String(height || '').replace(',', '.'));
      formik.current.setFieldValue(
        'imc',
        (fixedWeight / (fixedHeight * fixedHeight)).toFixed(2).replace('.', ','),
      );
    }
  };

  const checkParqQuiz = (values) => {
    let val = false;
    Object.keys(values).forEach((key) => {
      if (values[key] === 'yes') {
        val = true;
      }
    });
    return val;
  };

  const calculateIMME = (mme, height) => {
    if (formik.current) {
      const fixedMME = parseFloat((mme || '').replace(',', '.'));
      const fixedHeight = parseFloat((height || '').replace(',', '.'));
      formik.current.setFieldValue(
        'muscleMassIndex',
        (fixedMME / (fixedHeight * fixedHeight)).toFixed(2).replace('.', ','),
      );
    }
  };

  const calculateCalories = async (values, goalWeight) => {
    const params = {
      ...values,
      goalWeight,
      weight: formatTextToFloat(values.weight),
      height: formatTextToFloat(values.height),
      leisureTimeActivity: values.leisureTimeActivity,
      workActivity: values.workActivity,
      id: client.clientId,
    };
    try {
      const data = await ClientService.getMaxMinCalories(params);
      formik.current.setFieldValue('goal.minCalories', data.minCalories);
      formik.current.setFieldValue('goal.maxCalories', data.maxCalories);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const renderSection = ({
    values,
    icon,
    title,
    render,
    name,
    object,
    multiple,
    errors,
  }) => (
    <Paper className={classes.paperContainer} elevation={2}>
      <Grid container justify="center">
        <Grid item xs={10} className={classes.paperTitleContainer}>
          <div className="paperTitle">
            <img src={icon} alt="" className="iconStyle" />
            <span className="title">{title}</span>
          </div>
        </Grid>

        <Grid container spacing={2} justify="center">
          {render({
            values,
            errors,
            name,
            object,
            multiple,
          })}
        </Grid>
      </Grid>
    </Paper>
  );

  const renderBasicInfo = () => (
    <>
      <Grid item xs={10}>
        <HAUXInput label="Nome" fullWidth disabled value={client.name} />
      </Grid>
      <Grid item xs={10}>
        <HAUXInput
          label="Data de Nascimento"
          fullWidth
          disabled
          value={client.birthDate}
        />
      </Grid>
    </>
  );

  const renderActivities = (props) => {
    const {
      values, name, object, multiple, errors,
    } = props;
    const Group = multiple ? FormGroup : RadioGroup;
    const Item = multiple ? Checkbox : Radio;

    return (
      <>
        <Grid item xs={10} key={reload}>
          <Group name={name}>
            {object.map((activity) => (
              <Grid
                key={activity.level}
                container
                direction="row"
                className={classes.activitiesContainer}
                alignItems="center"
              >
                <Grid item md={4} sm={5}>
                  <Item
                    name={`${activity.value}`}
                    option={activity.level}
                    value={`${activity.value}`}
                    key={
                      values[name] && values[name].includes(`${activity.value}`)
                    }
                    checked={
                      values[name] && values[name].includes(`${activity.value}`)
                    }
                    onChange={(e) => {
                      const { value } = activity;
                      if (
                        formik.current.values[name]
                        && formik.current.values[name].includes(value)
                      ) {
                        formik.current.setFieldValue(
                          name,
                          formik.current.values[name].filter((i) => i !== value),
                        );
                      } else if (e.target.value === NONE) {
                        formik.current.setFieldValue(name, [NONE]);
                      } else if (e.target.value === TYPE_ONE_DIABETES) {
                        formik.current.setFieldValue(name, [
                          ...(formik.current.values[name] || []).filter(
                            (i) => i !== TYPE_TWO_DIABETES,
                          ),
                          value,
                        ]);
                      } else if (e.target.value === TYPE_TWO_DIABETES) {
                        formik.current.setFieldValue(name, [
                          ...(formik.current.values[name] || []).filter(
                            (i) => i !== TYPE_ONE_DIABETES,
                          ),
                          value,
                        ]);
                      } else {
                        formik.current.setFieldValue(name, [
                          ...(formik.current.values[name] || []).filter(
                            (i) => i !== NONE,
                          ),
                          value,
                        ]);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={8} sm={7}>
                  <span className="description">{activity.description}</span>
                </Grid>
              </Grid>
            ))}
          </Group>
        </Grid>
      </>
    );
  };

  const renderBodyComposition = ({ values }) => (
    <Grid container spacing={2} justify="center" xs={12}>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="bloodPressure"
          label="Pressão arterial"
          xs={3}
          unit="mmHg"
          format="###/###"
        />
        <TextInput
          name="height"
          label="Altura"
          placeholder="0,00"
          xs={3}
          unit="m"
          format="#,##"
          onChange={(e) => {
            formik.current.setFieldValue('height', e.target.value);
            calculateIMC(formik.current.values.weight, e.target.value);
            calculateIMME(formik.current.values.muscleMass, e.target.value);
          }}
        />
        <TextInput
          name="weight"
          label="Peso"
          xs={3}
          unit="kg"
          onChange={(e) => {
            formik.current.setFieldValue('weight', e.target.value);
            calculateIMC(e.target.value, formik.current.values.height);
          }}
        />
        <TextInput
          name="imc"
          label="IMC"
          xs={3}
          unit="kg/m2"
          decimalSeparator=","
          disabled
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="visceralFatLevel"
          label="Nível de Gordura Visceral"
          xs={3}
          decimalSeparator=","
        />
        <TextInput
          name="basalMetabolicRate"
          label="Taxa Metabólica Basal"
          xs={3}
          unit="KCal/dia"
          decimalSeparator=","
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="bodyWater"
          label="Água Corporal Total"
          xs={4}
          showMinMax
          unit="litros"
        />
        <TextInput
          name="freeMass"
          label="Massa Livre de Gordura"
          xs={4}
          showMinMax
          unit="kg"
        />
        <TextInput
          name="fatMass"
          label="Massa de Gordura"
          xs={4}
          showMinMax
          unit="kg"
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="muscleMass"
          label="Massa Muscular Esquelética"
          xs={6}
          showOptions={['Abaixo', 'Adequado']}
          unit="kg"
          onChange={(e) => {
            formik.current.setFieldValue('muscleMass', e.target.value);
            calculateIMME(e.target.value, formik.current.values.height);
          }}
        />
        <TextInput
          name="bodyFatPercentage"
          label="Percentual de Gordura Corporal"
          xs={6}
          showMinMax
          unit="%"
        />
      </Grid>
      <Grid container spacing={2} xs={10} style={{ alignItems: 'center' }}>
        <TextInput
          name="muscleMassIndex"
          label="Índice de Massa Muscular Esquelética"
          xs={5}
          unit="kg/m2"
          disabled
        />
        {values.muscleMassIndex
          && values.muscleMassIndex !== 'NaN'
          && values.muscleMassIndex.length > 0 && (
            <div
              className={classes.sarcopenicContainer}
              style={{
                width: 264,
                backgroundColor: getSarcopenicAlert(
                  client.biologicalSex,
                  values.muscleMassIndex,
                ).color,
              }}
            >
              <img src={icAttention} alt="" className="iconStyle" />
              <span className="paragraphWhite">
                {
                  getSarcopenicAlert(
                    client.biologicalSex,
                    values.muscleMassIndex,
                  ).alert
                }
              </span>
            </div>
        )}
      </Grid>
      <Grid container spacing={2} xs={10} style={{ marginTop: 30 }}>
        <TextInput
          name="bicepLeft"
          label="Circunferência braço E"
          xs={4}
          unit="cm"
        />
        <TextInput
          name="bicepRight"
          label="Circunferência braço D"
          xs={4}
          unit="cm"
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="calfLeft"
          label="Circunferência Panturrilha E"
          xs={4}
          unit="cm"
        />
        <TextInput
          name="calfRight"
          label="Circunferência Panturrilha D"
          xs={4}
          unit="cm"
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="midThighLeft"
          label="Circunferência Coxa E"
          xs={4}
          unit="cm"
        />
        <TextInput
          name="midThighRight"
          label="Circunferência Coxa D"
          xs={4}
          unit="cm"
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="torax"
          label="Circunferência Torácica"
          xs={4}
          unit="cm"
        />
        <TextInput
          name="hip"
          label="Circunferência do Quadril"
          xs={4}
          unit="cm"
        />
      </Grid>
      <Grid container spacing={2} xs={10}>
        <TextInput
          name="waistAbdominal"
          label="Circunferência Abdominal"
          xs={4}
          unit="cm"
          onChange={(e) => {
            const fixedWaistAbdominal = formatTextToFloat(e.target.value);
            formik.current.setFieldValue('waistAbdominal', e.target.value);
            const age = moment().diff(moment(client, 'DD/MM/YYYY'), 'year');
            formik.current.setFieldValue(
              'cardiovascularRisk',
              getCadiovascularRiskClassification(
                values.imc,
                age,
                fixedWaistAbdominal,
                client.biologicalSex,
              ),
            );
          }}
        />
      </Grid>
      <Grid container spacing={2} xs={10} style={{ padding: 7 }}>
        <p className="h6StylePurple">{values.cardiovascularRisk}</p>
      </Grid>
    </Grid>
  );

  const renderHandGrip = () => (
    <Grid container spacing={2} justify="center" xs={12}>
      <Grid container spacing={2} xs={10}>
        <TextInput name="handGrip" label="Hand Grip" xs={3} unit="kg" />
      </Grid>
    </Grid>
  );

  const renderAdditionalInfo = () => (
    <Grid container spacing={2} justify="center" xs={12}>
      <Grid item xs={10}>
        <Checkbox
          option="Preencher informação adicional"
          checked={formAdditionalInfo}
          onChange={(e) => {
            setFormAdditionalInfo(!formAdditionalInfo);
          }}
        />
      </Grid>
      {formAdditionalInfo && (
        <Grid container spacing={2} xs={10}>
          <Input
            name="additionalInfo"
            label="Informação adicional"
            fullWidth
            multiline
          />
        </Grid>
      )}
    </Grid>
  );

  const renderUploadFiles = ({ errors }) => (
    <Grid container spacing={2} justify="center" xs={12}>
      <Grid item spacing={2} xs={10}>
        <DropzoneArea
          key={reload}
          onChange={(f) => formik.current.setFieldValue('files', f)}
          acceptedFiles={[
            'image/jpg',
            'application/octet-stream',
            'image/jpeg',
            'image/png',
            '.obj',
          ]}
          dropzoneText="Arraste aqui as imagens do Styku"
          filesLimit={10}
          maxFileSize={50000000}
          getFileAddedMessage={(filename) => `Arquivo ${getFileConfig(filename)} adicionado.`}
        />
        {errors.files
          && errors.files.split('\n').map((err) => (
            <div key={err} style={{ color: '#F44336', marginTop: 10 }}>
              {err}
            </div>
          ))}
      </Grid>
    </Grid>
  );

  const renderUploadInBodyFiles = ({ errors }) => (
    <Grid container spacing={2} justify="center" xs={12}>
      <Grid item spacing={2} xs={10}>
        <DropzoneArea
          key={reload}
          onChange={(f) => formik.current.setFieldValue('inbodyfiles', f)}
          acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
          dropzoneText="Arraste aqui a imagem do InBody"
          filesLimit={10}
          maxFileSize={50000000}
          getFileAddedMessage={(filename) => `Arquivo ${getFileConfig(filename)} adicionado.`}
        />
        {errors.inbodyfiles
          && errors.inbodyfiles.split('\n').map((err) => (
            <div key={err} style={{ color: '#F44336', marginTop: 10 }}>
              {err}
            </div>
          ))}
      </Grid>
    </Grid>
  );

  const renderPhysicalEducationForm = (props) => {
    const { values, errors } = props;

    return (
      <Grid container spacing={2} justify="center" xs={12}>
        <Grid item xs={10}>
          <Checkbox
            name="formPhysicalEducation"
            option="Preencher avaliação educador físico"
            value={formPhysicalEducation}
            key="yourActivitiesOther"
            checked={formPhysicalEducation}
            onChange={(e) => {
              setFormPhysicalEducation(!formPhysicalEducation);
            }}
          />
        </Grid>
        {formPhysicalEducation && (
          <>
            <Grid item xs={10}>
              <div>
                <h4 className="title">{SUCINT_ANAMNESIS}</h4>
              </div>
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2} className={classes.spaceItems}>
                <Grid item xs={5}>
                  <span className="paragraph fontSmall">
                    {PHYSICAL_ACTIVITIES_YOU_PRACTICE}
                  </span>
                </Grid>
                <Grid item xs={5}>
                  <span className="paragraph fontSmall">{STRESS_LEVEL}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={5} className={classes.itemContainer}>
                  <FormGroup name="physicalEducation.yourActivities">
                    {physicalActivitiesArray.map((activity) => (
                      <Checkbox
                        name="physicalEducation.yourActivities"
                        option={activity}
                        value={`${activity}`}
                        key={`${activity}`}
                        checked={
                          values
                          && values.yourActivities !== undefined
                          && values.yourActivities.includes(`${activity}`)
                        }
                        onChange={(e) => {
                          const value = activity;
                          if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation
                              .yourActivities
                            && formik.current.values.physicalEducation.yourActivities.includes(
                              value,
                            )
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.yourActivities',
                              formik.current.values.physicalEducation.yourActivities.filter(
                                (i) => i !== value,
                              ),
                            );
                          } else if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation
                              .yourActivities
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.yourActivities',
                              [
                                ...(formik.current.values.physicalEducation
                                  .yourActivities || []),
                                value,
                              ],
                            );
                          } else {
                            formik.current.setFieldValue(
                              'physicalEducation.yourActivities',
                              [value],
                            );
                          }
                        }}
                      />
                    ))}
                    <Checkbox
                      name="physicalEducation.yourActivitiesOtherCheckbox"
                      option={OTHER}
                      value={OTHER}
                      key="yourActivitiesOther"
                      checked={otherPhysical}
                      onChange={(e) => {
                        setOtherPhysical(!otherPhysical);
                      }}
                    />
                  </FormGroup>

                  {otherPhysical ? (
                    <div className={classes.stressLevel}>
                      <Input
                        label="Quais?"
                        name="physicalEducation.yourActivitiesOther"
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={6} className={classes.stressLevel}>
                  <HauxSlider
                    value={
                      values && values.stressLevel !== undefined
                        ? values.stressLevel
                        : 0
                    }
                    handleChange={(event, newValue) => formik.current.setFieldValue(
                      'physicalEducation.stressLevel',
                      newValue,
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Grid container spacing={2} className={classes.spaceItems}>
                    <Grid item xs={5}>
                      <span className="paragraph fontSmall">
                        {HAVE_FREQUENT_MUSCLE_PAIN}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} className={classes.itemContainer}>
                  <FormGroup name="physicalEducation.musclePains">
                    {musclePainsArray.map((activity) => (
                      <Checkbox
                        name="physicalEducation.musclePains"
                        option={activity}
                        value={`${activity}`}
                        key={`${activity}`}
                        checked={
                          values
                          && values.musclePains !== undefined
                          && values.musclePains.includes(`${activity}`)
                        }
                        onChange={(e) => {
                          const value = activity;
                          if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation
                              .musclePains
                            && formik.current.values.physicalEducation.musclePains.includes(
                              value,
                            )
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.musclePains',
                              formik.current.values.physicalEducation.musclePains.filter(
                                (i) => i !== value,
                              ),
                            );
                          } else if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation.musclePains
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.musclePains',
                              [
                                ...(formik.current.values.physicalEducation
                                  .musclePains || []),
                                value,
                              ],
                            );
                          } else {
                            formik.current.setFieldValue(
                              'physicalEducation.musclePains',
                              [value],
                            );
                          }
                        }}
                      />
                    ))}
                    <Checkbox
                      name="physicalEducation.musclePainsOtherCheckbox"
                      option={OTHER}
                      value={OTHER}
                      key="yourMuscleOther"
                      checked={otherMuscle}
                      onChange={(e) => {
                        setOtherMuscle(!otherMuscle);
                      }}
                    />
                  </FormGroup>

                  {otherMuscle ? (
                    <div className={classes.stressLevel}>
                      <Input
                        label="Quais?"
                        name="physicalEducation.musclePainsOther"
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={10}>
                  <Grid container spacing={2} className={classes.spaceItems}>
                    <Grid item xs={5}>
                      <span className="paragraph fontSmall">
                        {DIFFICULTIES_IN_MOVING}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} className={classes.itemContainer}>
                  <FormGroup name="physicalEducation.difficultiesInMoving">
                    {difficultyMovingArray.map((activity) => (
                      <Checkbox
                        name="physicalEducation.difficultiesInMoving"
                        option={activity}
                        value={`${activity}`}
                        key={`${activity}`}
                        checked={
                          values
                          && values.difficultiesInMoving !== undefined
                          && values.difficultiesInMoving.includes(`${activity}`)
                        }
                        onChange={(e) => {
                          const value = activity;
                          if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation
                              .difficultiesInMoving
                            && formik.current.values.physicalEducation.difficultiesInMoving.includes(
                              value,
                            )
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.difficultiesInMoving',
                              formik.current.values.physicalEducation.difficultiesInMoving.filter(
                                (i) => i !== value,
                              ),
                            );
                          } else if (
                            formik.current.values.physicalEducation
                            && formik.current.values.physicalEducation
                              .difficultiesInMoving
                          ) {
                            formik.current.setFieldValue(
                              'physicalEducation.difficultiesInMoving',
                              [
                                ...(formik.current.values.physicalEducation
                                  .difficultiesInMoving || []),
                                value,
                              ],
                            );
                          } else {
                            formik.current.setFieldValue(
                              'physicalEducation.difficultiesInMoving',
                              [value],
                            );
                          }
                        }}
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item xs={10}>
                  <Grid item xs={10}>
                    <div>
                      <h4 className="title">{PARQ_CHECK}</h4>
                      <span className="paragraph marginTp">{PARQ_MESSAGE}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={10} className="marginTp">
                  {evaluationQuestions.map((item) => (
                    <RadioGroup
                      name={`physicalEducation.parqQuiz.${item.property}`}
                    >
                      <div key={item.question} className="questionContainer">
                        <span className="paragraph">{item.question}</span>
                        <div className="rowItems">
                          <div className="rowSpace" style={{ marginRight: 40 }}>
                            <Radio
                              name={item.property}
                              value="yes"
                              key={`${item.property}yes`}
                              checked={
                                values
                                && values[`parqQuiz.${item.property}`]
                                  !== undefined
                                && values[`parqQuiz.${item.property}`] === 'yes'
                              }
                              option="Sim"
                            />
                          </div>
                          <div className="rowSpace">
                            <Radio
                              name={item.property}
                              value="no"
                              checked={
                                values
                                && values[`parqQuiz.${item.property}`]
                                  !== undefined
                                && values[`parqQuiz.${item.property}`] === 'no'
                              }
                              key={`${item.property}no`}
                              option="Não"
                            />
                          </div>
                        </div>
                      </div>
                    </RadioGroup>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const renderMeetingPhysicalEducationForm = (props) => {
    const { values, errors } = props;
    return (
      <Grid container spacing={2} justify="center" xs={12}>
        <Grid item xs={10}>
          <Checkbox
            option="Preencher encontro educador físico"
            checked={formMeetingPhysical}
            onChange={(e) => {
              setFormMeetingPhysical(!formMeetingPhysical);
            }}
          />
        </Grid>
        {formMeetingPhysical && (
          <>
            <Grid item spacing={2} xs={10}>
              <Grid item xs={12}>
                <span className="h6StylePurple">{GOALS_LABEL}</span>
              </Grid>
              <FormGroup name="meetingPhysicalEducation.yourActivities">
                {GOALS.map((goal) => (
                  <Grid item xs={6} key={goal}>
                    <Checkbox
                      name="meetingPhysicalEducation.yourActivities"
                      option={goal}
                      value={`${goal}`}
                      checked={
                        values
                        && values.goals !== undefined
                        && values.goals.includes(`${goal}`)
                      }
                      onChange={(e) => {
                        const value = goal;
                        if (
                          formik.current.values.meetingPhysicalEducation
                          && formik.current.values.meetingPhysicalEducation
                            .goals
                          && formik.current.values.meetingPhysicalEducation.goals.includes(
                            value,
                          )
                        ) {
                          formik.current.setFieldValue(
                            'meetingPhysicalEducation.goals',
                            formik.current.values.meetingPhysicalEducation.goals.filter(
                              (i) => i !== value,
                            ),
                          );
                        } else if (
                          formik.current.values.meetingPhysicalEducation
                          && formik.current.values.meetingPhysicalEducation.goals
                        ) {
                          formik.current.setFieldValue(
                            'meetingPhysicalEducation.goals',
                            [
                              ...(formik.current.values.meetingPhysicalEducation
                                .goals || []),
                              value,
                            ],
                          );
                        } else {
                          formik.current.setFieldValue(
                            'meetingPhysicalEducation.goals',
                            [value],
                          );
                        }
                      }}
                    />
                  </Grid>
                ))}
              </FormGroup>
            </Grid>
            <Grid item spacing={2} xs={10} className="marginTp">
              <Grid item xs={12}>
                <span className="h6StylePurple">
                  {PHYSICAL_ACTIVITY_LEVELS_LABEL}
                </span>
              </Grid>
              <RadioGroup name="meetingPhysicalEducation.physicalActivityLevel">
                <div className="rowItems">
                  {PHYSICAL_ACTIVITY_LEVELS.map((level) => (
                    <div
                      className="rowSpace"
                      key={level}
                      style={{ marginRight: 40 }}
                    >
                      <Radio
                        name="meetingPhysicalEducation.physicalActivityLevel"
                        value={level}
                        checked={
                          values
                          && values.physicalActivityLevel !== undefined
                          && values['physicalActivityLevel}'] === level
                        }
                        option={level}
                      />
                    </div>
                  ))}
                </div>
              </RadioGroup>
            </Grid>
            <Grid item spacing={2} xs={10} className="marginTp">
              <Grid item xs={12}>
                <span className="h6StylePurple">
                  {NEXT_FORTNIGHT_GOALS_LABEL}
                </span>
                <span className="paragraph fontSmall">min</span>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
              >
                <span
                  className="paragraph fontSmall"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  {WEEKLY_AEROBIC_MINUTES}
                </span>
                <Input
                  name="meetingPhysicalEducation.weeklyAerobicMinutes"
                  placeholder="Ex: 300"
                />
                <span
                  className="paragraph fontSmall"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  min
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
              >
                <span
                  className="paragraph fontSmall"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  {WEEKLY_FORCE_TIMES}
                </span>
                <Input
                  name="meetingPhysicalEducation.weeklyForceTimes"
                  placeholder="Ex: 3"
                />
                <span
                  className="paragraph fontSmall"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  vezes por semana
                </span>
              </Grid>
            </Grid>
            <Grid item spacing={2} xs={10} className="marginTp">
              <Input
                name="meetingPhysicalEducation.notes"
                label={OTHER_NOTES}
                multiline
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const renderDialog = ({ values }) => (
    <Dialog open={openDialog}>
      <DialogTitle className={`${classes.title} ${classes.dialogMargin}`}>
        <div className={classes.dialogHeader}>
          <strong>Objetivos</strong>
          {values.imc ? (
            <span className="paragraph">
              {`IMC atual ${values.imc.replace('.', ',')} kg/m2`}
            </span>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.title}>
          <GpsFixed />
          &nbsp;Metas:&nbsp;
          <Grid container spacing={2} className={classes.weightInputContainer}>
            <TextInput
              label="Peso"
              name="goal.weight"
              unit="Kg"
              decimalScale={2}
              onChange={(e) => {
                formik.current.setFieldValue('goal.weight', e.target.value);
                const fixedHeight = formatTextToFloat(values.height);
                const fixedWeight = formatTextToFloat(e.target.value);
                formik.current.setFieldValue(
                  'goal.imc',
                  fixedWeight / (fixedHeight * fixedHeight),
                );
                calculateCalories(values, fixedWeight);
              }}
            />
            <TextInput
              label="IMC"
              name="goal.imc"
              unit="Kg/m2"
              decimalScale={2}
              onChange={(e) => {
                formik.current.setFieldValue('goal.imc', e.target.value);
                const fixedHeight = formatTextToFloat(values.height);
                const fixedIMC = formatTextToFloat(e.target.value);
                const goalWeight = fixedIMC * (fixedHeight * fixedHeight);
                formik.current.setFieldValue('goal.weight', goalWeight);
                calculateCalories(values, goalWeight);
              }}
            />
          </Grid>
        </DialogContentText>
        <div className={classes.sugestedGoal}>
          <span className="description">
            {`Sugestão: ${(values.goal || {}).suggestionWeight} Kg`}
          </span>
        </div>
        <DialogContentText className={classes.title} style={{ marginTop: 16 }}>
          <SignalCellularAlt />
          &nbsp;Faixa calórica sugerida:&nbsp;
          <strong>
            {`${(values.goal || {}).minCalories} - ${
              (values.goal || {}).maxCalories
            } Kcal/dia`}
          </strong>
        </DialogContentText>
        <DialogContentText className={classes.title}>
          <CalendarToday />
          &nbsp;Prazo restante:&nbsp;
          <strong>
            {`${moment(values.goal.date).diff(moment(), 'months') + 1} meses`}
          </strong>
        </DialogContentText>
        <DialogActions className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateGoal(values);
            }}
          >
            Salvar Objetivos
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} lg={11} className={classes.mainColumn}>
          <span className="title">FICHA DE ANAMNESE</span>

          <Formik
            innerRef={formik}
            onSubmit={(values) => {
              submit(values);
            }}
            initialValues={{}}
            validationSchema={NursingFormSchema}
          >
            {({ values, errors, isValid }) => (
              <Form style={{ width: '100%' }}>
                {openDialog && renderDialog({ values })}
                {renderSection({
                  values,
                  icon: icProfilePurple,
                  title: 'INFORMAÇÕES BÁSICAS',
                  render: renderBasicInfo,
                })}
                {renderSection({
                  values,
                  icon: icActivity,
                  title: 'NÍVEL DE ATIVIDADE FÍSICA NO LAZER',
                  render: renderActivities,
                  name: 'leisureTimeActivity',
                  object: recreationActivityLevels,
                })}
                {renderSection({
                  values,
                  icon: icActivity,
                  title: 'NÍVEL DE ATIVIDADE FÍSICA NO TRABALHO OU ESCOLA',
                  render: renderActivities,
                  name: 'workActivity',
                  object: workActivityLevels,
                })}
                {renderSection({
                  errors,
                  values,
                  icon: icHealth,
                  title: 'PROBLEMAS DE SAÚDE',
                  render: renderActivities,
                  name: 'healthProblems',
                  object: healthProblems,
                  multiple: true,
                })}
                {renderSection({
                  values,
                  icon: icCalculator,
                  title: 'ANTROPOMETRIA E COMPOSIÇÃO CORPORAL',
                  render: renderBodyComposition,
                })}
                {renderSection({
                  values,
                  icon: icMetric,
                  title: 'HAND GRIP',
                  render: renderHandGrip,
                })}
                {renderSection({
                  values,
                  icon: icUpload,
                  title: 'UPLOAD DE ARQUIVOS STYKU',
                  render: renderUploadFiles,
                  errors,
                })}

                {renderSection({
                  values,
                  icon: icUpload,
                  title: 'UPLOAD DE ARQUIVO INBODY',
                  render: renderUploadInBodyFiles,
                  errors,
                })}

                {renderSection({
                  values,
                  icon: icActivity,
                  title: 'AVALIAÇÃO EDUCADOR FÍSICO',
                  render: renderPhysicalEducationForm,
                  errors,
                  values: values.physicalEducation,
                })}

                {renderSection({
                  values,
                  icon: icActivity,
                  title: 'ENCONTRO EDUCADOR FÍSICO',
                  render: renderMeetingPhysicalEducationForm,
                  errors,
                  values: values.meetingPhysicalEducation,
                })}

                {renderSection({
                  values,
                  icon: icInfo,
                  title: 'INFORMAÇÃO ADICIONAL',
                  render: renderAdditionalInfo,
                })}

                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  spacing={2}
                  style={{ padding: 5, paddingTop: 20, paddingBottom: 40 }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/client/dashboard')}
                  >
                    Cancelar
                  </Button>
                  <div>
                    {formPhysicalEducation && (
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={
                          values.physicalEducation
                          && values.physicalEducation.parqQuiz
                            ? !checkParqQuiz(values.physicalEducation.parqQuiz)
                            : true
                        }
                        style={{ marginRight: 20 }}
                        onClick={() => {
                          AnalyticsManager.track(
                            'PhysicalEducation term clicked',
                          );
                          window.open(responsibilityTermPhysical);
                        }}
                      >
                        Termo de responsabilidade
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      style={{ marginRight: 10 }}
                      onClick={() => submit(formik.current.values, true)}
                    >
                      Salvar Rascunho
                    </Button>

                    <Button variant="contained" color="primary" type="submit">
                      Salvar e Publicar
                    </Button>
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default NursingForm;
