import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as IcArrowDropdown } from '../../assets/svgs/ic-arrow-dropdown.svg';
import './hauxDropdownButton.scss';


const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
  },
  imgStyle: {
    marginRight: 8,
  },
  arrowStyle: {
    marginLeft: 8,
  },
}));

const HauxDropdownButton = ({
  handleChange, label, options, Icon, className,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        className={`${className} marginRemove`}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon className={classes.imgStyle} />
        <span className="textStyle">{label}</span>
        <IcArrowDropdown className={classes.arrowStyle} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            onClick={() => {
              handleClose();
              handleChange(option, index);
            }}
          >
            <span className="paragraph">{option}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

HauxDropdownButton.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]),
  className: PropTypes.string,
};

HauxDropdownButton.defaultProps = {
  label: '',
  options: [],
  handleChange: () => {},
  Icon: <></>,
  className: 'CTASecondaryButton',
};

export default React.memo(HauxDropdownButton);
