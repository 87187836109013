export const initialState = {
  tab: 0,
  actualAnamnesis: {},
  firstAnamnesis: {},
  actualPhysicalEducationAnamnesis: {},
  goalPercentage: 0,
  healthClient: [],
  open: false,
  events: [],
  modalOpen: false,
  modalData: {},
  isSarcopenic: false,
  showAdditionalInfoModal: false,
  additionalInfo: [],
  exitFormDialog: false,
  tokenExpired: false,
  subscriptions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'TAB':
      return {
        ...state,
        tab: action.payload,
      };
    case 'GOAL_PERCENTAGE':
      return {
        ...state,
        goalPercentage: action.payload,
      };
    case 'HEALTH_CLIENT':
      return {
        ...state,
        healthClient: action.payload,
      };
    case 'OPEN':
      return {
        ...state,
        open: action.payload,
      };
    case 'EVENTS':
      return {
        ...state,
        events: action.payload,
      };
    case 'MODAL_OPEN':
      return {
        ...state,
        modalOpen: action.payload,
      };
    case 'MODAL_DATA':
      return {
        ...state,
        modalData: action.payload,
      };
    case 'IS_SARCOPENIC':
      return {
        ...state,
        isSarcopenic: action.payload,
      };
    case 'TOGGLE_ADDITIONAL_INFO_MODAL':
      return {
        ...state,
        showAdditionalInfoModal: action.payload,
      };
    case 'EXIT_FORM_DIALOG':
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case 'UPDATE_ADDITIONAL_INFO':
      return {
        ...state,
        showAdditionalInfoModal: false,
        additionalInfo: action.payload,
      };
    case 'TOKEN_EXPIRED':
      return {
        ...state,
        tokenExpired: action.payload,
      };
    case 'SET_DASH_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptions: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
