/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ViewContext from '../../../../ViewContext';
import { HauxButton, Grid } from '../../../../components';
import Form from '../form';

import MeetingService from '../../../../service/MeetingService';

const TrainingMeetingFollowUp = ({ client, load }) => {
  const { handleRequestsErrors, setSnackbarOption } = useContext(ViewContext);
  const {
    stretching, mobility, power, force, conditioning,
  } = client;

  const [trainingMeeting, setTrainingMeeting] = useState({
    stretching: stretching || '',
    mobility: mobility || '',
    power: power || '',
    force: force || '',
    conditioning: conditioning || '',
  });

  async function save() {
    try {
      await MeetingService
        .putTrainingMeeting(client.client._id || client.client.clientId, trainingMeeting);
      
      setSnackbarOption({
        type: 'success',
        message: `Ficha de acompanhamento do(a) cliente(a) ${client.client.name} atualizada!`,
        open: true,
      });
    } catch (err) {
      handleRequestsErrors(err);
    }
  }

  useEffect(() => {
    if (load) {
      try {
        MeetingService.getTrainingMeetingsId([client.client._id || client.client.clientId]).then(
          (res) => {
            const [meeting] = res;
            const {
              // eslint-disable-next-line no-shadow
              stretching, mobility, power, force, conditioning,
            } = meeting;
            setTrainingMeeting({
              stretching,
              mobility,
              power,
              force,
              conditioning,
            });
          },
        );
      } catch (err) {
        handleRequestsErrors(err);
      }
    }
  }, [load, client, handleRequestsErrors]);

  return (
    <Grid container direction="column">
      <Form training={trainingMeeting} setTraining={setTrainingMeeting} />
      <Grid container justify="flex-end" style={{ marginLeft: 10 }}>
        <HauxButton
          text="Salvar alterações"
          type="primary"
          onClick={save}
        />
      </Grid>
    </Grid>
  );
};

TrainingMeetingFollowUp.propTypes = {
  client: PropTypes.shape({
    stretching: PropTypes.string,
    mobility: PropTypes.string,
    power: PropTypes.string,
    force: PropTypes.string,
    conditioning: PropTypes.string,
    client: PropTypes.object,
  }).isRequired,
  load: PropTypes.bool,
};

TrainingMeetingFollowUp.defaultProps = {
  load: false,
};

export default TrainingMeetingFollowUp;
