/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import HauxCheckbox from '../../components/HauxCheckbox';
import HauxDropdown from '../../components/HauxDropdown';
import HAUXInput from '../../components/HauxInput';
import { setPayload } from '../../utils/Utils';

const alcoholicBeverage = {
  evaluation: 'Bebida alcoólica', frequency: '', period: '', whichAlcoholicBeverages: '',
};

const FoodFrequency = ({
  radiosItems, frequencyItems, handleChange, foodArray,
  isAlcoholicBeverages, rerender, dispatch, dropdownFrequency, setArray,
}) => (
  <div>
    {radiosItems.map((item, index) => (
      <div key={index.toString()} className="containerItems">
        <div className="checkboxItems">
          <HauxCheckbox
            checked={foodArray.find((food) => food.evaluation === item.evaluation)}
            handleChange={() => handleChange(item.evaluation)}
            value={item.evaluation}
          />
        </div>
        {foodArray.find((food) => food.evaluation === item.evaluation)
          ? (
            <div className="frequencyContainer">
              {dropdownFrequency
                ? (
                  <HauxDropdown
                    label="Frequência"
                    options={frequencyItems}
                    value={item.frequency}
                    handleChange={(event) => {
                      const array = foodArray;
                      array.find((
                        food,
                      ) => food.evaluation === item.evaluation).frequency = event.target.value;
                      item.frequency = event.target.value;
                      dispatch(setPayload(setArray, array));
                      dispatch(setPayload('RERENDER', !rerender));
                    }}
                  />
                )
                : (
                  <HAUXInput
                    size="small"
                    label="ML"
                    value={item.frequency}
                    onChange={(event) => {
                      const values = foodArray;
                      values.find((
                        food,
                      ) => food.evaluation === item.evaluation).frequency = event.target.value;
                      item.frequency = event.target.value;
                      dispatch(setPayload(setArray, values));
                      dispatch(setPayload('RERENDER', !rerender));
                    }}
                  />
                )}

              <HauxDropdown
                label="Período"
                options={['por mês', 'por dia', 'por semana']}
                value={item.period}
                handleChange={(event) => {
                  const array = foodArray;
                  array.find((
                    food,
                  ) => food.evaluation === item.evaluation).period = event.target.value;
                  item.period = event.target.value;
                  dispatch(setPayload(setArray, array));
                  dispatch(setPayload('RERENDER', !rerender));
                }}
              />
            </div>
          ) : null }
      </div>
    ))}
    {isAlcoholicBeverages
      ? (
        <div className="containerItems">
          <div className="checkboxItems">
            <HauxCheckbox
              checked={foodArray.find((food) => food.evaluation === alcoholicBeverage.evaluation)}
              handleChange={() => handleChange(alcoholicBeverage.evaluation)}
              value={alcoholicBeverage.evaluation}
            />
          </div>
          {foodArray.find((food) => food.evaluation === alcoholicBeverage.evaluation)
            ? (
              <div className="frequencyContainer">
                {dropdownFrequency
                  ? (
                    <HauxDropdown
                      label="Frequência"
                      options={frequencyItems}
                      value={alcoholicBeverage.frequency}
                      handleChange={(event) => {
                        const array = foodArray;
                        array.find((
                          food,
                        ) => food.evaluation
                      === alcoholicBeverage.evaluation).frequency = event.target.value;
                        alcoholicBeverage.frequency = event.target.value;
                        dispatch(setPayload(setArray, array));
                        dispatch(setPayload('RERENDER', !rerender));
                      }}
                    />
                  )
                  : (
                    <HAUXInput
                      label="ML"
                      value={alcoholicBeverage.frequency}
                      size="small"
                      onChange={(event) => {
                        const values = foodArray;
                        values.find((
                          food,
                        ) => food.evaluation
                    === alcoholicBeverage.evaluation).frequency = event.target.value;
                        alcoholicBeverage.frequency = event.target.value;
                        dispatch(setPayload(setArray, values));
                        dispatch(setPayload('RERENDER', !rerender));
                      }}
                    />
                  )}
                <HauxDropdown
                  label="Período"
                  options={['por mês', 'por dia', 'por semana']}
                  value={alcoholicBeverage.period}
                  handleChange={(event) => {
                    const array = foodArray;
                    array.find((
                      food,
                    ) => food.evaluation
                      === alcoholicBeverage.evaluation).period = event.target.value;
                    alcoholicBeverage.period = event.target.value;
                    dispatch(setPayload(setArray, array));
                    dispatch(setPayload('RERENDER', !rerender));
                  }}
                />
              </div>
            ) : null }

          {foodArray.find((food) => food.evaluation === alcoholicBeverage.evaluation)
            ? (
              <div className="alcoholicContainer">
                <HAUXInput
                  label="Quais?"
                  value={alcoholicBeverage.whichAlcoholicBeverages}
                  onChange={(event) => {
                    const array = foodArray;
                    array.find((
                      food,
                    ) => food.evaluation === alcoholicBeverage.evaluation)
                      .whichAlcoholicBeverages = event.target.value;
                    alcoholicBeverage.whichAlcoholicBeverages = event.target.value;
                    dispatch(setPayload(setArray, array));
                    dispatch(setPayload('RERENDER', !rerender));
                  }}
                />
              </div>
            )
            : null}
        </div>
      )
      : null }
  </div>
);

FoodFrequency.propTypes = {
  radiosItems: PropTypes.arrayOf(PropTypes.object),
  frequencyItems: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
  foodArray: PropTypes.arrayOf(PropTypes.object),
  isAlcoholicBeverages: PropTypes.bool,
  rerender: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  dropdownFrequency: PropTypes.bool,
  setArray: PropTypes.string.isRequired,
};

FoodFrequency.defaultProps = {
  radiosItems: [],
  frequencyItems: [],
  handleChange: () => {},
  foodArray: [],
  isAlcoholicBeverages: false,
  rerender: false,
  dropdownFrequency: true,
};

export default FoodFrequency;
