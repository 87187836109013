import React, {
  useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Route, Switch, useLocation,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CompanyTabs from './components/companyTabs';
import ContentTab from './tabs/content';
import FinancesTab from './tabs/finances';
import MealsTab from './tabs/meals';
import PartnersTab from './tabs/partners';
import AnalyticsManager from '../../AnalyticsManager';
import ViewContext from '../../ViewContext';

const useStyles = makeStyles(() => ({
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  maxWidthStyle: {
    maxWidth: 1156,
    backgroundColor: '#ffffff',
    width: '100%',
    marginTop: -32,
  },
}));

const routes = ['content', 'meals', 'partners', 'finances'];
const Company = ({ match, history }) => {
  const classes = useStyles();
  const location = useLocation();
  const { setRouteProperties } = useContext(ViewContext);

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Haux Company',
    });
  }, [setRouteProperties]);

  return (
    <>
      <Grid item className={classes.maxWidthStyle}>
        <CompanyTabs
          onChange={(index) => {
            AnalyticsManager.track(`Company tab_${routes[index]} clicked`);
            history.push(`${match.url}/${routes[index]}`);
          }}
          route={location.pathname.replace(`${match.url}/`, '')}
        />
        <Grid container justify="center" className={classes.tabContainer}>
          <Switch>
            <Route path={`${match.url}/content`} component={ContentTab} />
            <Route path={`${match.url}/meals`} component={MealsTab} />
            <Route path={`${match.url}/partners`} component={PartnersTab} />
            <Route path={`${match.url}/finances`} component={FinancesTab} />
          </Switch>
        </Grid>
      </Grid>
    </>
  );
};

Company.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Company;
