import React, {
  useRef, useEffect, useState, useCallback,
  useContext,
} from 'react';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Carousel, { Modal, ModalGateway } from 'react-images';
import {
  prepareScene,
  loadObject,
} from '../../../components/Haux3DObject';
import ClientContext from '../../../ClientContex';
import MeetingService from '../../../service/MeetingService';

const useStyles = makeStyles(() => ({
  paperContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    paddingTop: 48,
    paddingBottom: 48,
    marginTop: 16,
  },
}));


const StykuMeasure = () => {
  const classes = useStyles();
  const scene = useRef(null);
  const { client } = useContext(ClientContext);
  const [stykuMeasure, setStykuMeasure] = useState(undefined);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  useEffect(() => {
    if (client.nursingAnamnesis && client.nursingAnamnesis.length > 0) {
      setStykuMeasure(client.nursingAnamnesis[client.nursingAnamnesis.length - 1].stykuMeasure);
    }
  }, [client]);

  useEffect(() => {
    if (stykuMeasure) {
      const viewerElement = document.getElementById('viewer');
      viewerElement.innerHTML = '';
      scene.current = prepareScene(viewerElement);
      loadObject(scene.current, stykuMeasure.body_scan);
    }
  }, [stykuMeasure]);

  const openLightbox = useCallback((photo, index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      {stykuMeasure
      && (
      <Paper className={classes.paperContainer} elevation={3}>
        <div className="stykuMeasure">
          <div
            id="viewer"
            style={{ height: 500, width: 300, flex: 1 }}
          />
          <div className="stykuGallery">
            {Object.keys(stykuMeasure).map((key, index) => {
              const image = stykuMeasure[key];

              if (key !== 'body_scan') {
                return (
                <img // eslint-disable-line
                  src={image}
                  alt=""
                  className="imageStyku"
                  onClick={() => openLightbox(image, index)}
                />
                );
              }
            })}
          </div>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={Object.keys(stykuMeasure).filter((x) => x !== 'body_scan').map((key) => ({
                    src: stykuMeasure[key],
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </Paper>
      )}
    </>
  );
};

export default StykuMeasure;
