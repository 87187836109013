import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HAUXInput from '../../components/HauxInput';
import HauxRadio from '../../components/HauxRadio';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 32,
  },
  timeContainer: {
    display: 'flex',
    marginTop: 32,
  },
}));

const RestTime = ({
  handleChangeWakeUp, handleChangeWakeUpToEat, wakeUp, wakeUpToEat,
  wakeUpInput, sleepInput, handleChangeWakeUpInput, handleChangeSleepInInput,
  hoursOfSleep, hoursOfSleepInWeekend, handleChangeHoursOfSleep,
  handleChangeHoursOfSleepInWeekend, sleepDescription, handleSleepDescription,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={10} className={classes.timeContainer}>
        <Grid item xs={4}>
          <span className="paragraph rowSpace">Acorda às:</span>
          <HAUXInput
            value={wakeUpInput}
            onChange={handleChangeWakeUpInput}
            size="small"
            mask="99:99"
          />
        </Grid>
        <Grid item xs={4}>
          <span className="paragraph rowSpace">Dorme às:</span>
          <HAUXInput
            value={sleepInput}
            onChange={handleChangeSleepInInput}
            size="small"
            mask="99:99"
          />
        </Grid>
      </Grid>
      <Grid item xs={8} className={classes.timeContainer}>
        <span className="paragraph rowSpace">Horas de sono durante a semana:</span>
        <HAUXInput
          value={hoursOfSleep}
          onChange={handleChangeHoursOfSleep}
          size="small"
          mask="99:99"
        />
      </Grid>
      <Grid item xs={8} className={classes.timeContainer}>
        <span className="paragraph rowSpace">Horas de sono em finais de semana:</span>
        <HAUXInput
          value={hoursOfSleepInWeekend}
          onChange={handleChangeHoursOfSleepInWeekend}
          size="small"
          mask="99:99"
        />
      </Grid>
      <Grid item xs={5} className={classes.timeContainer}>
        <HAUXInput
          label="Como costuma ser seu sono?"
          placeholder="Descreva seu sono"
          value={sleepDescription}
          onChange={handleSleepDescription}
          fullWidth
        />
      </Grid>
      <Grid item xs={10} className={classes.root}>
        <span className="paragraph">Costuma acordar no meio da noite?</span>
        <div className="rowItems">
          {['Sim', 'Não'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                value={item}
                handleChange={() => handleChangeWakeUp(item)}
                checked={wakeUp === item}
                label={item}
              />
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={10} className={classes.root}>
        <span className="paragraph">Costuma acordar no meio da noite para comer?</span>
        <div className="rowItems">
          {['Sim', 'Não'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                value={item}
                handleChange={() => handleChangeWakeUpToEat(item)}
                checked={wakeUpToEat === item}
                label={item}
              />
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

RestTime.propTypes = {
  handleChangeWakeUp: PropTypes.func,
  handleChangeWakeUpToEat: PropTypes.func,
  handleChangeWakeUpInput: PropTypes.func,
  handleChangeSleepInInput: PropTypes.func,
  handleChangeHoursOfSleep: PropTypes.func,
  handleChangeHoursOfSleepInWeekend: PropTypes.func,
  handleSleepDescription: PropTypes.func,
  wakeUp: PropTypes.string,
  wakeUpToEat: PropTypes.string,
  wakeUpInput: PropTypes.string,
  sleepInput: PropTypes.string,
  hoursOfSleep: PropTypes.string,
  hoursOfSleepInWeekend: PropTypes.string,
  sleepDescription: PropTypes.string,
};

RestTime.defaultProps = {
  handleChangeWakeUp: () => {},
  handleChangeWakeUpToEat: () => {},
  handleChangeWakeUpInput: () => {},
  handleChangeSleepInInput: () => {},
  handleChangeHoursOfSleep: () => {},
  handleChangeHoursOfSleepInWeekend: () => {},
  handleSleepDescription: () => {},
  wakeUp: '',
  wakeUpToEat: '',
  wakeUpInput: '',
  sleepInput: '',
  hoursOfSleep: '',
  hoursOfSleepInWeekend: '',
  sleepDescription: '',
};

export default RestTime;
