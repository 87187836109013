/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const HauxCheckbox = ({
  basicColor, name, value, handleChange, checked, checkedColor,
  disabled, size, label,
}) => {
  const CustomCheckbox = withStyles({
    root: {
      color: basicColor,
      '&$checked': {
        color: checkedColor,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
    >
      <CustomCheckbox
        checked={checked}
        value={value}
        label={label}
        name={name}
        size={size}
        inputProps={{ 'aria-label': { value } }}
      />
      <span className="paragraph">{!label ? value : label}</span>
    </Button>
  );
};

export default HauxCheckbox;

HauxCheckbox.propTypes = {
  basicColor: PropTypes.string,
  checkedColor: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

HauxCheckbox.defaultProps = {
  basicColor: '#737373',
  name: '',
  value: '',
  label: '',
  handleChange: () => { },
  checked: false,
  checkedColor: '#F57A3D',
  disabled: false,
  size: 'medium',
};
