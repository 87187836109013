import pdf1 from "./HAUX_MAIS_VIDA_1.pdf";
import pdf2 from "./HAUX_MAIS_VIDA_2.pdf";
import pdf3 from "./HAUX_MAIS_VIDA_3.pdf";
import pdf4 from "./HAUX_MAIS_VIDA_4.pdf";
import pdf5 from "./HAUX_MAIS_VIDA_5.pdf";
import pdf6 from "./HAUX_MAIS_VIDA_6.pdf";
import pdf7 from "./HAUX_MAIS_VIDA_7.pdf";
import pdf8 from "./HAUX_MAIS_VIDA_8.pdf";
import pdf9 from "./HAUX_MAIS_VIDA_9.pdf";
import moreLifeClient1 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient2 from "./HAUX_MAIS_VIDA_CLIENTES_2.pdf";
import moreLifeClient3 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient4 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient5 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient6 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient7 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient8 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import moreLifeClient9 from "./HAUX_MAIS_VIDA_CLIENTES_1.pdf";
import edFisicoPdf1 from "./ENCONTRO_1_ED_FISICA.pdf";
import edFisicoPdf2 from "./ENCONTRO_2_ED_FISICA.pdf";
import edFisicoPdf3 from "./ENCONTRO_3_ED_FISICA.pdf";
import edFisicoPdf4 from "./ENCONTRO_4_ED_FISICA.pdf";
import edFisicoPdf5 from "./ENCONTRO_5_ED_FISICA.pdf";
import edFisicoPdf6 from "./ENCONTRO_6_ED_FISICA.pdf";
import edFisicoPdf7 from "./ENCONTRO_7_ED_FISICA.pdf";
import edFisicoPdf8 from "./ENCONTRO_8_ED_FISICA.pdf";
import edFisicoPdf9 from "./ENCONTRO_9_ED_FISICA.pdf";
import nutritionPdf1 from "./HAUX_NUTRICAO_1.pdf";
import nutritionPdf2 from "./HAUX_NUTRICAO_2.pdf";
import nutritionPdf3 from "./HAUX_NUTRICAO_3.pdf";
import nutritionPdf4 from "./HAUX_NUTRICAO_4.pdf";
import nutritionPdf5 from "./HAUX_NUTRICAO_5.pdf";
import nutritionPdf6 from "./HAUX_NUTRICAO_6.pdf";
import nutritionPdf7 from "./HAUX_NUTRICAO_7.pdf";
import nutritionPdf8 from "./HAUX_NUTRICAO_8.pdf";
import nutritionPdf9 from "./HAUX_NUTRICAO_9.pdf";
import nursingPdf1 from "./HAUX_ENFERMAGEM_1.pdf";
import nursingPdf2 from "./HAUX_ENFERMAGEM_2_6.pdf";

import responsibilityTermPhysical from "./HAUX_TERMO_RESPONSABILIDADE_AVALIACAO_FISICA.pdf";

import trainingGuiaGeral from "./HAUX_TRAINING_GUIAS_GUIA_GERAL.pdf";
import trainingGuiaAlongamento from "./HAUX_TRAINING_GUIAS_ALONGAMENTO.pdf";
import trainingGuiaMobilidade from "./HAUX_TRAINING_GUIAS_MOBILIDADE.pdf";
import trainingGuiaPotencia from "./HAUX_TRAINING_GUIAS_POTENCIA.pdf";
import trainingGuiaForca from "./HAUX_TRAINING_GUIAS_FORCA.pdf";
import trainingGuiaAerobico from "./HAUX_TRAINING_GUIAS_AEROBICO.pdf";

export default {
  pdf1,
  pdf2,
  pdf3,
  pdf4,
  pdf5,
  pdf6,
  pdf7,
  pdf8,
  pdf9,
  moreLifeClient1,
  moreLifeClient2,
  moreLifeClient3,
  moreLifeClient4,
  moreLifeClient5,
  moreLifeClient6,
  moreLifeClient7,
  moreLifeClient8,
  moreLifeClient9,
  edFisicoPdf1,
  edFisicoPdf2,
  edFisicoPdf3,
  edFisicoPdf4,
  edFisicoPdf5,
  edFisicoPdf6,
  edFisicoPdf7,
  edFisicoPdf8,
  edFisicoPdf9,
  nutritionPdf1,
  nutritionPdf2,
  nutritionPdf3,
  nutritionPdf4,
  nutritionPdf5,
  nutritionPdf6,
  nutritionPdf7,
  nutritionPdf8,
  nutritionPdf9,
  nursingPdf1,
  nursingPdf2,
  responsibilityTermPhysical,
  trainingGuiaGeral,
  trainingGuiaAlongamento,
  trainingGuiaMobilidade,
  trainingGuiaPotencia,
  trainingGuiaForca,
  trainingGuiaAerobico,
};
