import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);

export default {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name) => {
    mixpanel.track(name);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};
