/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import '../../App.scss';
import { withStyles } from '@material-ui/core/styles';

const HauxRadio = ({
  basicColor, value, handleChange, checked,
  checkedColor, disabled, label,
}) => {
  const CustomRadio = withStyles({
    root: {
      color: basicColor,
      '&$checked': {
        color: checkedColor,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Button onClick={handleChange} disabled={disabled}>
      <CustomRadio
        checked={checked}
        value={value}
        inputProps={{ 'aria-label': { value } }}
        disabled={disabled}
      />
      <span className="paragraph">{label}</span>
    </Button>
  );
};

export default HauxRadio;


HauxRadio.propTypes = {
  basicColor: PropTypes.string,
  checkedColor: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

HauxRadio.defaultProps = {
  basicColor: '#737373',
  name: '',
  value: '',
  label: '',
  handleChange: () => {},
  checked: false,
  checkedColor: '#F57A3D',
  disabled: false,
};
