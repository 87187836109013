import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Picker from 'emoji-picker-react';
import _ from 'lodash';
import HauxInput from '../../../../components/HauxInput';
import HauxButton from '../../../../components/HauxButton';
import icTimePurple from '../../../../assets/svgs/ic-time-purple.svg';
import icContent from '../../../../assets/svgs/ic-content.svg';
import AnalyticsManager from '../../../../AnalyticsManager';

import {
  DAY_SHIFTS,
} from '../../../../assets/strings.json';
import { setPayload } from '../../../../utils/Utils';

const ExpansionPanel = withStyles({
  root: {
    marginBottom: 3,
    background: '#685DA8',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.21);',
    borderRadius: 4,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    '& > span': {
      color: 'white',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#FAFAFA',
  },
}))(MuiExpansionPanelDetails);

const useStyle = makeStyles(() => ({
  container: {
    paddingTop: 30,
  },
  tipContainer: {
    padding: 40,
  },
  titleIcon: {
    marginRight: 8,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  durationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  tipDaysContainer: {
    display: 'flex',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  emojiButton: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 50,
    minWidth: 50,
    fontSize: 24,
    borderWidth: 2,
    borderColor: '#695DA8',
    borderStyle: 'solid',
  },
  descriptionTextContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  tipTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  emojiContainer: {
    position: 'absolute',
    zIndex: 1,
  },
  contentText: {
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
    },
    '& > .MuiInput-root > textarea': {
      fontFamily: 'Graviola',
      color: '#737373',
      '&::placeholder': {
        fontFamily: 'Graviola',
      },
    },
  },
}));

const Challenge = ({
  challenge,
  onChange,
  onSaveStep,
}) => {
  const classes = useStyle();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [duration, setDuration] = useState(1);
  const [emoji, setEmoji] = useState('🍒');
  const [showEmojis, setShowEmojis] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [focused, setFocused] = useState('');
  const [steps, setSteps] = useState([{
    index: 1,
    days: 1,
    period: DAY_SHIFTS.MORNING,
  }]);

  useEffect(() => {
    AnalyticsManager.track('View ChallengeForm');
  }, []);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.tagName === 'DIV') {
        setShowEmojis(false);
      }
    });

    const newSteps = challenge.steps || [];
    newSteps.forEach((s, i) => { newSteps[i].index = i + 1; });

    setTitle(challenge.title || '');
    setEmoji(challenge.emoji || '🍒');
    setText(challenge.description || '');
    setDuration(challenge.duration || 1);
    setSteps(newSteps);
  }, []);

  useEffect(() => {
    onChange(setPayload('CONTENT', {
      ...challenge,
      title,
      description: text,
      duration,
      emoji,
      steps: steps.filter((s) => !_.isEmpty(s)),
    }));
  }, [title, text, duration, emoji, steps]);

  const onEmojiClick = (event, emojiObject) => {
    setEmoji(emojiObject.emoji);
  };

  const addStep = () => {
    const filteredSteps = steps.filter((s) => !_.isEmpty(s));
    const newStep = {
      index: filteredSteps.length + 1,
      days: filteredSteps.length + 1,
      period: DAY_SHIFTS.MORNING,
    };
    setSteps([...steps, newStep]);
    setTimeout(() => setExpandedIndex(steps.length), 500);
  };

  const refreshedStepErrors = (step) => {
    const errors = {};
    if (!step.title || step.title.length === 0) {
      errors.title = 'Campo obrigatório';
    }
    if (!step.days || step.days <= 0) {
      errors.days = 'Campo deve ser maior que zero!';
    }
    if (!step.description || step.description.length === 0) {
      errors.description = 'Campo obrigatório';
    }

    return errors;
  };

  const removeStep = (index) => {
    const newSteps = [...steps];
    newSteps[index] = {};
    newSteps.filter((s) => !_.isEmpty(s)).forEach((s, i) => {
      // eslint-disable-next-line no-param-reassign
      s.index = i + 1;
    });
    setSteps(newSteps);
  };

  const changeStepProperty = (index, property, value) => {
    const newSteps = [...steps];
    newSteps[index][property] = value;
    newSteps.forEach((s, i) => {
      if (!_.isEmpty(s)) {
        newSteps[i].errors = refreshedStepErrors(newSteps[i]);
      }
    });
    setSteps(newSteps);
  };

  return (
    <>
      <Paper>
        <Grid item xs={12} className="paperContainer">
          <div className={classes.titleContainer}>
            <img src={icContent} alt="editar" className={classes.titleIcon} />
            {challenge.createdAt
              ? <span className="h5Purple">Edição do conteúdo</span>
              : <span className="h5Purple">Criação do conteúdo</span>}
          </div>
          <Grid item container spacing={2} xs={12} className={classes.container}>
            <Grid xs={12} item container spacing={2} className={classes.itemContainer} style={{ alignItems: 'flex-start' }}>
              <Grid item xs={1} style={{ position: 'relative' }}>
                <Button
                  id="emoji-button"
                  className={classes.emojiButton}
                  onClick={() => setShowEmojis(!showEmojis)}
                >
                  {emoji}
                </Button>
                {showEmojis && (
                  <div id="picker" className={classes.emojiContainer}>
                    <Picker onEmojiClick={onEmojiClick} />
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                <div className={classes.descriptionTextContainer}>
                  <HauxInput
                    label={focused === 'title' || !_.isEmpty(title) ? 'Título' : 'Nome do desafio'}
                    placeholder="Nome do desafio"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    inputProps={{ maxLength: 60 }}
                    fullWidth
                    onFocus={() => setFocused('title')}
                    onBlur={() => setFocused('')}
                  />
                  <span className="description hintText">
                    {title.length}
                    /60
                  </span>
                </div>
              </Grid>
              <Grid item xs={3} style={{ marginRight: 0, paddingRight: 0 }}>
                <div className={classes.descriptionTextContainer}>
                  <div className={classes.durationContainer}>
                    <HauxInput
                      label="Duração"
                      value={duration}
                      onChange={(e) => setDuration(parseInt(e.target.value, 10))}
                      type="number"
                      fullWidth
                    />
                    <span className="paragraph" style={{ marginLeft: 5 }}>dias</span>
                  </div>
                  <span className="description hintText" style={{ visibility: 'hidden' }}>0/0</span>
                </div>
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={classes.itemContainer}>
              <Grid xs={12} item className={classes.descriptionTextContainer}>
                <TextField
                  label="Descrição do desafio"
                  multiline
                  fullWidth
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className={classes.contentText}
                  inputProps={{ maxLength: 200 }}
                />
                <span className="description hintText">
                  {text.length}
                  /200
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Grid item xs={12} className="paperContainer">
          <div className={classes.titleContainer}>
            <img src={icTimePurple} alt="" className={classes.titleIcon} />
            <span className="h5Purple">
              Atividades para completar o desafio
            </span>
          </div>
          <Grid item container spacing={2} xs={12} className={classes.container}>
            {steps.map((step, index) => {
              if (_.isEmpty(step)) {
                return <></>;
              }
              return (
                <ExpansionPanel
                  key={step.index}
                  square
                  expanded={expandedIndex === index}
                  onChange={() => setExpandedIndex(expandedIndex === index ? -1 : index)}
                  style={{ width: '100%' }}
                >
                  <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon htmlColor="white" />}>
                    <span className="paragraph">{`Atividade ${step.index}`}</span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid item container spacing={2} xs={12} className={classes.tipContainer}>
                      <Grid item container spacing={2} xs={12} className={classes.tipTextContainer}>
                        <Grid item xs={5} style={{ marginLeft: 'inherit' }}>
                          <div className={classes.descriptionTextContainer}>
                            <HauxInput
                              label={focused === 'tip' || !_.isEmpty(step.title) ? 'Dica da atividade' : 'Dica do dia na timeline do app'}
                              placeholder="Dica do dia na timeline do app"
                              value={step.title}
                              onChange={(e) => changeStepProperty(index, 'title', e.target.value)}
                              inputProps={{ maxLength: 60 }}
                              fullWidth
                              error={step.errors && step.errors.title}
                              errorMsg={step.errors ? step.errors.title : ''}
                              onFocus={() => setFocused('tip')}
                              onBlur={() => setFocused('')}
                            />
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{ marginRight: 0, paddingRight: 0, paddingTop: 5 }}
                        >
                          <div>
                            <span className="paragraphPurple">Mostrar para o cliente: </span>
                          </div>
                          <div className={classes.tipDaysContainer}>
                            <HauxInput
                              label=""
                              value={step.days}
                              onChange={(e) => changeStepProperty(index, 'days', parseInt(e.target.value, 10))}
                              type="number"
                              error={step.errors && step.errors.days}
                              errorMsg={step.errors ? step.errors.days : ''}
                            />
                            <span className="paragraph" style={{ marginLeft: 5, alignItems: 'flex-end', marginTop: 10 }}>dias após iniciar o desafio</span>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={2} xs={12} style={{ display: 'grid' }}>
                        <TextField
                          label="Descrição da Atividade"
                          multiline
                          fullWidth
                          value={step.description}
                          onChange={(e) => changeStepProperty(index, 'description', e.target.value)}
                          className={classes.contentText}
                          inputProps={{ maxLength: 200 }}
                          error={step.errors && step.errors.description}
                        />
                        <span className="description hintText">
                          {step.description ? step.description.length : 0}
                          {' '}
                          / 200
                        </span>
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        style={{ justifyContent: 'flex-end', marginTop: 40, display: 'flex' }}
                      >
                        {!challenge.published && challenge.steps && challenge.steps.length > 1
                          ? (
                            <HauxButton
                              text="Excluir atividade"
                              variant="outlined"
                              type="secondary"
                              onClick={() => removeStep(index)}
                            />
                          ) : null}
                        {(challenge.id || challenge._id) && (
                          <HauxButton
                            text="Salvar"
                            variant="outlined"
                            type="primary"
                            disabled={!_.isEmpty(step.errors)}
                            onClick={() => {
                              changeStepProperty(index, 'title', step.title);
                              onSaveStep();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>

                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
            {!challenge.published
              ? (
                <Grid item container xs={12} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <HauxButton
                    text="+&nbsp;&nbsp;&nbsp;&nbsp;Adicionar atividade"
                    variant="link"
                    onClick={addStep}
                  />
                </Grid>
              ) : null}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

Challenge.defaultProps = {
  challenge: {
    title: '',
    image: '',
    text: '',
    rawContent: {},
  },
  onChange: () => { },
  onSaveStep: () => { },
};

Challenge.propTypes = {
  challenge: PropTypes.shape({
    id: PropTypes.string,
    _id: PropTypes.string,
    title: PropTypes.string,
    emoji: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.object),
    published: PropTypes.bool,
    createdAt: PropTypes.number,
    text: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onSaveStep: PropTypes.func,
};

export default Challenge;
