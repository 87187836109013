import React, {
  useReducer, useContext, useEffect, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { DropzoneArea } from 'material-ui-dropzone';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { useHistory } from 'react-router-dom';
import strings from '../../../../assets/strings.json';
import pdfs from '../../../../assets/pdfs/index';
import HAUXButton from '../../../../components/HauxButton';
import HauxPdfModal from '../../../../components/HauxPdfModal';
import HauxExpansionMeetingPanel from '../../../../components/HauxExpansionMeetingPanel';
import HauxFormModal from '../../../../components/HauxFormModal';
import MeetingsHeader from '../../components/MeetingsHeader';
import MeetingService from '../../../../service/MeetingService';
import FileService from '../../../../service/FileService';
import AnalyticsManager from '../../../../AnalyticsManager';
import ClientContext from '../../../../ClientContex';
import ViewContext from '../../../../ViewContext';
import { setPayload, getNurseForm, getCurrentUser } from '../../../../utils/Utils';
import { initialState, reducer } from './reducer';

const dImage = require('../../../../assets/images/3d.jpg');

const { nursingPdf1, nursingPdf2 } = pdfs;

const DEFAULT_MEETINGS_TITLE = 'Revisão do programa';

const useStyles = makeStyles(() => ({
  rootContainer: {
    position: 'relative',
    top: -40,
    maxWidth: 1156,
  },
  headerContainer: {
    maxHeight: 200,
    marginBottom: 22,
  },
  primaryButtonContainer: {
    margin: -10,
    marginLeft: 8,
  },
  itemsContainer: {
    marginBottom: 24,
  },
  sectionMargin: {
    marginTop: 40,
  },
}));

export default function NursingMeetings() {
  const classes = useStyles();
  const history = useHistory();
  const user = getCurrentUser();
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const { client } = useContext(ClientContext);
  const {
    handleRequestsErrors, setLoading, setRouteProperties, setSnackbarOption,
  } = useContext(ViewContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    modalData, openModal, editable,
    clientFeelings, notes, files,
    clientMeetings, stykuMeasure,
  } = state;

  const handleFilesChange = (f) => {
    dispatch(setPayload('SET_FILES', f));
  };

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Acompanhamento Clínico',
      breadcrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    AnalyticsManager.track('Nursing meeting view');
  }, []);

  useEffect(() => {
    setLoading(true);
    MeetingService
      .getAllNursingMeetingsByClient(client.clientId)
      .then((res) => dispatch(setPayload('SET_MEETINGS', res)))
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  }, [client, handleRequestsErrors, setLoading]);

  useEffect(() => {
    if (editable >= 0 && editable < clientMeetings.length) {
      dispatch(setPayload('SET_EDITABLE_DATA', editable));
    }
  }, [clientMeetings, editable]);

  const getFileConfig = (filename) => {
    let name = null;
    if (filename.toUpperCase().includes('BODY_FRONT')) {
      name = 'body_front';
    }
    if (filename.toUpperCase().includes('BODY_BACK')) {
      name = 'body_back';
    }
    if (filename.toUpperCase().includes('BODY_LEFT')) {
      name = 'body_left';
    }
    if (filename.toUpperCase().includes('BODY_RIGHT')) {
      name = 'body_right';
    }
    if (filename.toUpperCase().includes('SILHOUETTE_FRONT')) {
      name = 'silhouette_front';
    }
    if (filename.toUpperCase().includes('SILHOUETTE_LEFT')) {
      name = 'silhouette_left';
    }
    if (filename.toUpperCase().includes('.OBJ')) {
      name = 'body_scan';
    }

    return name;
  };

  const saveStykeMeasures = async () => {
    const styku = { ...stykuMeasure || {} };
    await Promise.all(files.map(async (file) => {
      const formData = new FormData();
      formData.append('image', file);
      const response = await FileService.uploadImage(formData);
      const name = getFileConfig(file.name);
      if (name) {
        styku[name] = response.data.file.url;
      }
      return file;
    }));

    return styku;
  };


  async function saveNursingMeeting(index) {
    try {
      setLoading(true);
      const styku = await saveStykeMeasures();

      await MeetingService
        .postNewNursingMeeting({
          client: client.clientId,
          clientFeelings: clientFeelings || '',
          notes: notes || '',
          stykuMeasure: styku,
        });
      setSnackbarOption({
        open: true,
        type: 'success',
        message: `Encontro ${index + 1} salvo com sucesso!`,
      });
      setTimeout(() => history.goBack(), 2000);
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function editNursingMeeting(index) {
    try {
      setLoading(true);
      const styku = await saveStykeMeasures();
      const updatedFollowUp = {
        ...clientMeetings[index],
        ...{ notes, clientFeelings, stykuMeasure: styku },
      };
      await MeetingService
        .putNursingMeeting(updatedFollowUp);
      const meetingsListUpdated = [...clientMeetings];
      meetingsListUpdated.splice(index, 1, updatedFollowUp);
      dispatch(setPayload('UPDATE_MEETING', {
        clientMeetings: meetingsListUpdated,
      }));
      setSnackbarOption({
        open: true,
        type: 'success',
        message: `Encontro ${index + 1} editado com sucesso!`,
      });
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  function renderMeetingInfo(index) {
    return (
      <Grid container direction="column">
        <Grid item container justify="flex-end">
          <Grid item>
            <HAUXButton
              type="tertiary"
              text="Ver anamnese clínica"
              onClick={() => {
                if (client.nursingAnamnesis && client.nursingAnamnesis.length > 0) {
                  dispatch(setPayload('MANAGE_MODAL', {
                    openModal: true,
                    modalData: getNurseForm(
                      client.nursingAnamnesis[client.nursingAnamnesis.length - 1],
                      client,
                    ),
                  }));
                } else {
                  setSnackbarOption({
                    visible: true,
                    type: 'error',
                    message: 'Este cliente ainda não realizou nenhuma Anamnese clínica!',
                    action: (
                      <button
                        className="reloadButton"
                        type="button"
                        onClick={() => history.push('/client/nursingConsult', { professional: user.name })}
                      >
                        Criar nova
                      </button>
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item className={classes.primaryButtonContainer}>
            <HAUXButton
              text="Guia de atendimento"
              onClick={() => {
                AnalyticsManager.track('Nursing meeting guide clicked');
                const pdfUrl = index ? nursingPdf2 : nursingPdf1;
                setOpen(true);
                setLink(`${window.location.origin.toString()}${pdfUrl}`);
              }}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item className={`${classes.itemsContainer} ${classes.sectionMargin}`}>
            <span className="paragraph">Dados evolutivos relevantes do cliente</span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={
                (
                  editable !== index
                  && clientMeetings[index]
                  && clientMeetings[index].clientFeelings
                )
                 || clientFeelings
              }
              disabled={clientMeetings.length > index && editable !== index}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(e) => dispatch(setPayload('SET_CLIENT_FEELINGS', e.target.value))}
            />
          </Grid>
          <Grid item className={classes.itemsContainer}>
            <span className="paragraph">Outras anotações</span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={
                (
                  editable !== index
                  && clientMeetings[index]
                  && clientMeetings[index].notes
                )
                 || notes
              }
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(e) => dispatch(setPayload('SET_NOTES', e.target.value))}
              disabled={clientMeetings.length > index && editable !== index}
            />
          </Grid>
          <Grid item className={classes.itemsContainer}>
            <DropzoneArea
              key={editable}
              onChange={handleFilesChange}
              acceptedFiles={['image/jpeg', 'image/png', '.obj']}
              dropzoneText="Arraste aqui as imagens do Styku"
              filesLimit={10}
              maxFileSize={50000000}
              getFileAddedMessage={(filename) => `Arquivo ${getFileConfig(filename)} adicionado.`}
            />
            <div className="imageListContainer">
              {Object.keys((clientMeetings[index] || {}).stykuMeasure || {}).map((stykuKey) => {
                const url = clientMeetings[index].stykuMeasure[stykuKey];
                return (
                  <div key={stykuKey} className="imageItemContainer">
                    <img className="imageItem" src={stykuKey === 'body_scan' ? dImage : url} alt={stykuKey} />
                    {editable >= 0 && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={() => {
                        delete clientMeetings[index].stykuMeasure[stykuKey];
                        dispatch(setPayload('SET_MEETINGS', clientMeetings));
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item container direction="row-reverse">
            <Grid item className={classes.primaryButtonContainer}>
              <HAUXButton
                text="Salvar"
                disabled={((clientMeetings.length > index && editable !== index)
                  || (files.length === 0 && notes.length === 0 && clientFeelings.length === 0))}
                onClick={() => {
                  if (editable !== index) {
                    saveNursingMeeting(index);
                  } else {
                    editNursingMeeting(index);
                  }
                }}
              />
            </Grid>
            {clientMeetings.length > index && editable !== index ? (
              <Grid item>
                <HAUXButton
                  text="Editar"
                  type="tertiary"
                  onClick={() => dispatch(setPayload('SET_EDITABLE', index))}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <HauxPdfModal open={open} link={link} handleClose={() => setOpen(false)} />
      </Grid>
    );
  }

  const nursingMeetings = [
    {
      name: 'Encontro 1',
      emphasisTitle: 'Bem-vindo ao Programa HAUX',
      renderDetails: renderMeetingInfo(0),
    },
    {
      name: 'Encontro 2',
      emphasisTitle: DEFAULT_MEETINGS_TITLE,
      renderDetails: renderMeetingInfo(1),
    },
    {
      name: 'Encontro 3',
      emphasisTitle: DEFAULT_MEETINGS_TITLE,
      renderDetails: renderMeetingInfo(2),
    },
    {
      name: 'Encontro 4',
      emphasisTitle: DEFAULT_MEETINGS_TITLE,
      renderDetails: renderMeetingInfo(3),
    },
    {
      name: 'Encontro 5',
      emphasisTitle: DEFAULT_MEETINGS_TITLE,
      renderDetails: renderMeetingInfo(4),
    },
    {
      name: 'Encontro 6',
      emphasisTitle: DEFAULT_MEETINGS_TITLE,
      renderDetails: renderMeetingInfo(5),
    },
  ];

  return (
    <Grid container direction="column" className={classes.rootContainer}>
      <Grid item className={classes.headerContainer}>
        <MeetingsHeader consultType={strings.PROFESSIONALS.NURSE} />
      </Grid>
      <Grid item>
        <HauxExpansionMeetingPanel
          items={nursingMeetings.slice(0, clientMeetings.length + 1)}
          changeOpenPanelCallback={(index) => {
            if (editable >= 0) {
              dispatch(setPayload('SET_EDITABLE', -1));
            }
            if (index === clientMeetings.length) {
              dispatch(setPayload('RESET_STATE'));
            }
          }}
        />
      </Grid>
      <HauxFormModal
        data={modalData}
        open={openModal}
        handleClose={() => dispatch(setPayload('MANAGE_MODAL', { openModal: false, modalData: {} }))}
      />
    </Grid>
  );
}
