import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cpfValidate from "validar-cpf";
import { useLocation } from "react-router-dom";
import strings from "../../../assets/strings.json";
import icProfilePurple from "../../../assets/svgs/ic-profile-purple.svg";
import HAUXInput from "../../../components/HauxInput";
import HauxDropdown from "../../../components/HauxDropdown";
import HauxRadio from "../../../components/HauxRadio";
import HauxCheckbox from "../../../components/HauxCheckbox";
import {
  setPayload,
  validateEmail,
  validateBirthDate,
  sourceChannelArray,
} from "../../../utils/Utils";

const {
  BASIC_INFORMATIONS,
  BIOLOGICAL_SEX,
  INVALID_CPF,
  FIELD_REQUIRED,
} = strings;

const useStyles = makeStyles(() => ({
  headerContainer: {
    position: "relative",
    zIndex: 2,
    top: -40,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
  },
  optionMargin: {
    marginRight: 8,
  },
  dropdownContainer: {
    paddingRight: 8,
  },
}));

export default function BasicInfo({ state, dispatch }) {
  const classes = useStyles();
  const location = useLocation();
  const {
    clientData,
    nameError,
    nameErrorMsg,
    cpfError,
    cpfErrorMsg,
    phoneError,
    phoneErrorMsg,
    birthDateError,
    birthDateErrorMsg,
    emailError,
    emailErrorMsg,
    confirmEmailError,
    confirmEmailErrorMsg,
    responsableNameError,
    responsableNameErrorMsg,
    responsableCpfError,
    responsableCpfErrorMsg,
    switchState,
    sourceChannelError,
    sourceChannelErrorMsg,
    biologicalSex,
    franchises,
  } = state;

  function checkSource(value) {
    return (
      value === "Indicação de Cliente" ||
      value === "Indicação de Funcionário" ||
      value === "Indicação de Médico"
    );
  }

  function renderBasicInfoHeader() {
    return (
      <Paper className={classes.headerContainer}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={4}>
            <span className="paragraph">
              {location.state &&
              (location.state.client || location.state.prospect)
                ? "Tipo de cadastro"
                : "Que tipo de cadastro você deseja criar?"}
            </span>
          </Grid>
          <Grid item container xs={4} justify="center">
            <Grid item className={classes.optionMargin}>
              <HauxRadio
                label="Prospect"
                value="prospect"
                checked={!switchState.checkedA}
                handleChange={() =>
                  dispatch(setPayload("SWITCH_STATE", { checkedA: false }))
                }
              />
            </Grid>
            <Grid item>
              <HauxRadio
                label="Cliente"
                value="client"
                checked={switchState.checkedA}
                handleChange={() =>
                  dispatch(setPayload("SWITCH_STATE", { checkedA: true }))
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  React.useEffect(() => {
    if (
      clientData.birthDate &&
      clientData.isChild &&
      validateBirthDate(clientData.birthDate)
    ) {
      dispatch(setPayload("BIRTH_DATE_ERROR", true));
      dispatch(setPayload("BIRTH_DATE_ERROR_MSG", "Insira uma data válida."));
    } else if (
      !validateBirthDate(clientData.birthDate) &&
      clientData.birthDate &&
      !clientData.isChild
    ) {
      dispatch(setPayload("BIRTH_DATE_ERROR_MSG", "Insira uma data válida."));
      dispatch(setPayload("BIRTH_DATE_ERROR", true));
    } else {
      dispatch(setPayload("BIRTH_DATE_ERROR", false));
      dispatch(setPayload("BIRTH_DATE_ERROR_MSG", ""));
    }
  }, [clientData.isChild, clientData.birthDate, dispatch]);

  return (
    <>
      {renderBasicInfoHeader()}
      <Paper>
        <Grid container spacing={2} className="paperContainer">
          <Grid item xs={10}>
            <div className="paperTitle">
              <img src={icProfilePurple} alt="" className="iconStyle" />
              <span className="newClientText">{BASIC_INFORMATIONS}</span>
            </div>
            <div className="inputStyle">
              <HAUXInput
                label="Nome"
                value={clientData.name}
                onChange={(event) => {
                  const { value } = event.target;
                  const formatValue = value.replace(/[^-'`˜ˆa-zÀ-ÿ ]/gi, "");

                  dispatch(setPayload("CLIENT_DATA", { name: formatValue }));
                }}
                onFocus={() => {
                  dispatch(setPayload("NAME_ERROR", false));
                  dispatch(setPayload("NAME_ERROR_MSG", ""));
                }}
                onBlur={() => {
                  if (!clientData.name) {
                    dispatch(setPayload("NAME_ERROR", true));
                    dispatch(setPayload("NAME_ERROR_MSG", FIELD_REQUIRED));
                  }
                }}
                error={nameError}
                errorMsg={nameErrorMsg}
                type="text"
                inputProps={{ pattern: "[a-z]" }}
                fullWidth
              />
            </div>
            <Grid container spacing={2} className="inputStyle">
              <Grid item xs={6}>
                <HAUXInput
                  label="E-mail"
                  value={clientData.email}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", { email: event.target.value })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("EMAIL_ERROR", false));
                    dispatch(setPayload("EMAIL_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.email) {
                      dispatch(setPayload("EMAIL_ERROR", true));
                      dispatch(setPayload("EMAIL_ERROR_MSG", FIELD_REQUIRED));
                    } else if (!validateEmail(clientData.email)) {
                      dispatch(setPayload("EMAIL_ERROR", true));
                      dispatch(
                        setPayload("EMAIL_ERROR_MSG", "Insira um e-mail válido")
                      );
                    }
                  }}
                  error={emailError}
                  errorMsg={emailErrorMsg}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <HAUXInput
                  label="Confirme o e-mail"
                  value={clientData.confirmEmail}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        confirmEmail: event.target.value,
                      })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("EMAIL_ERROR", false));
                    dispatch(setPayload("EMAIL_ERROR_MSG", ""));
                    dispatch(setPayload("CONFIRM_EMAIL_ERROR", false));
                    dispatch(setPayload("CONFIRM_EMAIL_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.confirmEmail) {
                      dispatch(setPayload("CONFIRM_EMAIL_ERROR", true));
                      dispatch(
                        setPayload("CONFIRM_EMAIL_ERROR_MSG", FIELD_REQUIRED)
                      );
                    } else if (!validateEmail(clientData.confirmEmail)) {
                      dispatch(setPayload("CONFIRM_EMAIL_ERROR", true));
                      dispatch(
                        setPayload(
                          "CONFIRM_EMAIL_ERROR_MSG",
                          "Insira um e-mail válido"
                        )
                      );
                    } else if (clientData.confirmEmail !== clientData.email) {
                      dispatch(setPayload("EMAIL_ERROR", true));
                      dispatch(setPayload("CONFIRM_EMAIL_ERROR", true));
                      dispatch(
                        setPayload(
                          "CONFIRM_EMAIL_ERROR_MSG",
                          "Os e-mails não correspondem"
                        )
                      );
                    }
                  }}
                  error={confirmEmailError}
                  errorMsg={confirmEmailErrorMsg}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="inputStyle">
              <Grid item xs={4}>
                <HAUXInput
                  label="Telefone"
                  mask="(99) 99999-9999"
                  value={clientData.phone}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        phone:
                          event.target.value === "(" ? "" : event.target.value,
                      })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("PHONE_ERROR", false));
                    dispatch(setPayload("PHONE_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.phone) {
                      dispatch(setPayload("PHONE_ERROR", true));
                      dispatch(setPayload("PHONE_ERROR_MSG", FIELD_REQUIRED));
                    }
                  }}
                  error={phoneError}
                  errorMsg={phoneErrorMsg}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <HAUXInput
                  label="Data de Nascimento"
                  mask="99/99/9999"
                  value={clientData.birthDate}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        birthDate: event.target.value,
                      })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("BIRTH_DATE_ERROR", false));
                    dispatch(setPayload("BIRTH_DATE_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.birthDate && switchState.checkedA) {
                      dispatch(setPayload("BIRTH_DATE_ERROR", true));
                      dispatch(
                        setPayload("BIRTH_DATE_ERROR_MSG", FIELD_REQUIRED)
                      );
                    }
                  }}
                  error={birthDateError}
                  errorMsg={birthDateErrorMsg}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <HAUXInput
                  label="CPF"
                  mask="999.999.999-99"
                  value={clientData.cpf}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", { cpf: event.target.value })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("CPF_ERROR", false));
                    dispatch(setPayload("CPF_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.cpf && switchState.checkedA) {
                      dispatch(setPayload("CPF_ERROR", true));
                      dispatch(setPayload("CPF_ERROR_MSG", FIELD_REQUIRED));
                    } else if (
                      clientData.cpf &&
                      !cpfValidate(`${clientData.cpf}`)
                    ) {
                      dispatch(setPayload("CPF_ERROR", true));
                      dispatch(setPayload("CPF_ERROR_MSG", INVALID_CPF));
                    }
                  }}
                  error={cpfError}
                  errorMsg={cpfErrorMsg}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="inputStyle">
              <Grid item xs={5} className="biologicalSexGrid">
                <span className="paragraph biologicalSexTitle">
                  {BIOLOGICAL_SEX}
                </span>
                <HauxRadio
                  checked={biologicalSex === "F"}
                  value="F"
                  label="F"
                  handleChange={() =>
                    dispatch(setPayload("BIOLOGICAL_SEX", "F"))
                  }
                />
                <HauxRadio
                  checked={biologicalSex === "M"}
                  value="M"
                  label="M"
                  handleChange={() =>
                    dispatch(setPayload("BIOLOGICAL_SEX", "M"))
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <HAUXInput
                  label="Como conheceu a Haux"
                  value={clientData.sourceChannel}
                  autocomplete
                  autocompleteProps={{
                    options: sourceChannelArray,
                    getOptionLabel: (option) => option.value || "",
                  }}
                  onChange={(event, newValue) => {
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        sourceChannel: newValue || event.target.value,
                      })
                    );
                  }}
                  onSelect={(itemState) => {
                    dispatch(setPayload("CLIENT_DATA", { sourceWho: "" }));
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        sourceChannel: itemState.value,
                      })
                    );
                  }}
                  onFocus={() => {
                    dispatch(setPayload("SOURCE_CHANNEL_ERROR", false));
                    dispatch(setPayload("SOURCE_CHANNEL_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    const hasSC =
                      sourceChannelArray.filter(
                        (s) => s.value === clientData.sourceChannel
                      ).length === 1;

                    if (!hasSC) {
                      dispatch(
                        setPayload("CLIENT_DATA", { sourceChannel: "" })
                      );
                      dispatch(setPayload("CLIENT_DATA", { sourceWho: "" }));
                    }

                    if (!clientData.sourceChannel || !hasSC) {
                      dispatch(setPayload("SOURCE_CHANNEL_ERROR", true));
                      dispatch(
                        setPayload("SOURCE_CHANNEL_ERROR_MSG", FIELD_REQUIRED)
                      );
                    }
                  }}
                  error={sourceChannelError}
                  errorMsg={sourceChannelErrorMsg}
                  fullWidth
                />
              </Grid>
              {checkSource(clientData.sourceChannel) && (
                <Grid item xs={3}>
                  <HAUXInput
                    label="Quem indicou"
                    value={clientData.sourceWho}
                    onChange={(event) =>
                      dispatch(
                        setPayload("CLIENT_DATA", {
                          sourceWho: event.target.value,
                        })
                      )
                    }
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            {switchState.checkedA && (
              <Grid container spacing={2} className="inputStyle">
                <Grid item xs={4} style={{ marginLeft: -20, marginRight: 20 }}>
                  <HauxCheckbox
                    label="É criança?"
                    checked={!!clientData.isChild}
                    handleChange={() => {
                      dispatch(
                        setPayload("CLIENT_DATA", {
                          isChild: !clientData.isChild,
                        })
                      );
                    }}
                  />
                </Grid>
                {clientData.isChild && (
                  <Grid item xs={5}>
                    <HAUXInput
                      label="Nome do responsável"
                      value={clientData.responsableName}
                      onChange={(event) =>
                        dispatch(
                          setPayload("CLIENT_DATA", {
                            responsableName: event.target.value,
                          })
                        )
                      }
                      onFocus={() => {
                        dispatch(setPayload("RESPONSABLE_NAME_ERROR", false));
                        dispatch(setPayload("RESPONSABLE_NAME_ERROR_MSG", ""));
                      }}
                      onBlur={() => {
                        if (!clientData.responsableName && clientData.isChild) {
                          dispatch(setPayload("RESPONSABLE_NAME_ERROR", true));
                          dispatch(
                            setPayload(
                              "RESPONSABLE_NAME_ERROR_MSG",
                              FIELD_REQUIRED
                            )
                          );
                        }
                      }}
                      error={responsableNameError}
                      errorMsg={responsableNameErrorMsg}
                      fullWidth
                    />
                  </Grid>
                )}
                {clientData.isChild && (
                  <Grid item xs={3}>
                    <HAUXInput
                      label="CPF do responsável"
                      mask="999.999.999-99"
                      value={clientData.responsableCpf}
                      onChange={(event) =>
                        dispatch(
                          setPayload("CLIENT_DATA", {
                            responsableCpf: event.target.value,
                          })
                        )
                      }
                      onFocus={() => {
                        dispatch(setPayload("RESPONSABLE_CPF_ERROR", false));
                        dispatch(setPayload("RESPONSABLE_CPF_ERROR_MSG", ""));
                      }}
                      onBlur={() => {
                        if (!clientData.responsableCpf && clientData.isChild) {
                          dispatch(setPayload("RESPONSABLE_CPF_ERROR", true));
                          dispatch(
                            setPayload(
                              "RESPONSABLE_CPF_ERROR_MSG",
                              FIELD_REQUIRED
                            )
                          );
                        }
                      }}
                      error={responsableCpfError}
                      errorMsg={responsableCpfErrorMsg}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {switchState.checkedA && franchises.length > 1 && (
              <Grid container className="inputStyle">
                <Grid
                  item
                  xs={6}
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.dropdownContainer}
                >
                  <Grid item xs={2}>
                    <span className="paragraph">Cliente</span>
                  </Grid>
                  <Grid item xs className={classes.dropdownContainer}>
                    <HauxDropdown
                      fullWidth
                      options={franchises.map((item) => item.name)}
                      handleChange={(e) => {
                        const selectedFranchise = franchises.find(
                          (item) => item.name === e.target.value
                        );
                        dispatch(
                          setPayload("CLIENT_DATA", {
                            franchises: [selectedFranchise],
                          })
                        );
                      }}
                      value={
                        clientData.franchises && clientData.franchises.length
                          ? clientData.franchises[0].name
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {clientData.clientId && (
              <Grid item xs={4}>
                <HAUXInput
                  label="Senha do App"
                  value={clientData.clientId.substring(
                    clientData.clientId.length - 5
                  )}
                  fullWidth
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

BasicInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.shape({
    nameError: PropTypes.bool,
    cpfError: PropTypes.bool,
    phoneError: PropTypes.bool,
    birthDateError: PropTypes.bool,
    emailError: PropTypes.bool,
    sourceChannelError: PropTypes.bool,
    confirmEmailError: PropTypes.bool,
    responsableNameError: PropTypes.bool,
    responsableCpfError: PropTypes.bool,
    nameErrorMsg: PropTypes.string,
    cpfErrorMsg: PropTypes.string,
    phoneErrorMsg: PropTypes.string,
    birthDateErrorMsg: PropTypes.string,
    emailErrorMsg: PropTypes.string,
    sourceChannelErrorMsg: PropTypes.string,
    confirmEmailErrorMsg: PropTypes.string,
    responsableNameErrorMsg: PropTypes.string,
    responsableCpfErrorMsg: PropTypes.string,
    biologicalSex: PropTypes.string,
    switchState: PropTypes.shape({
      checkedA: PropTypes.bool,
    }),
    clientData: PropTypes.shape({
      clientId: PropTypes.string,
      name: PropTypes.string,
      cpf: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      confirmEmail: PropTypes.string,
      sourceChannel: PropTypes.string,
      sourceWho: PropTypes.string,
      birthDate: PropTypes.string,
      isChild: PropTypes.bool,
      responsableName: PropTypes.string,
      responsableCpf: PropTypes.string,
      franchises: PropTypes.arrayOf(PropTypes.object),
    }),
    franchises: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
