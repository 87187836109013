export const initialState = {
  prospectData: {},
  clientData: {},
  nameError: false,
  nameErrorMsg: '',
  cpfError: false,
  cpfErrorMsg: '',
  phoneError: false,
  phoneErrorMsg: '',
  cepError: false,
  cepErrorMsg: '',
  streetError: false,
  streetErrorMsg: '',
  numberError: false,
  numberErrorMsg: '',
  districtError: false,
  districtErrorMsg: '',
  cityError: false,
  cityErrorMsg: '',
  ufError: false,
  ufErrorMsg: '',
  birthDateError: false,
  birthDateErrorMsg: '',
  emailError: false,
  emailErrorMsg: '',
  confirmEmailError: false,
  confirmEmailErrorMsg: '',
  responsableNameError: false,
  responsableNameErrorMsg: '',
  respoonsableCpfError: false,
  respoonsableCpfErrorMsg: '',
  biologicalSex: '',
  exitFormDialog: false,
  switchState: { checkedA: false },
  sourceChannelError: false,
  sourceChannelErrorMsg: '',
  saveFormDialog: false,
  franchises: [],
  planDialog: { open: false },
  subscriptions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENT_DATA':
      return {
        ...state,
        clientData: { ...state.clientData, ...action.payload },
      };
    case 'PROSPECT_DATA':
      return {
        ...state,
        prospectData: action.payload,
      };
    case 'NAME_ERROR':
      return {
        ...state,
        nameError: action.payload,
      };

    case 'NAME_ERROR_MSG':
      return {
        ...state,
        nameErrorMsg: action.payload,
      };
    case 'CPF_ERROR':
      return {
        ...state,
        cpfError: action.payload,
      };
    case 'CPF_ERROR_MSG':
      return {
        ...state,
        cpfErrorMsg: action.payload,
      };
    case 'PHONE_ERROR':
      return {
        ...state,
        phoneError: action.payload,
      };
    case 'PHONE_ERROR_MSG':
      return {
        ...state,
        phoneErrorMsg: action.payload,
      };
    case 'CEP_ERROR':
      return {
        ...state,
        cepError: action.payload,
      };
    case 'CEP_ERROR_MSG':
      return {
        ...state,
        cepErrorMsg: action.payload,
      };
    case 'STREET_ERROR':
      return {
        ...state,
        streetError: action.payload,
      };
    case 'STREET_ERROR_MSG':
      return {
        ...state,
        streetErrorMsg: action.payload,
      };
    case 'NUMBER_ERROR':
      return {
        ...state,
        numberError: action.payload,
      };
    case 'NUMBER_ERROR_MSG':
      return {
        ...state,
        numberErrorMsg: action.payload,
      };
    case 'DISTRICT_ERROR':
      return {
        ...state,
        districtError: action.payload,
      };
    case 'DISTRICT_ERROR_MSG':
      return {
        ...state,
        districtErrorMsg: action.payload,
      };
    case 'CITY_ERROR':
      return {
        ...state,
        cityError: action.payload,
      };
    case 'CITY_ERROR_MSG':
      return {
        ...state,
        cityErrorMsg: action.payload,
      };
    case 'UF_ERROR':
      return {
        ...state,
        ufError: action.payload,
      };
    case 'UF_ERROR_MSG':
      return {
        ...state,
        ufErrorMsg: action.payload,
      };
    case 'BIRTH_DATE_ERROR':
      return {
        ...state,
        birthDateError: action.payload,
      };
    case 'BIRTH_DATE_ERROR_MSG':
      return {
        ...state,
        birthDateErrorMsg: action.payload,
      };
    case 'EMAIL_ERROR':
      return {
        ...state,
        emailError: action.payload,
      };
    case 'EMAIL_ERROR_MSG':
      return {
        ...state,
        emailErrorMsg: action.payload,
      };
    case 'CONFIRM_EMAIL_ERROR':
      return {
        ...state,
        confirmEmailError: action.payload,
      };
    case 'CONFIRM_EMAIL_ERROR_MSG':
      return {
        ...state,
        confirmEmailErrorMsg: action.payload,
      };
    case 'SOURCE_CHANNEL_ERROR':
      return {
        ...state,
        sourceChannelError: action.payload,
      };
    case 'SOURCE_CHANNEL_ERROR_MSG':
      return {
        ...state,
        sourceChannelErrorMsg: action.payload,
      };
    case 'BIOLOGICAL_SEX':
      return {
        ...state,
        biologicalSex: action.payload,
      };
    case 'EXIT_FORM_DIALOG':
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case 'SWITCH_STATE':
      return {
        ...state,
        switchState: action.payload,
      };
    case 'SAVE_FORM_DIALOG':
      return {
        ...state,
        saveFormDialog: action.payload,
      };
    case 'LOAD_FRANCHISES':
      return {
        ...state,
        franchises: action.payload,
      };
    case 'OPEN_PLAN_DIALOG':
      return {
        ...state,
        planDialog: action.payload,
      };
    case 'SET_CLIENT_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptions: action.payload,
      };
    case 'RESPONSABLE_NAME_ERROR':
      return {
        ...state,
        responsableNameError: action.payload,
      };
    case 'RESPONSABLE_NAME_ERROR_MSG':
      return {
        ...state,
        responsableNameErrorMsg: action.payload,
      };
    case 'RESPONSABLE_CPF_ERROR':
      return {
        ...state,
        responsableCpfError: action.payload,
      };
    case 'RESPONSABLE_CPF_ERROR_MSG':
      return {
        ...state,
        responsableCpfErrorMsg: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
