import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ReactComponent as acChevronDown } from '../../assets/svgs/ac-chevron-down.svg';
import './HauxTableSortLabel.scss';

const HauxTableSortLabel = ({
  direction, active, onClick, label,
}) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <TableSortLabel
      className={`sortLabel ${isActive ? 'selectedColumn' : ''}`}
      active
      direction={direction}
      onClick={onClick}
      IconComponent={acChevronDown}
    >
      {label}
    </TableSortLabel>
  );
};


export default HauxTableSortLabel;

HauxTableSortLabel.propTypes = {
  direction: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
