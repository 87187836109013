import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Form, Formik, FieldArray } from "formik";
import { Grid, Paper, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Input } from "../../../components/Form";
import ViewContext from "../../../ViewContext";
import PlanService from "../../../service/PlanService";
import HauxCheckbox from "../../../components/HauxCheckbox";
import "./plansCreate.scss";

const REQUIRED_MSG = "Campo obrigatório";

const PlanFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MSG),
  value: Yup.number().required(REQUIRED_MSG),
  meetings: Yup.object().shape({
    nursing: Yup.number(),
    nutrition: Yup.number(),
    physicalEducation: Yup.number(),
    hauxMoreLife: Yup.number(),
    training: Yup.number(),
  }),
  descriptions: Yup.array().of(Yup.string()).required(REQUIRED_MSG),
  installment: Yup.bool().required(REQUIRED_MSG),
  maximumInstallment: Yup.number(),
  installmentOptions: Yup.array().of(
    Yup.object().shape({
      installmentNumber: Yup.number(),
      evaluemail: Yup.number(),
    })
  ),
});

const CreatePlan = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    setRouteProperties,
    setLoading,
    loading,
    setSnackbarOption,
  } = useContext(ViewContext);
  const [initialValues, setInitialValues] = useState({ installment: false });

  const findPlan = async (planId) => {
    setLoading(true);
    const plan = await PlanService.getPlan(planId);
    setInitialValues({
      ...plan,
      installmentOptions: plan.installmentOptions,
      descriptions: plan.descriptions,
    });
    setLoading(false);
  };

  const save = async (values) => {
    setLoading(true);
    const saveValues = values;
    setInitialValues({
      ...values,
      installmentOptions: values.installmentOptions,
      descriptions: values.descriptions,
    });

    try {
      const SaveAction = values._id
        ? PlanService.putPlan
        : PlanService.postPlan;
      await SaveAction(saveValues);
      setSnackbarOption({
        message: `Plano ${values._id ? "alterado" : "salvo"} com sucesso!`,
        type: "success",
        open: true,
        onClose: () => {
          history.push("/plans/table");
          setSnackbarOption((s) => ({
            ...s,
            open: false,
          }));
        },
      });
    } catch (error) {
      const { response } = error;
      setSnackbarOption({
        message: response.data.error,
        type: "error",
        open: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setRouteProperties({
      pageTitle: "Novo plano",
      breadcrumbs: "Planos",
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (location.state) {
      const { planId } = location.state;
      findPlan(planId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Formik
      key={loading}
      initialValues={initialValues}
      validationSchema={PlanFormSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          save(values);
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      {({ values, setFieldValue }) => (
        <Grid
          container
          direction="column"
          justify="center"
          className="planNewContainer"
        >
          <Form style={{ width: "100%" }}>
            <Grid item xs>
              <Paper className="paperContainer" elevation={2}>
                <Grid
                  className="userForm"
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item xs={10}>
                    <div className="paperTitle">
                      <span className="title">INFORMAÇÕES BÁSICAS</span>
                    </div>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <Input label="Nome" name="name" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Valor"
                        name="value"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className="paperContainer" elevation={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={10}>
                    <div className="paperTitle">
                      <span className="title">NÚMERO DE ENCONTROS</span>
                    </div>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <Input
                        label="Enfermagem"
                        name="meetings.nursing"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Input
                        label="Nutrição"
                        name="meetings.nutrition"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Input
                        label="Educação Física"
                        name="meetings.physicalEducation"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <Input
                        label="Haux Mais Vida"
                        name="meetings.hauxMoreLife"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Input
                        label="Treino"
                        name="meetings.training"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className="paperContainer" elevation={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={10}>
                    <div className="paperTitle">
                      <span className="title">DESCRIÇÃO</span>
                    </div>
                  </Grid>
                </Grid>
                <FieldArray
                  name="descriptions"
                  render={(arrayHelpers) => {
                    const { descriptions } = values;
                    return (
                      <>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          style={{ marginBottom: 20 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          Adicionar descrição
                        </Button>
                        {descriptions && descriptions.length > 0
                          ? descriptions.map((_, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid item container spacing={2} key={index}>
                                <Grid item xs={11}>
                                  <Input
                                    label="Item Descrição"
                                    name={`descriptions[${index}]`}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </Grid>
                              </Grid>
                            ))
                          : null}
                      </>
                    );
                  }}
                />
              </Paper>
              <Paper className="paperContainer" elevation={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={10}>
                    <div className="paperTitle">
                      <span className="title">PARCELAS</span>
                    </div>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <HauxCheckbox
                        checked={values.installment}
                        handleChange={() =>
                          setFieldValue("installment", !values.installment)
                        }
                        value="Parcelar"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item xs={6}>
                      <Input
                        label="Máximo de parcelas"
                        name="maximumInstallment"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <FieldArray
                  name="installmentOptions"
                  render={(arrayHelpers) => {
                    const { installmentOptions } = values;
                    return (
                      <>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          style={{ marginBottom: 20 }}
                          onClick={() =>
                            arrayHelpers.push({
                              installmentNumber: 0,
                              value: 0,
                            })
                          }
                        >
                          Adicionar parcela
                        </Button>
                        {installmentOptions && installmentOptions.length > 0
                          ? installmentOptions.map((_, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Grid item container spacing={2} key={index}>
                                <Grid item xs={6}>
                                  <Input
                                    label="Número de parcelas"
                                    name={`installmentOptions.${index}.installmentNumber`}
                                    fullWidth
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Input
                                    label="Valor Total"
                                    name={`installmentOptions.${index}.value`}
                                    fullWidth
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </Button>
                                </Grid>
                              </Grid>
                            ))
                          : null}
                      </>
                    );
                  }}
                />
              </Paper>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={2}
                style={{ padding: 5, paddingTop: 20, paddingBottom: 40 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/plans/table")}
                >
                  Cancelar
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  );
};

export default CreatePlan;
