import React from "react";
import Table from "./table";

const Meals = () => (
  <>
    <Table />
  </>
);

export default Meals;
