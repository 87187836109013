import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import HAUXRegisterReleaseDialog from './components/HauxRegisterReleaseDialog';
import HAUXButton from '../../../../components/HauxButton';
import AnalyticsManager from '../../../../AnalyticsManager';
import ViewContext from '../../../../ViewContext';

const Finances = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { handleRequestsErrors } = useContext(ViewContext);

  return (
    <>
      <Grid container>
        <HAUXButton
          text="Cadastrar plano de contas"
          type="secondary"
          onClick={() => {
            AnalyticsManager.track('Company finances button_register_posting_types clicked');
            setModalOpen(true);
          }}
        />
      </Grid>
      <HAUXRegisterReleaseDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        handleRequestsErrors={handleRequestsErrors}
      />
    </>
  );
};

export default Finances;
