import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import HauxTable from "../../../../../components/HauxTable";
import HauxConfirmDialog from "../../../../../components/HauxConfirmDialog";
import { HauxInput, HauxButton } from "../../../../../components";
import ViewContext from "../../../../../ViewContext";
import AnalyticsManager from "../../../../../AnalyticsManager";
import MealService from "../../../../../service/MealService";

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "mealType",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
  },
  {
    id: "calorificValue",
    numeric: false,
    disablePadding: false,
    label: "Calorias",
    format: (row) => {
      return `${row.calorificValue} kcal`;
    },
  },
];

const Table = () => {
  const history = useHistory();
  const timeoutRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 0,
    order: "asc",
    orderBy: "calorificValue",
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState();
  const { setLoading: setViewLoading, setSnackbarOption } = useContext(
    ViewContext
  );

  const search = async () => {
    timeoutRef.current = null;
    setLoading(true);
    try {
      const { page, order, orderBy } = pagination;
      const response = await MealService.getPaginatedMeals(
        page,
        searchText,
        orderBy,
        order
      );
      setItems(response.contents);
      setTotalPages(response.totalPages);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setLoading(false);
  };

  const debouncedSearch = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(search, 500);
  };

  useEffect(() => {
    debouncedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, searchText]);

  const edit = (mealId) => {
    const meal = items.find((x) => x._id === mealId);
    history.push("/meals/create", { ...meal });
  };

  const deleteMeal = async (mealId) => {
    setViewLoading(true);
    try {
      await MealService.deleteMeal(mealId);
      setSnackbarOption({
        message: "Refeição excluída com sucesso!",
        type: "success",
        open: true,
      });
    } catch (error) {
      setSnackbarOption({
        message: "Erro ao excluir refeição.",
        type: "error",
        open: true,
      });
    }
    setViewLoading(false);
    search();
  };

  return (
    <>
      <div className="filterDiv">
        <HauxInput
          value={searchText}
          onChange={({ target }) => setSearchText(target.value)}
          placeholder="Buscar por nome ou tipo"
        />
        <HauxButton
          text="Nova refeição"
          type="primary"
          onClick={() => {
            AnalyticsManager.track("Meals button_new_meal clicked");
            history.push("/meals/create");
          }}
        />
      </div>
      <HauxTable
        headCells={headCells}
        items={items}
        itemIdKey="_id"
        order={pagination.order}
        orderBy={pagination.orderBy}
        onSort={(orderBy, order) =>
          setPagination({ ...pagination, orderBy, order })
        }
        page={pagination.page}
        totalPages={totalPages}
        onChangePage={(page) => setPagination({ ...pagination, page })}
        loading={loading}
        onDelete={setShowConfirmDialog}
        onEdit={edit}
      />

      <HauxConfirmDialog
        isDestructive
        handleClose={() => setShowConfirmDialog(null)}
        handleConfirm={() => {
          deleteMeal(showConfirmDialog);
          setShowConfirmDialog(null);
        }}
        open={!!showConfirmDialog}
        title="Excluir refeição"
        message="Esta ação irá apagar esta refeição. Deseja continuar?"
      />
    </>
  );
};

export default Table;
