import axios from '../middlewares/axios';

const defaultRoute = '/prospect/prospects';

const sortProspectByName = (a, b) => {
  if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
  if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
  return 0;
};

class ProspectService {
  static getProspects() {
    const url = defaultRoute;
    return axios.get(url)
      .then((response) => response.data.sort(sortProspectByName))
      .catch((error) => { throw error; });
  }

  static postProspect(prospectData, biologicalSex) {
    const data = {
      ...prospectData,
      biologicalSex,
    };
    const url = defaultRoute;
    return axios.post(url, data)
      .catch((error) => {
        throw error;
      });
  }

  static getPaginatedProspects(page, searchTextProspect, showWithoutScheduling, sort) {
    const url = `/prospect/prospectsPaginated?page=${page}&search=${searchTextProspect}&schedule=${showWithoutScheduling}&sort=${sort}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static getProspectEvents(id) {
    const url = `/prospect/events?id=${id}`;
    return axios.get(url).then((res) => res.data);
  }

  static updateProspectById(id, prospectData, biologicalSex) {
    const data = {
      prospectId: id,
      update: {
        ...prospectData,
        biologicalSex,
      },
    };
    const url = defaultRoute;
    return axios.put(url, data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteProspect(prospectId) {
    const url = `/prospect/${prospectId}`;
    return axios.delete(url)
      .catch((error) => {
        throw error;
      });
  }
}

export default ProspectService;
