import React from 'react';
import {
  string, bool, func, node,
} from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, HauxButton,
} from '../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const classes = {
  actions: {
    flex: 1,
    justifyContent: 'space-between',
  }
};

const Modal = ({
  title,
  onClose,
  onSave,
  open,
  children,
  actionsDisabled,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions style={classes.actions}>
        <HauxButton
          onClick={onClose}
          text="Fechar"
          variant="outlined"
          className="CTA"
          disabled={actionsDisabled}
        />
        <HauxButton
          onClick={onSave}
          text="Salvar alterações"
          type="primary"
          disabled={actionsDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  title: string.isRequired,
  onClose: func.isRequired,
  onSave: func.isRequired,
  children: node.isRequired,
  actionsDisabled: bool,
  open: bool,
};

Modal.defaultProps = {
  actionsDisabled: false,
  open: false,
}

export default Modal;