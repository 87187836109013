import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EventPropTypes from './eventPropsTypes';
import CheckSVG from '../../assets/svgs/ic-check.svg';
import UnCheckSVG from '../../assets/svgs/ic-uncheck.svg';
import AtHomeSVG from '../../assets/svgs/ic-event-at-home.svg';
import strings from '../../assets/strings.json';

const {
  HAUX_MULTISPACE, HAUX_TRAINING,
} = strings;

const useStyles = makeStyles(() => ({
  monthContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: 0,
    fontFamily: 'Graviola',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  checkedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    marginRight: 7,
    height: 15,
  },
}));

const getName = (event) => {
  try {
    return event.room === HAUX_MULTISPACE && !event.isTour
      ? event.consultNumber : event.client.name;
  } catch (e) {
    return '';
  }
};

const MonthEventCell = ({ event }) => {
  const classes = useStyles();
  return (
    <div className={classes.monthContainer}>
      { event.franchise && event.franchise.slug === 'at_home' && (
      <img
        src={AtHomeSVG}
        alt={event.franchise.name}
        className={classes.checkIcon}
      />
      )}
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{getName(event)}</h1>
      </div>
      <div
        className={classes.checkedContainer}
        style={{
          opacity: event.confirmationStatus !== 'Não confirmado' || (
            event.room === HAUX_MULTISPACE && !event.isTour) ? 1 : 0,
        }}
      >
        {(event.room === HAUX_MULTISPACE || event.room === HAUX_TRAINING) && !event.isTour
          ? (
            <div>
              {Math.max(0, (event.room === HAUX_TRAINING ? 10 : 15) - event.participants.length)}
            </div>
          ) : (
            <img
              src={event.confirmationStatus === 'Presente' ? CheckSVG : UnCheckSVG}
              alt={`${event.confirmationStatus === 'Presente' ? 'Presença' : 'Ausência'} confirmada`}
              className={classes.checkIcon}
            />
          )}
      </div>
    </div>
  );
};

MonthEventCell.propTypes = {
  event: PropTypes.shape(EventPropTypes),
};

MonthEventCell.defaultProps = {
  event: {},
};

export default MonthEventCell;
