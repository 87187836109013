import axios from '../middlewares/axios';

export default class FranchiseService {
  static getAllFranchise() {
    const url = '/franchise';
    return axios.get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}
