import React from "react";
import Table from "./table";

const Plans = () => (
  <>
    <Table />
  </>
);

export default Plans;
