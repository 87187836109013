import axios from '../middlewares/axios';

const eventsMap = (events) => (
  events.map((e) => ({
    ...e, start: new Date(e.start), end: new Date(e.end),
  }))
);

class ScheduleService {
  static getEvents(start, end) {
    const url = `/schedule/events?start=${start}&end=${end}`;
    return axios
      .get(url)
      .then((response) => eventsMap(response.data))
      .catch((error) => {
        throw error;
      });
  }

  static getRemainingClientConsultations(clientId) {
    const url = `/schedule/remaining/${clientId}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postNewEvent(newEventData) {
    const url = '/schedule/events';
    return axios
      .post(url, newEventData);
  }

  static postConfirmStatus(eventId, clientId, status) {
    const url = `/schedule/confirmStatus/${eventId}`;
    return axios
      .post(url, {
        clientId,
        status,
      });
  }

  static postParticipants(eventId, participants) {
    const url = `/schedule/participants/${eventId}`;
    return axios
      .post(url, {
        participants,
      });
  }

  static deleteEvent(eventId) {
    const url = `/schedule/deleteEvent/${eventId}`;
    return axios
      .delete(url);
  }

  static getNextEvents() {
    const url = '/schedule/next';
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getNextMeetingsClients() {
    const url = '/schedule/nextMeetingsClients';
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getNextMeetingsProspects() {
    const url = '/schedule/nextMeetingsProspects';
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getNextMeetingsTraining() {
    const url = '/schedule/nextMeetingsTraining';
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getNextMeetingsMoreLife() {
    const url = '/schedule/nextMeetingsMoreLife';
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default ScheduleService;
