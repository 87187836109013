/* eslint-disable react/forbid-prop-types */
import React from 'react';
import './hauxFormModal.scss';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import icClose from '../../assets/svgs/ic-close.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxWidth: 768,
    minWidth: 768,
    maxHeight: 500,
    minHeight: 500,
    boxShadow: theme.shadows[5],
    padding: 32,
  },
  titleModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    marginTop: 16,
  },
  boxSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));

const HauxFormModal = ({
  open, handleClose, data,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <div className={classes.iconContainer}>
              <Button onClick={handleClose}>
                <img src={icClose} alt="" />
              </Button>
            </div>
            <Grid className={classes.titleModal}>
              <span className="h4Style">{data.title}</span>
              <span className="paragraph">
                {data.clientName}
                {' '}
                -
                {' '}
                {data.date}
              </span>
              <span className="paragraph">{data.professionalName}</span>
            </Grid>
            { data.clientName ? data.sections.map((section) => (
              section ? (
                <Grid className={classes.section} border={1}>
                  <Box border={1} borderColor="#E0E0E0" className={classes.boxSection}>
                    <span className="title">{section.name}</span>
                    { section.answers.map((answer) => (
                      answer
                        ? (
                          <div className="questionLine">
                            <span className="paragraph mainColor">{answer.title}</span>
                            <span className="paragraph">{answer.answer}</span>
                          </div>
                        ) : null
                    )) }
                  </Box>
                </Grid>
              ) : null
            )) : null }
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

HauxFormModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};

HauxFormModal.defaultProps = {
  open: false,
  handleClose: () => {},
  data: {},
};

export default HauxFormModal;
