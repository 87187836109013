import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as AcEdit } from "../../assets/svgs/ac-edit.svg";
import { ReactComponent as AcDelete } from "../../assets/svgs/ac-delete.svg";
import "./hauxTable.scss";

const StyledTableCell = withStyles(() => ({
  head: {
    color: "#3E2782",
    fontFamily: "GraviolaSoft",
  },
  body: {
    fontSize: 14,
    fontFamily: "Graviola",
  },
}))(TableCell);

const ColorCircularProgress = withStyles({
  root: {
    color: "#685DA8",
  },
})(CircularProgress);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    width: "100%",
  },
});

function HauxTable({
  headCells,
  itemIdKey,
  items,
  orderBy,
  order,
  onSort,
  totalPages,
  page,
  onChangePage,
  loading,
  onEdit,
  onDelete,
}) {
  const classes = useStyles();

  return (
    <div className="container">
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() =>
                    onSort(
                      headCell.id,
                      order === "desc" || orderBy !== headCell.id
                        ? "asc"
                        : "desc"
                    )
                  }
                >
                  {headCell.label}
                </TableSortLabel>
              </StyledTableCell>
            ))}
            {(onEdit || onDelete) && (
              <StyledTableCell align="center" style={{ minWidth: 72 }} />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && items.length === 0 && (
            <TableRow>
              <StyledTableCell style={{ visibility: "hidden" }} />
            </TableRow>
          )}
          {items.map((row) => (
            <TableRow key={row[itemIdKey]}>
              {headCells.map((cell) => (
                <StyledTableCell
                  key={cell.id}
                  align={cell.numeric ? "right" : "left"}
                  padding={cell.disablePadding ? "none" : "default"}
                >
                  {cell.format ? cell.format(row) : row[cell.id]}
                </StyledTableCell>
              ))}
              {(onEdit || onDelete) && (
                <StyledTableCell align="center" style={{ minWidth: 72 }}>
                  {onEdit && (
                    <AcEdit
                      className="actionButton"
                      onClick={() => onEdit(row[itemIdKey])}
                    />
                  )}
                  {onDelete && (
                    <AcDelete
                      className="actionButton"
                      onClick={() => onDelete(row[itemIdKey])}
                    />
                  )}
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {loading && (
        <div className="loadingContainer">
          <ColorCircularProgress />
        </div>
      )}
      <div className="paginationContainer">
        <Pagination
          count={totalPages}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(_, newPage) => onChangePage(newPage)}
        />
      </div>
    </div>
  );
}

HauxTable.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      format: PropTypes.func,
    })
  ),
  itemIdKey: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.any),
  orderBy: PropTypes.string,
  order: PropTypes.string,
  onSort: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onChangePage: PropTypes.func,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

HauxTable.defaultProps = {
  headCells: [],
  itemIdKey: "",
  items: [],
  orderBy: "",
  order: "",
  onSort: () => {},
  page: 0,
  totalPages: 0,
  onChangePage: () => {},
  loading: false,
  onEdit: null,
  onDelete: null,
};

export default HauxTable;
