import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    paddingBottom: 52,
  },
  mainColumn: {
    paddingTop: 40,
    maxWidth: 1156,
  },
  paperContainer: {
    justifyContent: 'center',
    borderRadius: 8,
    paddingTop: 48,
    paddingBottom: 48,
    marginTop: 16,
  },
  activitiesContainer: {
    marginTop: 16,
    alignItems: 'center',
  },
  sarcopenicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F57A3D',
    width: 224,
    height: 30,
    borderRadius: 4,
    marginLeft: 32,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Graviola',
    '& .MuiTypography-root': {
      fontFamily: 'Graviola',
    },
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogMargin: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  dialogContent: {
    marginLeft: 16,
    marginRight: 16,
  },
  weightInputContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  sugestedGoal: {
    marginLeft: 16,
    marginTop: 8,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
}));

export default useStyles;
