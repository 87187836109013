import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    width: 200,
    height: 12,
    borderRadius: 4,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#685DA8',
    },
    marginTop: 4,
  },
}));

function HauxProgressBar({ value }) {
  const classes = useStyles();

  return (
    <LinearProgress
      className={classes.root}
      variant="determinate"
      value={value}
      color="primary"
    />
  );
}

export default HauxProgressBar;

HauxProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};
