/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(() => ({
  tabContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  tabLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabTitle: {
    fontFamily: 'GraviolaSoft',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 15,
    color: '#685DA8',
  },
}));

const TAB_HEIGHT = 74;

const ROUTES = ['content', 'meals', 'partners', 'finances'];

export const valueToRoute = (value) => {
  if (value < 0 || value > ROUTES.length - 1) {
    return ROUTES[0];
  }

  return ROUTES[value];
};

export const routeToValue = (route) => {
  const index = ROUTES.indexOf(route);

  return index >= 0 ? index : 0;
};

// eslint-disable-next-line react/prop-types
const LabelTab = ({ label }) => {
  const classes = useStyles();
  return (
    <div className={classes.tabLabelWrapper}>
      <h1 className={classes.tabTitle}>{label}</h1>
    </div>
  );
};

const StyledTabs = withStyles({
  root: {
    height: TAB_HEIGHT,
    backgroundColor: 'rgba(104, 93, 168, 0.15)',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#685DA8',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    height: TAB_HEIGHT,
    opacity: 1,
    '&:focus': {
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: '#ffffff',
    },
  },
}))((props) => (
  <Tab
    {...props}
    label={<LabelTab label={props.label} />}
  />
));

const HAUXTab = ({ onChange, route }) => {
  const classes = useStyles();
  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={classes.tabContainer}>
      <StyledTabs
        variant="fullWidth"
        value={routeToValue(route)}
        onChange={handleChange}
        aria-label="nav tabs example"
      >
        <StyledTab label="Conteúdo" />
        <StyledTab label="Refeições" />
        <StyledTab label="Parceiros" />
        <StyledTab label="Financeiro" />
      </StyledTabs>
    </div>
  );
};

export default HAUXTab;

HAUXTab.propTypes = {
  onChange: PropTypes.func,
  route: PropTypes.string,
};

HAUXTab.defaultProps = {
  onChange: () => <></>,
  route: '',
};
