import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  pannelContainer: {
    marginBottom: 16,
    padding: 16,
  },
  titleContainer: {
    '& .MuiExpansionPanelSummary-content': {
      alignItems: 'center',
    },
  },
}));

const HauxExpansionPanel = ({ items, changeOpenPanelCallback, openFirst }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    if (openFirst >= 0) {
      setExpanded(items[openFirst].name);
    }
  }, [openFirst, items]);

  const handleChange = (panel, index) => (event, isExpanded) => {
    changeOpenPanelCallback(index);
    setExpanded(isExpanded ? panel : false);
  };

  const renderItems = () => (
    items.map((item, index) => (
      <ExpansionPanel
        className={classes.pannelContainer}
        key={item.name}
        expanded={expanded === item.name}
        onChange={handleChange(item.name, index)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.titleContainer}
        >
          <span
            className="paragraph"
            style={{ textAlign: 'center' }}
          >
            {item.name}
          </span>
          <span
            className="h5NormalStyle"
            style={{ marginLeft: 32 }}
          >
            {item.emphasisTitle}
          </span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {item.renderDetails}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ))
  );

  return (
    <div className={classes.root}>
      {renderItems()}
    </div>
  );
};

HauxExpansionPanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  changeOpenPanelCallback: PropTypes.func,
};

HauxExpansionPanel.defaultProps = {
  items: [],
  changeOpenPanelCallback: () => {},
};

export default HauxExpansionPanel;
