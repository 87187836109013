import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: 16,
  },
  descriptionText: {
    marginLeft: 32,
  },
  expansionPanel: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
  },
  expansionPanelSumary: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const HauxMeetingCollapsible = ({
  meeting, description, renderContent, expanded, onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded} className={classes.expansionPanel} onChange={onChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.expansionPanelSumary}>
            <span className="paragraph">
              Encontro
              {' '}
              {meeting}
            </span>
            <span className={`h5NormalStyle ${classes.descriptionText}`}>
              {description}
            </span>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {renderContent()}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default HauxMeetingCollapsible;

HauxMeetingCollapsible.propTypes = {
  meeting: PropTypes.string,
  description: PropTypes.string,
  renderContent: PropTypes.func,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
};

HauxMeetingCollapsible.defaultProps = {
  meeting: '',
  description: '',
  expanded: false,
  renderContent: () => <></>,
  onChange: () => { },
};
