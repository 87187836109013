import * as Yup from 'yup';
import strings from '../../../assets/strings.json';
import FileService from '../../../service/FileService';

const REQUIRED_MSG = 'Campo obrigatório';

const NumberValidation = () => Yup.number()
  .transform((_, value) => {
    if (String(value).includes('.')) {
      return null;
    }
    return +String(value).replace(/,/, '.');
  })
  .required(REQUIRED_MSG);

export const NursingFormSchema = Yup.object().shape({
  bloodPressure: Yup.string().required(REQUIRED_MSG),
  height: NumberValidation(),
  weight: NumberValidation(),
  visceralFatLevel: NumberValidation(),
  basalMetabolicRate: NumberValidation(),
  bodyWater: NumberValidation(),
  freeMass: NumberValidation(),
  fatMass: NumberValidation(),
  muscleMass: NumberValidation(),
  bodyFatPercentage: NumberValidation(),
  handGrip: NumberValidation(),
  bicepLeft: NumberValidation(),
  bicepRight: NumberValidation(),
  calfLeft: NumberValidation(),
  calfRight: NumberValidation(),
  midThighLeft: NumberValidation(),
  midThighRight: NumberValidation(),
  torax: NumberValidation(),
  hip: NumberValidation(),
  waistAbdominal: NumberValidation(),
  leisureTimeActivity: NumberValidation(),
  workActivity: NumberValidation(),
  healthProblems: Yup.array().min(1, REQUIRED_MSG).required(REQUIRED_MSG),
  files: Yup.array()
    .min(
      7,
      'Faltando arquivos, verifique se estão todos presentes:\n1 - Body Front\n2 - Body Back\n3 - Body Left\n4 - Body Right\n5 - Silhouette Front\n6 - Silhouette Left\n7 - 3D Body [.OBJ]',
    )
    .required(REQUIRED_MSG),
  inbodyfiles: Yup.array().min(1, 'Arquivo obrigatório').required(REQUIRED_MSG),
  references: Yup.object().shape({
    bodyWater: Yup.object().shape({
      min: NumberValidation(),
      max: NumberValidation(),
    }),
    freeMass: Yup.object().shape({
      min: NumberValidation(),
      max: NumberValidation(),
    }),
    fatMass: Yup.object().shape({
      min: NumberValidation(),
      max: NumberValidation(),
    }),
    muscleMass: Yup.string().required(REQUIRED_MSG),
    bodyFatPercentage: Yup.object().shape({
      min: NumberValidation(),
      max: NumberValidation(),
    }),
  }),
  goal: Yup.object()
    .shape({
      weight: Yup.number()
        .typeError('Campo inválido')
        .transform((_, value) => {
          try {
            if (value.includes('.')) {
              return null;
            }
            return +value.replace(/,/, '.');
          } catch (e) {
            return value;
          }
        }),
      imc: Yup.number()
        .typeError('Campo inválido')
        .min(20, 'IMC não pode ser menor que 20')
        .transform((_, value) => {
          try {
            if (value.includes('.')) {
              return null;
            }
            return +value.replace(/,/, '.');
          } catch (e) {
            return value;
          }
        }),
    })
    .nullable(),
});

const {
  NONE,
  TYPE_ONE_DIABETES,
  TYPE_TWO_DIABETES,
  OVERWEIGHT,
  ARTERIAL_HYPERTENSION,
  ARTERIAL_HYPERTENSION_DESC,
  DYSLIPIDEMIA,
  DYSLIPIDEMIA_DESC,
  OTHER_CARDIOVASCULAR_DISEASES,
  OTHER_CARDIOVASCULAR_DISEASES_DESC,
} = strings;

export const healthProblems = [
  {
    level: ARTERIAL_HYPERTENSION,
    value: ARTERIAL_HYPERTENSION,
    description: ARTERIAL_HYPERTENSION_DESC,
  },
  {
    level: DYSLIPIDEMIA,
    value: DYSLIPIDEMIA,
    description: DYSLIPIDEMIA_DESC,
  },
  {
    level: OTHER_CARDIOVASCULAR_DISEASES,
    value: OTHER_CARDIOVASCULAR_DISEASES,
    description: OTHER_CARDIOVASCULAR_DISEASES_DESC,
  },
  {
    level: TYPE_TWO_DIABETES,
    value: TYPE_TWO_DIABETES,
  },
  {
    level: TYPE_ONE_DIABETES,
    value: TYPE_ONE_DIABETES,
  },
  {
    level: OVERWEIGHT,
    value: OVERWEIGHT,
  },
  {
    level: NONE,
    value: NONE,
  },
];

export const recreationActivityLevels = [
  {
    level: 'Muito leve',
    description: 'Quase nenhuma atividade.',
    value: 1,
  },
  {
    level: 'Leve',
    description:
      'Caminhadas, ciclismo não exaustivo ou jardinagem aproximadamente uma vez por semana.',
    value: 2,
  },
  {
    level: 'Moderada',
    description:
      'Atividade regular pelo menos uma vez por semana, por exemplo, caminhadas, ciclismo (inclusive para o trabalho) ou jardinagem.',
    value: 3,
  },
  {
    level: 'Intensa',
    description:
      'Atividades regulares mais de uma vez por semana, por exemplo, caminhadas intensas, ciclismo ou esportes.',
    value: 4,
  },
  {
    level: 'Muito intensa',
    description: 'Atividades extenuantes várias vezes por semana.',
    value: 5,
  },
];

export const workActivityLevels = [
  {
    level: 'Muito leve',
    description:
      'Sentado no computador a maior parte do dia ou sentado em uma mesa.',
    value: 1,
  },
  {
    level: 'Leve',
    description:
      'Trabalho industrial leve, vendas ou trabalho de escritório que compreende atividades leves.',
    value: 2,
  },
  {
    level: 'Moderada',
    description:
      'Limpeza, pessoal da cozinha ou entrega de correspondência a pé ou de bicicleta.',
    value: 3,
  },
  {
    level: 'Intensa',
    description: 'Trabalho industrial pesado, construção ou agricultura.',
    value: 4,
  },
];

export const getSarcopenicAlert = (biologicalSex, imme) => {
  const fixedIMME = parseFloat((imme || '').replace(',', '.'));
  if (biologicalSex === 'M') {
    if (fixedIMME <= 8.5) {
      return { alert: 'Sarcopenia Severa', color: 'red' };
    }
    if (fixedIMME <= 10.75) {
      return { alert: 'Sarcopenia Moderada', color: '#F57A3D' };
    }
    return { alert: 'Massa Muscular Adequada', color: '#4ED25D' };
  }

  if (fixedIMME <= 5.75) {
    return { alert: 'Sarcopenia Severa', color: 'red' };
  }
  if (fixedIMME <= 6.75) {
    return { alert: 'Sarcopenia Moderada', color: '#F57A3D' };
  }
  return { alert: 'Massa Muscular Adequada', color: '#4ED25D' };
};

export const getFileConfig = (filename) => {
  let name = null;
  if (filename.toUpperCase().includes('BODY_FRONT')) {
    name = 'body_front';
  }
  if (filename.toUpperCase().includes('BODY_BACK')) {
    name = 'body_back';
  }
  if (filename.toUpperCase().includes('BODY_LEFT')) {
    name = 'body_left';
  }
  if (filename.toUpperCase().includes('BODY_RIGHT')) {
    name = 'body_right';
  }
  if (filename.toUpperCase().includes('SILHOUETTE_FRONT')) {
    name = 'silhouette_front';
  }
  if (filename.toUpperCase().includes('SILHOUETTE_LEFT')) {
    name = 'silhouette_left';
  }
  if (filename.toUpperCase().includes('.OBJ')) {
    name = 'body_scan';
  }

  return name;
};

export const saveStykeMeasures = async (files) => {
  const styku = {};
  await Promise.all(
    files.map(async (file) => {
      const formData = new FormData();
      formData.append('image', file);
      const response = await FileService.uploadImage(formData);
      const name = getFileConfig(file.name);
      if (name) {
        styku[name] = response.data.file.url;
      }
      return file;
    }),
  );

  return styku;
};

export const saveInBodyMeasures = async (file) => {
  const formData = new FormData();
  formData.append('image', file);
  const response = await FileService.uploadImage(formData);
  return response.data.file.url;
};

export const evaluationQuestions = [
  {
    question:
      'Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionado por profissionais de saúde?',
    property: 'heartProblem',
  },
  {
    question: 'Você sente dores no peito quando pratica atividades físicas?',
    property: 'chestPains',
  },
  {
    question:
      'No último mês, você sentiu dores no peito quando praticou atividade física?',
    property: 'chestPainsInTheLastMonth',
  },
  {
    question:
      'Você apresenta desequilíbrio devido à tontura e/ou perda de consciência?',
    property: 'haveImbalance',
  },
  {
    question:
      'Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?',
    property: 'boneOrJointProblem',
  },
  {
    question:
      'Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?',
    property: 'takeSomeMedicine',
  },
  {
    question:
      'Sabe de alguma outra razão pela qual você não deve praticar atividade física?',
    property: 'otherReason',
  },
];

const obesityClassification = (imc, age) => {
  if (imc < 18.5) {
    return 'Baixo Peso';
  }
  if (imc >= 18.5 && imc < 25) {
    return 'Adequado';
  }
  if (imc >= 25 && imc < 30) {
    return 'Sobrepeso';
  }
  if (imc >= 30 && imc < 35) {
    return 'Obesidade Grau I';
  }
  if (imc >= 35 && imc <= 40) {
    return 'Obesidade Grau II';
  }
  if (imc > 40) {
    return 'Obesidade Grau III';
  }
};

export const getCadiovascularRiskClassification = (
  imc,
  age,
  waistAbdominal,
  biologicalSex,
) => {
  if (biologicalSex === 'M') {
    if (waistAbdominal >= 102) {
      return 'Risco cardiovascular muito elevado';
    }
    if (waistAbdominal >= 94) {
      return 'Risco cardiovascular elevado';
    }
  } else {
    if (waistAbdominal >= 88) {
      return 'Risco cardiovascular muito elevado';
    }
    if (waistAbdominal >= 80) {
      return 'Risco cardiovascular elevado';
    }
  }

  return 'Sem Risco Cardiovascular';
};
