import axios from '../middlewares/axios';

const defaultRoute = '/partner/partners';

class PartnerService {
  static getParter(partnerId) {
    const url = `${defaultRoute}/${partnerId}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static getPaginatedPartners(page, searchText, orderBy, order) {
    const url = `${defaultRoute}?page=${page}&filter=${searchText}&orderBy=${orderBy}&order=${order}`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static putPartner(partner) {
    const url = `${defaultRoute}`;
    return axios.put(url, partner)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static postPartner(newPartner) {
    const url = `${defaultRoute}`;
    return axios.post(url, newPartner)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }

  static deletePartner(partnerId) {
    const url = `${defaultRoute}/${partnerId}`;
    return axios.delete(url)
      .then((response) => response.data)
      .catch((error) => { throw error; });
  }
}

export default PartnerService;
