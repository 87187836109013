/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ViewContext from '../../../../ViewContext';
import strings from '../../../../assets/strings.json';
import icLogo from '../../../../assets/svgs/ic-logo.svg';
import HauxButton from '../../../../components/HauxButton';
import '../sheduleCard.scss';

const {
  DATE_FORMATS,
} = strings;

function HauxProspectsCard({
  contentMeetings,

}) {
  const history = useHistory();
  const { setSelected } = useContext(ViewContext);

  return (
    <Paper>
      <Grid container direction="column" className="cardContainer">
        <div className="titleCardContainer">
          <img src={icLogo} alt="" style={{ height: 20 }} />
          <span className="h6StylePurple spaceLeftTitle">HAUX Tour</span>
          <div className="positionRoom">
            <span className="cardRoom">Sala Multispace</span>
          </div>
        </div>
        <Grid container direction="row">
          {contentMeetings.map((
            item,
          ) => (
            <>
              <div className="spaceCardSubtitleWithoutMargin">
                <span className="paragraph">{item.clientName}</span>
                <span className="paragraph ">{moment(item.start).format(DATE_FORMATS.DAYMONTH)}</span>
              </div>
              <div className="spaceCardSubtitle">
                <span className="description">{item.sourceChannel}</span>
                <span className="hourText">{moment(item.start).format(DATE_FORMATS.HOUR)}</span>
              </div>
              <div className="borderCard" />
            </>
          ))}
        </Grid>
        <div className="buttonCardContainer">
          <HauxButton
            type="secondary"
            text="Ver agenda"
            onClick={() => {
              history.push('/schedule/tour');
              setSelected(1);
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}

HauxProspectsCard.propTypes = {
  contentMeetings: PropTypes.array,
};
HauxProspectsCard.defaultProps = {
  contentMeetings: [],
};

export default HauxProspectsCard;
