/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useReducer, useEffect, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';
import './schedule.scss';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { HAUXTextField } from '../../components/HauxInput';
import HAUXSelect from '../../components/HauxSelect';
import HAUXButton from '../../components/HauxButton';
import HAUXCalendar from '../../components/HauxCalendar';
import HAUXTab from '../../components/HauxTab';
import ClientService from '../../service/ClientService';
import ProfessionalService from '../../service/ProfessionalService';
import ScheduleService from '../../service/ScheduleService';
import NewEventDialog from './components/newEvent';
import { getCurrentUser, setPayload } from '../../utils/Utils';
import AnalyticsManager from '../../AnalyticsManager';
import strings from '../../assets/strings.json';
import { reducer, initialState } from './reducers';
import ProspectService from '../../service/ProspectService';
import ViewContext from '../../ViewContext';

const {
  PROFESSIONALS_SCHEDULE, PHYSICAL_ACTIVITY_CLASSES, REMAINING_QUERIES,
  ROOMS, HAUX_TRAINING, HAUX_FIT, HAUX_NUTRI, HAUX_MULTISPACE, HAUX_TECH,
  NO_CLIENTS_FOUND, NO_PROSPECTS_FOUND,
} = strings;
const {
  NURSE, PHYSICAL_EDUCATOR, PSYCHOLOGIST, NUTRITIONIST,
} = PROFESSIONALS_SCHEDULE;

const FILTER_BY_ROOM = 1;
const FILTER_BY_CLIENT = 2;

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    marginTop: 41,
  },
  rowContainer: {
    marginTop: 32,
    marginBottom: 20,
    minHeight: 75,
  },
  searchButtonContainer: {
    marginTop: -3,
  },
  option: {
    fontFamily: 'Graviola',
    fontSize: 14,
    color: '#737373',
    mixBlendMode: 'normal',
  },
  maxWidthStyle: {
    maxWidth: 1156,
  },
}));

const Agenda = ({ tab }) => {
  const classes = useStyles();
  const user = getCurrentUser();
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { handleRequestsErrors } = useContext(ViewContext);

  const {
    filter, room, roomsToFilter, dateRange, currentClient, clients, professionals,
    events, filteredEvents, remainingConsultations, initialDate, newEventDialogOpen,
    newEventDialogDate, eventDialog, prospects,
  } = state;

  const getFilteredEvents = useCallback(() => {
    let resultEvents = events.filter((e) => roomsToFilter.includes(e.room));

    if (tab === 3) {
      resultEvents = resultEvents.filter((e) => e.isTour);
    } else {
      resultEvents = resultEvents.filter((e) => !e.isTour);
    }

    if (filter === FILTER_BY_CLIENT && currentClient && tab !== 1 && tab !== 2) {
      return resultEvents.filter((e) => e.client._id === currentClient.clientId
        || e.client._id === currentClient.prospectId);
    }

    if (filter === FILTER_BY_CLIENT && currentClient && (tab === 1 || tab === 2)) {
      return resultEvents.filter((e) => {
        const { participants } = e;
        return participants.filter((p) => p._id === currentClient.clientId).length > 0;
      });
    }

    if (filter === FILTER_BY_ROOM && room && tab === 0) {
      return resultEvents.filter((e) => e.room === room);
    }

    return resultEvents;
  }, [currentClient, events, filter, room, roomsToFilter, tab]);

  const loadClients = useCallback(() => {
    ClientService.getClients()
      .then((response) => {
        dispatch(setPayload('CLIENTS', response));
      })
      .catch((err) => {
        handleRequestsErrors(err);
      });
  }, [handleRequestsErrors]);

  useEffect(() => {
    AnalyticsManager.track('View Schedule');
  }, []);

  useEffect(() => {
    loadClients();
    ProspectService.getProspects()
      .then((response) => {
        dispatch(setPayload('PROSPECTS', response));
      })
      .catch((err) => {
        handleRequestsErrors(err);
      });
    ProfessionalService.getProfessionals()
      .then((response) => {
        dispatch(setPayload('PROFESSIONALS', response));
      })
      .catch((err) => {
        handleRequestsErrors(err);
      });
  }, [loadClients, handleRequestsErrors]);

  useEffect(() => {
    if (dateRange) {
      ScheduleService.getEvents(dateRange.startStr, dateRange.endStr)
        .then((response) => {
          dispatch(setPayload('EVENTS', response));
        });
    }
  }, [dateRange]);

  useEffect(() => {
    dispatch(setPayload('FILTERED_EVENTS', getFilteredEvents()));
  }, [events, roomsToFilter, getFilteredEvents]);

  useEffect(() => {
    if (currentClient && currentClient.clientId) {
      ScheduleService.getRemainingClientConsultations(currentClient.clientId)
        .then((response) => {
          dispatch(setPayload('REMAINING_CONSULTATIONS', response));
        })
        .catch((err) => {
          handleRequestsErrors(err);
        });
    }
  }, [currentClient, handleRequestsErrors]);

  useEffect(() => {
    dispatch(setPayload('CURRENT_CLIENT', null));
    dispatch(setPayload('ROOM', ''));
    dispatch(setPayload('FILTER', ''));
    if (tab === 0) {
      dispatch(setPayload('ROOMS_TO_FILTER', ROOMS.filter((r) => r !== HAUX_MULTISPACE)));
    } else if (tab === 2) {
      dispatch(setPayload('ROOMS_TO_FILTER', [HAUX_TRAINING]));
    } else {
      dispatch(setPayload('ROOMS_TO_FILTER', [HAUX_MULTISPACE]));
    }
  }, [tab]);

  const onEventSelected = (event) => {
    dispatch(setPayload('EVENT_DIALOG_OPEN', true));
    dispatch(setPayload('EVENT_DIALOG_DATE', moment(event.start)));
    dispatch(setPayload('EVENT_DIALOG', event._id ? { ...event } : {}));
  };

  useEffect(() => {
    if (location.state && location.state.client) {
      dispatch(setPayload('FILTER', FILTER_BY_CLIENT));
      dispatch(setPayload('CURRENT_CLIENT', location.state.client));
    }
  }, [location.state]);

  const renderFilters = () => (
    <Grid container spacing={2} className={classes.rowContainer}>
      <Grid item xs={3}>
        <HAUXSelect
          label="Filtrar por"
          options={tab === 0 ? [
            { label: 'Sala', value: FILTER_BY_ROOM },
            { label: 'Cliente', value: FILTER_BY_CLIENT },
          ] : tab === 3 ? [
            { label: 'Prospect', value: FILTER_BY_CLIENT },
          ] : [{ label: 'Cliente', value: FILTER_BY_CLIENT }]}
          value={filter}
          onChange={(value) => dispatch(setPayload('FILTER', value))}
        />
      </Grid>
      <Grid item xs={6}>
        {filter === FILTER_BY_ROOM && tab === 0 && (
          <HAUXSelect
            label="Escolha a sala"
            options={ROOMS.map((r) => ({ label: `Sala ${r}`, value: r }))}
            value={room}
            onChange={(value) => dispatch(setPayload('ROOM', value))}
          />
        )}
        {filter === FILTER_BY_CLIENT && (
          <Autocomplete
            options={tab === 3 ? prospects : clients}
            getOptionLabel={(option) => option.name}
            noOptionsText={tab === 3 ? NO_PROSPECTS_FOUND : NO_CLIENTS_FOUND}
            id="clientes"
            classes={{
              margin: 0,
              option: classes.option,
            }}
            value={currentClient}
            onChange={(e, newValue) => dispatch(setPayload('CURRENT_CLIENT', newValue))}
            renderInput={(params) => (
              <HAUXTextField {...params} label="Nome" fullWidth />
            )}
          />
        )}
      </Grid>
      {filter != null && (
        <Grid item xs={2} className={classes.searchButtonContainer}>
          <HAUXButton
            text="Buscar"
            type="secondary"
            className="CTA"
            fullWidth
            onClick={() => {
              AnalyticsManager.track('Schedule button_find_by_filter clicked');
              dispatch(setPayload('FILTERED_EVENTS', getFilteredEvents()));
            }}
            disabled={
              !filter
            }
          />
        </Grid>
      )}
    </Grid>
  );

  const renderFooter = () => (
    <div className="remainingConsultationContainer">
      {tab === 0 && (
        <>
          <div className="remainingConsultationWrapper enfermagem">
            <h1>
              <li />
              {NURSE}
            </h1>
            <p>
              {remainingConsultations[HAUX_TECH]}
              {' '}
              {REMAINING_QUERIES}
            </p>
          </div>
          <div className="remainingConsultationWrapper edfisico">
            <h1>
              {' '}
              <li />
              {PHYSICAL_EDUCATOR}
            </h1>
            <p>
              {remainingConsultations[HAUX_FIT]}
              {' '}
              {REMAINING_QUERIES}
            </p>
          </div>
          <div className="remainingConsultationWrapper psicologia">
            <h1>
              {' '}
              <li />
              {PSYCHOLOGIST}
            </h1>
            <p>
              {remainingConsultations[HAUX_MULTISPACE]}
              {' '}
              {REMAINING_QUERIES}
            </p>
          </div>
          <div className="remainingConsultationWrapper nutricionista">
            <h1>
              {' '}
              <li />
              {NUTRITIONIST}
            </h1>
            <p>
              {remainingConsultations[HAUX_NUTRI]}
              {' '}
              {REMAINING_QUERIES}
            </p>
          </div>
        </>
      )}
      {tab === 2 && (
        <div className="remainingConsultationWrapper edfisico">
          <h1>
            {' '}
            <li />
            {PHYSICAL_ACTIVITY_CLASSES}
          </h1>
          <p>
            {remainingConsultations[HAUX_TRAINING]}
            {' '}
            {REMAINING_QUERIES}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <>
      {renderFilters()}
      <HAUXCalendar
        initialDate={initialDate}
        events={filteredEvents}
        onRangeChange={dispatch}
        onSelectEvent={(event) => onEventSelected(event)}
        onSelectSlot={(slot) => onEventSelected(slot)}
        dispatch={dispatch}
        isTraining={tab === 2}
      />
      {currentClient && filter === FILTER_BY_CLIENT && renderFooter()}
      {(user.profileType === 'Admin'
        || user.profileType === 'Enfermagem'
        || user.profileType === 'Coordenador'
        || user.profileType === 'Recepcionista'
        || user.profileType === 'Psicologia')
        && (
          <NewEventDialog
            open={newEventDialogOpen}
            onCloseClick={() => {
              dispatch(setPayload('EVENT_DIALOG_OPEN', false));
              dispatch(setPayload('EVENT_DIALOG', {}));
            }}
            isMoreLife={tab === 1}
            isTraining={tab === 2}
            isTour={tab === 3}
            multiple={tab === 1 || tab === 2}
            client={currentClient}
            clients={clients}
            prospects={prospects}
            professionals={professionals}
            date={newEventDialogDate}
            event={eventDialog}
            onDateChange={(date, clientId, eventRoom) => {
              dispatch(setPayload('EVENT_DIALOG_OPEN', false));
              dispatch(setPayload('EVENT_DIALOG', {}));
              dispatch(setPayload('INITIAL_DATE', date));
              if ((clientId && currentClient
                && clientId !== currentClient.clientId && filter === FILTER_BY_CLIENT)
                || (eventRoom && room !== eventRoom && filter === FILTER_BY_ROOM)) {
                dispatch(setPayload('FILTER', ''));
              }
              dispatch(setPayload('CURRENT_CLIENT', { ...currentClient }));
            }}
            reloadClients={loadClients}
          />
        )}
    </>
  );
};

const Schedule = ({ match, history }) => {
  const classes = useStyles();
  const location = useLocation();
  const { setRouteProperties } = useContext(ViewContext);

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Agendas',
    });
  }, [setRouteProperties]);

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item xs={10} className={classes.maxWidthStyle}>
        <HAUXTab
          route={location.pathname.replace(`${match.url}/`, '')}
          onChange={(tab) => {
            history.push(`${match.url}/${tab}`);
          }}
        />
        <Switch>
          <Route path={`${match.url}/consults`} component={() => <Agenda tab={0} />} />
          <Route path={`${match.url}/moreLife`} component={() => <Agenda tab={1} />} />
          <Route path={`${match.url}/training`} component={() => <Agenda tab={2} />} />
          <Route path={`${match.url}/tour`} component={() => <Agenda tab={3} />} />
        </Switch>
      </Grid>
    </Grid>
  );
};

Schedule.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Agenda.propTypes = {
  tab: PropTypes.number.isRequired,
};

export default Schedule;
