/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { ReactComponent as AcDrag } from '../../assets/svgs/ac-drag.svg';
import Chat from './index';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    pointerEvents: 'none',
    cursor: 'default',

    '& .MuiBackdrop-root': {
      display: 'none',
      pointerEvents: 'none',
      cursor: 'default',
    },

    '& .MuiDialog-container': {

      pointerEvents: 'none',
      cursor: 'default',

      '& .MuiPaper-root': {
        pointerEvents: 'auto',
        position: 'absolute',
        top: 10,
        right: 10,
      },
    },
  },
  content: {
    padding: 16,
  },
  title: {
    padding: 16,
  },
}));

export default function DraggableDialog({
  open, onClose, closeChat,
}) {
  const classes = useStyles();
  const handleClose = () => {
    if (onClose) { onClose(); }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.container}
      disableEnforceFocus
    >
      <DialogTitle
        disableTypography
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
        className={classes.title}
      >
        <AcDrag height={24} width={24} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Chat width={380} height={440} closeChat={closeChat} isDialog />
      </DialogContent>
    </Dialog>
  );
}

DraggableDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeChat: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

DraggableDialog.defaultProps = {
  onClose: () => { },
};
