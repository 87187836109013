import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HauxConfirmDialog from "../../../components/HauxConfirmDialog";
import icInfo from "../../../assets/svgs/ic-info.svg";
import acDown from "../../../assets/svgs/ac-chevron-down.svg";
import acUp from "../../../assets/svgs/ac-chevron-up.svg";
import HauxButton from "../../../components/HauxButton";
import AnalyticsManager from "../../../AnalyticsManager";
import AdditionalInfoCard from "../components/additionalnfoCard";
import { setPayload, getCurrentUser } from "../../../utils/Utils";
import ClientService from "../../../service/ClientService";
import ViewContext from "../../../ViewContext";

const useStyles = makeStyles((theme) => ({
  additionalInfoContainer: {
    marginTop: 40,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxWidth: 768,
    minWidth: 768,
    minHeight: 400,
    maxHeight: 600,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 5, 2),
  },
  textareaContainer: {
    marginTop: 40,
    paddingRight: 10,
    paddingLeft: 10,
    justifyContent: "flex-start",
    width: "100%",
  },
  infoPaper: {
    marginTop: 8,
    paddingTop: 40,
    paddingBottom: 32,
    paddingLeft: 96,
    paddingRight: 96,
  },
  paperButtonContainer: {
    marginTop: 16,
  },
}));

function AdditionalInfo({ state, dispatch, client }) {
  const { showAdditionalInfoModal, additionalInfo, exitFormDialog } = state;
  const { handleRequestsErrors, setLoading } = useContext(ViewContext);
  const [inputValue, setInputValue] = useState("");
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();
  const user = getCurrentUser();

  const submitNewInfo = () => {
    setLoading(true);
    ClientService.postNewClientAdditionalInfo(client.clientId, inputValue)
      .then((res) => {
        AnalyticsManager.track("Create AdditionalInfo");
        setInputValue("");
        dispatch(setPayload("UPDATE_ADDITIONAL_INFO", res));
      })
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  };

  const renderAdditionalInfoContainer = () =>
    additionalInfo.map((info) => (
      <AdditionalInfoCard
        dispatch={dispatch}
        state={state}
        info={info}
        key={info.infoId}
        userId={user.userId}
        userName={user.name}
      />
    ));

  const exitForm = () => {
    if (!inputValue) {
      dispatch(setPayload("TOGGLE_ADDITIONAL_INFO_MODAL", false));
    } else {
      dispatch({ type: "EXIT_FORM_DIALOG", payload: true });
    }
  };

  const renderConfirmModal = () => (
    <HauxConfirmDialog
      title="Sair do formulário"
      message="Alterações não salvas serão perdidas. Deseja continuar?"
      open={exitFormDialog}
      handleClose={() => dispatch(setPayload("EXIT_FORM_DIALOG", false))}
      handleConfirm={() => {
        dispatch(setPayload("TOGGLE_ADDITIONAL_INFO_MODAL", false));
        dispatch(setPayload("EXIT_FORM_DIALOG", false));
        setInputValue("");
      }}
    />
  );

  const renderAdditionalInfoModal = () => (
    <Modal
      open={showAdditionalInfoModal}
      onClose={() =>
        dispatch(setPayload("TOGGLE_ADDITIONAL_INFO_MODAL", false))
      }
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
    >
      <Fade in={showAdditionalInfoModal}>
        <Grid container className={classes.paperModal} justify="center">
          <Grid item justify="center" container>
            {renderConfirmModal()}
            <span className="additionalInfoModalTitle">
              Informações adicionais
            </span>
          </Grid>
          {renderAdditionalInfoContainer()}
          <Grid item className={classes.textareaContainer}>
            <span className="paragraph">ADICIONAR INFORMAÇÕES</span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            className={classes.modalButtonContainer}
          >
            <HauxButton
              type="secondary"
              text="cancelar"
              onClick={() => {
                AnalyticsManager.track(
                  "Canceled button_modal_additionalInfo clicked"
                );
                exitForm();
              }}
            />
            <HauxButton onClick={submitNewInfo} text="salvar" />
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );

  const renderNewInfoButton = () => (
    <HauxButton
      text="Adicionar"
      onClick={() => {
        AnalyticsManager.track("Dashboard button_modal_additionalInfo clicked");
        dispatch(setPayload("TOGGLE_ADDITIONAL_INFO_MODAL", true));
      }}
    />
  );

  const renderLatesInfoPaper = () => {
    return (
      <Paper className={classes.infoPaper}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Collapse in={showMore} collapsedHeight={40}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              {additionalInfo.map((item) => {
                return (
                  <Grid item style={{ padding: 10 }}>
                    <span className="paragraph">{item.text}</span>
                  </Grid>
                );
              })}
            </Grid>
          </Collapse>
          <Grid item className={classes.paperButtonContainer}>
            {additionalInfo.length > 1 ? (
              <Button onClick={() => setShowMore(!showMore)}>
                {showMore ? (
                  <img src={acUp} alt="" />
                ) : (
                  <img src={acDown} alt="" />
                )}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Grid
      className={classes.additionalInfoContainer}
      container
      direction="column"
      item
      xs
    >
      <Grid
        item
        justify="space-between"
        container
        style={{ alignItems: "center" }}
      >
        <Grid item>
          <img src={icInfo} alt="" className="iconStyle" />
          <span className="h5Purple">Informações adicionais</span>
        </Grid>
        {additionalInfo.length ? (
          <Grid item>
            <HauxButton
              text="Adicionar e Editar"
              onClick={() =>
                dispatch(setPayload("TOGGLE_ADDITIONAL_INFO_MODAL", true))
              }
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        {additionalInfo.length ? renderLatesInfoPaper() : renderNewInfoButton()}
      </Grid>
      {renderAdditionalInfoModal()}
    </Grid>
  );
}

export default AdditionalInfo;

AdditionalInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.shape({
    showAdditionalInfoModal: PropTypes.bool,
    exitFormDialog: PropTypes.bool,
    additionalInfo: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  client: PropTypes.shape({
    clientId: PropTypes.string,
  }).isRequired,
};
