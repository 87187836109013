import React, { useReducer, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {
  TextField,
  Grid,
  Dialog,
  GridList,
  GridListTile,
  DialogActions,
} from "@material-ui/core";
import { FIELD_REQUIRED } from "../../../../../assets/strings.json";
import {
  HauxInput,
  HauxDropdown,
  HauxCheck,
  HauxButton,
  HauxRadio,
} from "../../../../../components";
import HauxConfirmDialog from "../../../../../components/HauxConfirmDialog";
import { setPayload } from "../../../../../utils/Utils";
import { initialState, reducer } from "./reducers";
import MealService from "../../../../../service/MealService";
import ViewContext from "../../../../../ViewContext";
import AnalyticsManager from "../../../../../AnalyticsManager";
import "./styles.scss";

const restrictionsOptionsArray = [
  { label: "DCV", value: "cardio" },
  { label: "DM1/DM2", value: "diabetes" },
  { label: "OB", value: "obeso" },
];

const foodRestrictionsOptionsArray = [
  { label: "Glúten Free", value: "Glúten" },
  { label: "Lactose Free", value: "Lactose" },
];

const restrictionValue = {
  cardio: false,
  diabetes: false,
  obeso: false,
};

const CreateMeal = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    setRouteProperties,
    setLoading,
    setSnackbarOption,
    handleRequestsErrors,
  } = useContext(ViewContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    exitFormDialog,
    title,
    ingredients,
    dietType,
    mealType,
    restrictions,
    foodRestrictions,
    calorificValue,
    photoUrl,
    difficulty,
    preparationMinutes,
    additionalInformation,
    description,
    picturesUrls,
    openPictureSelectionDialog,
    titleError,
    calorificValueError,
    preparationMinutesError,
    healthyDish,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle: "Nova refeição",
      breadcrumbs: "Refeições",
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (location && location.state) {
      const newData = location.state;
      dispatch(setPayload("SET_EDITABLE_DATA", newData));
    }
  }, [location]);

  async function saveMeal() {
    AnalyticsManager.track("Meal button_save clicked");
    try {
      setLoading(true);
      await MealService.postMeal({
        title,
        mealType,
        restrictions,
        calorificValue,
        preparationMinutes,
        difficulty,
        dietType,
        description,
        additionalInformation,
        ingredients,
        photoUrl,
        healthyDish,
      });
      setSnackbarOption({
        open: true,
        type: "success",
        message: "Refeição salva com sucesso!",
      });
      setTimeout(() => history.goBack(), 2000);
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function editMeal() {
    AnalyticsManager.track("Meal button_edit clicked");
    try {
      setLoading(true);
      const mealId = location.state._id;
      const updatedMeal = {
        title,
        mealType,
        restrictions,
        calorificValue,
        preparationMinutes,
        difficulty,
        dietType,
        description,
        additionalInformation,
        ingredients,
        photoUrl,
        healthyDish,
      };
      await MealService.putMeal(mealId, updatedMeal);
      setSnackbarOption({
        open: true,
        type: "success",
        message: "Refeição atualizada com sucesso!",
      });
      setTimeout(() => history.push("/company/meals", { refresh: true }), 2000);
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPicturesUrls() {
    if (!picturesUrls.length) {
      try {
        setLoading(true);
        const urls = await MealService.getMealsUrl();
        dispatch(
          setPayload("MENAGE_PICTURES_DIALOG", {
            picturesUrls: urls,
            openPictureSelectionDialog: true,
          })
        );
      } catch (error) {
        handleRequestsErrors(error);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(
        setPayload("MENAGE_PICTURES_DIALOG", {
          openPictureSelectionDialog: true,
        })
      );
    }
  }

  function verifyFields() {
    return !(
      title &&
      mealType &&
      calorificValue &&
      preparationMinutes &&
      difficulty &&
      dietType &&
      ingredients &&
      description
    );
  }

  function renderPictureSelectionSection() {
    if (!photoUrl) {
      return (
        <Grid
          item
          container
          direction="column"
          className="selectPictureButtonContainer"
        >
          <HauxButton
            text="selecionar foto"
            onClick={() => {
              AnalyticsManager.track("Meal button_select_photo clicked");
              fetchPicturesUrls();
            }}
          />
        </Grid>
      );
    }

    return (
      <>
        <Grid item className="selectedPictureContainer">
          <img src={photoUrl} alt="fototo" className="selectedPicture" />
        </Grid>
        <Grid item>
          <HauxButton
            text="Substituir foto"
            type="tertiary"
            onClick={() => {
              AnalyticsManager.track("Meal button_replace_photo clicked");
              fetchPicturesUrls();
            }}
          />
        </Grid>
      </>
    );
  }

  function renderPicutreSelectionDialog() {
    return (
      <Dialog
        open={openPictureSelectionDialog}
        maxWidth="md"
        className="dialog"
        onClose={() =>
          dispatch(
            setPayload("MENAGE_PICTURES_DIALOG", {
              openPictureSelectionDialog: false,
            })
          )
        }
      >
        <GridList cellHeight={120} cols={4} spacing={0}>
          {picturesUrls.map((url) => (
            <GridListTile
              className="listTile"
              key={url}
              onClick={() => {
                dispatch(setPayload("SELECT_PICTURE", url));
              }}
            >
              <img src={url} alt="foto" />
            </GridListTile>
          ))}
        </GridList>
        <DialogActions>
          <HauxButton
            text="fechar"
            type="tertiary"
            onClick={() => {
              AnalyticsManager.track("Meal button_close clicked");
              dispatch(
                setPayload("MENAGE_PICTURES_DIALOG", {
                  openPictureSelectionDialog: false,
                })
              );
            }}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Grid className="mealNewContainer">
      <Paper className="paperContainer" elevation={2}>
        <Grid className="mealForm" container direction="column">
          <HauxInput
            label="Nome"
            placeholder="Refeição 1"
            value={title}
            onChange={(e) => dispatch(setPayload("SET_TITLE", e.target.value))}
            onBlur={() => {
              if (!title) {
                dispatch(
                  setPayload("SET_TITLE_ERROR", {
                    error: true,
                    message: FIELD_REQUIRED,
                  })
                );
              }
            }}
            onFocus={() => {
              if (titleError.error) {
                dispatch(
                  setPayload("SET_TITLE_ERROR", { error: false, message: "" })
                );
              }
            }}
            error={titleError.error}
            errorMsg={titleError.message}
          />
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className="type-calories-preparationTime-difficult"
          >
            <Grid item xs={3}>
              <HauxDropdown
                label="Tipo de refeição"
                placeholder="Almoço ou jantar"
                options={["Almoço/Janta", "Lanche"]}
                value={mealType}
                style={{
                  minWidth: 100,
                }}
                handleChange={(e) =>
                  dispatch(setPayload("SET_MEAL_TYPE", e.target.value))
                }
                fullWidth
              />
            </Grid>
            <HauxInput
              label="Calorias"
              placeholder="300 kcal"
              type="number"
              value={calorificValue}
              onChange={(e) =>
                dispatch(setPayload("SET_CALORIFIC", e.target.value))
              }
              error={calorificValueError.error}
              errorMsg={calorificValueError.message}
              onBlur={() => {
                if (!calorificValue) {
                  dispatch(
                    setPayload("SET_CALORIFIC_VALUE_ERROR", {
                      error: true,
                      message: FIELD_REQUIRED,
                    })
                  );
                }
              }}
              onFocus={() => {
                if (calorificValueError.error) {
                  dispatch(
                    setPayload("SET_CALORIFIC_VALUE_ERROR", {
                      error: false,
                      message: "",
                    })
                  );
                }
              }}
            />
            <HauxInput
              label="Tempo de preparo"
              placeholder="10 min"
              type="number"
              value={preparationMinutes}
              onChange={(e) =>
                dispatch(setPayload("SET_PREPARATION_MINUTES", e.target.value))
              }
              error={preparationMinutesError.error}
              errorMsg={preparationMinutesError.message}
              onBlur={() => {
                if (!preparationMinutes) {
                  dispatch(
                    setPayload("SET_PREPARATION_MINUTES_ERROR", {
                      error: true,
                      message: FIELD_REQUIRED,
                    })
                  );
                }
              }}
              onFocus={() => {
                if (preparationMinutesError.error) {
                  dispatch(
                    setPayload("SET_PREPARATION_MINUTES_ERROR", {
                      error: false,
                      message: "",
                    })
                  );
                }
              }}
            />
            <Grid item xs={2}>
              <HauxDropdown
                label="Dificuldade"
                options={["Fácil", "Média", "Difícil"]}
                value={difficulty}
                handleChange={(e) =>
                  dispatch(setPayload("SET_DIFFICULTY", e.target.value))
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid className="categoriesOfMeals">
            {restrictionsOptionsArray.map((option) => (
              <HauxCheck
                key={option.value}
                label={option.label}
                value={option.value}
                checked={restrictionValue[`${option.value}`]}
                handleChange={() => {
                  const aux = restrictionValue;
                  aux[`${option.value}`] = !restrictionValue[`${option.value}`];
                  dispatch(setPayload("SET_RESTRICTIONS", aux));
                }}
              />
            ))}
            {foodRestrictionsOptionsArray.map((option) => (
              <HauxCheck
                key={option.value}
                label={option.label}
                value={option.value}
                checked={!foodRestrictions.includes(option.value)}
                handleChange={() => {
                  if (foodRestrictions.includes(option.value)) {
                    dispatch(
                      setPayload(
                        "SET_FOOD_RESTRICTIONS",
                        foodRestrictions.filter((v) => v !== option.value)
                      )
                    );
                  } else {
                    dispatch(
                      setPayload("SET_FOOD_RESTRICTIONS", [
                        ...foodRestrictions,
                        option.value,
                      ])
                    );
                  }
                }}
              />
            ))}
            {["Padrão", "Vegano", "Vegetariano"].map((item, index) => (
              <HauxRadio
                key={index.toString()}
                label={item}
                value={item}
                checked={dietType.includes(item)}
                handleChange={() => dispatch(setPayload("SET_DIET_TYPE", item))}
              />
            ))}
          </Grid>
          <Grid className="description-additionalInformation">
            <Grid className="description">
              <span className="h6NormalPurpleStyle">Descrição curta</span>
              <TextField
                variant="outlined"
                multiline
                rows={6}
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 16,
                    color: "#737373",
                    fontFamily: "Graviola",
                    height: 100,
                    padding: 8,
                  },
                }}
                style={{ marginTop: 8 }}
                value={description}
                onChange={(e) =>
                  dispatch(setPayload("SET_DESCRIPTION", e.target.value))
                }
              />
            </Grid>
            <Grid className="additionalInformation">
              <span className="h6NormalPurpleStyle">
                Informações adicionais
              </span>
              <TextField
                variant="outlined"
                multiline
                rows={6}
                fullWidth
                inputProps={{
                  style: {
                    fontSize: 16,
                    color: "#737373",
                    fontFamily: "Graviola",
                    height: 100,
                    padding: 8,
                  },
                }}
                style={{ marginTop: 8 }}
                value={additionalInformation}
                onChange={(e) =>
                  dispatch(
                    setPayload("SET_ADDITIONAL_INFORMATION", e.target.value)
                  )
                }
                maxLength="50"
              />
              <span className="description charactersText">{`${additionalInformation.length}/50`}</span>
            </Grid>
          </Grid>
          <span className="h6NormalPurpleStyle">Alimentos</span>
          <TextField
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            inputProps={{
              style: {
                fontSize: 16,
                color: "#737373",
                fontFamily: "Graviola",
                height: 100,
                padding: 8,
              },
            }}
            style={{ marginTop: 8 }}
            value={ingredients}
            onChange={(e) =>
              dispatch(setPayload("SET_INGREDIENTS", e.target.value))
            }
          />
          {renderPictureSelectionSection()}
          <Grid className="categoriesOfMeals">
            <HauxCheck
              label="Prato Saudável"
              value={healthyDish}
              checked={healthyDish}
              handleChange={() => {
                dispatch(setPayload("SET_HEALTHY_DISH", !healthyDish));
              }}
            />
          </Grid>
          <Grid
            item
            className="actionButtons"
            container
            justify="space-between"
          >
            <HauxButton
              text="Cancelar"
              type="secondary"
              onClick={() => dispatch(setPayload("EXIT_FORM_DIALOG", true))}
            />
            <HauxButton
              text="Salvar"
              type="primary"
              disabled={verifyFields()}
              onClick={() => {
                if (!location.state) {
                  saveMeal();
                  dispatch(setPayload("RESET_STATE"));
                } else {
                  editMeal();
                }
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      {renderPicutreSelectionDialog()}
      <HauxConfirmDialog
        title="Sair do formulário"
        message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
        open={exitFormDialog}
        handleClose={() => dispatch(setPayload("EXIT_FORM_DIALOG", false))}
        handleConfirm={() => {
          history.goBack();
        }}
      />
    </Grid>
  );
};

export default CreateMeal;
