/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./hauxSearchList.scss";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useHistory } from "react-router-dom";
import HauxTableSortLabel from "../HauxTableSortLabel";
import HauxSwitch from "../HauxSwitch";
import HauxConfirmDialog from "../HauxConfirmDialog";
import acEdit from "../../assets/svgs/ac-edit.svg";
import acDelete from "../../assets/svgs/ac-delete.svg";
import acPresence from "../../assets/svgs/ac-presence.svg";
import acAbsence from "../../assets/svgs/ac-absence.svg";
import { ReactComponent as IcAlertNext } from "../../assets/svgs/ic-alert-next.svg";
import { ReactComponent as IcAlertRecent } from "../../assets/svgs/ic-alert-recent.svg";
import { setPayload, converEventToBaseContult } from "../../utils/Utils";
import strings from "../../assets/strings.json";
import HauxCheckbox from "../HauxCheckbox";
import PlanConfirmationDialog from "../HauxPlanConfirmationDialog";

const {
  NOT_CONFIRMED,
  PRESENT,
  DATE_FORMATS,
  DELETE_CLIENT_INFO_DIALOG,
  DELETE_PROSPECT_INFO_DIALOG,
  UNSCHEDULED,
} = strings;

const WARNING_TEXT = "Inativação próxima";
const INACTIVE_TEXT = "Cliente inativado recentemente";

const NEW_CLIENT_ROUTE = "/client/newClient/";

const useStyles = makeStyles(() => ({
  itemContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerItems: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  listItems: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  paperContainer: {
    padding: 32,
    position: "relative",
  },
  orderButton: {
    padding: 8,
    minWidth: 0,
  },
  actionButton: {
    minWidth: 0,
  },
  actionButtonName: {
    fontFamily: "Graviola, sans-serif",
    fontStyle: "normal",
    fontWight: "normal",
    fontSize: 16,
    color: "#737373",
    textTransform: "none",
    marginLeft: -8,

    "& > .MuiButton-label": {
      textAlign: "left",
    },
  },
  progress: {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const buildWarningText = (events, client, isWarning = false) => {
  const convertedEvents = events.map((event) =>
    converEventToBaseContult(event, client)
  );
  return (
    <div>
      <span>{isWarning ? WARNING_TEXT : INACTIVE_TEXT}</span>
      <br />
      {convertedEvents.map((event, index) => (
        <React.Fragment key={index.toString()}>
          <span>
            {`Ausencia ${index + 1}: ${moment(event.date).format(
              DATE_FORMATS.NORMAL
            )} - Encontro ${event.consultNumber} ${event.label}`}
          </span>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const HauxSearchList = ({
  data,
  loading,
  dispatch,
  updateStatus,
  getClients,
  type,
  totalItems,
  deleteClient,
  warnings,
  inactives,
  selectedClients,
  deleteProspect,
  selectedProspects,
  allProspects,
  getProspects,
  totalPages,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [sort, setSort] = useState({
    activeColumn: "name",
    contractDirection: "asc",
    nameDirection: "asc",
    tourDirection: "asc",
  });
  const [showDeleteClientModal, setShowDeleteClientModal] = useState({
    clientId: 0,
  });
  const [showDeleteProspectModal, setShowDeleteProspectModal] = useState({
    prospectId: 0,
  });
  const [planDialog, setPlanDialog] = useState({});

  const handleDeleteClientAction = async (id, index) => {
    await deleteClient(id, index);
    setShowDeleteClientModal({ clientId: 0 });
  };

  const handleDeleteProspectAction = async (id) => {
    await deleteProspect(id);
    setShowDeleteProspectModal({ prospectId: 0 });
  };

  const renderAlertIcon = (clientId) => {
    const warning = warnings.find((warn) => warn.id === clientId);
    if (warning) {
      return (
        <Tooltip
          placement="top-start"
          arrow
          title={buildWarningText(warning.events, clientId, true)}
        >
          <IcAlertNext className="alert" />
        </Tooltip>
      );
    }

    const inactive = inactives.find(
      (inactivaged) => inactivaged.id === clientId
    );
    if (inactive) {
      return (
        <Tooltip
          placement="top-start"
          arrow
          title={buildWarningText(inactive.events, clientId)}
        >
          <IcAlertRecent className="alert" />
        </Tooltip>
      );
    }

    return <div className="warningPlaceholder" />;
  };

  const renderClientListItem = () =>
    data.map((client, index) => (
      <React.Fragment key={client.clientId}>
        <HauxConfirmDialog
          handleClose={() => setShowDeleteClientModal({ clientId: 0 })}
          message={DELETE_CLIENT_INFO_DIALOG.MESSAGE}
          title={DELETE_CLIENT_INFO_DIALOG.TITLE}
          open={showDeleteClientModal.clientId === client.clientId}
          isDestructive
          handleConfirm={() => handleDeleteClientAction(client.clientId, index)}
        />
        <Grid item container className={classes.listItems} spacing={2}>
          <Grid item xs={1}>
            <HauxCheckbox
              checked={
                !!(
                  selectedClients.length &&
                  selectedClients.find(
                    (item) => item.clientId === client.clientId
                  )
                )
              }
              handleChange={() => {
                const array = selectedClients;
                if (
                  array.length &&
                  array.find((item) => item.clientId === client.clientId)
                ) {
                  const removeIndex = array
                    .map((elem) => elem.clientId)
                    .indexOf(client.clientId);
                  array.splice(removeIndex, 1);
                } else {
                  array.push(client);
                }
                dispatch(setPayload("SET_SELECTED_CLIENTS", array));
              }}
            />
          </Grid>
          <Grid item xs={6} container className={classes.itemContainer}>
            <Grid item>{renderAlertIcon(client.clientId)}</Grid>
            <Grid item>
              <Button
                className={classes.actionButtonName}
                onClick={() => history.push(NEW_CLIENT_ROUTE, { client })}
              >
                {client.name}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.itemContainer}>
            <HauxSwitch
              checked={client.active}
              onChange={() => updateStatus(client, index)}
              name="clientStatus"
            />
            {client.active ? (
              <span className="paragraph switchClientColor">Ativo</span>
            ) : (
              <span className="paragraph">Inativo</span>
            )}
          </Grid>
          <Grid item xs={2} className={classes.itemContainer}>
            <Button
              className={classes.actionButton}
              onClick={() =>
                history.push(NEW_CLIENT_ROUTE, { client, checkedA: true })
              }
            >
              <img src={acEdit} alt="" />
            </Button>
            <Button
              className={classes.actionButton}
              onClick={() => {
                setShowDeleteClientModal({ clientId: client.clientId });
              }}
            >
              <img src={acDelete} alt="" />
            </Button>
          </Grid>
        </Grid>
        <Box borderBottom="1px solid #e0e0e0" />
      </React.Fragment>
    ));

  const renderProspectEventInfo = (events) => {
    const eventDate = events.length ? events[events.length - 1].start : "";
    const eventConfirmation = events.length
      ? events[events.length - 1].confirmationStatus
      : "";
    if (eventDate) {
      if (eventConfirmation === NOT_CONFIRMED) {
        return (
          <Button onClick={() => history.push("/schedule/tour")}>
            <span className="paragraphPurple">
              {moment(eventDate).format(DATE_FORMATS.NORMAL)}
            </span>
          </Button>
        );
      }

      const icon = eventConfirmation === PRESENT ? acPresence : acAbsence;

      return (
        <Button onClick={() => history.push("/schedule/tour")}>
          <span className="paragraphPurple">
            {moment(eventDate).format(DATE_FORMATS.NORMAL)}
          </span>
          <img src={icon} alt="" className="iconSpaceLeft" />
        </Button>
      );
    }

    return <span className="paragraph iconSpaceLeft">{UNSCHEDULED}</span>;
  };

  const renderProspectListItem = () =>
    data.map((prospect) => (
      <React.Fragment key={prospect.prospectId}>
        <HauxConfirmDialog
          handleClose={() => setShowDeleteProspectModal({ prospectId: 0 })}
          message={DELETE_PROSPECT_INFO_DIALOG.MESSAGE}
          title={DELETE_PROSPECT_INFO_DIALOG.TITLE}
          open={showDeleteProspectModal.prospectId === prospect.prospectId}
          isDestructive
          handleConfirm={() => handleDeleteProspectAction(prospect.prospectId)}
        />
        <Grid item container className={classes.listItems}>
          <Grid item xs={1}>
            <HauxCheckbox
              checked={
                !!(
                  selectedProspects.length &&
                  selectedProspects.find(
                    (item) => item.prospectId === prospect.prospectId
                  )
                )
              }
              handleChange={() => {
                const array = selectedProspects;
                if (
                  array.length &&
                  array.find((item) => item.prospectId === prospect.prospectId)
                ) {
                  const removeIndex = array
                    .map((elem) => elem.prospectId)
                    .indexOf(prospect.prospectId);
                  array.splice(removeIndex, 1);
                } else {
                  array.push(prospect);
                }
                dispatch(setPayload("SELECTED_PROSPECTS", array));
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.itemContainer}>
            <Button
              className={classes.actionButtonName}
              onClick={() =>
                history.push(NEW_CLIENT_ROUTE, { prospect, checkedA: false })
              }
            >
              {prospect.name}
            </Button>
          </Grid>
          <Grid item xs={2} className={classes.itemContainer}>
            <span className="paragraphPurple">{prospect.sourceChannel}</span>
          </Grid>
          <Grid item xs={2} className={classes.itemContainer}>
            <span className="paragraph">{prospect.phone}</span>
          </Grid>
          <Grid item xs={2} className={classes.itemContainer}>
            {renderProspectEventInfo(prospect.events)}
          </Grid>
          <Grid item xs={2} className={classes.itemContainer}>
            <Button
              className={classes.actionButton}
              onClick={() => history.push(NEW_CLIENT_ROUTE, { prospect })}
            >
              <img src={acEdit} alt="" />
            </Button>
            <Button
              className={classes.actionButton}
              onClick={() =>
                setShowDeleteProspectModal({ prospectId: prospect.prospectId })
              }
            >
              <img src={acDelete} alt="" />
            </Button>
          </Grid>
        </Grid>
        <Box borderBottom="1px solid #e0e0e0" />
      </React.Fragment>
    ));

  const renderCheckboxHeader = () => {
    if (type === 1) {
      return (
        <Grid item xs={1}>
          <HauxCheckbox
            checked={
              !!(
                selectedClients.length && selectedClients.length === data.length
              )
            }
            handleChange={() => {
              if (
                selectedClients.length &&
                selectedClients.length === data.length
              ) {
                dispatch(setPayload("SET_SELECTED_CLIENTS", []));
              } else {
                dispatch(setPayload("SET_SELECTED_CLIENTS", data));
              }
            }}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={1}>
        <HauxCheckbox
          checked={
            !!(selectedProspects.length && selectedProspects === allProspects)
          }
          handleChange={() => {
            if (
              selectedProspects.length &&
              selectedProspects === allProspects
            ) {
              dispatch(setPayload("SELECTED_PROSPECTS", []));
            } else {
              dispatch(setPayload("SELECTED_PROSPECTS", allProspects));
            }
          }}
        />
      </Grid>
    );
  };

  const renderTableHeader = () => {
    if (type === 1) {
      return (
        <>
          <Grid item xs={3} className={classes.itemContainer}>
            <span className="h6NormalStyle spaceLeft">Status</span>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={2} className={classes.itemContainer}>
          <span className="h6NormalStyle">Canal</span>
        </Grid>
        <Grid item xs={2} className={classes.itemContainer}>
          <span className="h6NormalStyle">Telefone</span>
        </Grid>
        <Grid item xs={2} className={classes.itemContainer}>
          <HauxTableSortLabel
            active={sort.activeColumn === "tour"}
            direction={sort.tourDirection}
            onClick={() => {
              if (sort.tourDirection === "asc") {
                const organizerArray = data.sort((a, b) => {
                  if (!a.events.length && b.events.length) return 1;
                  if (a.events.length && !b.events.length) return -1;
                  if (a.events.length === b.events.length) return 0;
                  return 0;
                });
                const sortedArray = organizerArray.sort((a, b) => {
                  if (a.events.length && b.events.length) {
                    const dateA = moment(
                      a.events[a.events.length - 1].date
                    ).valueOf();
                    const dateB = moment(
                      b.events[b.events.length - 1].date
                    ).valueOf();
                    return dateA > dateB ? 1 : -1;
                  }
                  return 0;
                });
                dispatch(setPayload("PROSPECTS", sortedArray));
              } else {
                getClients();
              }
              setSort({
                ...sort,
                activeColumn: "tour",
                tourDirection: sort.tourDirection === "asc" ? "desc" : "asc",
              });
            }}
            label="Haux Tour"
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid item xs={12}>
      {loading && <CircularProgress className={classes.progress} />}
      <Grid item className={classes.headerItems}>
        {renderCheckboxHeader()}
        <Grid item xs={type === 1 ? 6 : 3} className={classes.itemContainer}>
          <HauxTableSortLabel
            active={sort.activeColumn === "name"}
            direction={sort.nameDirection}
            onClick={() => {
              if (sort.nameDirection === "asc") {
                if (type === 1) getClients(-1);
                else getProspects(-1);
              } else if (type === 1) getClients(1);
              else getProspects(1);
              setSort({
                ...sort,
                activeColumn: "name",
                nameDirection: sort.nameDirection === "asc" ? "desc" : "asc",
              });
            }}
            label="Nome"
          />
        </Grid>
        {renderTableHeader()}
        <Grid item xs={2} className={classes.itemContainer}>
          <span className="h6NormalStyle leftSpace">Ações</span>
        </Grid>
      </Grid>
      <Grid item>
        {type === 1 ? renderClientListItem() : renderProspectListItem()}
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          breakLabel=""
          breakClassName="breakme"
          pageCount={totalPages || totalItems / 20}
          marginPagesDisplayed={totalPages || totalItems / 20}
          pageRangeDisplayed={totalPages || totalItems / 20}
          onPageChange={(item) => {
            if (type === 1) {
              dispatch(setPayload("CHANGE_PAGE", item.selected + 1));
            } else {
              dispatch(setPayload("PROSPECTS_PAGE", item.selected));
            }
          }}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </Grid>
      <PlanConfirmationDialog
        open={planDialog.visible}
        cart={planDialog.cart}
        client={planDialog.client}
        user={planDialog.user}
        recalculate
        canConfirm={false}
        createdAt={planDialog.createdAt}
        onClose={() => {
          setPlanDialog({
            ...planDialog,
            visible: false,
          });
        }}
      />
    </Grid>
  );
};

export default HauxSearchList;

HauxSearchList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  updateStatus: PropTypes.func,
  getClients: PropTypes.func,
  getProspects: PropTypes.func,
  type: PropTypes.number,
  totalItems: PropTypes.number,
  deleteClient: PropTypes.func,
  selectedClients: PropTypes.array,
  deleteProspect: PropTypes.func,
  selectedProspects: PropTypes.array,
  allProspects: PropTypes.array,
  totalPages: PropTypes.number,
  warnings: PropTypes.arrayOf(PropTypes.object),
  inactives: PropTypes.arrayOf(PropTypes.object),
};

HauxSearchList.defaultProps = {
  data: [],
  loading: false,
  dispatch: () => {},
  updateStatus: () => {},
  getClients: () => {},
  getProspects: () => {},
  deleteClient: () => {},
  deleteProspect: () => {},
  type: 1,
  totalItems: 0,
  selectedClients: [],
  selectedProspects: [],
  allProspects: [],
  warnings: [],
  inactives: [],
  totalPages: 0,
};
