import React, { useReducer, useEffect, useContext } from 'react';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import HauxButton from '../../../components/HauxButton';
import HauxConfirmDialog from '../../../components/HauxConfirmDialog';
import ArticleForm from './forms/articleForm';
import SendSettingsForm from './forms/sendSettingsForm';
import ChallengeForm from './forms/challengeForm';
import Tip from './forms/tipForm';
import ContentService from '../../../service/ContentService';
import './newContent.scss';
import {
  CONTENT_TYPES, CONTENT_SUCCESS, CONFIRM_PAGE_EXIT, CONTENT_ERROR,
} from '../../../assets/strings.json';
import { reducer, initialState } from './reducers';
import { setPayload } from '../../../utils/Utils';
import ViewContext from '../../../ViewContext';
import AnalyticsManager from '../../../AnalyticsManager';

const CONTENT_ROUTE = 'company/content';

const {
  ARTICLE, TIP, CHALLENGE,
} = CONTENT_TYPES;


const useStyles = makeStyles(() => ({
  maxWidthStyle: {
    maxWidth: 1156,
    marginTop: 40,
  },
  selectContainer: {
    marginTop: 20,
    marginBottom: 40,
    maxWidth: 200,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tipTextContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  contentText: {
    marginTop: 8,
  },
  icon: {
    display: 'flex',
  },
  headerContainer: {
    marginBottom: 16,
  },
}));

const NewContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const { contentType } = location.state;
  const {
    setLoading, handleRequestsErrors, setRouteProperties, setSnackbarOption,
  } = useContext(ViewContext);

  const {
    exitFormDialog, content, sendSettings, touched, btnDisabled,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'novo conteúdo',
      breadcrumbs: 'Haux Company',
    });
  }, [setRouteProperties]);

  const verifyAnyFilledField = () => touched && !!(contentType
    || sendSettings.linkedToMeeting
    || (sendSettings.sendTo !== 'Todos')
    || (sendSettings.public && sendSettings.public.all)
    || (sendSettings.public && sendSettings.public.cardio)
    || (sendSettings.public && sendSettings.public.diabetes)
    || (sendSettings.public && sendSettings.public.glutenFree)
    || (sendSettings.public && sendSettings.public.lactoseFree)
    || (sendSettings.public && sendSettings.public.omnivorous)
    || (sendSettings.public && sendSettings.public.vegan)
    || (sendSettings.public && sendSettings.public.vegetarian)
  );

  useEffect(() => {
    dispatch(setPayload('TOUCHED', true));
  }, [content, contentType, sendSettings]);

  useEffect(() => {
    if (sendSettings && sendSettings.public) {
      const checkAllFalse = Object
        .keys(sendSettings.public)
        .every((key) => !sendSettings.public[key]);
      if (checkAllFalse) {
        dispatch(setPayload('BTN_DISABLED', true));
      } else {
        dispatch(setPayload('BTN_DISABLED', false));
      }
    }
  }, [sendSettings]);

  const renderPrompt = () => (
    <Prompt
      when={verifyAnyFilledField() && !exitFormDialog}
      message={CONFIRM_PAGE_EXIT}
    />
  );

  useEffect(() => {
    if (location.state && location.state.content) {
      dispatch(setPayload('CONTENT', location.state.content));
      dispatch(setPayload('SEND_SETTINGS', {
        contentId: location.state.content._id,
        ...location.state.content.sendSettings,
      }));
    }
  }, [location]);

  const getSaveData = () => ({
    ...content,
    type: contentType,
    sendSettings,
    subjects: sendSettings.linkedToMeeting
      ? sendSettings.specialty
      : (content.subjects || []),
  });

  const canSave = () => {
    if (contentType === ARTICLE) {
      return content.title && content.image
        && content.article;
    }

    if (contentType === CHALLENGE) {
      return content.title && content.description
        && content.duration && content.duration > 0
        && content.emoji && content.steps && content.steps.length > 0;
    }

    return true;
  };

  const save = async (publish) => {
    if (!canSave()) {
      setSnackbarOption({
        message: CONTENT_ERROR,
        type: 'error',
        open: true,
      });
      return;
    }
    try {
      setLoading(true);
      const SaveFunction = (content.createdAt)
        ? ContentService.updateContent : ContentService.postContent;


      const publishmentData = publish ? { published: true } : { published: false };
      const data = {
        ...getSaveData(),
        ...publishmentData,
      };

      await SaveFunction(data);
      dispatch(setPayload('TOUCHED', false));
      dispatch(setPayload('BTN_DISABLED', true));
      setSnackbarOption({
        message: CONTENT_SUCCESS,
        type: 'success',
        open: true,
      });
      setTimeout(() => {
        history.push(CONTENT_ROUTE);
      }, 3000);
    } catch (error) {
      handleRequestsErrors(
        error,
        {
          message: 'Erro ao salvar conteúdo. Por favor revise todos os campos.',
          action: (
            <button
              className="reloadButton"
              type="button"
              onClick={() => setSnackbarOption((s) => ({ ...s, open: false }))}
            >
              Ok
            </button>
          ),
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const renderArtigoForm = () => (
    <ArticleForm
      key={`${content._id}`}
      content={content}
      onChange={dispatch}
    />
  );

  const renderDicaForm = () => (
    <Tip
      key={`${content._id}`}
      content={content}
      onChange={dispatch}
    />
  );

  const renderDesafioForm = () => (
    <ChallengeForm
      key={`${content._id}`}
      challenge={content}
      onChange={dispatch}
      onSaveStep={() => save()}
    />
  );

  const renderEnvio = () => (
    <SendSettingsForm
      key={`${content._id}`}
      sendSettings={sendSettings}
      onChange={dispatch}
      published={content.published || false}
      showTipForm={contentType === ARTICLE}
      content={content}
    />
  );

  const renderEnvioChallenge = () => (
    <SendSettingsForm
      challange
      key={`${content._id}`}
      sendSettings={sendSettings}
      onChange={dispatch}
      published={content.published || false}
    />
  );

  const renderBottom = () => (
    <Grid item xs={12} className={classes.footerContainer}>
      <div className={classes.footerContainer}>
        <HauxButton
          text="Voltar"
          type="secondary"
          onClick={() => {
            AnalyticsManager.track('Content button_back clicked', contentType);
            if (verifyAnyFilledField()) {
              dispatch(setPayload('EXIT_FORM_DIALOG', true));
            } else {
              history.push(CONTENT_ROUTE);
            }
          }}
        />
      </div>
      <div className={classes.footerContainer}>
        {
          (!content.published)
            ? (
              <>
                <HauxButton
                  text="Salvar rascunho"
                  type="secondary"
                  onClick={() => {
                    AnalyticsManager.track('Content button_save_draft clicked', contentType);
                    save();
                  }}
                  disabled={btnDisabled}
                />
                <HauxButton
                  text="Publicar"
                  onClick={() => {
                    AnalyticsManager.track('Content button_publish clicked', contentType);
                    save(true);
                  }}
                  disabled={btnDisabled}
                />
              </>
            )
            : (
              <HauxButton
                text="Salvar alterações"
                type="primary"
                onClick={() => {
                  AnalyticsManager.track('Content button_save_editions', contentType);
                  save();
                }}
                disabled={btnDisabled}
              />
            )
        }
      </div>
    </Grid>
  );

  const getFormattedTitle = () => {
    if (contentType === 'Dica do dia') return 'Nova Dica do dia';
    if (contentType === 'Artigo blog') return 'Novo Artigo blog';
    if (contentType === 'Desafio') return 'Novo Desafio';
    return '';
  };

  return (
    <Grid container justify="center" spacing={2} className="container">
      {renderPrompt()}
      <Grid item xs={10} className={classes.maxWidthStyle}>
        <Grid item xs={12}>
          <span className="title">{(content.createdAt) ? `Editar ${contentType}` : getFormattedTitle()}</span>
        </Grid>
        {contentType && contentType !== CHALLENGE && (
          <>
            <Paper>
              <Grid item xs={12} className="paperContainer">
                <Grid item xs={12}>
                  {contentType === ARTICLE && renderArtigoForm()}
                  {contentType === TIP && renderDicaForm()}
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {contentType && contentType === CHALLENGE && (
          renderDesafioForm()
        )}
        <Paper>
          <Grid item xs={12} className="paperContainer">
            <Grid item xs={12}>
              {contentType === CHALLENGE ? renderEnvioChallenge() : renderEnvio()}
            </Grid>
          </Grid>
        </Paper>
        {renderBottom()}
      </Grid>

      <HauxConfirmDialog
        title="Sair do formulário"
        message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
        open={exitFormDialog}
        handleClose={() => dispatch(setPayload('EXIT_FORM_DIALOG', false))}
        handleConfirm={() => {
          dispatch(setPayload('EXIT_FORM_DIALOG', false));
          history.push(CONTENT_ROUTE);
        }}
      />
    </Grid>
  );
};

export default NewContent;
