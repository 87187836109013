import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HAUXTab, {
  valueToRoute,
  routeToValue,
} from "../../../../components/HauxTab";
import HAUXButton from "../../../../components/HauxButton";
import strings from "../../../../assets/strings.json";
import ClientService from "../../../../service/ClientService";
import { setPayload } from "../../../../utils/Utils";
import ClientContext from "../../../../ClientContex";

const {
  SCHEDULE_OF_CONSULTS,
  SPECIALTY,
  DATE,
  HOUR,
  ROOM,
  EXPORT_PDF,
  HAUX_TRAINING,
  DATE_FORMATS,
} = strings;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxWidth: 768,
    minWidth: 768,
    maxHeight: 500,
    minHeight: 500,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 5, 2),
  },
  buttonsGrid: {
    marginTop: 32,
    justifyContent: "flex-end",
    paddingRight: 8,
    paddingLeft: 8,
  },
}));

const ScheduleModal = ({ state, dispatch }) => {
  const { tab, open, events } = state;
  const classes = useStyles();
  const { client } = useContext(ClientContext);

  const formatDate = (date) => moment(date).format(DATE_FORMATS.NORMAL);
  const formatHours = (hours) => moment(hours).format(DATE_FORMATS.HOUR);

  const showTrainig = events.filter(
    (trainig) => `${trainig.room}` === HAUX_TRAINING
  );
  const showConsults = events.filter(
    (consult) => `${consult.room}` !== HAUX_TRAINING
  );

  const exportSchedule = async () => {
    const pdfURL = await ClientService.getClientSchedule(client.clientId, tab);
    window.open(pdfURL);
  };

  const renderModalData = () => {
    if (tab === 0) {
      return (
        <>
          <HAUXTab
            route={valueToRoute(tab)}
            onChange={(route) =>
              dispatch(setPayload("TAB", routeToValue(route)))
            }
          />
          <Grid container direction="row">
            <span className="titleModalSchedule">{SCHEDULE_OF_CONSULTS}</span>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              container
              justify="center"
              alignItems="center"
              className="gridContainer"
            >
              <Grid item xs={4}>
                <span className="titleDataModal">{SPECIALTY}</span>
              </Grid>
              <Grid item xs={3}>
                <span className="titleDataModal">{DATE}</span>
              </Grid>
              <Grid item xs={2}>
                <span className="titleDataModal">{HOUR}</span>
              </Grid>
              <Grid item xs={3}>
                <span className="titleDataModal">{ROOM}</span>
              </Grid>
            </Grid>
            {showConsults.map((event) => (
              <Grid
                container
                key={event._id}
                item
                xs={12}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <span className="paragraph">
                    {event.professional.type === "Psicologia Cognitiva"
                      ? "HAUX Mais Vida"
                      : event.room}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span className="paragraph">{formatDate(event.start)}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className="paragraph">{formatHours(event.start)}</span>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
    return (
      <>
        <HAUXTab
          route={valueToRoute(tab)}
          onChange={(route) => dispatch(setPayload("TAB", routeToValue(route)))}
        />
        <Grid container direction="row">
          <span className="titleModalSchedule">
            AGENDA HAUX {tab === 1 ? "TRAINING" : "TOUR"}
          </span>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className="gridContainer"
          >
            <Grid item xs>
              <span className="titleDataModal">{DATE}</span>
            </Grid>
            <Grid iitem xs>
              <span className="titleDataModal">{HOUR}</span>
            </Grid>
          </Grid>
          {showTrainig.map((event) => (
            <Grid
              container
              key={event._id}
              item
              justify="center"
              alignItems="center"
            >
              <Grid item xs>
                <span className="paragraph">{formatDate(event.start)}</span>
              </Grid>
              <Grid item xs>
                <span className="paragraph">{formatHours(event.start)}</span>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => dispatch(setPayload("OPEN", false))}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paperModal}>
          {renderModalData()}
          <Grid container className={classes.buttonsGrid}>
            <HAUXButton
              text={EXPORT_PDF}
              type="primary"
              onClick={exportSchedule}
            />
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ScheduleModal;

ScheduleModal.propTypes = {
  state: PropTypes.shape({
    tab: PropTypes.number,
    open: PropTypes.bool,
    events: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};
