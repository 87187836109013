import moment from 'moment';

export const formatAsMoney = (value) => Intl
  .NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  .format(value);

export const formatAsDecimal = (value, isEntry) => Intl
  .NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  .format(isEntry || !value ? value : -value);

export const formatAsDate = (value) => moment(value)
  .format('DD/MM');
