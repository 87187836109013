/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import cepPromise from "cep-promise";
import { useHistory, useLocation } from "react-router-dom";
import icAddress from "../../../assets/svgs/ic-adress.svg";
import strings from "../../../assets/strings.json";
import HAUXInput from "../../../components/HauxInput";
import HAUXButton from "../../../components/HauxButton";
import HauxPlanConfirmationDialog from "../../../components/HauxPlanConfirmationDialog";
import PlansInfo from "./plansInfo";
import { statesArray, setPayload } from "../../../utils/Utils";
import AnalyticsManager from "../../../AnalyticsManager";
import ClientService from "../../../service/ClientService";

const {
  REGISTER_CLIENT_FAIL,
  REGISTER_CLIENT_SUCCESS,
  ADDRESS,
  REGISTER_PROSPECT_FAIL,
  CLIENT_CPF_ALREADY_REGISTERED,
  FIELD_REQUIRED,
} = strings;

export default function ClientForm({
  dispatch,
  state,
  renderBasicInfo,
  renderConfirmation,
  handleRequestsErrors,
  setSnackbarOption,
  setLoading,
  openCurrentContractPDF,
}) {
  const location = useLocation();
  const history = useHistory();
  const {
    clientData,
    cepError,
    cepErrorMsg,
    cityErrorMsg,
    biologicalSex,
    streetError,
    streetErrorMsg,
    districtError,
    districtErrorMsg,
    numberError,
    numberErrorMsg,
    ufError,
    ufErrorMsg,
    cityError,
    planDialog,
  } = state;

  async function createClient(sendPassword = false) {
    AnalyticsManager.track("NewClientForm button_save_client clicked");
    setLoading(true);
    if (location.state && location.state.prospect) {
      const { prospect } = location.state;
      await ClientService.updateProspectToClient(
        prospect.prospectId,
        clientData,
        biologicalSex
      )
        .then(async (response) => {
          AnalyticsManager.track("UpdateProspectToClient success");
          if (sendPassword) {
            const pdfURL = await ClientService.getNewContract(response.data.id);
            window.open(pdfURL);
          }
          setSnackbarOption({
            type: "success",
            open: true,
            message: REGISTER_CLIENT_SUCCESS,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
        })
        .catch((error) => {
          AnalyticsManager.track("UpdateProspectToClient failed");
          handleRequestsErrors(error, { message: REGISTER_PROSPECT_FAIL });
        });
    } else {
      await ClientService.postClient(clientData, biologicalSex, sendPassword)
        .then(async (response) => {
          AnalyticsManager.track("CreateClient success");
          if (sendPassword) {
            const pdfURL = await ClientService.getNewContract(response.data.id);
            window.open(pdfURL);
          }
          setSnackbarOption({
            type: "success",
            open: true,
            message: REGISTER_CLIENT_SUCCESS,
          });
          setTimeout(() => {
            history.goBack();
          }, 3000);
        })
        .catch((err) => {
          AnalyticsManager.track("CreateClient failed");
          handleRequestsErrors(err, {
            message:
              err.message.substr(-3) === "422"
                ? CLIENT_CPF_ALREADY_REGISTERED
                : REGISTER_CLIENT_FAIL,
          });
        });
    }
    setLoading(false);
  }

  function validateForm() {
    return (
      !clientData.name ||
      !clientData.birthDate ||
      !biologicalSex ||
      !clientData.cpf ||
      !clientData.phone ||
      !clientData.email ||
      !clientData.confirmEmail ||
      clientData.email !== clientData.confirmEmail ||
      !clientData.cep ||
      !clientData.street ||
      !clientData.number ||
      !clientData.district ||
      !clientData.city ||
      !clientData.uf ||
      !clientData.sourceChannel ||
      !clientData.franchises ||
      !clientData.franchises.length > 0 ||
      (clientData.isChild &&
        (!clientData.responsableName || !clientData.responsableCpf)) ||
      state.cpfError
    );
  }

  function renderSaveButtons() {
    if (location.state && location.state.client) {
      return (
        <Grid item xs className="saveButton">
          <HAUXButton
            text="Salvar alterações"
            onClick={() => dispatch(setPayload("SAVE_FORM_DIALOG", true))}
            disabled={validateForm()}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs container alignItems="center" justify="flex-end">
        <Grid item style={{ marginRight: 20 }}>
          <HAUXButton
            text="salvar"
            type="tertiary"
            onClick={() => createClient(false)}
            disabled={validateForm()}
          />
        </Grid>
        <Grid item>
          <HAUXButton
            text="Salvar e Gerar Contrato"
            onClick={() => createClient(true)}
            disabled={validateForm()}
          />
        </Grid>
        <span className="hintText">
          Ao gerar o contrato, a senha do app será enviada para o e-mail
          cadastrado do cliente.
        </span>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className="formGridContainer">
      <Grid item xs={12}>
        {renderBasicInfo()}
        <Paper>
          <Grid container spacing={2} className="paperContainer">
            <Grid item xs={10}>
              <div className="paperTitle">
                <img src={icAddress} alt="" className="iconStyle" />
                <span className="newClientText">{ADDRESS}</span>
              </div>
              <Grid item xs={4}>
                <div className="inputStyle">
                  <HAUXInput
                    label="CEP"
                    mask="99999-999"
                    value={clientData.cep}
                    onChange={(event) => {
                      if (event.target.value.length === 9) {
                        cepPromise(`${event.target.value}`).then((res) => {
                          dispatch(
                            setPayload("CLIENT_DATA", {
                              cep: res.cep,
                              uf: res.state,
                              city: res.city,
                              district: res.neighborhood,
                              street: res.street,
                            })
                          );
                        });
                      } else {
                        dispatch(
                          setPayload("CLIENT_DATA", { cep: event.target.value })
                        );
                      }
                    }}
                    onFocus={() => {
                      dispatch(setPayload("CEP_ERROR", false));
                      dispatch(setPayload("CEP_ERROR_MSG", ""));
                    }}
                    onBlur={() => {
                      if (!clientData.cep) {
                        dispatch(setPayload("CEP_ERROR", true));
                        dispatch(setPayload("CEP_ERROR_MSG", FIELD_REQUIRED));
                      }
                    }}
                    error={cepError}
                    errorMsg={cepErrorMsg}
                    fullWidth
                  />
                </div>
              </Grid>
              <div className="inputStyle">
                <HAUXInput
                  label="Logradouro"
                  value={clientData.street}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", { street: event.target.value })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("STREET_ERROR", false));
                    dispatch(setPayload("STREET_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.street) {
                      dispatch(setPayload("STREET_ERROR", true));
                      dispatch(setPayload("STREET_ERROR_MSG", FIELD_REQUIRED));
                    }
                  }}
                  error={streetError}
                  errorMsg={streetErrorMsg}
                  fullWidth
                />
              </div>
              <Grid container spacing={2} className="inputStyle">
                <Grid item xs={3}>
                  <HAUXInput
                    label="Número"
                    value={clientData.number}
                    onChange={(event) =>
                      dispatch(
                        setPayload("CLIENT_DATA", {
                          number: event.target.value,
                        })
                      )
                    }
                    onFocus={() => {
                      dispatch(setPayload("NUMBER_ERROR", false));
                      dispatch(setPayload("NUMBER_ERROR_MSG", ""));
                    }}
                    onBlur={() => {
                      if (!clientData.number) {
                        dispatch(setPayload("NUMBER_ERROR", true));
                        dispatch(
                          setPayload("NUMBER_ERROR_MSG", FIELD_REQUIRED)
                        );
                      }
                    }}
                    error={numberError}
                    errorMsg={numberErrorMsg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <HAUXInput
                    label="Complemento (opcional)"
                    value={clientData.complement}
                    placeholder="Apartamento, Bloco..."
                    onChange={(event) =>
                      dispatch(
                        setPayload("CLIENT_DATA", {
                          complement: event.target.value,
                        })
                      )
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div className="inputStyle">
                <HAUXInput
                  label="Bairro"
                  value={clientData.district}
                  onChange={(event) =>
                    dispatch(
                      setPayload("CLIENT_DATA", {
                        district: event.target.value,
                      })
                    )
                  }
                  onFocus={() => {
                    dispatch(setPayload("DISTRICT_ERROR", false));
                    dispatch(setPayload("DISTRICT_ERROR_MSG", ""));
                  }}
                  onBlur={() => {
                    if (!clientData.district) {
                      dispatch(setPayload("DISTRICT_ERROR", true));
                      dispatch(
                        setPayload("DISTRICT_ERROR_MSG", FIELD_REQUIRED)
                      );
                    }
                  }}
                  error={districtError}
                  errorMsg={districtErrorMsg}
                  fullWidth
                />
              </div>
              <Grid container spacing={2} className="inputStyle">
                <Grid item xs={9}>
                  <HAUXInput
                    label="Cidade"
                    value={clientData.city}
                    onChange={(event) =>
                      dispatch(
                        setPayload("CLIENT_DATA", { city: event.target.value })
                      )
                    }
                    onFocus={() => {
                      dispatch(setPayload("CITY_ERROR", false));
                      dispatch(setPayload("CITY_ERROR_MSG", ""));
                    }}
                    onBlur={() => {
                      if (!clientData.city) {
                        dispatch(setPayload("CITY_ERROR", true));
                        dispatch(setPayload("CITY_ERROR_MSG", FIELD_REQUIRED));
                      }
                    }}
                    error={cityError}
                    errorMsg={cityErrorMsg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <HAUXInput
                    label="UF"
                    value={clientData.uf}
                    autocomplete
                    autocompleteProps={{
                      options: statesArray,
                      getOptionLabel: (option) => option.value || "",
                    }}
                    onChange={(event, newValue) => {
                      dispatch(
                        setPayload("CLIENT_DATA", {
                          uf:
                            newValue ||
                            (event.target.value
                              ? event.target.value.toUpperCase()
                              : ""),
                        })
                      );
                    }}
                    onSelect={(itemState) =>
                      dispatch(
                        setPayload("CLIENT_DATA", { uf: itemState.value })
                      )
                    }
                    onFocus={() => {
                      dispatch(setPayload("UF_ERROR", false));
                      dispatch(setPayload("UF_ERROR_MSG", ""));
                    }}
                    onBlur={() => {
                      const hasUF =
                        statesArray.filter((s) => s.value === clientData.uf)
                          .length === 1;

                      if (!hasUF) {
                        dispatch(setPayload("CLIENT_DATA", { uf: "" }));
                      }

                      if (!clientData.uf || !hasUF) {
                        dispatch(setPayload("UF_ERROR", true));
                        dispatch(setPayload("UF_ERROR_MSG", FIELD_REQUIRED));
                      }
                    }}
                    error={ufError}
                    errorMsg={ufErrorMsg}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {location.state && location.state.client ? (
          <PlansInfo
            state={state}
            dispatch={dispatch}
            openCurrentContractPDF={openCurrentContractPDF}
          />
        ) : (
          <></>
        )}
        <Grid container spacing={2} className="buttonsContainer">
          {renderSaveButtons()}
          <Grid item xs={6}>
            <HAUXButton
              text="Voltar"
              type="secondary"
              onClick={() => {
                AnalyticsManager.track("NewClientForm button_back clicked");
                if (Object.keys(clientData).length !== 0) {
                  dispatch(setPayload("EXIT_FORM_DIALOG", true));
                } else {
                  history.goBack();
                }
              }}
            />
          </Grid>
        </Grid>

        {renderConfirmation()}

        <HauxPlanConfirmationDialog {...planDialog} />
      </Grid>
    </Grid>
  );
}

ClientForm.propTypes = {
  renderBasicInfo: PropTypes.func.isRequired,
  renderConfirmation: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleRequestsErrors: PropTypes.func.isRequired,
  setSnackbarOption: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  state: PropTypes.shape({
    cpfError: PropTypes.bool,
    cepError: PropTypes.bool,
    streetError: PropTypes.bool,
    districtError: PropTypes.bool,
    numberError: PropTypes.bool,
    ufError: PropTypes.bool,
    cityError: PropTypes.bool,
    cepErrorMsg: PropTypes.string,
    requiredFieldMsg: PropTypes.string,
    streetErrorMsg: PropTypes.string,
    districtErrorMsg: PropTypes.string,
    numberErrorMsg: PropTypes.string,
    ufErrorMsg: PropTypes.string,
    cityErrorMsg: PropTypes.string,
    biologicalSex: PropTypes.string,
    clientData: PropTypes.shape({
      cep: PropTypes.string,
      street: PropTypes.string,
      number: PropTypes.string,
      complement: PropTypes.string,
      uf: PropTypes.string,
      district: PropTypes.string,
      city: PropTypes.string,
      name: PropTypes.string,
      cpf: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      confirmEmail: PropTypes.string,
      sourceChannel: PropTypes.string,
      isChild: PropTypes.bool,
      responsableName: PropTypes.string,
      responsableCpf: PropTypes.string,
      birthDate: PropTypes.string,
      franchises: PropTypes.arrayOf(PropTypes.object),
    }),
    planDialog: PropTypes.object,
    subscriptions: PropTypes.array,
  }).isRequired,
  openCurrentContractPDF: PropTypes.func.isRequired,
};
