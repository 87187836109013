import React, { useReducer, useEffect, useContext } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HAUXButton from '../../components/HauxButton';
import HauxConfirmDialog from '../../components/HauxConfirmDialog';
import HauxCheckbox from '../../components/HauxCheckbox';
import HauxRadio from '../../components/HauxRadio';
import HAUXInput from '../../components/HauxInput';
import HauxQuiz from '../../components/HauxQuiz';
import {
  foodFrequencyArray, frequencyDropdownItems, foodLiquids, setPayload,
} from '../../utils/Utils';
import FoodFrequency from './FoodFrequency';
import RestTime from './RestTime';
import ClientService from '../../service/ClientService';
import AnalyticsManager from '../../AnalyticsManager';
import strings from '../../assets/strings.json';
import { reducer, initialState } from './reducers';
import ClientContext from '../../ClientContex';
import ViewContext from '../../ViewContext';
import './nutritionForm.scss';

const {
  MEALS_COUNT, HAVE_FOOD_INTOLERANCES, HAVE_FOOD_PREFERENCE, DIGESTION_AND_FOOD_FUNCTION,
  CONSIDER_CHEWING, CONSIDER_INTESTINAL_FUNCTION, HAVE_SYMTOMS, NUTRITION_EVALUATION,
  EVALUATION_DATE, FOOD_INQUERIT, HAVE_FOOD_ALERGIES, HAVE_DIET, WHO_COOKS_HOME,
  FOOD_FREQUENCY, HOW_FREQUENTLY_FOOD, LIQUIDS_INGESTION, REST, NUTRITION_SAVE_SUCCESS,
  NUTRITION_SAVE_FAIL, CONFIRM_PAGE_EXIT,
} = strings;

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainer: {
    borderRadius: 8,
    paddingTop: 48,
    paddingBottom: 48,
    marginTop: 16,
  },
  frequencyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  bottomSpace: {
    marginBottom: 32,
  },
  nutritionContainer: {
    maxWidth: 1156,
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginTop: 32,
    marginBottom: 48,
  },
}));

const NutritionAnamnesis = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { client, loadClient } = useContext(ClientContext);
  const {
    handleRequestsErrors, setSnackbarOption, setRouteProperties, setLoading,
  } = useContext(ViewContext);
  const { professional } = location.state;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    exitFormDialog, rerender, numberMeal, foodIntolerance, foodPreference, foodSpeed,
    intestinalFunction, symptoms, foodAlergie, foodDiet, wakeUp, wakeUpToEat, foodFrequency,
    foodLiquid, wakeUpInput, sleepIn, hoursOfSleep, hoursOfSleepInWeekend, sleepDescription,
    evaluationFrequency, questions, alergieDescription, alergieDescriptionError,
    alergieDescriptionMsg, dietDescription, dietDescriptionError, dietDescriptionMsg,
    alcoholicBeverages, saveFormClicked, whoCooksAtHome, stomachacheWhatFoods, formCompleted,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'acompanhamento nutricional',
      breadcrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    AnalyticsManager.track('View NewNutritionAnamnesis');
  }, []);

  useEffect(() => {
    if (rerender) dispatch(setPayload('RERENDER', !rerender));
  }, [rerender]);

  const exitForm = () => dispatch(setPayload('EXIT_FORM_DIALOG', true));

  const saveForm = async () => {
    try {
      setLoading(true);
      const questionsData = {
        useSalt: null,
        useSugar: null,
        useSweetener: null,
        haveFoodAllergies: null,
        haveDieted: null,
        whoCooksInYourHouse: whoCooksAtHome,
      };
      let data = {
        date: moment(),
        professional,
        foodSection: {
          numberOfMealsDay: Number(numberMeal),
          foodIntolerances: foodIntolerance,
          foodPreference,
        },
        digestionAndFoodFunction: {
          foodFast: foodSpeed,
          intestinalFunction,
          evaluationFrequency,
          symptoms,
          stomachacheWhatFoods,
        },
        foodEvaluation: foodFrequency,
        liquidIngestion: foodLiquid,
        rest: {
          wakeUp: wakeUpInput,
          sleepIn,
          sleepHours: hoursOfSleep,
          sleepHoursInWeekend: hoursOfSleepInWeekend,
          sleepDescription,
          middleOfTheNight: wakeUp ? wakeUp === 'Sim' : null,
          middleOfTheNightToEat: wakeUpToEat ? wakeUpToEat === 'Sim' : null,
        },
      };
      if (questions.find((item) => item.value !== null)) {
        questionsData.useSalt = questions[0].value || false;
        questionsData.useSugar = questions[1].value || false;
        questionsData.useSweetener = questions[2].value || false;
        questionsData.haveFoodAllergies = alergieDescription;
        questionsData.haveDieted = dietDescription;
        data = {
          ...data,
          foodSurvey: questionsData,
        };
      }
      await ClientService.postNewNutritionAnamnesis(client.clientId, data);
      AnalyticsManager.track('Create Nutritionist Form');
      dispatch(setPayload('FORM_COMPLETED', true));
      setSnackbarOption({
        open: true,
        message: NUTRITION_SAVE_SUCCESS,
        type: 'success',
      });
      await loadClient();
      setTimeout(() => {
        history.push('/client/dashboard');
      }, 3000);
    } catch (error) {
      handleRequestsErrors(error, { message: NUTRITION_SAVE_FAIL });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeMeal = (value) => {
    dispatch(setPayload('NUMBER_MEAL', value));
  };

  const handleWakeUpChange = (event) => {
    dispatch(setPayload('WAKE_UP_INPUT', event.target.value));
  };

  const handleSleepInChange = (event) => {
    dispatch(setPayload('SLEEP_IN', event.target.value));
  };

  const handleHoursOfSleepChange = (event) => {
    dispatch(setPayload('HOURS_OF_SLEEP', event.target.value));
  };

  const handleHoursOfSleepChangeInWeekend = (event) => {
    dispatch(setPayload('HOURS_OF_SLEEP_IN_WEEKEND', event.target.value));
  };

  const handleSleepDescriptionChange = (event) => {
    dispatch(setPayload('SLEEP_DESCRIPTION', event.target.value));
  };

  const handleChangeAlcoholicBeverages = (event) => {
    dispatch(setPayload('ALCOHOLIC_BEVERAGES', event.target.value));
  };

  const handleChangeQuestions = (item, value) => {
    const alterQuestions = questions;
    const findItem = alterQuestions.find((qt) => item.question === qt.question);
    if (findItem) findItem.value = value;
    dispatch(setPayload('QUESTIONS', alterQuestions));
    dispatch(setPayload('RERENDER', !rerender));
  };

  const handleSelectFoodIntolerance = (value) => {
    let newIntolerances = null;
    if (foodIntolerance.includes(value)) {
      newIntolerances = foodIntolerance.filter((food) => food !== value);
    } else {
      newIntolerances = foodIntolerance.concat(value);
    }
    if (newIntolerances.includes('Nenhuma')) {
      dispatch(setPayload('FOOD_INTOLERANCE', ['Nenhuma']));
      return;
    }
    dispatch(setPayload('FOOD_INTOLERANCE', newIntolerances));
  };

  const handleSelectFoodFrequency = (value) => {
    const objValue = { evaluation: value, frequency: '', period: '' };
    let newFrequency = null;
    if (foodFrequency.find((food) => food.evaluation === objValue.evaluation)) {
      newFrequency = foodFrequency.filter((activity) => activity.evaluation !== value);
    } else {
      newFrequency = foodFrequency.concat(objValue);
    }
    dispatch(setPayload('FOOD_FREQUENCY', newFrequency));
  };

  const handleSelectFoodLiquids = (value) => {
    const objValue = { evaluation: value, frequency: '', period: '' };
    let newLiquid = null;
    if (foodLiquid.find((food) => food.evaluation === objValue.evaluation)) {
      newLiquid = foodLiquid.filter((activity) => activity.evaluation !== value);
    } else {
      newLiquid = foodLiquid.concat(objValue);
    }
    dispatch(setPayload('FOOD_LIQUID', newLiquid));
  };

  const handleChangeFoodPreference = (value) => {
    dispatch(setPayload('FOOD_PREFERENCE', value));
  };

  const handleChangeFoodAlergie = (value) => {
    dispatch(setPayload('FOOD_ALERGIE', value));
  };

  const handleChangeWakeUp = (value) => {
    dispatch(setPayload('WAKE_UP', value));
  };

  const handleChangeWakeUpToEat = (value) => {
    dispatch(setPayload('WAKE_UP_TO_EAT', value));
  };
  const handleChangeFoodDiet = (value) => {
    dispatch(setPayload('FOOD_DIET', value));
  };

  const handleFoodSpeed = (value) => {
    dispatch(setPayload('FOOD_SPEED', value));
  };

  const handleIntestinalFunction = (value) => {
    dispatch(setPayload('INTESTINAL_FUNCTION', value));
  };

  const handleSelectSymptoms = (value) => {
    let newSymptoms = null;
    if (symptoms.includes(value)) {
      newSymptoms = symptoms.filter((symptom) => symptom !== value);
    } else {
      newSymptoms = symptoms.concat(value);
    }
    dispatch(setPayload('SYMPTOMS', newSymptoms));
  };

  const verifyAnyField = () => !!(numberMeal
  || foodIntolerance.length
  || foodPreference
  || foodSpeed
  || intestinalFunction
  || symptoms.length
  || foodAlergie
  || foodDiet
  || wakeUp
  || wakeUpToEat
  || foodFrequency.length
  || foodLiquid.length
  || wakeUpInput
  || sleepIn
  || hoursOfSleep
  || hoursOfSleepInWeekend
  || sleepDescription
  || evaluationFrequency
  || questions.some((quest) => quest.value)
  || alergieDescription
  || alergieDescriptionError
  || alergieDescriptionMsg
  || dietDescription
  || dietDescriptionError
  || dietDescriptionMsg
  || alcoholicBeverages
  || saveFormClicked
  || whoCooksAtHome
  || stomachacheWhatFoods
  );

  const renderPrompt = () => (
    <Prompt
      when={verifyAnyField() && !exitFormDialog && !formCompleted}
      message={CONFIRM_PAGE_EXIT}
    />
  );

  const renderQuestions = () => (
    <Grid item xs={10} className={classes.root}>
      <div className="questionContainer">
        <span className="paragraph">{MEALS_COUNT}</span>
        <div className="radioContainer questionSpace">
          {['4'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                disabled
                value={item}
                handleChange={() => handleChangeMeal(item)}
                checked={numberMeal === item}
                label={item}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="questionContainer">
        <span className="paragraph">{HAVE_FOOD_INTOLERANCES}</span>
        <div className="questionSpace">
          {['Lactose', 'Glúten', 'Nenhuma'].map((item, index) => (
            <div key={index.toString()}>
              <HauxCheckbox
                checked={foodIntolerance.includes(item)}
                handleChange={() => handleSelectFoodIntolerance(item)}
                value={item}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="questionContainer">
        <span className="paragraph">{HAVE_FOOD_PREFERENCE}</span>
        <div className="questionSpace">
          {['Vegetariano', 'Vegano', 'Padrão'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                value={item}
                handleChange={() => handleChangeFoodPreference(item)}
                checked={foodPreference === item}
                label={item}
              />
            </div>
          ))}
        </div>
      </div>
    </Grid>
  );

  const renderFoodFuncionsQuestions = () => (
    <Grid item xs={10} className={classes.root}>
      <span className="title">{DIGESTION_AND_FOOD_FUNCTION}</span>
      <div className="questionContainer">
        <span className="paragraph">{CONSIDER_CHEWING}</span>
        <div className="radioContainer questionSpace">
          {['Rápida', 'Normal', 'Lenta'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                value={item}
                handleChange={() => handleFoodSpeed(item)}
                checked={foodSpeed === item}
                label={item}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="questionContainer">
        <span className="paragraph">{CONSIDER_INTESTINAL_FUNCTION}</span>
        <div className="radioContainer questionSpace">
          {['Constipado', 'Normal', 'Diarréia'].map((item, index) => (
            <div key={index.toString()}>
              <HauxRadio
                value={item}
                handleChange={() => handleIntestinalFunction(item)}
                checked={intestinalFunction === item}
                label={item}
              />
            </div>
          ))}
        </div>
        <HAUXInput
          label="Frequência evacuatória"
          value={evaluationFrequency}
          onChange={(event) => dispatch(setPayload('EVALUATION_FREQUENCY', event.target.value))}
        />
      </div>
      <div className="questionContainer">
        <span className="paragraph">{HAVE_SYMTOMS}</span>
        <div className="questionSpace">
          <div>
            <HauxCheckbox
              checked={symptoms.includes('Azia')}
              handleChange={() => handleSelectSymptoms('Azia')}
              value="Azia"
            />
          </div>
          { symptoms.includes('Azia')
            ? (
              <div className="frequencyContainer">
                <HAUXInput
                  label="Com quais alimentos?"
                  value={stomachacheWhatFoods}
                  onChange={(event) => dispatch(setPayload('STOMACHACHE_WHAT_FOODS', event.target.value))}
                />
              </div>
            )
            : null }
          {['Gastrite', 'Náusea', 'Vômitos'].map((item, index) => (
            <div key={index.toString()}>
              <HauxCheckbox
                checked={symptoms.includes(item)}
                handleChange={() => handleSelectSymptoms(item)}
                value={item}
              />
            </div>
          ))}
        </div>
      </div>
    </Grid>
  );

  return (
    <Grid container spacing={2} className={classes.root}>
      {renderPrompt()}
      <Grid item className={classes.nutritionContainer} xs={10}>
        <Grid item xs={12}>
          <div>
            <h4 className="title">{NUTRITION_EVALUATION}</h4>
            <span className="h4Style">{client.name}</span>
          </div>
          <span className="paragraph">
            {EVALUATION_DATE}
            {' '}
            {moment().format('DD/MM/YYYY')}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              {renderQuestions()}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              {renderFoodFuncionsQuestions()}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10}>
                <span className="title">{FOOD_INQUERIT}</span>
                <div className="questionContainer">
                  <HauxQuiz
                    questions={questions}
                    handleChange={handleChangeQuestions}
                    isRowItems
                  />
                  <div className="questionContainer">
                    <span className="paragraph">{HAVE_FOOD_ALERGIES}</span>
                    <div className="questionSpace">
                      <div className="rowItems">
                        {['Sim', 'Não'].map((item, index) => (
                          <div key={index.toString()}>
                            <HauxRadio
                              value={item}
                              handleChange={() => handleChangeFoodAlergie(item)}
                              checked={foodAlergie === item}
                              label={item}
                            />
                          </div>
                        ))}
                      </div>
                      { foodAlergie === 'Sim'
                        ? (
                          <HAUXInput
                            label="Qual?"
                            value={alergieDescription}
                            onChange={(event) => dispatch(setPayload('ALERGIE_DESCRIPTION', event.target.value))}
                            error={alergieDescriptionError}
                            errorMsg={alergieDescriptionMsg}
                            onFocus={() => {
                              dispatch(setPayload('ALERGIE_DESCRIPTION_ERROR', false));
                              dispatch(setPayload('ALERGIE_DESCRIPTION_MSG', ''));
                            }}
                            onBlur={() => {
                              if (!alergieDescription) {
                                dispatch(setPayload('ALERGIE_DESCRIPTION_ERROR', true));
                                dispatch(setPayload('ALERGIE_DESCRIPTION_MSG', 'Campo obrigatório'));
                              }
                            }}
                          />
                        )
                        : null }
                    </div>
                  </div>
                  <div className="questionContainer">
                    <span className="paragraph">{HAVE_DIET}</span>
                    <div className="questionSpace">
                      <div className="rowItems">
                        {['Sim', 'Não'].map((item, index) => (
                          <div key={index.toString()}>
                            <HauxRadio
                              value={item}
                              handleChange={() => handleChangeFoodDiet(item)}
                              checked={foodDiet === item}
                              label={item}
                            />
                          </div>
                        ))}
                      </div>
                      { foodDiet === 'Sim'
                        ? (
                          <HAUXInput
                            label="Se sim, quem orientou?"
                            value={dietDescription}
                            onChange={(event) => dispatch(setPayload('DIET_DESCRIPTION', event.target.value))}
                            error={dietDescriptionError}
                            errorMsg={dietDescriptionMsg}
                            onFocus={() => {
                              dispatch(setPayload('DIET_DESCRIPTION_ERROR', false));
                              dispatch(setPayload('DIET_DESCRIPTION_MSG', ''));
                            }}
                            onBlur={() => {
                              if (!dietDescription) {
                                dispatch(setPayload('DIET_DESCRIPTION_ERROR', true));
                                dispatch(setPayload('DIET_DESCRIPTION_MSG', 'Campo obrigatório'));
                              }
                            }}
                          />
                        )
                        : null }
                    </div>
                  </div>
                </div>
                <span className="paragraph rowSpace">{WHO_COOKS_HOME}</span>
                <HAUXInput
                  value={whoCooksAtHome}
                  onChange={(event) => {
                    dispatch(setPayload('WHO_COOKS_AT_HOME', event.target.value));
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10} className={classes.bottomSpace}>
                <span className="title">{FOOD_FREQUENCY}</span>
              </Grid>
              <Grid item xs={10}>
                <span className="paragraph">{HOW_FREQUENTLY_FOOD}</span>
                <div className="questionSpace">
                  <FoodFrequency
                    radiosItems={foodFrequencyArray}
                    foodArray={foodFrequency}
                    frequencyItems={frequencyDropdownItems}
                    handleChange={handleSelectFoodFrequency}
                    dispatch={dispatch}
                    setArray="FOOD_FREQUENCY"
                    rerender={rerender}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10} className={classes.bottomSpace}>
                <span className="title">{LIQUIDS_INGESTION}</span>
              </Grid>

              <Grid item xs={10}>
                <div className="questionSpace">
                  <FoodFrequency
                    dropdownFrequency={false}
                    radiosItems={foodLiquids}
                    foodArray={foodLiquid}
                    frequencyItems={frequencyDropdownItems}
                    handleChange={handleSelectFoodLiquids}
                    dispatch={dispatch}
                    setArray="FOOD_LIQUID"
                    rerender={rerender}
                    whichAlcoholicBeverages={alcoholicBeverages}
                    handleChangeWichAlcoholicBeverages={handleChangeAlcoholicBeverages}
                    isAlcoholicBeverages
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paperContainer} elevation={2}>
            <Grid container className={classes.root}>
              <Grid item xs={10}>
                <span className="title">{REST}</span>
              </Grid>
              <Grid item xs={10}>
                <RestTime
                  handleChangeWakeUp={handleChangeWakeUp}
                  handleChangeWakeUpToEat={handleChangeWakeUpToEat}
                  handleChangeWakeUpInput={handleWakeUpChange}
                  handleChangeSleepInInput={handleSleepInChange}
                  wakeUp={wakeUp}
                  wakeUpToEat={wakeUpToEat}
                  wakeUpInput={wakeUpInput}
                  sleepInput={sleepIn}
                  hoursOfSleep={hoursOfSleep}
                  hoursOfSleepInWeekend={hoursOfSleepInWeekend}
                  handleChangeHoursOfSleep={handleHoursOfSleepChange}
                  handleChangeHoursOfSleepInWeekend={handleHoursOfSleepChangeInWeekend}
                  sleepDescription={sleepDescription}
                  handleSleepDescription={handleSleepDescriptionChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item justify="space-between" className={classes.buttonsContainer}>
          <Grid item>
            <HAUXButton
              text="Voltar"
              type="secondary"
              onClick={() => {
                AnalyticsManager.track('NewNutritionAnamnesis button_back clicked');
                exitForm();
              }}
            />
          </Grid>
          <Grid item>
            <HAUXButton
              text="Salvar"
              type="primary"
              onClick={() => dispatch(setPayload('SAVE_FORM_CLICKED', true))}
              disabled={
                !numberMeal
                || (foodIntolerance && !foodIntolerance.length)
                || !foodPreference
                || (foodDiet === 'Sim' && !dietDescription)
                || (foodAlergie === 'Sim' && !alergieDescription)
                || (foodFrequency.find((item) => item.frequency === ''))
                || (foodFrequency.find((item) => item.period === ''))
                || (foodLiquid.find((item) => item.frequency === ''))
                || (foodLiquid.find((item) => item.period === ''))
              }
            />
          </Grid>
        </Grid>
        <HauxConfirmDialog
          title="Salvar formulário"
          message="Após salvar este documento, você não poderá realizar edições. Deseja continuar?"
          open={saveFormClicked}
          handleClose={() => dispatch(setPayload('SAVE_FORM_CLICKED', false))}
          handleConfirm={() => {
            AnalyticsManager.track('NewNutritionistForm button_save_form clicked');
            dispatch(setPayload('SAVE_FORM_CLICKED', false));
            saveForm();
          }}
        />
        <HauxConfirmDialog
          title="Sair do formulário"
          message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
          open={exitFormDialog}
          handleClose={() => dispatch(setPayload('EXIT_FORM_DIALOG', false))}
          handleConfirm={() => {
            history.goBack();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default NutritionAnamnesis;
