import React from "react";
import "./hauxCSV.scss";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const HauxCSV = ({ data, exportCallback }) => {
  const exportAction = (btn) => {
    exportCallback();
    btn.click();
  };

  return (
    <ExcelFile
      element={
        <button
          ref={(btn) => (btn && btn.click ? exportAction(btn) : "")}
          type="button"
        />
      }
      name="data"
    >
      <ExcelSheet dataSet={data} name="dados" />
    </ExcelFile>
  );
};

HauxCSV.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exportCallback: PropTypes.func,
};

HauxCSV.defaultProps = {
  data: [],
  exportCallback: () => {},
};

export default HauxCSV;
