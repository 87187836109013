import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import HorizontalScroll from "react-horizontal-scrolling-menu";
import { ReactComponent as AcPresenceTimeline } from "../../../../assets/svgs/ac-presence-timeline.svg";
import { ReactComponent as AcAbsenceTimeline } from "../../../../assets/svgs/ac-absence-timeline.svg";
import { ReactComponent as AcChevronLeftPurple } from "../../../../assets/svgs/ac-chevron-left.svg";
import { ReactComponent as AcChevronRightPurple } from "../../../../assets/svgs/ac-chevron-right.svg";
import { ReactComponent as acWaitingSchedule } from "../../../../assets/svgs/ac-waiting-schedule.svg";
import { ReactComponent as AcSchedule } from "../../../../assets/svgs/ac-schedule.svg";
import strings from "../../../../assets/strings.json";
import { converEventToBaseContult } from "../../../../utils/Utils";
import "./scheduleTimeline.scss";

const { CONSULTS_STATUS, CONSULTS_STATUS_PT } = strings;

const TimelineItem = ({ date, label, status, consultNumber }) => {
  let Icon;
  let tooltipText;

  if (status === CONSULTS_STATUS.ABSENT) {
    Icon = AcAbsenceTimeline;
    tooltipText = CONSULTS_STATUS_PT.ABSENT;
  } else if (status === CONSULTS_STATUS.NOT_CONFIRMED) {
    Icon = AcSchedule;
    tooltipText = CONSULTS_STATUS_PT.NOT_CONFIRMED;
  } else if (status === CONSULTS_STATUS.NOT_SCHEDULED) {
    Icon = acWaitingSchedule;
    tooltipText = CONSULTS_STATUS_PT.NOT_SCHEDULED;
  } else {
    Icon = AcPresenceTimeline;
    tooltipText = CONSULTS_STATUS_PT.PRESENT;
  }

  return (
    <div className="itemContainer">
      <div className="itemLine" />
      <div className="innerItemContainer">
        {date ? (
          <span className="description">
            {moment(date).format("DD/MM/YYYY")}
          </span>
        ) : (
          <span className="itemTextPlaceHolder">*</span>
        )}
        <Tooltip title={tooltipText} placement="top-start" arrow>
          <Icon className="iconTimeline" />
        </Tooltip>
        <span className="itemParagraph">{label}</span>
        <span className="itemParagraph">{`Encontro ${consultNumber}`}</span>
      </div>
    </div>
  );
};

const timeline = (events, client) => {
  const timelineEvents = events.map((event) =>
    converEventToBaseContult(event, client)
  );

  return timelineEvents.map((consult) => (
    <TimelineItem
      key={consult.id}
      date={consult.date}
      label={consult.label}
      status={consult.status}
      consultNumber={consult.consultNumber}
    />
  ));
};

const RightArrow = <AcChevronRightPurple className="arrowButton" />;
const LeftArrow = <AcChevronLeftPurple className="arrowButton" />;

const ScheduleTimeline = ({ events, client }) => {
  const items = timeline(events, client);

  return (
    <HorizontalScroll
      data={items}
      arrowLeft={LeftArrow}
      arrowRight={RightArrow}
      hideSingleArrow
      wheel={false}
      alignCenter={false}
      clickWhenDrag={false}
      useButtonRole={false}
      menuStyle={{
        width: "100%",
      }}
      innerWrapperStyle={{
        display: "flex",
        alignItems: "center",
      }}
      itemStyle={{
        display: "flex",
        outline: 0,
      }}
    />
  );
};

export default ScheduleTimeline;

ScheduleTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  client: PropTypes.string.isRequired,
};

TimelineItem.propTypes = {
  date: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  consultNumber: PropTypes.string.isRequired,
};
