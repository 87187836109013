export const initialState = {
  title: "",
  ingredients: "",
  dietType: "",
  mealType: "",
  restrictions: {},
  foodRestrictions: [],
  calorificValue: "",
  difficulty: "",
  preparationMinutes: "",
  additionalInformation: "",
  description: "",
  exitFormDialog: false,
  picturesUrls: [],
  openPictureSelectionDialog: false,
  photoUrl: "",
  titleError: {
    error: false,
    message: "",
  },
  calorificValueError: {
    error: false,
    message: "",
  },
  preparationMinutesError: {
    error: false,
    message: "",
  },
  healthyDish: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EDITABLE_DATA":
      return {
        ...state,
        title: action.payload.title,
        ingredients: action.payload.ingredients,
        dietType: action.payload.dietType,
        mealType: action.payload.mealType,
        restrictions: action.payload.restrictions,
        foodRestrictions: action.payload.foodRestrictions,
        calorificValue: action.payload.calorificValue,
        difficulty: action.payload.difficulty,
        preparationMinutes: action.payload.preparationMinutes,
        additionalInformation: action.payload.additionalInformation,
        description: action.payload.description,
        photoUrl: action.payload.photoUrl,
        healthyDish: action.payload.healthyDish,
      };
    case "RESET_STATE":
      return {
        ...state,
        title: "",
        ingredients: "",
        dietType: "",
        mealType: "",
        restrictions: {},
        foodRestrictions: [],
        calorificValue: "",
        difficulty: "",
        preparationMinutes: "",
        additionalInformation: "",
        description: "",
      };
    case "SET_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SET_INGREDIENTS":
      return {
        ...state,
        ingredients: action.payload,
      };
    case "SET_DIET_TYPE":
      return {
        ...state,
        dietType: action.payload,
      };
    case "SET_MEAL_TYPE":
      return {
        ...state,
        mealType: action.payload,
      };
    case "SET_RESTRICTIONS":
      return {
        ...state,
        restrictions: action.payload,
      };
    case "SET_FOOD_RESTRICTIONS":
      return {
        ...state,
        foodRestrictions: action.payload,
      };
    case "SET_CALORIFIC":
      return {
        ...state,
        calorificValue: action.payload,
      };
    case "SET_DIFFICULTY":
      return {
        ...state,
        difficulty: action.payload,
      };
    case "SET_PREPARATION_MINUTES":
      return {
        ...state,
        preparationMinutes: action.payload,
      };
    case "SET_ADDITIONAL_INFORMATION":
      return {
        ...state,
        additionalInformation: action.payload,
      };
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "UPDATE_MEAL":
      return {
        ...state,
        listOfMeals: action.payload.listOfMeals,
      };
    case "EXIT_FORM_DIALOG":
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case "MENAGE_PICTURES_DIALOG": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SELECT_PICTURE": {
      return {
        ...state,
        photoUrl: action.payload,
        openPictureSelectionDialog: false,
      };
    }
    case "SET_TITLE_ERROR": {
      return {
        ...state,
        titleError: action.payload,
      };
    }
    case "SET_CALORIFIC_VALUE_ERROR": {
      return {
        ...state,
        calorificValueError: action.payload,
      };
    }
    case "SET_PREPARATION_MINUTES_ERROR": {
      return {
        ...state,
        preparationMinutesError: action.payload,
      };
    }
    case "SET_HEALTHY_DISH": {
      return {
        ...state,
        healthyDish: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
