import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';
import HauxConfirmDialog from '../../../components/HauxConfirmDialog';
import HauxButton from '../../../components/HauxButton';
import { ReactComponent as IcContract } from '../../../assets/svgs/ic-contract.svg';
import { setPayload, getCurrentUser } from '../../../utils/Utils';
import ViewContext from '../../../ViewContext';
import PlanService from '../../../service/PlanService';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '48px 84px',
    marginTop: 24,
  },
  titleContainer: {
    marginBottom: 24,
  },
  subscriptionItem: {
    borderBottom: '1px solid #E0E0E0',
    padding: 16,
  },
  paymentOptions: {
    marginTop: 24,
    paddingLeft: 40,
    paddingBottom: 16,
  },
  paymentInfoText: {
    marginRight: 8,
  },
  paymentInfoItem: {
    marginBottom: 8,
  },
  printButtonContainer: {
    marginBottom: -10,
    marginRight: -10,
  },
  btnContact: {
    marginTop: 10,
  },
}));

export default function PlansInfo({ state, dispatch, openCurrentContractPDF }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { setLoading, setSnackbarOption } = useContext(ViewContext);
  const [deleteSusbscriptionId, setDeleteSubscriptionId] = useState();

  function getValue(options, parcel) {
    const val = options.find((x) => x.installmentNumber === parcel);
    return val ? val.value : '';
  }

  function renderPlanInfo(subscription) {
    const {
      items, paymentOptions, createdBy, createdAt,
    } = subscription;
    const totalPrice = items
      .reduce((acc, item) => acc + (getValue(item.plan.installmentOptions, paymentOptions.parcels) * item.quantity), 0);
    const discount = paymentOptions.discountType === 'R$'
      ? paymentOptions.discount
      : (totalPrice * (paymentOptions.discount / 100));

    return (
      <Grid container direction="column">
        <Grid item xs className="saveButton" />
        <Grid item container direction="column">
          {items.map((item, index) => (
            <Grid key={index.toString()} item className={classes.subscriptionItem} container>
              <Grid item xs={1} container justify="center">
                <span className="paragraph">
                  {item.quantity.toString().padStart(2, '0')}
                </span>
              </Grid>
              <Grid item xs={8} container direction="column">
                <Grid item>
                  <span className="paragraph">
                    {item.plan.name}
                  </span>
                </Grid>
                {item.plan.descriptions.map((desc) => (
                  <Grid item key={desc}>
                    <span className="description">
                      {desc}
                      ;
                    </span>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs container justify="center" className="paragraphSecondary">
                {Intl.NumberFormat(
                  'pt-BR', { style: 'currency', currency: 'BRL' },
                ).format(getValue(
                  item.plan.installmentOptions, paymentOptions.parcels,
                ) * item.quantity)}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item container className={classes.paymentOptions}>
          <Grid item xs={9} container direction="column">
            <Grid item className={classes.paymentInfoItem}>
              <span className={`paragraph ${classes.paymentInfoText}`}>
                <strong>
                  Total:
                </strong>
              </span>
              <span className="paragraph">
                {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalPrice)}
              </span>
            </Grid>
            <Grid item className={classes.paymentInfoItem}>
              <span className={`paragraph ${classes.paymentInfoText}`}>
                <strong>
                  Desconto:
                </strong>
              </span>
              <span className="paragraph">
                {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(discount)}
              </span>
            </Grid>
            <Grid item className={classes.paymentInfoItem}>
              <span className={`paragraph ${classes.paymentInfoText}`}>
                <strong>
                  Total pago:
                </strong>
              </span>
              <span className="paragraph">
                {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalPrice - discount)}
              </span>
            </Grid>
            <Grid item className={classes.paymentInfoItem}>
              <span className={`paragraph ${classes.paymentInfoText}`}>
                <strong>
                  Gerado por:
                </strong>
              </span>
              <span className="paragraph">
                {createdBy && createdBy.name
                  ? `${createdBy.name}`
                  : null}
              </span>
            </Grid>
            <Grid item>
              <span className={`paragraph ${classes.paymentInfoText}`}>
                <strong>
                  Data da contratação:
                </strong>
              </span>
              <span className="paragraph">
                {moment(createdAt).format('DD/MM/YYYY')}
              </span>
            </Grid>
          </Grid>

        </Grid>
        <Grid item container spacing={2} style={{ paddingLeft: 30 }}>
          <Grid item xs={3}>
            <HauxButton
              text="Imprimir"
              onClick={() => dispatch(setPayload(
                'OPEN_PLAN_DIALOG',
                {
                  open: true,
                  client: location.state.client,
                  user: getCurrentUser(),
                  totalPrice,
                  initialParcels: paymentOptions.parcels,
                  initialDiscount: paymentOptions.discount,
                  initialDiscountType: paymentOptions.discountType,
                  createdAt,
                  canConfirm: false,
                  cart: items,
                  onClose: () => dispatch(setPayload('OPEN_PLAN_DIALOG', { open: false })),
                },
              ))}
            />
          </Grid>
          <Grid item xs={3}>
            <HauxButton
              text="Excluir"
              variant="outlined"
              type="secondary"
              onClick={() => {
                setDeleteSubscriptionId(subscription._id);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const plans = state.subscriptions.map((sub) => ({
    name: `Contrato ${moment(sub.createdAt).format('DD/MM/YYYY')}`,
    renderDetails: renderPlanInfo(sub),
  }));

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={10} container className={classes.titleContainer}>
          <Grid item>
            <IcContract height={32} width={32} style={{ marginRight: 16 }} />
          </Grid>
          <Grid item>
            <span className="newClientText">Informações de contrato</span>
          </Grid>
          <Grid item xs={10} container className={classes.btnContact}>
            {plans && plans.length ? (
              <HauxButton
                text="Visualizar Contrato"
                type="secondary"
                onClick={() => openCurrentContractPDF()}
              />
            ) : <span>Assine um pacote</span>}
          </Grid>
        </Grid>
        <Grid item xs={10} container>
          <HauxExpansionPanel items={plans} />
        </Grid>
      </Grid>

      <HauxConfirmDialog
        title="Excluir pacote do cliente?"
        message="Tem certeza que deseja excluir os dados do pacote do cliente? Esta ação não poderá ser desfeita"
        open={deleteSusbscriptionId}
        handleClose={() => setDeleteSubscriptionId(undefined)}
        handleConfirm={async () => {
          setLoading(true);
          try {
            await PlanService.unsubscribe(deleteSusbscriptionId);
            setSnackbarOption({
              message: 'Pacote do cliente excluído com sucesso!',
              type: 'success',
              open: true,
              onClose: () => {
                setSnackbarOption({ open: false });
                history.goBack();
              },
            });
          } catch (e) {
            setSnackbarOption({
              message: 'Erro ao excluir pacote do cliente.',
              type: 'error',
              open: true,
            });
          }
          setDeleteSubscriptionId(undefined);
          setLoading(false);
        }}
      />
    </Paper>
  );
}

PlansInfo.propTypes = {
  state: PropTypes.shape({
    subscriptions: PropTypes.array,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  openCurrentContractPDF: PropTypes.func.isRequired,
};
