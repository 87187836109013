import React from 'react';

import Grid from '@material-ui/core/Grid';

function Aerobic() {
  return (
    <Grid justify="center" container>
      <span className="h3Style">Aeróbico</span>
    </Grid>
  );
}

export default Aerobic;
