import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AuthService from '../../service/AuthService';
import HAUXButton from '../../components/HauxButton';
import HAUXInput from '../../components/HauxInput';
import AnalyticsManager from '../../AnalyticsManager';
import strings from '../../assets/strings.json';
import ViewContext from '../../ViewContext';
import './login.scss';

const {
  FAIL_LOGIN, LOGIN, USER_NAME, PASSWORD, SIGN_IN, FIELD_REQUIRED,
} = strings;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    top: -40,
  },
  paperContainer: {
    padding: 150,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
  screenSize: {
    height: '100%',
  },
  loginText: {
    justifyContent: 'center',
  },
  maxWidthStyle: {
    maxWidth: 1156,
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setRouteProperties, setLoading, handleRequestsErrors } = useContext(ViewContext);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState({ error: false, message: '' });
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState({ error: false, message: '' });

  useEffect(() => {
    AnalyticsManager.track('View Login');
    setRouteProperties({ pageTitle: 'Login' });
  }, [setRouteProperties]);

  const loginUser = async () => {
    AnalyticsManager.track('Login button_sign_in clicked');
    setLoading(true);
    await AuthService.signIn(username, password)
      .then(() => history.push('/'))
      .catch((error) => handleRequestsErrors(error, { message: FAIL_LOGIN, action: undefined }));
    setLoading(false);
  };

  return (
    <Grid container direction="row" className={classes.root} spacing={2}>
      <Grid item xs={10} className={classes.maxWidthStyle}>
        <Paper elevation={3} className={classes.paperContainer}>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={10}>
              <div className="inputContainer">
                <span className="searchText">{LOGIN}</span>
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="inputContainer">
                <HAUXInput
                  label={USER_NAME}
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  onEnterPress={() => loginUser()}
                  fullWidth
                  error={usernameError.error}
                  errorMsg={usernameError.message}
                  onBlur={() => {
                    if (!username) {
                      setUsernameError({ error: true, message: FIELD_REQUIRED });
                    }
                  }}
                  onFocus={() => {
                    if (usernameError.error) {
                      setUsernameError({ error: true, message: '' });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="inputContainer">
                <HAUXInput
                  label={PASSWORD}
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  onEnterPress={() => loginUser()}
                  fullWidth
                  error={passwordError.error}
                  errorMsg={passwordError.message}
                  onBlur={() => {
                    if (!password) {
                      setPasswordError({ error: true, message: FIELD_REQUIRED });
                    }
                  }}
                  onFocus={() => {
                    if (usernameError.error) {
                      setPasswordError({ error: true, message: '' });
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={10} className={classes.button}>
              <HAUXButton
                text={SIGN_IN}
                onClick={() => loginUser()}
                disabled={!username || !password}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Login;
