import '../../App.scss';
import './hauxQuiz.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HauxRadio from '../HauxRadio';

const HauxQuiz = ({
  questions, handleChange, isRowItems,
}) => {
  const [hauxQuestions, setHauxQuestions] = useState(questions);
  useEffect(() => {
    setHauxQuestions(questions);
  }, [questions]);

  const renderQuestions = () => hauxQuestions
    .map((item) => (
      <div key={item.question} className="questionContainer">
        <span className="paragraph">{item.question}</span>
        <div className={isRowItems ? 'rowItems' : null}>
          <div className="rowSpace">
            <HauxRadio
              checked={!!item.value}
              handleChange={() => handleChange(item, true)}
              value={item.value}
              label="Sim"
            />
          </div>
          <div className="rowSpace">
            <HauxRadio
              checked={item.value === false}
              handleChange={() => handleChange(item, false)}
              value={item.value}
              label="Não"
            />
          </div>
        </div>
      </div>
    ));

  return (
    <div>
      {renderQuestions()}
    </div>
  );
};

export default HauxQuiz;


HauxQuiz.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  isRowItems: PropTypes.bool,
};

HauxQuiz.defaultProps = {
  questions: [],
  handleChange: () => {},
  isRowItems: false,
};
