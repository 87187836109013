const classes = {
  stretching: {
    fontSize: 16,
    color: '#737373',
    fontFamily: 'Graviola',
    padding: 16,
  },
  mobility: {
    fontSize: 16,
    color: '#737373',
    fontFamily: 'Graviola',
    padding: 16,
  },
  power: {
    fontSize: 16,
    color: '#737373',
    fontFamily: 'Graviola',
    padding: 16,
  },
  force: {
    fontSize: 16,
    color: '#737373',
    fontFamily: 'Graviola',
    padding: 16,
  },
  conditioning: {
    fontSize: 16,
    color: '#737373',
    fontFamily: 'Graviola',
    padding: 16,
  },
};

export default classes;
