import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as HauxColorLogo } from '../../assets/svgs/haux-logo-color.svg';
import { ReactComponent as IcLogo } from '../../assets/svgs/ic-logo.svg';
import { ReactComponent as IcReception } from '../../assets/svgs/ic-reception.svg';
import { ReactComponent as IcMeetings } from '../../assets/svgs/ic-meetings.svg';
import { ReactComponent as IcSchedule } from '../../assets/svgs/ic-schedule.svg';
import { ReactComponent as IcMoreLife } from '../../assets/svgs/ic-mais-vida-menu.svg';
import { ReactComponent as IcTraining } from '../../assets/svgs/ic-haux-training-menu.svg';
import ViewContext from '../../ViewContext';
import AnalyticsManager from '../../AnalyticsManager';
import './HauxSidebar.scss';

const useStyles = makeStyles(() => ({
  sidebarContainer: {
    height: '100%',
    scrollbarWidth: 'none',
    backgroundColor: '#ffffff',
    boxShadow: '4px 0px 20px rgba(0, 0, 0, 0.05)',

    '& .Mui-selected': {
      backgroundColor: '#fafafa',
    },
    position: 'fixed',
    maxWidth: 235,
    minWidth: 235,
  },
  sidebarOption: {
    marginTop: 24,
    marginBottom: 24,
    paddingBottom: 20,
    paddingLeft: 24,
  },
}));

function HauxSidebar() {
  const { selected, routes, setSelected } = useContext(ViewContext);
  const history = useHistory();
  const classes = useStyles();

  function selectLogo(routeName) {
    switch (routeName) {
      case 'Recepção':
        return <IcReception className="sidebarIcon" />;
      case 'Encontros':
        return <IcMeetings className="sidebarIcon" />;
      case 'Agendas':
        return <IcSchedule className="sidebarIcon" />;
      case 'Haux mais vida':
        return <IcMoreLife className="sidebarIcon" />;
      case 'Haux Training':
        return <IcTraining className="sidebarIcon" />;
      default:
        return <IcLogo width={26} height={26} className="sidebarLogoIcon" />;
    }
  }

  return (
    <Grid
      container
      className={classes.sidebarContainer}
    >
      <Grid item xs>
        <List
          disablePadding
        >
          <HauxColorLogo
            className="sidebarLogo"
            onClick={() => {
              AnalyticsManager.track('logo menu button clicked');
              setSelected(0);
              history.push('/');
            }}
            style={{ cursor: 'pointer' }}
          />
          {routes.map((route, index) => (
            <ListItem
              button
              key={route.path}
              className={classes.sidebarOption}
              onClick={() => {
                AnalyticsManager.track(`${route.name} menu button clicked`);
                setSelected(index);
                history.push(route.path);
              }}
              selected={selected === index}
            >
              {selectLogo(route.name)}
              <span className="sidebarLabel">
                {route.name}
              </span>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default React.memo(HauxSidebar);
