import React, {
  useState, useEffect, useReducer, useContext,
} from 'react';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import _ from 'lodash';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListItem from '@material-ui/core/GridListTile';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import { debounce } from 'debounce';
import PlanConfirmationDialog from '../../components/HauxPlanConfirmationDialog';
import HauxInputSearch from '../../components/HauxInputSearch';
import HauxButton from '../../components/HauxButton';
import HauxPlanItem, { HauxPlanItemCart } from '../../components/HauxPlanItem';
import PlanService from '../../service/PlanService';
import strings from '../../assets/strings.json';
import { reducer, initialState } from './reducers';
import { setPayload, getCurrentUser } from '../../utils/Utils';
import ClientService from '../../service/ClientService';
import ViewContext from '../../ViewContext';
import './plans.scss';

const { NO_CLIENTS_FOUND, MINIMUN_SEARCH_LENGTH } = strings;

const useStyles = makeStyles(() => ({
  rootContainer: {
    maxWidth: 1156,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -40,
    marginBottom: 20,
  },
  centered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '100vw',
    },
  },
  title: {
    fontFamily: 'Graviola',
    '& .MuiTypography-root': {
      fontFamily: 'Graviola',
    },
  },
}));

export default function Plans() {
  const classes = useStyles();
  const user = getCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const {
    handleRequestsErrors, setLoading, setRouteProperties, setSnackbarOption,
  } = useContext(ViewContext);
  const [searchClient, setSearchClient] = useState('');
  const [searched, setSearched] = useState(false);
  const [created, setCreated] = useState(false);
  const [client, setClient] = useState({});
  const [clients, setClients] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    plans, cart,
  } = state;
  const [clientDialogOpen, setClientDialogOpen] = useState({ visible: false });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Montar pacote',
      breadcrumbs: location.state && location.state.origin ? location.state.origin : 'Agendas',
    });
  }, [setRouteProperties, location]);

  useEffect(() => {
    if (location.state && location.state.client) {
      setClient(location.state.client);
      setSearchClient(location.state.client.name);
    }
  }, [location.state]);

  useEffect(() => {
    async function loadPlans() {
      try {
        setLoading(true);
        const loadedPlans = await PlanService.getPlans();
        dispatch(setPayload('PLANS', loadedPlans));
      } catch (error) {
        handleRequestsErrors(error);
      } finally {
        setLoading(false);
      }
    }
    loadPlans();
  }, [handleRequestsErrors, setLoading]);


  const confirmPlan = () => {
    if (_.isEmpty(client)) {
      setClientDialogOpen({
        visible: true,
        message: 'Você precisa selecionar um cliente antes de continuar.',
      });
    } else if (_.isEmpty(cart)) {
      setClientDialogOpen({
        visible: true,
        message: 'Você precisa selecionar um plano antes de continuar.',
      });
    } else {
      setModalOpen(true);
    }
  };

  const subscribe = async (paymentOptions) => {
    setLoading(true);
    try {
      await PlanService.subscribePlans(client.clientId, cart.map((item) => ({
        quantity: item.quantity,
        plan: item.plan.planId,
      })), paymentOptions);
      setModalOpen(false);
      setSnackbarOption({
        open: true,
        message: 'Planos assinados com sucesso!',
        type: 'success',
      });
      setCreated(true);
      setTimeout(() => {
        if (location.state && location.state.client) {
          history.push('/');
        } else {
          window.location.reload();
        }
      }, 3000);
    } catch (error) {
      handleRequestsErrors(error, { message: 'Erro ao realizar assinatura dos planos.' });
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce((name) => {
    ClientService.getClientsFilteredByName(name)
      .then((response) => {
        if (name.length >= 3) {
          setClients(response);
          setSearched(true);
        }
      })
      .catch(() => { });
  }, 750);

  const handleSearchInputChange = (event, selectedEvent) => {
    if (selectedEvent && selectedEvent.name) {
      setSearchClient(selectedEvent.name);
    } else {
      setSearchClient(event.target.value);
    }
    if (searchClient && event.target.value) {
      if (event.target.value.length >= 3) {
        debouncedSearch(event.target.value);
      } else if (searchClient && event.target.value.length < 3 && searched) {
        setClients([]);
        setSearched(false);
      }
    }
  };

  const getTotalPrice = () => cart.reduce(
    (sum, item) => (item.plan.value * item.quantity) + sum,
    0,
  );

  const addToCart = (plan) => {
    dispatch(setPayload('ADD_TO_CART', plan));
  };

  const subtractFromCart = (plan) => {
    dispatch(setPayload('SUBTRACT_FROM_CART', plan));
  };

  const removeFromCart = (plan) => {
    dispatch(setPayload('REMOVE_FROM_CART', plan));
  };


  return (
    <Grid container direction="column" justify="center" className={classes.rootContainer}>
      <Grid item className={classes.searchContainer} container>
        <Grid item xs={8}>
          <HauxInputSearch
            placeholder="Selecione um cliente para montar um pacote Haux"
            noOptionsText={searched ? NO_CLIENTS_FOUND : MINIMUN_SEARCH_LENGTH}
            rightIcon={() => <ArrowDropDownIcon />}
            value={searchClient}
            onChange={handleSearchInputChange}
            onSelect={(c) => setClient(c)}
            fullWidth
            autocomplete
            autocompleteProps={{
              options: clients,
              getOptionLabel: (option) => option.name || '',
            }}
            renderOption={(option) => (
              <Grid container>
                <span className="paragraph">{option.name}</span>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Paper elevation={1} className="cartContainer">
          <span className="h6NormalStyle">Plano Haux Gerado</span>
          <div className="cartInfoContainer">
            {cart.length === 0 && (
              <div className="emptyCart">
                <span className="h6NormalStyle">Escolha um item abaixo</span>
              </div>
            )}
            {cart.length > 0 && (
              <div className="cart">
                {cart.map((cartItem) => (
                  <HauxPlanItemCart
                    key={cartItem.plan.planId}
                    plan={cartItem.plan}
                    quantity={cartItem.quantity}
                    onAddClick={() => addToCart(cartItem.plan)}
                    onSubtractClick={() => subtractFromCart(cartItem.plan)}
                    onRemoveClick={() => removeFromCart(cartItem.plan)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="priceContainer">
            <div className="totalContainer">
              <span className="h6NormalStyle">Total</span>
            </div>
            <span className="h6StylePurple priceLabel">
              {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(getTotalPrice())}
            </span>
            <HauxButton text="Confirmar plano" onClick={confirmPlan} disabled={created} />
          </div>
        </Paper>
      </Grid>
      <Grid item>
        <GridList cellHeight={300} cols={2} spacing={8}>
          {plans.map((plan) => (
            <GridListItem key={plan.planId}>
              <HauxPlanItem
                key={plan.name}
                plan={plan}
                onAddClick={addToCart}
              />
            </GridListItem>
          ))}
        </GridList>
      </Grid>


      <Dialog
        open={clientDialogOpen.visible}
      >
        <DialogContent className={classes.centered}>
          <DialogContentText className={classes.title}>
            {clientDialogOpen.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.centered}>
          <HauxButton text="OK" onClick={() => setClientDialogOpen({ ...clientDialogOpen, visible: false })} />
        </DialogActions>
      </Dialog>

      <PlanConfirmationDialog
        open={!!(modalOpen && client)}
        client={client}
        user={user}
        cart={cart}
        totalPrice={getTotalPrice()}
        onClose={() => setModalOpen(false)}
        onConfirm={subscribe}
      />
    </Grid>
  );
}
