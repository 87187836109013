import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import HAUXInput from '../../components/HauxInput';
import HAUXButton from '../../components/HauxButton';
import icHeart from '../../assets/svgs/ic-heart.svg';
import HauxCheckbox from '../../components/HauxCheckbox';
import strings from '../../assets/strings.json';
import './psychologyForm.scss';

const {
  MANAGED_FOLLOW_DIET, MANAGED_FOLLOW_EXERCISE, LOST_WEIGHT, ABOUT_THE_PROGRAM,
  NEXT_STEPS, CLIENT_HEALTH, HIGHLIGHTS_OF_THE_WEEK, CLIENT_NAME, CONSULT_DATE,
} = strings;

const customerHealthArray = [
  {
    value: MANAGED_FOLLOW_DIET,
  },
  {
    value: MANAGED_FOLLOW_EXERCISE,
  },
  {
    value: LOST_WEIGHT,
  },
];

const ConsultationForm = () => {
  const history = useHistory();

  const renderHealthProblemsOption = () => customerHealthArray
    .map((health) => (
      <Grid key={health.value} container direction="row">
        <Grid item md={5} sm={8}>
          <HauxCheckbox
            checked={health.value}
            handleChange={health.value}
            value={health.value}
          />
          <span className="paragraph">{health.value}</span>
        </Grid>
      </Grid>
    ));

  return (
    <Grid container justify="center" className="paperContainer">
      <Grid item xs={5}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <span className="clientName">{CLIENT_NAME}</span>
          </Grid>
          <Grid item xs={12}>
            <span className="description">{CONSULT_DATE}</span>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" className="gridContainer">
              <span className="title">{ABOUT_THE_PROGRAM}</span>
              <Grid item xs={6}>
                <HAUXInput
                  label="Facilidades na última semana"
                  fullWidth
                />
                <HAUXInput
                  label="Dificuldades na última semana"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column">
              <span className="title marginTp">{NEXT_STEPS}</span>
              <Grid item xs={6}>
                <HAUXInput
                  label="Combinações para próxima semana"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container className="gridContainer">
          <Grid item xs={12}>
            <img src={icHeart} alt="" className="iconStyle" />
            <span className="title">{CLIENT_HEALTH}</span>
          </Grid>
          <Grid item xs={12}>
            <span className="description">{HIGHLIGHTS_OF_THE_WEEK}</span>
          </Grid>
          {renderHealthProblemsOption()}
        </Grid>
      </Grid>
      <Grid container spacing={2} className="buttonsContainer">
        <Grid item xs={6} className="buttonBack">
          <HAUXButton text="Voltar" type="secondary" onClick={() => history.goBack()} />
        </Grid>
        <Grid item xs={6} className="saveButton">
          <HAUXButton text="Salvar" type="primary" onClick={() => { }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultationForm;
