import axios from '../middlewares/axios';

class ContentService {
  static getPaginatedContent(page, filters) {
    const url = `/content/contents?page=${page}&filters=${filters}&sort=-1`;
    return axios.get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static postContent(contentData) {
    const url = '/content/contents';
    return axios.post(url, contentData)
      .catch((error) => {
        throw error;
      });
  }

  static updateContent(content) {
    const url = '/content/updateContent';
    return axios.post(url, content)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteContent(contentId) {
    const url = `/content/${contentId}`;
    return axios.delete(url)
      .catch((error) => {
        throw error;
      });
  }
}

export default ContentService;
