import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import HauxDropdown from '../HauxDropdown';
import HauxButton from '../HauxButton';
import HauxInput from '../HauxInput';
import strings from '../../assets/strings.json';
import './HauxPlanConfirmationDialog.scss';

const { DATE_FORMATS } = strings;
const DISCOUNT_OPTIONS = ['R$', '%'];

const useStyles = makeStyles(() => ({
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '100vw',
    },
  },
  title: {
    fontFamily: 'Graviola',
    '& .MuiTypography-root': {
      fontFamily: 'Graviola',
    },
  },
}));

function PlanConfirmationModal({
  open, client, user, cart, totalPrice,
  recalculate, onClose, onConfirm,
  createdAt, canConfirm, initialDiscountType,
  initialDiscount, initialParcels,
}) {
  const [discountType, setDiscountType] = useState(initialDiscountType);
  const [discount, setDiscount] = useState(initialDiscount);
  const [parcels, setParcels] = useState(initialParcels);
  const classes = useStyles();
  const componentRef = useRef();

  useEffect(() => {
    if (!discountType) {
      setDiscountType(initialDiscountType);
    }
  }, [initialDiscountType, discountType]);

  useEffect(() => {
    if (!discount) {
      setDiscount(initialDiscount);
    }
  }, [discount, initialDiscount]);

  useEffect(() => {
    if (cart.length) {
      if (cart.length > 1) {
        const plans = cart.filter(({ plan }) => plan.installment);

        if (plans.length > 1) {
          const { installmentSelected } = plans[0].plan;

          const sameInstallment = (installment) => installmentSelected === installment;
          const allSameInstallment = plans
            .every(({ plan }) => sameInstallment(plan.installmentSelected));

          if (allSameInstallment) setParcels(installmentSelected);
          else setParcels(6);
        } else {
          const { installmentSelected } = plans[0].plan;
          setParcels(installmentSelected);
        }
      } else {
        const { installmentSelected } = cart[0].plan;
        setParcels(installmentSelected);
      }
    }
  }, [cart]);

  const getTotalPrice = () => {
    let price;
    if (!recalculate) {
      price = totalPrice;
    } else {
      price = cart.reduce(
        (sum, item) => (item.plan.value * item.quantity) + sum,
        0,
      );
    }

    if (discount && discountType) {
      if (discountType === DISCOUNT_OPTIONS[0]) {
        price -= discount;
      } else {
        price *= 1 - (discount / 100);
      }
    }

    return price;
  };

  const getParcels = () => {
    const price = getTotalPrice();
    return price / (Number(parcels) || 1);
  };

  function getValue(options, parcel) {
    const val = options.find((x) => x.installmentNumber === parcel);
    return val ? val.value : '';
  }

  return (
    <Dialog
      open={open}
      className={classes.dialog}
    >
      <DialogTitle className={classes.title}>Confirmar contratação do pacote</DialogTitle>
      <DialogContent ref={componentRef}>
        <div className={classes.title}>
          <span className="h6NormalStyle">
            <strong>Cliente: </strong>
            {client.name}
          </span>
          <br />
          <span className="h6NormalStyle">
            <strong>CPF: </strong>
            {client.cpf}
          </span>
          <br />
          <span className="h6NormalStyle">
            <strong>Data de Contratação: </strong>
            {moment(createdAt).format(DATE_FORMATS.NORMAL)}
          </span>
          <br />
          <br />
          <br />
          <span className="h6NormalStyle">Resumo do pacote contratado:</span>
          <div className="plansResume">
            {cart.map((cartItem) => (
              <div key={cartItem.plan.planId || cartItem.plan._id} className="resumeCartItem">
                <div className="resumeCartIdentifier">
                  <span className="h6NormalStyle resumeQuantity">{cartItem.quantity}</span>
                  <div>
                    <span className="h6NormalStyle">{cartItem.plan.name}</span>
                    <br />
                    <div className="h6NormalStyle resumeDescription">
                      <small>
                        {cartItem.plan.descriptions.join('; ')}
                      </small>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="h6NormalStyle">
                    {!canConfirm
                      ? (
                        <>
                          {Intl.NumberFormat(
                            'pt-BR', { style: 'currency', currency: 'BRL' },
                          ).format(getValue(
                            cartItem.plan.installmentOptions, initialParcels,
                          ) * cartItem.quantity)}
                        </>
                      )
                      : (
                        <>
                          {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartItem.quantity * cartItem.plan.value)}
                        </>
                      )}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="paymentArea">
            <div className="labelsArea">
              <div className="resumeCartIdentifier">
                <span className="h6NormalStyle resumeQuantity discountLabel">
                  <strong>Desconto</strong>
                </span>
              </div>
              <div className="resumeCartIdentifier">
                <span className="h6NormalStyle resumeQuantity discountLabel">
                  <strong>Total</strong>
                </span>
              </div>
              <div className="resumeCartIdentifier">
                <span className="h6NormalStyle resumeQuantity discountLabel">
                  <strong>Parcelas</strong>
                </span>
              </div>
            </div>
            <div className="valuesArea">
              <div className="discount">
                <div className="inputContainer">
                  <HauxDropdown
                    options={DISCOUNT_OPTIONS}
                    value={discountType}
                    handleChange={(e) => setDiscountType(e.target.value)}
                    disabled={!canConfirm}
                  />
                </div>
                <div className="inputContainer">
                  <HauxInput
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (!Number.isNaN(value)) {
                        setDiscount(value);
                      }
                    }}
                    value={discount}
                    disabled={!canConfirm}
                  />
                </div>
              </div>
              <div className="discount">
                <span className="h6NormalStyle discountLabel">
                  <strong>
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(getTotalPrice())}
                  </strong>
                </span>
              </div>
              <div className="discount">
                <div className="inputContainer parcelInput">
                  <HauxInput
                    inputProps={{
                      maxLength: 2,
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (!Number.isNaN(value)) {
                        setParcels(value);
                      }
                    }}
                    value={parcels}
                    disabled={!canConfirm}
                  />
                </div>
                <span className="paragraph">x de</span>
                <div>
                  <span className="h6NormalStyle discountLabel parcelText">
                    <strong>
                      {Intl
                        .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                        .format(getParcels())}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span className="h6NormalStyle">Gerado por:</span>
          <br />
          <span className="h6NormalStyle"><strong>{user.name}</strong></span>
          <br />
          <span className="h6NormalStyle"><small>{user.profileType}</small></span>
        </div>
      </DialogContent>
      <DialogActions className={classes.spaceBetweenRow}>
        <HauxButton text={canConfirm ? 'Cancelar' : 'Fechar'} type="secondary" onClick={onClose} />
        {canConfirm && (
        <div className={classes.spaceBetweenRow}>
          <HauxButton
            text="Confirmar"
            type="secondary"
            onClick={() => onConfirm(
              {
                discountType: discountType || 'R$',
                discount: discount || 0,
                parcels: parcels || 1,
              },
            )}
          />
          <HauxButton
            text="Confirmar e imprimir"
            onClick={() => {
              document.getElementById('print').click();
              onConfirm(
                {
                  discountType: discountType || 'R$',
                  discount: discount || 0,
                  parcels: parcels || 1,
                },
              );
            }}
          />
        </div>
        )}
        {!canConfirm && (
        <HauxButton
          text="Imprimir"
          onClick={() => {
            document.getElementById('print').click();
          }}
        />
        )}
      </DialogActions>
      <ReactToPrint
        trigger={() => <button type="button" id="print" style={{ display: 'none' }}>Print this out!</button>}
        content={() => componentRef.current}
      />
    </Dialog>
  );
}

PlanConfirmationModal.propTypes = {
  open: PropTypes.bool,
  totalPrice: PropTypes.number,
  recalculate: PropTypes.bool,
  canConfirm: PropTypes.bool,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  client: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    profileType: PropTypes.string,
  }),
  cart: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    subtitle: PropTypes.string,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.number,
    installment: PropTypes.bool,
    maximumInstallment: PropTypes.number,
    meetings: PropTypes.shape({
      nursing: PropTypes.number,
      nutrition: PropTypes.number,
      physicalEducation: PropTypes.number,
      hauxMoreLife: PropTypes.number,
      training: PropTypes.number,
    }),
    plan: PropTypes.object,
  })),
  initialParcels: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialDiscountType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlanConfirmationModal.defaultProps = {
  open: false,
  totalPrice: 0,
  recalculate: false,
  onClose: () => {},
  onConfirm: () => {},
  client: {},
  user: {},
  cart: [],
  canConfirm: true,
  createdAt: undefined,
  initialParcels: '1',
  initialDiscount: '',
  initialDiscountType: '',
};

export default PlanConfirmationModal;
