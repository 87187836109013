import React from 'react';
import { shape, string } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import classes from './styles';

const Form = ({ training, setTraining }) => (
  <Grid>
    <span className="paragraph">Alongamento</span>
    <TextField
      variant="outlined"
      multiline
      rows={3}
      fullWidth
      value={training.stretching}
      onChange={(e) => setTraining({ ...training, stretching: e.target.value })}
      inputProps={{ style: classes.stretching }}
      style={{ marginTop: 8, marginBottom: 40 }}
    />
    <span className="paragraph">Mobilidade, estabilidade, ativação e aquecimento</span>
    <TextField
      variant="outlined"
      multiline
      rows={3}
      fullWidth
      value={training.mobility}
      onChange={(e) => setTraining({ ...training, mobility: e.target.value })}
      inputProps={{ style: classes.mobility }}
      style={{ marginTop: 8, marginBottom: 40 }}
    />
    <span className="paragraph">Potência</span>
    <TextField
      variant="outlined"
      multiline
      rows={3}
      fullWidth
      value={training.power}
      onChange={(e) => setTraining({ ...training, power: e.target.value })}
      inputProps={{ style: classes.power }}
      style={{ marginTop: 8, marginBottom: 40 }}
    />
    <span className="paragraph">Força</span>
    <TextField
      variant="outlined"
      multiline
      rows={3}
      fullWidth
      value={training.force}
      onChange={(e) => setTraining({ ...training, force: e.target.value })}
      inputProps={{ style: classes.force }}
      style={{ marginTop: 8, marginBottom: 40 }}
    />
    <span className="paragraph">Condicionamento</span>
    <TextField
      variant="outlined"
      multiline
      rows={3}
      fullWidth
      value={training.conditioning}
      onChange={(e) => setTraining({ ...training, conditioning: e.target.value })}
      inputProps={{ style: classes.conditioning }}
      style={{ marginTop: 8, marginBottom: 40 }}
    />
  </Grid>
);

Form.propTypes = {
  training: shape({
    client: string.isRequired,
    stretching: string,
    mobility: string,
    power: string,
    force: string,
    conditioning: string,
  }),
};

export default Form;