import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItems: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 20,
    paddingLeft: 20,
  },
  scroll: {
    overflow: 'scroll',
    position: 'relative',
  },
  tableText: {
    fontFamily: 'Graviola',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 400,
    color: '#737373',
  },
  tableTextRed: {
    color: 'red',
  },
  tableHeadItem: {
    border: 'none',
  },
  confirmDialog: {
    overflowX: 'hidden',
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 250,
  },
  flowContainer: {
    paddingTop: 60,
    paddingLeft: 20,
    paddingBottom: -20,
    minWidth: '100%',
  },
  dropdownContainer: {
    display: 'flex',
    marginRight: 15,
    marginTop: 24,
    marginBottom: 15,
  },
  totalContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'center',
  },
  totalContent: {
    margin: 15,
    marginLeft: 8,
    height: 80,
    width: 327,
    display: 'grid',
    justifyItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderRadius: 4,
  },
  valuesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyItems: 'center',
  },
  entryText: {
    color: '#4ED25D',
  },
  exitText: {
    color: '#D64E0F',
  },
}));
