export const daysArray = [
  { label: '15 dias', value: '15' },
  { label: '10 a 14 dias', value: '10 to 14' },
  { label: '7 a 9 dias', value: '7 to 9' },
  { label: '7 ou menos', value: '7 or less' },
  { label: 'não fez o desafio', value: '0' },
];

export const appMealsArray = [
  { label: 'Estou realizando todas as refeições conforme sugestões.', value: 'all' },
  { label: 'Sigo mais de 70% das sugestões de refeições.', value: '70% or more' },
  { label: 'Sigo menos de 70% das sugestões de refeições.', value: 'less than 70%' },
  { label: 'Não estou seguindo as sugestões.', value: 'not following' },
];

export const chewingOptionsArray = [
  { label: 'Rápida', value: 'fast' },
  { label: 'Normal', value: 'normal' },
  { label: 'Lenta', value: 'slow' },
];

export const yesOrNo = [
  { label: 'Não', value: false },
  { label: 'Sim', value: true },
];

export const intestinalFunctionOptionsArray = [
  { label: 'Constipado', value: 'constipated' },
  { label: 'Normal', value: 'normal' },
  { label: 'Diarréia', value: 'diarrhea' },
];
