import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ViewContext from '../../../../ViewContext';
import icCalendar from '../../../../assets/svgs/ic-calendar.svg';
import strings from '../../../../assets/strings.json';
import HauxButton from '../../../../components/HauxButton';
import '../sheduleCard.scss';

const {
  DATE_FORMATS, AREAS,
} = strings;

const selectSpecialityTextStyle = (speciality) => {
  switch (speciality) {
    case AREAS[1]:
      return 'nutrition';
    case AREAS[2]:
      return 'physicalEducation';
    default:
      return 'nursing';
  }
};
function HauxMeetingsCard({
  contentMeetings,
}) {
  const history = useHistory();
  const { setSelected } = useContext(ViewContext);

  return (
    <Paper>
      <Grid container direction="column" className="cardContainer">
        <div className="titleCardContainer">
          <img src={icCalendar} alt="" style={{ height: 18 }} />
          <span className="h6StylePurple spaceLeftTitle">Próximos encontros</span>
        </div>
        <Grid container direction="row">
          {contentMeetings.map((
            item, index,
          ) => (
            <React.Fragment key={index.toString()}>
              <span className="paragraph">{item.client.name}</span>
              <div className="spaceCardSubtitle">
                <span className={`description ${selectSpecialityTextStyle(item.speciality)}`}>
                  {`${item.speciality} - encontro ${item.consultNumber}`}
                </span>
                <span className="hourText">{moment(item.start).format(DATE_FORMATS.HOUR)}</span>
              </div>
              <div className="borderCard" />
            </React.Fragment>
          ))}
        </Grid>
        <div className="buttonCardContainer">
          <HauxButton
            type="secondary"
            text="Ver agenda"
            onClick={() => {
              history.push('/schedule/consults');
              setSelected(1);
            }}
          />
        </div>
      </Grid>
    </Paper>
  );
}

HauxMeetingsCard.propTypes = {
  contentMeetings: PropTypes.arrayOf(PropTypes.object),
};
HauxMeetingsCard.defaultProps = {
  contentMeetings: [],
};

export default HauxMeetingsCard;
