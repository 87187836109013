/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import _ from 'lodash';
import HauxInput from '../../../../components/HauxInput';
import FileService from '../../../../service/FileService';
import { setPayload } from '../../../../utils/Utils';
import AnalyticsManager from '../../../../AnalyticsManager';
import icContent from '../../../../assets/svgs/ic-content.svg';

import {
  ADD_IMAGE, FIELD_REQUIRED,
} from '../../../../assets/strings.json';

const useStyle = makeStyles(() => ({
  button: {
    width: '100%',
    height: 120,
    borderWidth: 2,
    borderColor: '#695DA8',
    borderStyle: 'dashed',
    padding: 0,
    overflow: 'hidden',
    marginBottom: 18,
  },
  image: {
    width: 250,
    height: 120,
    objectFit: 'cover',
  },
  input: {
    maxWidth: 500,
    display: 'none',
  },
  itemTextImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 50,
  },
  loading: {
    position: 'absolute',
    color: '#695DA8',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  articleContainer: {
    marginTop: 24,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  titleIcon: {
    marginRight: 8,
  },
}));

const Article = ({
  content,
  onChange,
}) => {
  const classes = useStyle();
  const [savingImage, setSavingImage] = useState(false);
  const [title, setTitle] = useState(content.title || '');
  const [imageLink, setImageLink] = useState(content.image || null);
  const [articleText, setArticleText] = useState(content.article || '');
  const [titleError, setTitleError] = useState({ error: false, message: '' });
  const [focused, setFocused] = useState('');

  const saveImage = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    setSavingImage(true);
    FileService.uploadImage(formData)
      .then((response) => {
        setSavingImage(false);
        setImageLink(response.data.file.url);
      })
      .catch(() => setSavingImage(false));
  };

  useEffect(() => {
    AnalyticsManager.track('View ArticleForm');
    setTitle(content.title || '');
    setImageLink(content.image || null);
    setArticleText(content.article || '');
  }, []);

  useEffect(() => {
    onChange(setPayload('CONTENT', {
      ...content,
      title,
      image: imageLink,
      article: articleText,
    }));
  }, [title, imageLink, articleText, onChange]);

  return (
    <Grid item xs={12} className={classes.container}>
      <div className={classes.titleContainer}>
        <img src={icContent} alt="" className={classes.titleIcon} />
        {content.createdAt
          ? <span className="h5Purple">Edição do conteúdo</span>
          : <span className="h5Purple">Criação do conteúdo</span>}
      </div>
      <Grid container className={classes.itemContainer}>
        <Grid item xs={12}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            name="image"
            type="file"
            onChange={saveImage}
          />
          <br />

          <label htmlFor="contained-button-file">
            <Button className={classes.button} component="span">
              {!savingImage && imageLink && <img src={imageLink} className={classes.image} alt="" />}
              {!savingImage && !imageLink && <Add color="action" style={{ fontSize: 40, color: '#695DA8' }} />}
              {savingImage && <CircularProgress className={classes.loading} />}
            </Button>
          </label>
          <Grid className={classes.itemTextImage}>
            <span className="descriptionPurple descriptionImage">{ADD_IMAGE}</span>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <HauxInput
            label={focused === 'title' || !_.isEmpty(content.title) ? 'Título' : 'Título do artigo'}
            placeholder="Título do artigo"
            value={title}
            onChange={(e) => { setTitle(e.target.value); }}
            inputProps={{ maxLength: 60 }}
            fullWidth
            error={titleError.error}
            errorMsg={titleError.message}
            onFocus={() => {
              setFocused('title');
              if (titleError.error) {
                setTitleError({ error: false, message: '' });
              }
            }}
            onBlur={() => {
              setFocused('');
              if (!title) {
                setTitleError({ error: true, message: FIELD_REQUIRED });
              }
            }}
          />
        </Grid>
        <span className="description charactersText">{`${title.length} / 60`}</span>

        <Grid item xs={12} className={classes.articleContainer}>
          <span className="h6NormalPurpleStyle">Artigo</span>
          <TextField
            variant="outlined"
            multiline
            rows={6}
            fullWidth
            contentText
            value={articleText}
            className={classes.contentText}
            inputProps={{
              style: {
                fontSize: 16,
                color: '#737373',
                fontFamily: 'Graviola',
                height: 399,
                padding: 32,
              },
            }}
            style={{ marginTop: 8 }}
            onChange={(e) => setArticleText(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

Article.defaultProps = {
  content: {
    title: '',
    image: '',
    text: '',
    article: '',
    published: false,
    createdAt: 0,
  },
  onChange: () => { },

};

Article.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string,
    article: PropTypes.string,
    published: PropTypes.bool,
    createdAt: PropTypes.number,
  }),
  onChange: PropTypes.func,
};


export default Article;
