/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const CustomRadio = withStyles({
  root: {
    color: '#737373',
    '&$checked': {
      color: '#F57A3D',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function FormikRadio({ option, value }) {
  return (
    <div>
      <FormControlLabel
        value={value}
        control={(
          <CustomRadio />
    )}
        label=""
      />
      <span className="paragraph">{option}</span>
    </div>
  );
}

export default FormikRadio;
