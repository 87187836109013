import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  rootContainer: {
    position: 'relative',
    top: -40,
    maxWidth: 1156,
  },
  topButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -20,
    marginBottom: 50,
  },
  bottomButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: -20,
    marginTop: 50,
  },
  fixMarginLeft: {
    marginLeft: -20,
  },
  weeklyContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,

    '& span': {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  contentText: {
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
    },
    '& > .MuiInput-root > textarea': {
      fontFamily: 'Graviola',
      color: '#737373',
      '&::placeholder': {
        fontFamily: 'Graviola',
      },
    },
  },
  progress: {
    marginTop: 10,
  },
}));

export default useStyle;
