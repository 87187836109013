import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import _ from 'lodash';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';

import '../training.scss';

const VIDEOS = [
  'https://www.youtube.com/embed/o0U_hvT2PD0',
  'https://www.youtube.com/embed/Dy0ncFAnlqY',
  'https://www.youtube.com/embed/voFMlZ70s0Q',
  'https://www.youtube.com/embed/e5tDN4iRT7s',
  'https://www.youtube.com/embed/e5tDN4iRT7s',
  'https://www.youtube.com/embed/voFMlZ70s0Q',
  'https://www.youtube.com/embed/o0U_hvT2PD0',
  'https://www.youtube.com/embed/Dy0ncFAnlqY',
];

const SECTIONS = [
  {
    name: 'Alongamento',
    videos: VIDEOS,
  },
  {
    name: 'Mobilidade',
    videos: VIDEOS,
  },
  {
    name: 'Potência',
    videos: VIDEOS,
  },
  {
    name: 'Força',
    videos: VIDEOS,
  },
  {
    name: 'Condicionamento',
    videos: VIDEOS,
  },
];

const Gallery = () => {
  const [openVideoId, setOpenVideoId] = useState();

  const renderGallery = (item) => (
    <div className="gallery">
      {item.videos.map((videoUrl, index) => (
        <button
          type="button"
          // eslint-disable-next-line react/no-array-index-key
          key={`${videoUrl}-${index}`}
          onClick={() => setOpenVideoId(videoUrl.replace('https://www.youtube.com/embed/', ''))}
        >
          <iframe
            width="220"
            height="145"
            src={videoUrl}
            title={videoUrl}
          />
        </button>
      ))}
    </div>
  );

  const renderCollapses = () => (
    <HauxExpansionPanel
      items={SECTIONS.map((item) => ({
        name: item.name,
        renderDetails: renderGallery(item),
      }))}
    />
  );

  return (
    <div className="trainingContainer">
      {renderCollapses()}
      <ModalVideo
        channel="youtube"
        isOpen={!_.isEmpty(openVideoId)}
        videoId={openVideoId}
        onClose={() => setOpenVideoId(null)}
      />
    </div>
  );
};

export default Gallery;
