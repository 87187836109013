export const setTab = (tab) => ({
  type: 'SET_TAB',
  payload: tab,
});

export const setData = (data) => ({
  type: 'SET_DATA',
  payload: data,
});

export const setErrors = (errors) => ({
  type: 'SET_ERRORS',
  payload: errors,
});
