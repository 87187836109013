/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import HAUXInput from '../../../../components/HauxInput';
import icContent from '../../../../assets/svgs/ic-content.svg';
import { setPayload } from '../../../../utils/Utils';
import AnalyticsManager from '../../../../AnalyticsManager';

const useStyles = makeStyles(() => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    marginBottom: 16,
  },
  tipTextContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  titleIcon: {
    marginRight: 8,
  },
}));

function Tip({
  content,
  onChange,
}) {
  const classes = useStyles();
  const [title, setTitle] = useState(content.title || '');
  const [text, setText] = useState(content.tipText || '');
  const [titleError, setTitleError] = useState({ error: false, message: '' });
  const [textError, setTextError] = useState({ error: false, message: '' });
  const [focused, setFocused] = useState('');

  useEffect(() => {
    AnalyticsManager.track('View TipForm');
  }, []);

  useEffect(() => {
    onChange(setPayload('CONTENT', {
      ...content,
      title,
      tipText: text,
    }));
  }, [title, text, onChange]);

  return (
    <div className={classes.contentContainer}>
      <div className={classes.titleContainer}>
        <img src={icContent} alt="editar" className={classes.titleIcon} />
        {content.createdAt
          ? <span className="h5Purple">Edição do conteúdo</span>
          : <span className="h5Purple">Criação do conteúdo</span>}
      </div>
      <Grid container className={classes.itemContainer}>
        <Grid item xs={12} className={classes.tipTextContainer}>
          <HAUXInput
            label={focused === 'title' || !_.isEmpty(content.title) ? 'Título' : 'Dica do dia na timeline do app'}
            placeholder="Dica do dia na timeline do app"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            inputProps={{ maxLength: 60 }}
            fullWidth
            error={titleError.error}
            errorMsg={titleError.message}
            onFocus={() => {
              setFocused('title');
              if (titleError.error) {
                setTitleError({ error: false, message: '' });
              }
            }}
            onBlur={() => {
              setFocused('');
              if (!content.title) {
                setTitleError({ error: true, message: 'Campo obrigatório' });
              }
            }}
          />
          <span className="description charactersText">
            {content.title ? content.title.length : 0}
            {' '}
            / 60
          </span>
        </Grid>
      </Grid>
      <Grid container className={classes.itemContainer}>
        <Grid item xs={12} className={classes.tipTextContainer}>
          <HAUXInput
            label={focused === 'text' || !_.isEmpty(content.tipText) ? 'Descrição breve' : 'Descrição da dica'}
            placeholder="Descrição da dica"
            value={text}
            onChange={(e) => setText(e.target.value)}
            inputProps={{ maxLength: 200 }}
            fullWidth
            error={textError.error}
            errorMsg={textError.message}
            onFocus={() => {
              setFocused('text');
              if (!content.tipText) {
                setTextError({ error: false, message: '' });
              }
            }}
            onBlur={() => {
              setFocused('');
              if (!content.tipText) {
                setTextError({ error: true, message: 'Campo obrigatório' });
              }
            }}
          />
          <span className="description charactersText">
            {content.tipText ? content.tipText.length : 0}
            {' '}
            / 200
          </span>
        </Grid>
      </Grid>
    </div>
  );
}


Tip.defaultProps = {
  onChange: () => { },
  content: {
    title: '',
    image: '',
    tipText: '',
    article: '',
  },
};

Tip.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    tipText: PropTypes.string,
    article: PropTypes.string,
    createdAt: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

export default Tip;
