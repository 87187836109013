import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import icEdit from '../../../../assets/svgs/ic-edit.svg';
import icDelete from '../../../../assets/svgs/ic-delete.svg';
import icSave from '../../../../assets/svgs/ic-save.svg';
import icClose from '../../../../assets/svgs/ic-cancel.svg';
import Strings from '../../../../assets/strings.json';
import ClientService from '../../../../service/ClientService';
import { setPayload } from '../../../../utils/Utils';
import ViewContext from '../../../../ViewContext';
import './additionalInfoCard.scss';

const useStyles = makeStyles(() => ({
  infoContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 24,
  },
  textArea: {
    fontFamily: 'Graviola',
  },
}));

const { DATE_FORMATS } = Strings;

const AdditionalInfoCard = ({
  state, dispatch, info, userId, userName,
}) => {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(info.text);
  const { handleRequestsErrors, setLoading } = useContext(ViewContext);
  const { additionalInfo } = state;
  const classes = useStyles();

  const formatDates = (time) => moment(time).format(DATE_FORMATS.COMPLETE);

  const deleteInfo = () => {
    setLoading(true);
    ClientService.deleteClientAdditionalInfo(info.infoId)
      .then(() => {
        const newInfos = additionalInfo.filter((information) => information.infoId !== info.infoId);
        dispatch(setPayload('UPDATE_ADDITIONAL_INFO', newInfos));
      })
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  };

  const updateInfo = () => {
    setLoading(true);
    ClientService
      .putClientAdditionalInfo(info, inputValue)
      .then((res) => {
        dispatch(setPayload('UPDATE_ADDITIONAL_INFO', res));
        setEditable(false);
      })
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  };
  const renderButtons = () => {
    if (info.professionalId !== userId) {
      return null;
    }

    if (editable) {
      return (
        <>
          <Grid item xs container direction="column" justify="flex-end" spacing={1}>
            <Grid item container justify="flex-end">
              <button
                type="button"
                className="svgButton"
                onClick={updateInfo}
              >
                <img src={icSave} alt="salvar" />
              </button>
            </Grid>
            <Grid item container justify="flex-end">
              <button
                type="button"
                className="svgButton"
                onClick={() => {
                  setInputValue(info.text);
                  setEditable(false);
                }}
              >
                <img src={icClose} alt="cancelar" />
              </button>
            </Grid>
          </Grid>
          <Grid item xs container justify="flex-end">
            <button
              type="button"
              className="svgButton"
              onClick={deleteInfo}
            >
              <img src={icDelete} alt="deletar" />
            </button>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs container justify="flex-end">
          <button
            type="button"
            className="svgButton"
            onClick={() => setEditable(true)}
          >
            <img src={icEdit} alt="editar" />
          </button>
        </Grid>
        <Grid item xs container justify="flex-end">
          <button
            type="button"
            className="svgButton"
            onClick={deleteInfo}
          >
            <img src={icDelete} alt="deletar" />
          </button>
        </Grid>
      </>
    );
  };

  const renderText = () => {
    if (editable) {
      return (
        <TextField
          className={classes.textArea}
          variant="outlined"
          multiline
          rows={3}
          fullWidth
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
      );
    }

    return <span className="paragraph">{inputValue}</span>;
  };

  return (
    <Grid item container className={classes.infoContainer}>
      <Grid item xs container direction="column">
        <Grid item>
          {renderText()}
        </Grid>
        <Grid item>
          <span className="description">
            {
            `Publicado em: ${formatDates(info.createdAt)}h - ${info.professionalName || userName} ${info.updatedAt ? `- Editado em: ${formatDates(info.updatedAt)}h` : ''}`
            }
          </span>
        </Grid>
      </Grid>
      <Grid item xs={2} alignItems="center" container justify="center">
        {renderButtons()}
      </Grid>
    </Grid>
  );
};

export default AdditionalInfoCard;

AdditionalInfoCard.propTypes = {
  info: PropTypes.shape({
    infoId: PropTypes.string,
    text: PropTypes.string,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    professionalId: PropTypes.string,
    professionalName: PropTypes.string,
  }).isRequired,
  state: PropTypes.shape({
    additionalInfo: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};
