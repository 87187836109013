import React from 'react';
import {
  shape, number, func,
} from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const FORMAT = 'DD/MM/YYYY';
const INVALID_DATE_MESSAGE = "Data inválida";

const DateRangeFilter = ({ filter, setFilter }) => {
  const handleState = (prop, date) => setFilter(
    (ps) => ({ ...ps, [prop]: moment(date).valueOf() }));

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
      >
        <Grid item xs={6}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Data inicial"
            format={FORMAT}
            InputAdornmentProps={{ position: 'end' }}
            value={filter.startDate}
            onChange={(date) => handleState('startDate', date)}
            invalidDateMessage={INVALID_DATE_MESSAGE}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Data final"
            format={FORMAT}
            InputAdornmentProps={{ position: 'end' }}
            value={filter.endDate}
            onChange={(date) => handleState('endDate', date)}
            invalidDateMessage={INVALID_DATE_MESSAGE}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

DateRangeFilter.propTypes = {
  filter: shape({
    startDate: number,
    endDate: number,
  }).isRequired,
  setFilter: func.isRequired,
};

export default DateRangeFilter;
