import axios from 'axios';
import { isAuthenticated } from '../utils/Utils';

const getAuthHeader = () => (isAuthenticated()
  ? `Bearer ${localStorage.getItem('TOKEN_KEY')}`
  : null);

axios.defaults.baseURL = process.env.REACT_APP_HAUX_API_URL;
axios.defaults.headers.common.Authorization = getAuthHeader();

const reloadAxiosInstance = () => {
  axios.defaults.headers.common.Authorization = getAuthHeader();
};


export { reloadAxiosInstance };
export default axios;
