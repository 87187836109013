import React, { useEffect, useState, useContext } from 'react';
import './hauxMoreLife.scss';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import AnalyticsManager from '../../AnalyticsManager';
import HauxMeetingCollapsible from '../../components/HauxMeetingCollapsible';
import HauxPdfModal from '../../components/HauxPdfModal';
import HauxPresentationModal from '../../components/HauxPresentationModal';
import { moreLifeMeetings } from '../../utils/Utils';
import HAUXButton from '../../components/HauxButton';
import pdfs from '../../assets/pdfs';
import MeetingService from '../../service/MeetingService';
import MeetingModal from './meetingModal';
import ViewContext from '../../ViewContext';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    maxWidth: 1156,
    zIndex: 2,
    top: -32,
  },
  buttons: {
    marginLeft: 64,
  },
  historyContainer: {
    marginTop: 16,
  },
}));

function HauxMoreLife() {
  const classes = useStyles();
  const history = useHistory();
  const { setRouteProperties } = useContext(ViewContext);
  const [expanded, setExpanded] = useState(-1);
  const [meetings, setMeetings] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPresentation, setOpenPresentation] = useState(false);
  const [link, setLink] = useState('');
  const [modalInfo, setModalInfo] = useState({ visible: false, data: {} });
  const {
    pdf1, pdf2, pdf3, pdf4, pdf5, pdf6, pdf7, pdf8, pdf9, moreLifeClient1,
    moreLifeClient2, moreLifeClient3, moreLifeClient4, moreLifeClient5,
    moreLifeClient6, moreLifeClient7, moreLifeClient8, moreLifeClient9,
  } = pdfs;

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Haux mais vida',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    AnalyticsManager.track('View HauxMoreLife');
    MeetingService.getMeetings()
      .then((res) => setMeetings(res));
  }, []);

  const openMeetingPDF = async (pdfURL) => {
    AnalyticsManager.track('Dashboard button_meeting_pdf clicked');
    setOpen(true);
    setLink(`${window.location.origin.toString()}${pdfURL}`);
  };

  const openPresentationPDF = async (pdfURL) => {
    AnalyticsManager.track('Dashboard button_presentation_pdf clicked');
    setOpenPresentation(true);
    setLink(`${window.location.origin.toString()}${pdfURL}`);
  };

  const renderContent = (item) => {
    let pdfURL = '';
    let pdfClientURL = '';
    switch (item.number) {
      case '2':
        pdfURL = pdf2;
        pdfClientURL = moreLifeClient2;
        break;
      case '3':
        pdfURL = pdf3;
        pdfClientURL = moreLifeClient3;
        break;
      case '4':
        pdfURL = pdf4;
        pdfClientURL = moreLifeClient4;
        break;
      case '5':
        pdfURL = pdf5;
        pdfClientURL = moreLifeClient5;
        break;
      case '6':
        pdfURL = pdf6;
        pdfClientURL = moreLifeClient6;
        break;
      case '7':
        pdfURL = pdf7;
        pdfClientURL = moreLifeClient7;
        break;
      case '8':
        pdfURL = pdf8;
        pdfClientURL = moreLifeClient8;
        break;
      case '9':
        pdfURL = pdf9;
        pdfClientURL = moreLifeClient9;
        break;
      default:
        pdfURL = pdf1;
        pdfClientURL = moreLifeClient1;
        break;
    }

    return (
      <Grid item xs={12}>
        <span className="paragraph">Histórico</span>
        <Grid container className={classes.historyContainer}>
          <Grid item xs={7}>
            <Grid container>
              {meetings.filter((meet) => meet.number.toString() === item.number)
                .map((meetItem) => (
                  <HAUXButton text={moment(meetItem.date).format('DD/MM/YYYY')} type="tertiary" onClick={() => setModalInfo({ visible: true, data: meetItem })} />
                ))}
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.buttons}>
            <HAUXButton text="Apresentação encontro" type="primary" onClick={() => openPresentationPDF(pdfClientURL)} />
            <HAUXButton text="Guia do encontro" type="primary" onClick={() => openMeetingPDF(pdfURL)} />
            <HAUXButton text="Ficha de registro" type="secondary" onClick={() => history.push('/therapyMeeting', { meeting: item })} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" justify="center" className={classes.root}>
      <Grid item xs>
        {moreLifeMeetings.map((item, index) => (
          <HauxMeetingCollapsible
            key={item.theme}
            meeting={item.number}
            description={item.theme}
            renderContent={() => renderContent(item)}
            onChange={() => {
              if (expanded !== index) {
                setExpanded(index);
              } else {
                setExpanded(-1);
              }
            }}
            expanded={expanded === index}
          />
        ))}
        <MeetingModal
          open={modalInfo.visible}
          handleClose={() => setModalInfo({ ...modalInfo, visible: false })}
          data={modalInfo.data}
        />
      </Grid>
      <HauxPdfModal open={open} link={link} handleClose={() => setOpen(false)} />
      <HauxPresentationModal
        open={openPresentation}
        link={link}
        handleClose={() => setOpenPresentation(false)}
      />
    </Grid>
  );
}

export default HauxMoreLife;
