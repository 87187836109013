/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './hauxInput.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  option: {
    fontFamily: 'Graviola',
    fontSize: 14,
    color: '#737373',
    mixBlendMode: 'normal',
  },
  smallSize: {
    width: 60,
  },
});

const autocompleteFilter = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  stringify: (option) => option.name || option.value || option,
});

const HAUXTextField = withStyles({
  root: {
    '& input': {
      fontFamily: 'Graviola',
      color: '#737373',
      '&::placeholder': {
        fontFamily: 'Graviola',
      },
    },
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
      color: (props) => (props.value ? '#685DA8' : '#737373'),
    },
    '& label.Mui-focused': {
      color: '#685DA8',
    },
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: '#D64E0F',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important',
    },
  },
})(TextField);

export { HAUXTextField };

const HAUXInput = ({
  label, placeholder, noOptionsText, rightIcon, error, errorMsg, fullWidth,
  autocomplete, autocompleteProps, onChange, onEnterPress, onSelect, onBlur, mask,
  onFocus, disabled, value, maskChar, size, type, inputProps,
}) => {
  const classes = useStyles();
  let fieldSize = null;
  if (size === 'small') {
    fieldSize = classes.smallSize;
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onEnterPress();
    }
  };

  const renderInput = (params) => {
    if (mask) {
      return (
        <InputMask
          mask={mask}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value}
          maskChar={maskChar}
        >
          {() => (
            <HAUXTextField
              {...params}
              label={label}
              placeholder={placeholder}
              helperText={errorMsg}
              error={error}
              fullWidth={fullWidth}
              color="primary"
              className={fieldSize}
              type={type}
              inputProps={inputProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {rightIcon()}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      );
    }
    return (
      <HAUXTextField
        {...params}
        label={label}
        placeholder={placeholder}
        helperText={errorMsg}
        error={error}
        fullWidth={fullWidth}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        color="primary"
        disabled={disabled}
        className={fieldSize}
        value={value}
        type={type}
        inputProps={inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {rightIcon()}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    autocomplete
      ? (
        <Autocomplete
          options={autocompleteProps.options.sort((a, b) => {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name);
            }

            return 0;
          })}
          getOptionLabel={autocompleteProps.getOptionLabel
            ? autocompleteProps.getOptionLabel
            : (option) => option.value || ''}
          noOptionsText={noOptionsText}
          id={label}
          classes={{
            option: classes.option,
          }}
          onChange={(event, onChangeValue, reason) => {
            onChange(event, onChangeValue, reason);
            if (onChangeValue) {
              onSelect(onChangeValue);
            }
          }}
          value={value}
          inputValue={value}
          filterOptions={autocompleteFilter}
          renderInput={(params) => (
            <HAUXTextField
              {...params}
              placeholder={placeholder}
              label={label}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              onKeyPress={onKeyPress}
              error={error}
              helperText={errorMsg}
              fullWidth
              disabled={disabled}
              value={value}
            />
          )}
        />
      ) : (
        renderInput()
      ));
};

export default HAUXInput;

HAUXInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  rightIcon: PropTypes.func,
  autocomplete: PropTypes.bool,
  autocompleteProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
  maskChar: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  inputProps: PropTypes.object,
};

HAUXInput.defaultProps = {
  label: '',
  placeholder: '',
  noOptionsText: 'Não encontrado',
  error: false,
  errorMsg: '',
  fullWidth: false,
  rightIcon: () => <></>,
  autocomplete: false,
  autocompleteProps: {},
  value: '',
  inputValue: null,
  onChange: () => {},
  onEnterPress: () => {},
  onSelect: () => {},
  onBlur: () => {},
  onFocus: () => {},
  mask: '',
  disabled: false,
  maskChar: null,
  size: '',
  type: '',
  inputProps: {},
};
