import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import HauxRadio from '../../../../components/HauxRadio';
import { daysArray } from './constants';

function ChallengeItem({
  index, challenge, editable, clientMeetings,
}) {
  return (
    <>
      <Grid item>
        <span className="paragraph">{challenge.challengeLabel}</span>
      </Grid>
      <Grid item container justify="space-between">
        {daysArray.map((option) => (
          <HauxRadio
            key={option.value}
            label={option.label}
            value={option.value}
            checked={
                editable !== index && clientMeetings[index] && clientMeetings[index][`${challenge.challengeKey}`]
                  ? clientMeetings[index][`${challenge.challengeKey}`] === option.value
                  : challenge.checkedState === option.value
              }
            handleChange={() => challenge.handleChange(option.value)}
            disabled={clientMeetings.length > index && editable !== index}
          />
        ))}
      </Grid>
    </>
  );
}

export default React.memo(ChallengeItem);

ChallengeItem.propTypes = {
  index: PropTypes.number.isRequired,
  editable: PropTypes.number.isRequired,
  challenge: PropTypes.shape({
    challengeLabel: PropTypes.string,
    challengeKey: PropTypes.string,
    checkedState: PropTypes.string,
    handleChange: PropTypes.func,
  }).isRequired,
  clientMeetings: PropTypes.arrayOf(PropTypes.object).isRequired,
};
