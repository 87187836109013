import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Grid, Paper, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  Input, MaskInput, Select,
} from '../../../../../components/Form';
import ViewContext from '../../../../../ViewContext';
import PartnerService from '../../../../../service/PartnerService';
import { statesArray } from '../../../../../utils/Utils';

import './partnersCreate.scss';

const SPECIALTIES = ['Anestesiologia', 'Cancerologia', 'Cardiologia', 'Cirurgia Geral', 'Cirurgia Plástica', 'Cirurgia Vascular', 'Clínica Médica', 'Dermatologia', 'Endocrinologia', 'Gastroenterologia', 'Geriatria', 'Ginecologia', 'Hematologia', 'Infectologia', 'Mastologia', 'Medicina de Família e Comunidade', 'Medicina do Trabalho', 'Medicina preventiva', 'Nefrologia', 'Oftalmologia', 'Ortopedia', 'Otorrinolaringologia', 'Pediatria', 'Psiquiatria', 'Radiologia', 'Reumatologia', 'Pneumologia Pediátrica', 'Urologia'];
const REQUIRED_MSG = 'Campo obrigatório';

const PartnerFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Muito curto')
    .required(REQUIRED_MSG),
  specialty: Yup.string()
    .required(REQUIRED_MSG),
  phone: Yup.string()
    .required(REQUIRED_MSG),
  address: Yup.object().shape({
    cep: Yup.string()
      .required(REQUIRED_MSG),
    complement: Yup.string(),
    state: Yup.string()
      .required(REQUIRED_MSG),
    city: Yup.string()
      .required(REQUIRED_MSG),
    street: Yup.string()
      .required(REQUIRED_MSG),
    number: Yup.string()
      .required(REQUIRED_MSG),
    district: Yup.string()
      .required(REQUIRED_MSG),
  }),
  location: Yup.object().shape({
    type: Yup.string(),
    latitude: Yup.string()
      .required(REQUIRED_MSG)
      .matches(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/, 'Latitude inválida'),
    longitude: Yup.string()
      .required(REQUIRED_MSG)
      .matches(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/, 'Longitude inválida'),
  }),
});

const CreatePartner = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    setRouteProperties, setLoading, loading, setSnackbarOption,
  } = useContext(ViewContext);
  const [initialValues, setInitialValues] = useState({
    location: { type: 'Point' },
  });

  const findPartner = async (partnerId) => {
    setLoading(true);
    const partner = await PartnerService.getParter(partnerId);
    const { location: partnerLocation } = partner;
    const [latitude, longitude] = partnerLocation.coordinates;
    setInitialValues({ ...partner, location: { ...partnerLocation, latitude, longitude } });
    setLoading(false);
  };

  const save = async (values) => {
    setLoading(true);
    setInitialValues(values);
    const { location: formLocation } = values;
    const { type, latitude, longitude } = formLocation;
    const saveValues = {
      ...values,
      location: {
        type,
        coordinates: [
          latitude, longitude,
        ],
      },
    };
    try {
      const SaveAction = values._id ? PartnerService.putPartner : PartnerService.postPartner;
      await SaveAction(saveValues);
      setSnackbarOption({
        message: `Parceiro ${values._id ? 'alterado' : 'salvo'} com sucesso!`,
        type: 'success',
        open: true,
        onClose: () => {
          history.push('/company/partners');
          setSnackbarOption((s) => ({
            ...s,
            open: false,
          }));
        },
      });
    } catch (error) {
      setSnackbarOption({
        message: `Erro ao ${values._id ? 'aleterar' : 'salvar'} parceiro.`,
        type: 'error',
        open: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Novo parceiro',
      breadcrumbs: 'Parceiros',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (location.state) {
      const { partnerId } = location.state;
      findPartner(partnerId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Formik
      key={loading}
      initialValues={initialValues}
      validationSchema={PartnerFormSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          save(values);
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      {() => (
        <Form style={{ width: '100%' }}>
          <Grid className="partnerNewContainer">
            <Paper className="paperContainer" elevation={2}>
              <Grid className="partnerForm" container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={8}>
                    <Input label="Nome" name="name" fullWidth />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      label="Especialidade"
                      fullWidth
                      name="specialty"
                      options={SPECIALTIES.map((specialty) => ({
                        label: specialty, value: specialty,
                      }))}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Input label="Registro" fullWidth name="register" />
                  </Grid>
                  <Grid item xs={6}>
                    <MaskInput
                      label="Telefone"
                      fullWidth
                      name="phone"
                      format="(##) ####-#####"
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: 30 }}>
                    <span className="h5Purple">ENDEREÇO</span>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={8}>
                    <Input label="Rua" fullWidth name="address.street" />
                  </Grid>
                  <Grid item xs={4}>
                    <Input label="Número" fullWidth name="address.number" />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={8}>
                    <Input label="Complemento" fullWidth name="address.complement" />
                  </Grid>
                  <Grid item xs={4}>
                    <MaskInput label="CEP" fullWidth name="address.cep" format="#####-###" />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={4}>
                    <Input label="Bairro" fullWidth name="address.district" />
                  </Grid>
                  <Grid item xs={4}>
                    <Input label="Cidade" fullWidth name="address.city" />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      label="Estado"
                      options={statesArray.map((state) => ({ ...state, label: state.value }))}
                      fullWidth
                      name="address.state"
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: 30 }}>
                    <span className="h5Purple">Localização</span>
                  </Grid>
                  <Grid item container spacing={2}>
                    <input type="hidden" value="Point" name="location.type" />
                    <Grid item xs={6}>
                      <Input label="Latitude" fullWidth name="location.latitude" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input label="Longitude" fullWidth name="location.longitude" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container direction="row" justify="space-between" spacing={2} style={{ padding: 5, paddingTop: 20, paddingBottom: 40 }}>
              <Button variant="outlined" color="primary" onClick={() => history.push('/company/partners')}>Cancelar</Button>
              <Button variant="contained" color="primary" type="submit">Salvar</Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePartner;
