import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import EventPropTypes from './eventPropsTypes';
import CheckSVG from '../../assets/svgs/ic-check.svg';
import UnCheckSVG from '../../assets/svgs/ic-uncheck.svg';
import AtHomeSVG from '../../assets/svgs/ic-event-at-home.svg';
import strings from '../../assets/strings.json';

const {
  HAUX_TOUR, HAUX_MULTISPACE, HAUX_TRAINING,
} = strings;

const useStyles = makeStyles(() => ({
  dayContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    margin: 0,
    fontFamily: 'Graviola',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 2,
    color: 'white',
  },
  checkedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    marginRight: 7,
  },
  none: {
    display: 'none',
  },
}));

const getName = (event) => {
  try {
    return event.room === HAUX_MULTISPACE && !event.isTour
      ? event.consultNumber : event.client.name;
  } catch (e) {
    return '';
  }
};

const DayEventCell = ({ event }) => {
  const classes = useStyles();
  return (
    <div className={classes.dayContainer}>
      <p className={classes.none}>{`HOUR: ${moment(event.start).format('HH:mm')}`}</p>
      {event.franchise && event.franchise.slug === 'at_home' && (
      <img
        src={AtHomeSVG}
        alt={event.franchise.name}
        className={classes.checkIcon}
      />
      )}
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{getName(event)}</h1>
        <h2 className={classes.title}>
          {event.isTour ? HAUX_TOUR : event.professional.type}
          {` ${event.isTour ? '' : event.professional.name}`}
        </h2>
      </div>
      {(((event.room === HAUX_MULTISPACE || event.room === HAUX_TRAINING) && !event.isTour) || event.confirmationStatus !== 'Não confirmado') && (
        <div className={classes.checkedContainer}>
          {((event.room === HAUX_MULTISPACE || event.room === HAUX_TRAINING) && !event.isTour)
            ? (
              <>
                <div className={classes.titleContainer}>
                  <p className={classes.title}>{`${Math.max(0, (event.room === HAUX_TRAINING ? 10 : 15) - event.participants.length)} vagas`}</p>
                  <p className={classes.title}>restantes</p>
                </div>
              </>
            )
            : (
              <>
                <img
                  src={event.confirmationStatus === 'Presente' ? CheckSVG : UnCheckSVG}
                  alt={`${event.confirmationStatus === 'Presente' ? 'Presença' : 'Ausência'} confirmada`}
                  className={classes.checkIcon}
                />
                <div className={classes.titleContainer}>
                  <p className={classes.title}>{event.confirmationStatus === 'Presente' ? 'presença' : 'ausência'}</p>
                  <p className={classes.title}>confirmada</p>
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};

DayEventCell.propTypes = {
  event: PropTypes.shape(EventPropTypes),
};

DayEventCell.defaultProps = {
  event: {},
};

export default DayEventCell;
