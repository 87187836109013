import React from 'react';
import PropTypes from 'prop-types';
import './hauxSwitch.scss';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const HauxSwitch = ({
  checkColor, checked, onChange, name, value,
  disabled,
}) => {
  const CustomSwitch = withStyles({
    switchBase: {
      color: '#FFFFFF',
      '&$checked': {
        color: checkColor,
        '& + $track': {
          backgroundColor: checkColor,
        },
      },
    },
    checked: {},
    track: {
      backgroundColor: '#B2B2B2',
    },
  })(Switch);

  return (
    <CustomSwitch
      className="colorSwitch"
      checked={checked}
      onChange={onChange}
      value={value}
      name={name}
      inputProps={{ 'aria-label': { value } }}
      disabled={disabled}
    />
  );
};

export default React.memo(HauxSwitch);

HauxSwitch.propTypes = {
  checkColor: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

HauxSwitch.defaultProps = {
  name: '',
  value: '',
  onChange: () => <></>,
  checked: false,
  checkColor: '#F57A3D',
  disabled: false,
};
