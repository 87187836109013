/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import HauxInput from "../../../../components/HauxInput";
import HauxSelect from "../../../../components/HauxSelect";
import HauxCheckbox from "../../../../components/HauxCheckbox";
import HauxSwitch from "../../../../components/HauxSwitch";
import icTimePurple from "../../../../assets/svgs/ic-time-purple.svg";
import {
  AREAS,
  DAY_SHIFTS,
  MEETING_OPTIONS,
  NURSE_OPTIONS,
  MORE_LIFE_OPTIONS,
} from "../../../../assets/strings.json";
import { setPayload } from "../../../../utils/Utils";
import AnalyticsManager from "../../../../AnalyticsManager";

const useStyles = makeStyles(() => ({
  sectionTitle: {
    marginTop: 48,
    marginBottom: 10,
  },
  checkBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: -19,
  },
  shiftContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: -16,
  },
  publicContainer: {
    marginLeft: 0,
  },
  linkedToMeet: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: -8,
  },
  icon: {
    display: "flex",
  },
  checkContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tipTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  tipTextSpace: {
    marginTop: 34,
  },
  message: {
    marginTop: 8,
  },
}));

const challengeMsg =
  'Este desafio será mostrado na seção "Desafios" para o público selecionado no dia subsequente à sua publicação.';

const SendSettingsForm = ({
  onChange,
  sendSettings,
  published,
  showTipForm,
  content,
  challange,
}) => {
  const classes = useStyles();
  const [currentContentId, setCurrentContentId] = useState("");
  const [linkedToMeeting, setLinkedToMeeting] = useState(false);
  const [sendTo, setSendTo] = useState("Clientes");
  const [checkedPublic, setCheckedPublic] = useState({
    all: false,
    diabetes: false,
    cardio: false,
    vegetarian: false,
    vegan: false,
    omnivorous: false,
    lactoseFree: false,
    glutenFree: false,
  });
  const [days, setDays] = useState(1);
  const [before, setBefore] = useState(true);
  const [specialty, setSpecialty] = useState();
  const [meetingNumber, setMeetingNumber] = useState(1);
  const [dayShift, setDayShift] = useState(DAY_SHIFTS.MORNING);
  const [tipTitle, setTipTitle] = useState(content.tipTitle || "");
  const [tipText, setTipText] = useState(content.tipText || "");
  const [focused, setFocused] = useState("");

  useEffect(() => {
    AnalyticsManager.track("View SendSettingsForm");
  }, []);

  useEffect(() => {
    if (sendSettings.contentId && sendSettings.contentId !== currentContentId) {
      setCurrentContentId(sendSettings.contentId);
      setLinkedToMeeting(sendSettings.linkedToMeeting);
      setDays(sendSettings.days);
      setBefore(sendSettings.before);
      setSpecialty(sendSettings.specialty);
      setMeetingNumber(sendSettings.meetingNumber);
      setDayShift(sendSettings.dayShift);
      setSendTo(sendSettings.sendTo);
      setCheckedPublic(sendSettings.public ? sendSettings.public : {});
    }
  }, [sendSettings]);

  useEffect(() => {
    const settings = {
      ...sendSettings,
      linkedToMeeting,
      sendTo,
      days,
      before,
      specialty,
      meetingNumber,
      dayShift,
      public: checkedPublic,
    };
    onChange(setPayload("SEND_SETTINGS", settings));
    if (linkedToMeeting) setSendTo("Clientes");
  }, [
    linkedToMeeting,
    sendTo,
    checkedPublic,
    days,
    before,
    specialty,
    meetingNumber,
    dayShift,
    onChange,
  ]);

  useEffect(() => {
    if (!challange) {
      onChange(
        setPayload("CONTENT", {
          ...content,
          tipTitle,
          tipText,
        })
      );
    }
  }, [tipTitle, tipText, onChange]);

  const renderCheck = (column, label) => (
    <Grid item xs={4} className={classes.checkBox}>
      <HauxCheckbox
        checked={checkedPublic[column]}
        handleChange={() => {
          if (column === "all") {
            const newCheckedPublic = { ...checkedPublic };
            if (!checkedPublic[column]) {
              Object.keys(newCheckedPublic).forEach((key) => {
                newCheckedPublic[key] = true;
              });
            } else {
              Object.keys(newCheckedPublic).forEach((key) => {
                newCheckedPublic[key] = false;
              });
            }
            setCheckedPublic(newCheckedPublic);
          } else {
            let allChecked = !checkedPublic[column] ? checkedPublic.all : false;
            let setAllTrue = true;
            Object.keys(checkedPublic).forEach((key) => {
              if (key !== "all") {
                if (key === column) {
                  setAllTrue = setAllTrue && !checkedPublic[key];
                } else {
                  setAllTrue = setAllTrue && checkedPublic[key];
                }
              }
            });
            if (setAllTrue) allChecked = true;
            setCheckedPublic({
              ...checkedPublic,
              [column]: !checkedPublic[column],
              all: allChecked,
            });
          }
        }}
        value={label}
        disabled={published}
      />
    </Grid>
  );

  const getOptions = (value) => {
    switch (value) {
      case "Enfermagem":
        return NURSE_OPTIONS;
      case "Psicologia":
        return MORE_LIFE_OPTIONS;
      default:
        return MEETING_OPTIONS;
    }
  };

  const renderTipForm = () => (
    <>
      <Grid container className={classes.itemContainer}>
        <Grid
          item
          xs={12}
          className={`${classes.tipTextContainer} ${classes.tipTextSpace}`}
        >
          <HauxInput
            label={
              focused === "title" || !_.isEmpty(content.tipTitle)
                ? "Título"
                : "Dica do dia na timeline do app"
            }
            placeholder="Dica do dia na timeline do app"
            value={content.tipTitle}
            onChange={(e) => setTipTitle(e.target.value)}
            inputProps={{ maxLength: 60 }}
            fullWidth
            onFocus={() => setFocused("title")}
            onBlur={() => setFocused("")}
          />
          <span className="description charactersText">
            {content.tipTitle ? content.tipTitle.length : 0} / 60
          </span>
        </Grid>
      </Grid>
      <Grid container className={classes.itemContainer}>
        <Grid item xs={12} className={classes.tipTextContainer}>
          <HauxInput
            label={
              focused === "text" || !_.isEmpty(content.tipText)
                ? "Descrição breve"
                : "Descrição da dica"
            }
            placeholder="Descrição da dica"
            value={content.tipText}
            onChange={(e) => setTipText(e.target.value)}
            inputProps={{ maxLength: 200 }}
            fullWidth
            onFocus={() => setFocused("text")}
            onBlur={() => setFocused("")}
          />
          <span className="description charactersText">
            {content.tipText ? content.tipText.length : 0} / 200
          </span>
        </Grid>
      </Grid>
    </>
  );

  if (!challange) {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item className={classes.icon}>
            <img src={icTimePurple} alt="editar" />
          </Grid>
          <span className="h5Purple">Envio e Publicação</span>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={`h6StylePurple ${classes.sectionTitle}`}>
              Público
            </div>
            <span className="description">Selecione pelo menos uma opção</span>
          </Grid>
          <Grid item xs={12} className={classes.checkContainer}>
            {renderCheck("all", "Todos os clientes")}
            {renderCheck("diabetes", "Haux Diabetes")}
            {renderCheck("cardio", "Haux Cardio")}
            {renderCheck("vegetarian", "Vegetariano")}
            {renderCheck("vegan", "Vegano")}
            {renderCheck("omnivorous", "Onívoro")}
            {renderCheck("lactoseFree", "Sem lactose")}
            {renderCheck("glutenFree", "Sem glúten")}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className={`h6StylePurple ${classes.sectionTitle}`}>
            Configuração do envio
          </div>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.linkedToMeet}>
            <HauxSwitch
              checked={linkedToMeeting}
              onChange={() => setLinkedToMeeting(!linkedToMeeting)}
              disabled={published}
            />
            <span className="h6NormalStyle">Vinculado a um encontro</span>
            <br />
            <span className="hintText" style={{ marginLeft: 10 }}>
              Se você não vincular a um encontro específico do Programa Haux, a
              dica do dia será mostrada para o público selecionado
            </span>
            <br />
            <span className="hintText" style={{ marginLeft: 10 }}>
              no dia subsequente à publicação.
            </span>
          </Grid>
          {linkedToMeeting && (
            <>
              <Grid container spacing={2} className={classes.publicContainer}>
                <Grid item xs={2}>
                  <HauxSelect
                    label="Enviar"
                    value={days}
                    onChange={setDays}
                    options={[
                      { label: "Um dia", value: 1 },
                      { label: "Dois dias", value: 2 },
                      { label: "Três dias", value: 3 },
                      { label: "Uma semana", value: 7 },
                    ]}
                    disabled={published}
                  />
                </Grid>
                <Grid item xs={3}>
                  <HauxSelect
                    value={before}
                    onChange={setBefore}
                    options={[
                      { label: "Antes do encontro", value: true },
                      { label: "Depois do encontro", value: false },
                    ]}
                    disabled={published}
                  />
                </Grid>
                <Grid item xs={3}>
                  <HauxSelect
                    label="Escolha a especialidade"
                    value={specialty}
                    onChange={setSpecialty}
                    options={AREAS.map((key) => ({
                      label: key,
                      value: key,
                    }))}
                    disabled={published}
                  />
                </Grid>
                <Grid item xs={2}>
                  <HauxSelect
                    label="Encontro"
                    value={meetingNumber}
                    onChange={(value) => setMeetingNumber(value)}
                    options={getOptions(specialty).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    disabled={published || _.isEmpty(specialty)}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {showTipForm && linkedToMeeting && renderTipForm()}
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item className={classes.icon}>
          <img src={icTimePurple} alt="editar" />
        </Grid>
        <span className="h5Purple">Envio e Publicação</span>
      </Grid>
      <Grid container className={classes.message}>
        <span className="paragraph">{challengeMsg}</span>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={`h6StylePurple ${classes.sectionTitle}`}>Público</div>
          <span className="description">Selecione pelo menos uma opção</span>
        </Grid>
        <Grid item xs={12} className={classes.checkContainer}>
          {renderCheck("all", "Todos os clientes")}
          {renderCheck("diabetes", "Haux Diabetes")}
          {renderCheck("cardio", "Haux Cardio")}
          {renderCheck("vegetarian", "Vegetariano")}
          {renderCheck("vegan", "Vegano")}
          {renderCheck("omnivorous", "Onívoro")}
          {renderCheck("lactoseFree", "Sem lactose")}
          {renderCheck("glutenFree", "Sem glúten")}
        </Grid>
      </Grid>
    </Grid>
  );
};

SendSettingsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sendSettings: PropTypes.object,
  onChange: PropTypes.func,
  published: PropTypes.bool,
  showTipForm: PropTypes.bool,
  content: PropTypes.shape({
    tipTitle: PropTypes.string,
    tipText: PropTypes.string,
    published: PropTypes.bool,
  }),
  challange: PropTypes.bool,
};

SendSettingsForm.defaultProps = {
  sendSettings: {},
  onChange: () => {},
  published: false,
  showTipForm: false,
  content: {
    tipTitle: "",
    tipText: "",
  },
  challange: false,
};

export default SendSettingsForm;
