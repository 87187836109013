import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import HauxHeader from '../HauxHeader';
import HauxLoading from '../HauxLoading';
import HauxSnackbar from '../HauxSnackbar';
import HauxSidebar from '../HauxSidebar';
import ViewContext from '../../ViewContext';
import './hauxView.scss';

const useStyles = makeStyles(() => ({
  viewContainer: {
    height: '100%',
  },
  sidebarContainer: {
    position: 'relative',
    left: 0,
    top: 0,
    bottom: 0,
    minWidth: 235,
    maxWidth: 235,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const HauxView = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { loading, snackbarOption } = useContext(ViewContext);

  const renderSnackbar = () => (
    <HauxSnackbar
      type={snackbarOption.type}
      message={snackbarOption.message}
      openSnackbar={snackbarOption.open}
      action={snackbarOption.action}
      onClose={snackbarOption.onClose}
    />
  );

  const renderSidebar = () => (
    <Grid item xs={1} className={classes.sidebarContainer}>
      <HauxSidebar />
    </Grid>
  );

  return (
    <Grid
      container
      className={classes.viewContainer}
    >
      { loading && <HauxLoading />}
      { location && location.pathname !== '/login' && renderSidebar()}
      <Grid
        container
        direction="column"
        item
        xs
      >
        <Grid item>
          <HauxHeader />
        </Grid>
        <Grid
          item
          className={classes.contentContainer}
        >
          {children}
        </Grid>
        {renderSnackbar()}
      </Grid>
    </Grid>
  );
};

export default HauxView;

HauxView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
