import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HAUXButton from "../../../components/HauxButton";
import icHauxTimeline from "../../../assets/svgs/ic-haux-timeline.svg";
import ScheduleTimeline from "../components/scheduleTimeline";
import strings from "../../../assets/strings.json";

const { CLIENT_SCHEDULE, ROOMS } = strings;

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  timelineContainer: {
    marginTop: 8,
  },
  timelinePaper: {
    padding: "16px 16px 32px 16px",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: 8,
  },
  scheduleBtn: {
    display: "flex",
    justifyContent: "flex-end",
    margin: -10,
    marginTop: 30,
  },
}));

const ProgramTimeline = ({ state, onPrintClick, client }) => {
  const { events } = state;

  const classes = useStyles();

  if (!events.length) {
    return <></>;
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item container spacing={2}>
        <Grid item>
          <img src={icHauxTimeline} alt="" />
        </Grid>
        <Grid item>
          <span className="h5Purple">Programa</span>
        </Grid>
      </Grid>
      <Grid item container className={classes.timelineContainer}>
        <Paper className={classes.timelinePaper} elevation={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <span className="h6NormalStyle">Encontros</span>
            </Grid>
            <Grid item container>
              <ScheduleTimeline
                events={events.filter((event) => ROOMS.includes(event.room))}
                client={client}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.scheduleBtn}>
            <HAUXButton
              type="primary"
              text={CLIENT_SCHEDULE}
              onClick={onPrintClick}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProgramTimeline;

ProgramTimeline.propTypes = {
  state: PropTypes.shape({
    events: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onPrintClick: PropTypes.func,
  client: PropTypes.string.isRequired,
};

ProgramTimeline.defaultProps = {
  onPrintClick: () => {},
};
