import React, { useEffect, useState, useContext } from 'react';
import './therapyMeeting.scss';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Paper, Grid, makeStyles, TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import AnalyticsManager from '../../AnalyticsManager';
import HAUXInput from '../../components/HauxInput';
import HAUXButton from '../../components/HauxButton';
import MeetingService from '../../service/MeetingService';
import HauxConfirmDialog from '../../components/HauxConfirmDialog';
import strings from '../../assets/strings.json';
import ViewContext from '../../ViewContext';

const { REGISTER_FORM_SUCCESS, REGISTER_FORM_FAIL } = strings;

const useStyles = makeStyles(() => ({
  root: {
    marginTop: -32,
  },
  paperContainer: {
    padding: 32,
    position: 'relative',
  },
  picker: {
    '& input': {
      fontFamily: 'Graviola',
      color: '#737373',
      '&::placeholder': {
        fontFamily: 'Graviola',
      },
    },
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
      color: '#737373',
    },
    '& label.Mui-focused': {
      color: '#685DA8',
    },
  },
  pickerColor: {
    '& label': {
      fontFamily: 'Graviola',
      fontSize: 16,
      color: '#685DA8',
    },
  },
}));

const initialForm = {
  theme: '',
  professional: '',
  date: null,
  hour: null,
  numberOfParticipants: '',
  positivePoints: '',
  negativePoints: '',
  difficulties: '',
  reviews: '',
  suggestions: '',
};

function TherapyMeeting() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [isSaved, setIsSaved] = useState(false);
  const [exitFormDialog, setExitFormDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const {
    setRouteProperties, setLoading, setSnackbarOption, handleRequestsErrors,
  } = useContext(ViewContext);

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Haux mais vida',
      pageSubtitle: 'Ficha de registro e avaliação do psicólogo - Haux Mais Vida',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    AnalyticsManager.track('View HauxMoreLife');
  }, []);

  useEffect(() => {
    if (location.state && location.state.meeting) {
      setForm((s) => ({ ...s, theme: location.state.meeting.theme }));
    }
  }, [location]);

  const submitForm = async () => {
    try {
      setLoading(true);
      await MeetingService.createNewMeeting(location.state.meeting.number, form);
      AnalyticsManager.track('CreateTherapyMeeting success');
      setSnackbarOption({
        open: true,
        message: REGISTER_FORM_SUCCESS,
        type: 'success',
      });
      setIsSaved(true);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error) {
      AnalyticsManager.track('CreateTherapyMeeting fail');
      handleRequestsErrors(error, { message: REGISTER_FORM_FAIL });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={10} className={classes.root}>
      <Paper elevation={2} className={classes.paperContainer}>
        <Grid container spacing={5} direction="column">
          <Grid item>
            <HAUXInput
              label="Tema do encontro"
              value={form.theme}
              onChange={(event) => setForm({ ...form, theme: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item>
            <HAUXInput
              label="Profissional"
              value={form.professional}
              onChange={(event) => setForm({ ...form, professional: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid container justify="space-around" spacing={2}>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        label="Data"
                        format="DD/MM/YYYY"
                        InputAdornmentProps={{ position: 'end' }}
                        fullWidth
                        value={form.date}
                        onChange={(d) => setForm({ ...form, date: moment(d) })}
                        className={`${classes.picker} ${form.date ? classes.pickerColor : null}`}
                        error={errors.date}
                        helperText={errors.date}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardTimePicker
                        label="Hora"
                        mask="__:__"
                        ampm={false}
                        value={form.hour}
                        onChange={(d) => setForm({ ...form, hour: moment(d) })}
                        fullWidth
                        className={`${classes.picker} ${form.hour ? classes.pickerColor : null}`}
                        error={errors.time}
                        helperText={errors.time}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <HAUXInput
                  label="Nº de participantes"
                  value={form.numberOfParticipants}
                  onChange={(event) => setForm(
                    { ...form, numberOfParticipants: event.target.value },
                  )}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <span className="h6NormalStyle">Pontos positivos</span>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              contentText
              value={form.positivePoints}
              className={classes.contentText}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  height: 100,
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(event) => setForm({ ...form, positivePoints: event.target.value })}
            />
          </Grid>
          <Grid item>
            <span className="h6NormalStyle">Pontos negativos</span>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              contentText
              value={form.negativePoints}
              className={classes.contentText}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  height: 100,
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(event) => setForm({ ...form, negativePoints: event.target.value })}
            />
          </Grid>
          <Grid item>
            <span className="h6NormalStyle">Dificuldades</span>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              contentText
              value={form.difficulties}
              className={classes.contentText}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  height: 100,
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(event) => setForm({ ...form, difficulties: event.target.value })}
            />
          </Grid>
          <Grid item>
            <span className="h6NormalStyle">Críticas</span>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              contentText
              value={form.reviews}
              className={classes.contentText}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  height: 100,
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(event) => setForm({ ...form, reviews: event.target.value })}
            />
          </Grid>
          <Grid item>
            <span className="h6NormalStyle">Sugestões</span>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              contentText
              value={form.suggestions}
              className={classes.contentText}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  height: 100,
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(event) => setForm({ ...form, suggestions: event.target.value })}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container className="buttonsContainer">
        <Grid item xs={6} className="saveButton">
          <HAUXButton
            text="Salvar"
            type="primary"
            disabled={isSaved || (!form.theme
              || !form.professional || !form.date || !form.hour || !form.numberOfParticipants)}
            onClick={submitForm}
          />
        </Grid>
        <Grid item xs={6}>
          <HAUXButton
            text="Voltar"
            type="secondary"
            disabled={isSaved}
            onClick={() => {
              if (form !== initialForm) {
                setExitFormDialog(true);
              } else {
                history.push('/hauxMoreLife');
              }
            }}
          />
        </Grid>
      </Grid>
      <HauxConfirmDialog
        title="Sair do formulário"
        message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
        open={exitFormDialog}
        handleClose={() => setExitFormDialog(false)}
        handleConfirm={() => {
          setExitFormDialog(false);
          history.push('/hauxMoreLife');
        }}
      />
      )
    </Grid>
  );
}

export default TherapyMeeting;
