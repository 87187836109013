import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import strings from '../../../../assets/strings.json';
import HauxFormModal from '../../../../components/HauxFormModal';
import HauxButton from '../../../../components/HauxButton';
import ClientContex from '../../../../ClientContex';
import AnalyticsManager from '../../../../AnalyticsManager';
import { getNurseForm, getCurrentUser } from '../../../../utils/Utils';
import './meetingsHeader.scss';


const { PROFESSIONALS, FOLLOWUP_BUTTONS_TEXTS, DATE_FORMATS } = strings;

const useStyles = makeStyles(() => ({
  paper: {
    padding: 40,
  },
  nameContainer: {
    paddingBottom: 24,
  },
  buttonContainer: {
    marginTop: 16,
  },
}));

export default function MeetingsHeader({ consultType }) {
  const { client } = useContext(ClientContex);
  const history = useHistory();
  const classes = useStyles();
  const user = getCurrentUser();
  const [modalConfig, setModalConfig] = useState({
    open: false,
    data: {},
  });

  function selectConsultTypeText() {
    if (consultType === PROFESSIONALS.NUTRITIONIST) {
      return FOLLOWUP_BUTTONS_TEXTS.NEW_NUTRITION_EVALUATION;
    }
    if (consultType === PROFESSIONALS.PHYSICAL_EDUCATOR) {
      return FOLLOWUP_BUTTONS_TEXTS.NEW_PHYSICAL_EVALUATION;
    }

    return FOLLOWUP_BUTTONS_TEXTS.FILL_CLINICAL_ANAMNESIS;
  }

  function handlePrimaryButtonAction() {
    if (consultType === PROFESSIONALS.NURSE) {
      AnalyticsManager.track('Nursing followup button_new_nurse_form clicked');
      history.push('/client/nursingConsult', { professional: user.name });
    } else if (consultType === PROFESSIONALS.NUTRITIONIST) {
      AnalyticsManager.track('Nutrition followup button_new_nutritionist_form clicked');
      history.push('/client/nutritionConsult', { professional: user.name });
    } else {
      AnalyticsManager.track('Physical Education followup button_new_physical_educator_form clicked');
      history.push('/client/physicalEducationConsult', { professional: user.name });
    }
  }

  function formatLastAnamnesisDate() {
    return moment(client.nursingAnamnesis[client.nursingAnamnesis.length - 1].date)
      .format(DATE_FORMATS.NORMAL);
  }

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={9} container direction="column">
          <Grid item className={classes.nameContainer}>
            <span className="clientName">{client.name}</span>
          </Grid>
          <Grid item>
            <span className="description">
              {
                client.nursingAnamnesis && client.nursingAnamnesis.length
                  ? `Peso inicial: ${client.nursingAnamnesis[0].weight}kg`
                  : 'Peso inicial: Não registrado'
              }
            </span>
          </Grid>
          <Grid item>
            <span className="description">
              {
                client.nursingAnamnesis && client.nursingAnamnesis.length
                  ? `Ultima pesagem: ${client.nursingAnamnesis[client.nursingAnamnesis.length - 1].weight}kg em ${formatLastAnamnesisDate()}`
                  : 'Ultima pesagem: Não registrado'
              }
            </span>
          </Grid>
        </Grid>
        <Grid item xs={3} container direction="column">
          <Grid item className="primaryButtonHeaderContainer">
            <HauxButton
              fullWidth
              text={selectConsultTypeText()}
              onClick={handlePrimaryButtonAction}
            />
          </Grid>
          { consultType !== PROFESSIONALS.NURSE ? (
            <Grid item className={classes.buttonContainer}>
              <HauxButton
                fullWidth
                type="secondary"
                text={FOLLOWUP_BUTTONS_TEXTS.CLINICAL_ANAMNESIS}
                disabled={!(client.nursingAnamnesis && client.nursingAnamnesis.length)}
                onClick={() => {
                  setModalConfig({
                    open: true,
                    data: getNurseForm(
                      client.nursingAnamnesis[client.nursingAnamnesis.length - 1],
                      client,
                    ),
                  });
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <HauxFormModal
        open={modalConfig.open}
        handleClose={() => setModalConfig({ open: false, data: {} })}
        data={modalConfig.data}
      />
    </Paper>
  );
}

MeetingsHeader.propTypes = {
  consultType: PropTypes.oneOf([
    PROFESSIONALS.NURSE,
    PROFESSIONALS.NUTRITIONIST,
    PROFESSIONALS.PHYSICAL_EDUCATOR,
  ]).isRequired,
};
