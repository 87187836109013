import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HauxExpansionPanel from '../../../components/HauxExpansionPanel';

const useStyles = makeStyles(() => ({
  plansInfoContainer: {
    marginTop: 40,
  },
  expansionPanelContainer: {
    marginTop: 10,
  },
  subscriptionItem: {
    padding: 16,
  },
}));

export default function PlansInfo({ state }) {
  const classes = useStyles();

  function renderPlanInfo(subscription) {
    const {
      items,
    } = subscription;

    return (
      <Grid container direction="column">
        <Grid item xs className="saveButton" />
        <Grid item container direction="column">
          {items.map((item, index) => (
            <Grid key={index.toString()} item className={classes.subscriptionItem} container>
              <Grid item xs={1} container justify="center">
                <span className="paragraph">
                  {item.quantity.toString().padStart(2, '0')}
                </span>
              </Grid>
              <Grid item xs={8} container direction="column">
                <Grid item>
                  <span className="paragraph">
                    {item.plan.name}
                  </span>
                </Grid>
                {item.plan.descriptions.map((desc) => (
                  <Grid item key={desc}>
                    <span className="description">
                      {desc}
                      ;
                    </span>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>

      </Grid>
    );
  }

  const plans = state.subscriptions.map((sub) => ({
    name: `Contrato ${moment(sub.createdAt).format('DD/MM/YYYY')}`,
    renderDetails: renderPlanInfo(sub),
  }));

  return (
    <Grid container xs={12} className={classes.plansInfoContainer}>
      <Grid item>
        <span className="h5Purple">Pacotes</span>
      </Grid>
      <Grid item container className={classes.expansionPanelContainer}>
        <HauxExpansionPanel items={plans} />
      </Grid>
    </Grid>
  );
}

PlansInfo.propTypes = {
  state: PropTypes.shape({
    subscriptions: PropTypes.arrayOf([PropTypes.shape({})]),
  }).isRequired,
};
