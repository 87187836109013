/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import './home.scss';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { debounce } from 'debounce';
import ScheduleService from '../../service/ScheduleService';
import { ReactComponent as AcAddCircle } from '../../assets/svgs/ac-add-circle.svg';
import HAUXButton from '../../components/HauxButton';
import HauxDropdownButton from '../../components/HauxDropdownButton';
import HauxMeetingsCard from './components/HauxMeetingsCard';
import HauxProspectCard from './components/HauxProspectsCard';
import HauxTrainingCard from './components/HauxTrainingCard';
import HauxMoreLifeCard from './components/HauxMoreLifeCard';
import { getCurrentUser, registerTypes } from '../../utils/Utils';
import AnalyticsManager from '../../AnalyticsManager';
import strings from '../../assets/strings.json';
import ClientService from '../../service/ClientService';
import HauxInputSearch from '../../components/HauxInputSearch';
import ClientContext from '../../ClientContex';
import ViewContext from '../../ViewContext';

const {
  NEW_PACKAGE_HAUX, NEW_REGISTER, PROFESSIONALS,
  PT_PROFESSIONALS, SCHEDULE_HAUX_TOUR,
  NO_CLIENTS_FOUND, NAME, MINIMUN_SEARCH_LENGTH,
  ADD_PACKAGE_HAUX, SCHEDULE_AN_APPOINTMENT,
  CHANGE_REGISTRATION,
} = strings;

const {
  PT_NURSE, PT_COORDINATOR, PT_RECEPTIONIST,
} = PT_PROFESSIONALS;
const NEW_CLIENT_ROUTE = '/client/newClient/';

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  meetingContainer: {
    padding: '0px 120px',
  },
  meetingContent: {
    maxWidth: 446,
    marginBottom: 20,
  },
  paperContainer: {
    justifyContent: 'center',
    padding: 32,
    marginTop: 16,
  },
  btnContainer: {
    marginTop: 8,
    marginBottom: 58,
    justifyContent: 'center',
  },
  rowContainer: {
    margin: 'auto',
  },
  maxWidthStyle: {
    maxWidth: 1156,
  },
  clientStatus: {
    borderRadius: 50,
    backgroundColor: '#E0E0E0',
    padding: '4px 8px 4px 8px',
    width: 98,
    display: 'flex',
    justifyContent: 'center',
  },
  clientActive: {
    backgroundColor: '#4ED25D',
  },
  clientInactive: {
    backgroundColor: '#DB8D8D',
  },
  optionContainer: {
    borderWidth: 2,
    display: 'flex',
  },
  topSpace: {
    marginTop: 8,
  },
  searchContainer: {
    position: 'relative',
    top: -72,
    maxWidth: 1156,
    zIndex: 2,
  },
  viewAll: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
  },
}));

function Reception() {
  const classes = useStyles();
  const history = useHistory();
  const user = getCurrentUser();
  const { setClient } = useContext(ClientContext);
  const { setRouteProperties, handleRequestsErrors } = useContext(ViewContext);
  const [nextMeetingsClient, setNextMeetingsClient] = useState([]);
  const [nextMeetingsProspect, setNextMeetingsProspect] = useState([]);
  const [nextMeetingsTraining, setNextMeetingsTraining] = useState([]);
  const [nextMeetingsMoreLife, setNextMeetingsMoreLife] = useState([]);
  const [clients, setClients] = useState([]);
  const [searched, setSearched] = useState(false);
  const [searchClient, setSearchClient] = useState('');
  const [selectedClient, setSelectedClient] = useState({});

  useEffect(() => {
    AnalyticsManager.track('View Home');
  }, []);

  useEffect(() => {
    setRouteProperties({ pageTitle: 'Recepção' });
  }, [setRouteProperties]);

  useEffect(() => {
    if (!searchClient) {
      setSearched(false);
      setClients([]);
    }
  }, [searchClient]);

  useEffect(() => {
    async function loadEvents() {
      try {
        const nextClients = await ScheduleService.getNextMeetingsClients();
        const nextProspects = await ScheduleService.getNextMeetingsProspects();
        const nextTraining = await ScheduleService.getNextMeetingsTraining();
        const nextMoreLife = await ScheduleService.getNextMeetingsMoreLife();
        setNextMeetingsClient(nextClients);
        setNextMeetingsProspect(nextProspects);
        setNextMeetingsTraining(nextTraining);
        setNextMeetingsMoreLife(nextMoreLife);
      } catch (error) {
        handleRequestsErrors(error);
      }
    }
    loadEvents();
  }, [handleRequestsErrors]);

  const debouncedSearch = debounce((name) => {
    ClientService.getClientsAndProspectsFilteredByName(name)
      .then((response) => {
        if (name.length >= 3) {
          setClients(response);
          setSearched(true);
        }
      })
      .catch(handleRequestsErrors);
  }, 750);

  const handleSearchInputChange = (event, selectedEvent) => {
    if (selectedEvent && selectedEvent.name) {
      setSearchClient(selectedEvent.name);
    } else {
      setSearchClient(event.target.value);
    }
    if (searchClient && event.target.value) {
      if (event.target.value.length >= 3) {
        debouncedSearch(event.target.value);
      } else if (searchClient && event.target.value.length < 3 && searched) {
        setClients([]);
        setSearched(false);
      }
    }
  };

  const renderClientOptions = () => (
    <Grid item container justify="center">
      <Grid item xs={8}>
        <Paper elevation={2} className={classes.paperContainer}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <span className="h4NormalStyle">{selectedClient.name}</span>
            </Grid>
            <Grid item>
              {selectedClient.prospectId
                ? (
                  <Grid item className={classes.clientStatus}>
                    <span className="description fontProspect">Prospect</span>
                  </Grid>
                )
                : selectedClient.active
                  ? (
                    <Grid item className={`${classes.clientStatus} ${classes.clientActive}`}>
                      <span className="descriptionWhite">Cliente Ativo</span>
                    </Grid>
                  )
                  : (
                    <Grid
                      item
                      className={`${classes.clientStatus} ${classes.clientInactive}`}
                    >
                      <span className="descriptionWhite">Cliente Inativo</span>
                    </Grid>
                  )}
            </Grid>
          </Grid>
          <Grid item className={classes.topSpace}>
            <span className="paragraph">O que deseja fazer?</span>
          </Grid>
          <Grid container spacing={2} className={classes.topSpace} justify="center">
            {selectedClient.prospectId
              ? (
                <HAUXButton
                  text={SCHEDULE_HAUX_TOUR}
                  type="secondary"
                  className="CTA"
                  onClick={() => {
                    AnalyticsManager.track('Home button_schedule_haux_tour clicked');
                    setClient(selectedClient);
                    history.push('/schedule/tour', { client: selectedClient });
                  }}
                />
              )
              : (
                <HAUXButton
                  text={ADD_PACKAGE_HAUX}
                  type="secondary"
                  className="CTA"
                  onClick={() => {
                    AnalyticsManager.track('Home button_add_package clicked');
                    setClient(selectedClient);
                    history.push('/plans', { client: selectedClient, origin: 'Recepção' });
                  }}
                />
              )}
            {

              selectedClient.active
                ? (
                  <HAUXButton
                    text={SCHEDULE_AN_APPOINTMENT}
                    type="secondary"
                    className="CTA"
                    onClick={() => {
                      AnalyticsManager.track('Home button_schedule_and_appointment clicked');
                      history.push('/schedule/consults', { client: selectedClient });
                    }}
                  />
                ) : null
            }
            <HAUXButton
              text={CHANGE_REGISTRATION}
              type="secondary"
              className="CTA"
              onClick={() => {
                AnalyticsManager.track('Home button_schedule_and_appointment clicked');
                history.push(NEW_CLIENT_ROUTE, {
                  [selectedClient.prospectId ? 'prospect' : 'client']: selectedClient,
                  checkedA: !selectedClient.prospectId,
                });
              }}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const handleChangeRegister = (option) => {
    if (option === 'Cliente') {
      history.push(NEW_CLIENT_ROUTE, { checkedA: true });
      AnalyticsManager.track('Home button_new_register_client clicked');
    } else {
      history.push(NEW_CLIENT_ROUTE, { checkedA: false });
      AnalyticsManager.track('Home button_new_register_prospect clicked');
    }
  };

  const renderButtons = () => (
    <Grid item container direction="row" className={classes.root}>
      <Grid item xs={10} className={classes.maxWidthStyle}>
        <Grid container className={classes.btnContainer}>
          {user.profileType === PROFESSIONALS.ADMIN || user.profileType === PT_NURSE
            || user.profileType === PT_COORDINATOR || user.profileType === PT_RECEPTIONIST
            ? (
              <>
                <HauxDropdownButton
                  label={NEW_REGISTER}
                  options={registerTypes}
                  Icon={AcAddCircle}
                  handleChange={handleChangeRegister}
                  className="CTAPrimaryButton"
                />
                <div style={{ marginTop: 16 }}>
                  <HAUXButton
                    text={NEW_PACKAGE_HAUX}
                    type="secondary"
                    className="CTA"
                    onClick={() => {
                      AnalyticsManager.track('Home button_plains clicked');
                      history.push('/plans', { client: selectedClient, origin: 'Recepção' });
                    }}
                  />
                </div>
              </>
            )
            : null}
        </Grid>
      </Grid>
    </Grid>
  );

  const meetings = nextMeetingsClient.map((event) => (event));
  const meetingsProspect = nextMeetingsProspect.map((event) => (event));
  const meetingsTraining = nextMeetingsTraining.map((event) => (event));
  const meetingsMoreLife = nextMeetingsMoreLife.map((event) => (event));

  const renderCards = () => (
    <Grid container item justify="space-between" className={classes.meetingContainer}>
      <Grid item xs={6} className={classes.meetingContent}>
        <HauxMeetingsCard
          contentMeetings={meetings}
        />
      </Grid>
      <Grid item xs={6} className={classes.meetingContent}>
        <HauxProspectCard
          contentMeetings={meetingsProspect}
        />
      </Grid>
      <Grid item xs={6} className={classes.meetingContent}>
        <HauxTrainingCard
          contentMeetings={meetingsTraining}
        />
      </Grid>
      <Grid item xs={6} className={classes.meetingContent}>
        <HauxMoreLifeCard
          contentMeetings={meetingsMoreLife}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid item container direction="column" className={classes.searchContainer}>
      <Grid item container justify="center">
        <Grid item xs={8} className={classes.viewAll}>
          <HAUXButton text="Ver todos" type="linkWhite" onClick={() => history.push('/clinic/clients')} />
        </Grid>
      </Grid>
      <Grid item container justify="center">
        <Grid item xs={8}>
          <HauxInputSearch
            label={NAME}
            noOptionsText={searched ? NO_CLIENTS_FOUND : MINIMUN_SEARCH_LENGTH}
            rightIcon={() => <ArrowDropDownIcon />}
            value={searchClient}
            onChange={handleSearchInputChange}
            onSelect={(client) => setSelectedClient(client)}
            fullWidth
            autocomplete
            autocompleteProps={{
              options: clients,
              getOptionLabel: (option) => option.name || '',
            }}
            renderOption={(option) => (
              <Grid container>
                <Grid item xs={12} className={classes.optionContainer}>
                  <Grid item xs={2}>
                    {option.prospectId
                      ? (
                        <Grid item className={classes.clientStatus}>
                          <span className="description fontProspect">Prospect</span>
                        </Grid>
                      )
                      : option.active
                        ? (
                          <Grid item className={`${classes.clientStatus} ${classes.clientActive}`}>
                            <span className="descriptionWhite">Cliente Ativo</span>
                          </Grid>
                        )
                        : (
                          <Grid
                            item
                            className={`${classes.clientStatus} ${classes.clientInactive}`}
                          >
                            <span className="descriptionWhite">Cliente Inativo</span>
                          </Grid>
                        )}
                  </Grid>
                  <Grid item xs={4}>
                    <span className="paragraph">{option.name}</span>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      {selectedClient && selectedClient.name
        ? (
          renderClientOptions()
        )
        : null}
      {renderButtons()}

      {renderCards()}
    </Grid>
  );
}

export default Reception;
