import React, { useReducer, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import strings from "../../assets/strings.json";
import HauxConfirmDialog from "../../components/HauxConfirmDialog";
import BasicInfo from "./components/basicInfo";
import ClientForm from "./components/clientForm";
import ProspectForm from "./components/prospectForm";
import ClientService from "../../service/ClientService";
import ProspectService from "../../service/ProspectService";
import FranchiseService from "../../service/FranchiseService";
import AnalyticsManager from "../../AnalyticsManager";
import ViewContext from "../../ViewContext";
import { reducer, initialState } from "./reducers";
import { setPayload, getCurrentUser } from "../../utils/Utils";
import "./newClient.scss";
import "../../App.scss";

const {
  REGISTER_CLIENT_FAIL,
  REGISTER_PROSPECT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  REGISTER_CLIENT_SUCCESS,
  UPDATE_PROSPECT_SUCCESS,
  REGISTER_PROSPECT_SUCCESS,
} = strings;

const useStyles = makeStyles(() => ({
  maxWidthStyle: {
    maxWidth: 1156,
  },
}));

const NewClient = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const {
    setRouteProperties,
    handleRequestsErrors,
    setSnackbarOption,
    setLoading,
  } = useContext(ViewContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    clientData,
    biologicalSex,
    saveFormDialog,
    exitFormDialog,
    switchState,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle:
        location.state && (location.state.prospect || location.state.client)
          ? "editar cadastro"
          : "novo cadastro",
      breadcrumbs: "Voltar",
    });
  }, [setRouteProperties, location.state]);

  useEffect(() => {
    const user = getCurrentUser();
    FranchiseService.getAllFranchise()
      .then((res) => {
        const franchises = res.filter(
          (item) => user.franchises.filter((f) => f._id === item._id).length > 0
        );
        if (franchises.length > 1) {
          dispatch(setPayload("LOAD_FRANCHISES", franchises));
        } else {
          dispatch(setPayload("CLIENT_DATA", { franchises }));
        }
      })
      .catch(handleRequestsErrors);
  }, [handleRequestsErrors]);

  useEffect(() => {
    AnalyticsManager.track("View NewClient");
  }, []);

  useEffect(() => {
    if (location.state.client && location.state.client.clientId) {
      ClientService.getClientSubscriptions(location.state.client.clientId)
        .then((subscriptions) => {
          dispatch(setPayload("SET_CLIENT_SUBSCRIPTIONS", subscriptions));
        })
        .catch(handleRequestsErrors);
    }
  }, [location.state.client, handleRequestsErrors]);

  useEffect(() => {
    const { prospect, client, checkedA } = location.state;
    let check = checkedA;
    if (location.state && (location.state.prospect || location.state.client)) {
      const data = prospect || client;
      let newData = {
        clientId: data.clientId,
        name: data.name,
        phone: data.phone,
        email: data.email,
        confirmEmail: data.email,
        birthDate: data.birthDate,
        cpf: data.cpf,
        sourceChannel: data.sourceChannel,
        sourceWho: data.sourceWho,
        isChild: !!data.isChild,
        responsableName: data.responsableName,
        responsableCpf: data.responsableCpf,
      };

      if (client && client.address) {
        check = true;
        newData = {
          ...newData,
          cep: data.address.cep,
          street: data.address.street,
          number: data.address.number,
          district: data.address.district,
          city: data.address.city,
          complement: data.address.complement,
          uf: data.address.state,
        };
      }
      if (client && client.franchises) {
        newData = {
          ...newData,
          franchises: client.franchises,
        };
      }

      dispatch(setPayload("CLIENT_DATA", newData));
      dispatch(setPayload("BIOLOGICAL_SEX", data.biologicalSex));
    }
    dispatch(setPayload("SWITCH_STATE", { checkedA: check }));
  }, [location.state]);

  const openCurrentContractPDF = async (id) => {
    AnalyticsManager.track("Clinic button_open_contract clicked");
    let pdfURL = null;
    try {
      pdfURL = await ClientService.getCurrentContract(
        location.state.client.clientId
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (!pdfURL) {
      pdfURL = await ClientService.getNewContract(
        location.state.client.clientId
      );
    }
    window.open(pdfURL);
  };

  const updateClient = async () => {
    AnalyticsManager.track("UpdateClientForm button_save_client clicked");
    const { client } = location.state;
    setLoading(true);
    await ClientService.updateClientById(client.clientId, {
      ...clientData,
      biologicalSex,
    })
      .then(() => {
        AnalyticsManager.track("UpdateClient success");
        setSnackbarOption({
          type: "success",
          message:
            location.state && location.state.client
              ? UPDATE_CLIENT_SUCCESS
              : REGISTER_CLIENT_SUCCESS,
          open: true,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch((error) => {
        AnalyticsManager.track("UpdateClient failed");
        handleRequestsErrors(error, { message: REGISTER_CLIENT_FAIL });
      });
    setLoading(false);
  };

  const updateProspect = async () => {
    AnalyticsManager.track("UpdateProspectForm button_save_prospect clicked");
    const { prospect } = location.state;
    setLoading(true);
    await ProspectService.updateProspectById(
      prospect.prospectId,
      clientData,
      biologicalSex
    )
      .then(() => {
        AnalyticsManager.track("UpdateProspect success");
        setSnackbarOption({
          open: true,
          type: "success",
          message:
            location.state && location.state.prospect
              ? UPDATE_PROSPECT_SUCCESS
              : REGISTER_PROSPECT_SUCCESS,
        });
        setTimeout(() => {
          history.goBack();
        }, 3000);
      })
      .catch((error) => {
        AnalyticsManager.track("UpdateProspect failed");
        handleRequestsErrors(error, { message: REGISTER_PROSPECT_FAIL });
      });
    setLoading(false);
  };

  const renderBasicInfo = () => <BasicInfo dispatch={dispatch} state={state} />;

  function renderConfirmation() {
    return (
      <>
        {exitFormDialog ? (
          <HauxConfirmDialog
            title="Sair do formulário"
            message="Tem certeza que deseja sair do formulário? Com isso, você irá perder os dados preenchidos."
            open={exitFormDialog}
            handleClose={() => dispatch(setPayload("EXIT_FORM_DIALOG", false))}
            handleConfirm={() => {
              dispatch(setPayload("EXIT_FORM_DIALOG", false));
              history.goBack();
            }}
          />
        ) : null}
        {saveFormDialog ? (
          <HauxConfirmDialog
            title="Salvar formulário"
            message="Algumas alterações foram feitas no formulário. Deseja salvar?"
            open={saveFormDialog}
            handleClose={() => dispatch(setPayload("SAVE_FORM_DIALOG", false))}
            handleConfirm={() => {
              AnalyticsManager.track(
                "RegisterNewClient button_save_form clicked"
              );
              dispatch(setPayload("SAVE_FORM_DIALOG", false));

              if (location.state && location.state.prospect) {
                updateProspect();
              } else {
                updateClient();
              }
            }}
          />
        ) : null}
      </>
    );
  }

  function renderProspectForm() {
    return (
      <ProspectForm
        state={state}
        dispatch={dispatch}
        renderBasicInfo={renderBasicInfo}
        renderConfirmation={renderConfirmation}
        handleRequestsErrors={handleRequestsErrors}
        setSnackbarOption={setSnackbarOption}
        setLoading={setLoading}
      />
    );
  }

  const renderClientForm = () => (
    <ClientForm
      state={state}
      dispatch={dispatch}
      openCurrentContractPDF={openCurrentContractPDF}
      renderBasicInfo={renderBasicInfo}
      renderConfirmation={renderConfirmation}
      handleRequestsErrors={handleRequestsErrors}
      setSnackbarOption={setSnackbarOption}
      setLoading={setLoading}
    />
  );

  const renderItem = () => {
    if (switchState.checkedA) {
      return (
        <Grid container spacing={2} className="formGridContainer">
          <Grid item xs={10} className={classes.maxWidthStyle}>
            {renderClientForm()}
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} className="formGridContainer">
        <Grid item xs={10} className={classes.maxWidthStyle}>
          {renderProspectForm()}
        </Grid>
      </Grid>
    );
  };

  return renderItem();
};

export default NewClient;
