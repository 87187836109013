import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import AgoraRTC from 'agora-rtc-sdk';
import { Base64 } from 'js-base64';
import HauxButton from '../../components/HauxButton';
import ClientContext from '../../ClientContex';

function Chat({
  height, width, closeChat, isDialog,
}) {
  const streamClient = useRef(null);
  const localStream = useRef(null);
  const { client } = useContext(ClientContext);
  const { encodedId } = useParams();
  const [state, setState] = useState({
    uid: Math.floor(Math.random() * 100),
    appid: '972de515217d4a6da75f317c5e150a43',
    channelName: client.clientId || client._id || Base64.decode(encodedId) || 'haux',
    vidMute: false,
    audMute: false,
    joinSucceed: false,
    joined: false,
    published: false,
    localStream: null,
    remoteStreams: [],
    params: {},
    token: 'haux',
  });

  const join = () => {
    streamClient.current.init(state.appid, () => {
      streamClient.current.join(null, state.channelName, state.uid, () => {
        localStream.current = AgoraRTC.createStream({
          streamID: state.uid,
          audio: true,
          video: true,
          screen: false,
        });

        localStream.current.init(() => {
          localStream.current.play('local_stream');
          streamClient.current.publish(localStream.current);
        }, (err) => {
          console.error('client join failed', err);
        });
      }, (err) => {
        console.error('client join failed', err);
      });
    }, (err) => {
      console.error(err);
    });
  };

  const leave = () => {
    streamClient.current.leave(() => {
      localStream.current.stop();
      localStream.current.close();
      state.remoteStreams.forEach((remoteStream) => {
        remoteStream.stop();
      });

      setState({
        ...state,
        remoteStreams: [],
      });
    });

    closeChat();
  };

  useEffect(() => {
    streamClient.current = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
    streamClient.current.on('stream-added', (evt) => {
      const remoteStream = evt.stream;
      if (remoteStream.getId() !== state.uid) {
        streamClient.current.subscribe(remoteStream, () => { });
      }
    });

    streamClient.current.on('stream-subscribed', (evt) => {
      const remoteStream = evt.stream;

      setState({
        ...state,
        remoteStreams: state.remoteStreams.concat(remoteStream),
      });
    });

    streamClient.current.on('stream-removed', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      const newRemotes = [];
      state.remoteStreams.forEach((r) => {
        if (r.getId() === id) {
          r.stop(`remote_stream_${r.getId()}`);
        } else {
          newRemotes.concat(r);
        }
      });
      setState({
        ...state,
        remoteStreams: newRemotes,
      });
    });
    join();
  }, []);

  useEffect(() => {
    state.remoteStreams.forEach((remoteStream) => {
      remoteStream.play(`remote_stream_${remoteStream.getId()}`);
    });
  }, [state.remoteStreams]);


  return (
    <div style={{ position: 'relative' }}>
      <div
        id="local_stream"
        style={{
          position: 'absolute',
          bottom: 10,
          right: 0,
          height: '30%',
          width: '30%',
          zIndex: 1000,
        }}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        height: height || '100vh',
        width: width || '100vw',
      }}
      >
        {state.remoteStreams.map((remote) => (
          <div id={`remote_stream_${remote.getId()}`} key={remote.getId()} style={{ flex: 1 }} />
        ))}
      </div>
      {isDialog && (
        <div style={{ marginLeft: -10, marginBottom: -10 }}>
          <HauxButton
            text="Finalizar atendimento"
            className="destructive"
            onClick={leave}
          />
        </div>
      )}
    </div>
  );
}

export default Chat;

Chat.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  closeChat: PropTypes.func,
  isDialog: PropTypes.bool,
};

Chat.defaultProps = {
  isDialog: false,
  closeChat: () => { },
};
