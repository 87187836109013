import React, {
  useReducer,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import icProfilePurple from "../../assets/svgs/ic-profile-purple.svg";
import icNutrition from "../../assets/svgs/ic-nutricao.svg";
import icHeight from "../../assets/svgs/ic-height.svg";
import icGender from "../../assets/svgs/ic-gender.svg";
import icCalendar from "../../assets/svgs/ic-calendar.svg";
import HAUXButton from "../../components/HauxButton";
import HauxProgressBar from "../../components/HauxProgressBar";
import HauxFormModal from "../../components/HauxFormModal";
import ScheduleModal from "./components/scheduleModal";
import ClientInfo from "./sections/clientInfo";
import StykuMeasures from "./sections/stykuMeasures";
import ConsultsArea from "./sections/consultsArea";
import AdditionalInfo from "./sections/additionalInfo";
import ProgramTimeline from "./sections/programTimeline";
import PlansInfo from "./sections/plansInfo";
import ClientService from "../../service/ClientService";
import AnalyticsManager from "../../AnalyticsManager";
import { setPayload, getCurrentUser } from "../../utils/Utils";
import strings from "../../assets/strings.json";
import { reducer, initialState } from "./reducers";
import ClientContext from "../../ClientContex";
import ViewContext from "../../ViewContext";
import "./dashboard.scss";
import { HauxButton } from "../../components";

const {
  DATE_FORMATS,
  INITIAL_WEIGHT,
  GOAL,
  PT_PROFESSIONALS,
  EDIT_PROFILE,
  IMC,
  PROFESSIONALS,
} = strings;

const { PT_NURSE, PT_COORDINATOR } = PT_PROFESSIONALS;

const CONTRACT_PERMISSION_ARRAY = [
  PROFESSIONALS.ADMIN,
  PT_NURSE,
  PT_COORDINATOR,
];

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
  basicInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  maxWidthStyle: {
    maxWidth: 1156,
    marginTop: 40,
    position: "relative",
  },
  topSpace: {
    marginTop: 16,
  },
  buttonsContainer: {
    marginTop: 24,
  },
  contractButtons: {
    display: "flex",
  },
}));

const Dashboard = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setRouteProperties, setLoading, handleRequestsErrors } = useContext(
    ViewContext
  );
  const { client, loadClient } = useContext(ClientContext);
  const {
    actualAnamnesis,
    firstAnamnesis,
    modalData,
    goalPercentage,
    modalOpen,
  } = state;
  const user = getCurrentUser();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setRouteProperties({
      pageTitle: "Dashboard",
      breadcrumbs: "Encontros Haux",
      breadcrumbsAction: () => history.push("/meetings"),
    });
    loadClient();
  }, [setRouteProperties, history]);

  const clientInitialDataLoad = useCallback(async () => {
    const clientAdditionalInfo = await ClientService.getClientAdditionalInfo(
      client.clientId
    );
    const events = await ClientService.getClientEvents(client.clientId);

    const latestNursingAnamnesis = client.nursingAnamnesis
      ? client.nursingAnamnesis[client.nursingAnamnesis.length - 1]
      : null;
    const [firstNursingAnamnesis] = client.nursingAnamnesis
      ? client.nursingAnamnesis
      : [];
    const restrictions = [];

    const latestPhysicalEducationAnamnesis = client.physicalEducationAnamnesis
      ? client.physicalEducationAnamnesis[
          client.physicalEducationAnamnesis.length - 1
        ]
      : null;

    if (latestNursingAnamnesis && latestNursingAnamnesis.restriction) {
      if (latestNursingAnamnesis.restriction.cardio) {
        restrictions.push("Cardio");
      }
      if (latestNursingAnamnesis.restriction.diabetes) {
        restrictions.push("Diabetes");
      }
      if (latestNursingAnamnesis.restriction.gluten) {
        restrictions.push("Gluten");
      }
      if (latestNursingAnamnesis.restriction.lactose) {
        restrictions.push("Lactose");
      }
    }

    dispatch(
      setPayload("INITIAL_LOAD", {
        actualAnamnesis: latestNursingAnamnesis,
        healthClient: restrictions,
        firstAnamnesis: firstNursingAnamnesis,
        actualPhysicalEducationAnamnesis: latestPhysicalEducationAnamnesis,
        additionalInfo: clientAdditionalInfo,
        events,
      })
    );
  }, [client]);

  useEffect(() => {
    try {
      setLoading(true);
      clientInitialDataLoad();
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }, [clientInitialDataLoad, handleRequestsErrors, setLoading]);

  useEffect(() => {
    ClientService.getClientSubscriptions(client.clientId)
      .then((subscriptions) => {
        dispatch(setPayload("SET_DASH_SUBSCRIPTIONS", subscriptions));
      })
      .catch(handleRequestsErrors);
  }, [client, handleRequestsErrors]);

  useEffect(() => {
    if (client && client.goal && actualAnamnesis && firstAnamnesis) {
      const weightToLose = firstAnamnesis.weight - client.goal.weight;
      const lostWeight = firstAnamnesis.weight - actualAnamnesis.weight;

      let percentage = (lostWeight / weightToLose) * 100;

      if (percentage > 100) percentage = 100;
      if (percentage < 0) percentage = 0;
      dispatch(setPayload("GOAL_PERCENTAGE", percentage));
    } else {
      dispatch(setPayload("GOAL_PERCENTAGE", 0));
    }
  }, [firstAnamnesis, actualAnamnesis, client]);

  useLayoutEffect(() => {
    if (actualAnamnesis && Object.keys(actualAnamnesis).length) {
      const { muscleMass, height } = actualAnamnesis;

      if (muscleMass && height) {
        const MME = parseFloat(muscleMass.toFixed(2).replace(",", "."));
        const convHeigth = parseFloat(height.toFixed(2).replace(",", "."));
        const calculateMuscleMassIndex = MME / (convHeigth * convHeigth);
        if (
          (client.biologicalSex === "M" && calculateMuscleMassIndex < 8.87) ||
          calculateMuscleMassIndex < 6.42
        ) {
          dispatch(setPayload("IS_SARCOPENIC", true));
        }
      }
    }
  }, [actualAnamnesis, client]);

  const handleChangeModal = () => {
    dispatch(setPayload("MODAL_OPEN", !modalOpen));
  };

  const handlePrint = () => {
    AnalyticsManager.track("Dashboard button_client_schedule clicked");
    dispatch(setPayload("OPEN", true));
  };

  const renderContractButton = () => {
    if (!CONTRACT_PERMISSION_ARRAY.includes(user.profileType)) {
      return null;
    }

    return (
      <>
        <HAUXButton
          type="secondary"
          text={EDIT_PROFILE}
          onClick={() => {
            AnalyticsManager.track("EditClientProfile button clicked");
            history.push("/client/newClient", { client, checkedA: true });
          }}
        />
      </>
    );
  };

  const renderClientInfo = () => {
    if (client.nursingAnamnesis && client.nursingAnamnesis.length) {
      return <ClientInfo state={state} client={client} />;
    }

    return null;
  };

  const renderStykuMeasures = () => <StykuMeasures />;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={10} className={classes.maxWidthStyle}>
        <Grid item xs={12}>
          <Grid item xs={2} md={12}>
            <div className="infoContainer">
              <img src={icProfilePurple} alt="" className="iconStyle" />
              <span className="clientNameDashboard">{client.name}</span>
              <span
                className={`clientStatus ${
                  client.active ? "active" : "inactive"
                }`}
              >
                {client.active ? "Ativo" : "Inativo"}
              </span>
            </div>
            {client.nursingAnamnesis && client.nursingAnamnesis.length ? (
              <div className="dateContainer">
                <span className="dateText">
                  {`Início do tratamento: ${moment(
                    client.nursingAnamnesis[0].date
                  ).format(DATE_FORMATS.NORMAL)} | atualizado em: ${moment(
                    client.updatedAt
                  ).format(DATE_FORMATS.NORMAL)}`}
                </span>
                <Grid
                  container
                  xs={8}
                  direction="row"
                  justify="flex-end"
                  spacing={3}
                  item
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <span className="h5Purple">
                        {firstAnamnesis !== undefined
                          ? `${firstAnamnesis.weight}KG`
                          : ""}
                      </span>
                      <span className="description">{INITIAL_WEIGHT}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <span
                      className="descriptionPurple"
                      style={{ marginLeft: `${goalPercentage - 8}%` }}
                    >
                      {actualAnamnesis !== undefined
                        ? actualAnamnesis.weight
                        : ""}
                      KG
                    </span>
                    <HauxProgressBar value={goalPercentage} />
                  </Grid>
                  <Grid item className={classes.progress}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <span className="h5Purple">
                        {client && client.goal
                          ? `${client.goal.weight
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(",00", "")}KG`
                          : ""}
                      </span>
                      <span className="description">{GOAL}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            <Grid
              item
              container
              className={`${classes.basicInfo} ${
                !(client.nursingAnamnesis && client.nursingAnamnesis.length)
                  ? classes.topSpace
                  : null
              }`}
              justify="center"
            >
              <Grid item>
                <div className="infoContainer">
                  <img src={icCalendar} alt="" />
                  <span className="paragraph basicInfoText">
                    {`${moment()
                      .diff(
                        moment(client.birthDate, DATE_FORMATS.NORMAL),
                        "years"
                      )
                      .toString()} anos`}
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className="infoContainer">
                  <img src={icGender} alt="" />
                  <span className="paragraph basicInfoText">
                    {client.biologicalSex}
                  </span>
                </div>
              </Grid>
              {firstAnamnesis && firstAnamnesis.height ? (
                <Grid item>
                  <div className="infoContainer">
                    <img src={icHeight} width={16} height={16} alt="" />
                    <span className="paragraph basicInfoText">
                      {`${firstAnamnesis.height
                        .toFixed(2)
                        .replace(".", ",")} m`}
                    </span>
                  </div>
                </Grid>
              ) : null}
              {actualAnamnesis && actualAnamnesis.imc ? (
                <Grid item>
                  <div className="infoContainer">
                    <span className="imcTitle">{IMC}</span>
                    <span className="paragraph basicInfoText">
                      {actualAnamnesis.imc.toFixed(2).replace(".", ",")}
                    </span>
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <Grid
              item
              container
              className={`${classes.basicInfo} ${
                !(client.nursingAnamnesis && client.nursingAnamnesis.length)
                  ? classes.topSpace
                  : null
              }`}
              justify="center"
            >
              {client && client.goal ? (
                <Grid item>
                  <div className="infoContainer">
                    <img src={icNutrition} width={16} height={16} alt="" />
                    <span className="paragraph basicInfoText">
                      {`Faixa calórica sugerida: ${client.goal.minCalories} - ${client.goal.maxCalories} Kcal/dia`}
                    </span>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <div className="clientInfoContainer">
          <div style={{ flex: 1 }}>{renderClientInfo()}</div>
          <div style={{ flex: 2 }}>{renderStykuMeasures()}</div>
        </div>
        <ProgramTimeline
          state={state}
          onPrintClick={handlePrint}
          client={client.clientId || client._id}
        />
        <ConsultsArea state={state} dispatch={dispatch} />
        <HauxButton
          text="Baixar último laudo"
          onClick={() => {
            setLoading(true);
            ClientService.getClientReport(client.clientId || client._id)
              .then((res) => {
                window.open(res);
                setLoading(false);
              })
              .catch(() => setLoading(false));
          }}
        />
        <AdditionalInfo state={state} dispatch={dispatch} client={client} />
        {state.subscriptions && state.subscriptions.length ? (
          <PlansInfo state={state} />
        ) : null}
        <Grid container className={classes.buttonsContainer}>
          <Grid container>
            <Grid item xs={8} className={classes.contractButtons}>
              {renderContractButton()}
            </Grid>
          </Grid>
        </Grid>
        <ScheduleModal state={state} dispatch={dispatch} />
        <HauxFormModal
          open={modalOpen}
          handleClose={handleChangeModal}
          data={modalData}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
