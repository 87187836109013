/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, MenuItem,
} from '@material-ui/core';
import {
  useField,
  useFormikContext,
} from 'formik';

const Select = ({ options, ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  let errors = {};

  if (meta.error && (meta.touched || form.submitCount > 0)) {
    errors = { error: true, helperText: meta.error };
  }

  return (
    <TextField
      select
      {...field}
      {...props}
      {...errors}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
};

Select.defaultProps = {
  options: [],
};


export default Select;
