import React, { useState } from 'react';
import {
  useHistory,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import AnalyticsManager from '../../../AnalyticsManager';
import HauxButton from '../../../components/HauxButton';

import '../training.scss';

import pdfs from '../../../assets/pdfs';

import HauxPdfModal from '../../../components/HauxPdfModal';

const GUIDES = [
  { name: 'Guia Geral', pdf: pdfs.trainingGuiaGeral },
  { name: 'Alongamento', pdf: pdfs.trainingGuiaAlongamento },
  { name: 'Mobilidade', pdf: pdfs.trainingGuiaMobilidade },
  { name: 'Potência', pdf: pdfs.trainingGuiaPotencia },
  { name: 'Força', pdf: pdfs.trainingGuiaForca },
  { name: 'Aeróbico', pdf: pdfs.trainingGuiaAerobico },
];

const GuideButton = withStyles({
  root: {
    flex: 1,
    color: '#3E2782',
    textTransform: 'none',
    fontSize: 16,
    fontFamily: [
      'Graviola',
    ].join(','),
  },
})(Button);

const Guides = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');

  const openGuidePDF = async (pdfURL) => {
    AnalyticsManager.track('Haux Trainings button_guide_pdf clicked');
    setOpen(true);
    setLink(`${window.location.origin.toString()}${pdfURL}`);
  };

  return (
    <Paper className="trainingGuides">
      <span className="h6NormalStyle guideTitle">Guias</span>
      <div className="guidesWrapper">
        <div>
          {GUIDES.slice(0, 3).map((guide) => (
            <GuideButton
              key={guide.name}
              startIcon={<DescriptionIcon />}
              onClick={() => openGuidePDF(guide.pdf)}
            >
              {guide.name}
            </GuideButton>
          ))}
        </div>
        <div>
          {GUIDES.slice(3, 6).map((guide) => (
            <GuideButton
              key={guide.name}
              startIcon={<DescriptionIcon />}
              onClick={() => openGuidePDF(guide.pdf)}
            >
              {guide.name}
            </GuideButton>
          ))}
        </div>
      </div>
      {/* <HauxButton
        text="Ver galeria de videos"
        type="secondary"
        onClick={() => history.push('training/gallery')}
      /> */}

      <HauxPdfModal open={open} link={link} handleClose={() => setOpen(false)} />
    </Paper>
  );
};

export default Guides;
