import React, {
  useContext, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MeetingService from '../../../service/MeetingService';
import ClientContext from '../../../ClientContex';
import strings from '../../../assets/strings.json';
import icHeart from '../../../assets/svgs/ic-heart.svg';
import icTraining from '../../../assets/svgs/ic-training.svg';
import icAttention from '../../../assets/svgs/ic-attention.svg';
import HauxChip from '../../../components/HauxChip';
import HauxProgressIndicator from '../../../components/HauxProgressIndicator';
import HAUXButton from '../../../components/HauxButton';
import HAUXModal from '../../../components/HauxModal';
import TrainingMeetingFollowUp from '../../training/components/trainingMeetingFollowUp';

const { CLIENT_HEALTH, PHYSICAL_ACTIVITY, SARCOPENIC_PATIENT } = strings;

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    paddingTop: 76,
    paddingBottom: 77,
    marginTop: 16,
  },
  rootChip: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    marginTop: 8,
  },
  maxWidthStyle: {
    maxWidth: 1156,
    marginRight: 10,
    flex: 1,
    position: 'relative',
  },
  sarcopenicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F57A3D',
    width: 224,
    height: 30,
    borderRadius: 4,
    marginLeft: 32,
    position: 'absolute',
    bottom: -15,
  },
  leisureTimeActivityProgress: {
    marginTop: 24,
  },
}));

function ClientInfo({ state }) {
  const classes = useStyles();
  const { client } = useContext(ClientContext);
  const [meetingsActivityLevel, setMeetingsActivityLevel] = useState([]);
  const [trainingModalOpen, setTrainingModalOpen] = useState(false);
  const {
    isSarcopenic, healthClient,
  } = state;

  useEffect(() => {
    MeetingService.getPhysicalEducationMeetings(client.clientId)
      .then((events) => setMeetingsActivityLevel(events));
  }, [client.clientId]);

  const renderHealthProblemChips = () => healthClient
    .map((item) => <HauxChip key={item} label={item} />);

  const renderSarcopenicAlert = () => {
    if (!isSarcopenic) {
      return null;
    }

    return (
      <div className={classes.sarcopenicContainer}>
        <img src={icAttention} alt="" className="iconStyle" />
        <span className="paragraphWhite">{SARCOPENIC_PATIENT}</span>
      </div>
    );
  };

  const renderTrainingModal = () => (
    <HAUXModal
      title="Histórico Haux Training"
      subtitle={client.name}
      open={trainingModalOpen}
      handleClose={() => setTrainingModalOpen(false)}
    >
      <div style={{ paddingTop: 50 }}>
        <TrainingMeetingFollowUp
          client={{ client }}
          load
          onSave={() => setTrainingModalOpen(false)}
        />
      </div>
    </HAUXModal>
  );

  return (
    <>

      <Grid item xs={12} className={classes.maxWidthStyle}>
        <Paper className={classes.paperContainer} elevation={3}>
          <Grid container item xs={10} direction="row" justify="center">
            {(meetingsActivityLevel.data && meetingsActivityLevel.data.length > 0)
              && (
              <div style={{ marginBottom: 40 }}>
                <Grid container direction="row" justify="center">
                  <img src={icTraining} alt="" />
                  <span className="paragraphPurple basicInfoText">{PHYSICAL_ACTIVITY}</span>
                </Grid>
                <Grid item container className={classes.leisureTimeActivityProgress}>
                  <HauxProgressIndicator
                    indicator={meetingsActivityLevel.data[meetingsActivityLevel.data.length - 1]
                      .physicalActivityLevel}
                  />
                </Grid>
              </div>
              )}
            <HAUXButton text="Histórico Haux Training" onClick={() => setTrainingModalOpen(true)} />
          </Grid>
          {renderSarcopenicAlert()}
        </Paper>
      </Grid>

      {(healthClient && healthClient.length > 0)
      && (
      <Grid item xs={12} className={classes.maxWidthStyle}>
        <Paper className={classes.paperContainer} elevation={3}>
          <Grid container item xs={10} direction="row" justify="center">
            <div>
              <Grid container direction="row" alignItems="center">
                <img src={icHeart} alt="" />
                <span className="paragraphPurple basicInfoText">{CLIENT_HEALTH}</span>
              </Grid>
              <Grid container justify="flex-start" className={classes.rootChip}>
                {renderHealthProblemChips()}
              </Grid>
            </div>
          </Grid>
        </Paper>
      </Grid>
      )}
      {renderTrainingModal()}
    </>
  );
}

export default ClientInfo;

ClientInfo.propTypes = {
  state: PropTypes.shape({
    isSarcopenic: PropTypes.bool,
    healthClient: PropTypes.array,
  }).isRequired,
  client: PropTypes.shape({
    nutritionAnamnesis: PropTypes.arrayOf(PropTypes.object),
    goal: PropTypes.shape({
      minCalories: PropTypes.number,
      maxCalories: PropTypes.number,
    }),
    program: PropTypes.shape({
      foodIntolerances: PropTypes.arrayOf(PropTypes.string),
      tag: PropTypes.string,
    }),
  }).isRequired,
};
