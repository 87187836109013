import axios from "../middlewares/axios";

const baseUrl = "/meal";

class MealService {
  static postMeal(meal) {
    return axios.post(baseUrl, meal).catch((error) => {
      throw error;
    });
  }

  static putMeal(mealId, updatedMeal) {
    return axios
      .put(baseUrl, { mealId, updatedMeal })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getPaginatedMeals(page, searchText, orderBy, order) {
    const url = `${baseUrl}?page=${page}&filter=${searchText}&orderBy=${orderBy}&order=${order}`;
    return axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static getMealsUrl() {
    const url = `${baseUrl}/images`;

    return axios
      .get(url)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  static deleteMeal(mealId) {
    const url = `${baseUrl}/${mealId}`;
    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}

export default MealService;
