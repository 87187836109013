import React, { useReducer, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import HauxInput from "../../../../../components/HauxInput";
import HauxCheckbox from "../../../../../components/HauxCheckbox";
import HauxButton from "../../../../../components/HauxButton";
import AnalyticsManager from "../../../../../AnalyticsManager";
import { StyledTabs, StyledTab } from "../../../../../components/HauxStyledTab";
import { initialState, reducer } from "./reducers";
import { setTab, setData, setErrors } from "./actions";
import FinanceService from "../../../../../service/FinanceService";

const PAYMENT_TYPE = [
  "Elo - Débito",
  "Elo - Crédito",
  "Visa - Débito",
  "Visa - Crédito",
  "Master - Débito",
  "Master - Crédito",
  "Banricompras",
  "Dinheiro",
  "Cheque",
  "Cheque pré-datado",
  "Transferência bancária",
  "Boleto bancário",
];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  alignVertical: {
    display: "flex",
    alignItems: "center",
    color: "#737373",
  },
  checkBoxAlign: {
    marginLeft: -15,
  },
  contentText: {
    "& label": {
      fontFamily: "Graviola",
      fontSize: 16,
    },
    "& > .MuiInput-root > textarea": {
      fontFamily: "Graviola",
      color: "#737373",
      "&::placeholder": {
        fontFamily: "Graviola",
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Create = ({
  franchise,
  financeTab,
  onChangeFinanceTab,
  onClose,
  onSuccess,
  onError,
  handleRequestsErrors,
}) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [financeTypes, setFinanceTypes] = useState([]);
  const [saving, setSaving] = useState(false);
  const {
    tab,
    financeType,
    description,
    competencyDate,
    paymentType,
    value,
    discount,
    finalValue,
    installments,
    installmentValue,
    comments,
    installmentDate,
    isRecurring,
    errors,
    nf,
  } = state;

  useEffect(() => {
    FinanceService.getAllFinanceTypes()
      .then((data) => setFinanceTypes(data))
      .catch(handleRequestsErrors);
  }, [handleRequestsErrors]);

  useEffect(() => {
    dispatch(setData({ financeType: {} }));
  }, [tab]);

  useEffect(() => {
    dispatch(setData({ finalValue: value - discount }));
  }, [dispatch, value, discount]);

  useEffect(() => {
    if (isRecurring && tab === 1) {
      dispatch(setData({ installmentValue: finalValue || 0 }));
    } else {
      dispatch(setData({ installmentValue: finalValue / installments || 0 }));
    }
  }, [dispatch, finalValue, installments, isRecurring, tab]);

  useEffect(() => {
    dispatch(setData({ installments: 1 }));
  }, [dispatch, isRecurring]);

  const getFinanceTypes = useCallback(() => {
    if (tab === 0) {
      return financeTypes.filter((f) => f.active && f.type === "entry");
    }

    return financeTypes.filter((f) => f.active && f.type === "exit");
  }, [financeTypes, tab]);

  const validate = () => {
    let newErrors = {};

    const requiresMessage = "Campo obrigatório";

    if (_.isEmpty(financeType)) {
      newErrors = { ...newErrors, financeType: requiresMessage };
    }
    if (tab === 0 && _.isEmpty(description)) {
      newErrors = { ...newErrors, description: requiresMessage };
    }
    if (_.isEmpty(competencyDate)) {
      newErrors = { ...newErrors, competencyDate: requiresMessage };
    }
    if (tab === 0 && _.isEmpty(paymentType)) {
      newErrors = { ...newErrors, paymentType: requiresMessage };
    }
    if (value <= 0) {
      newErrors = { ...newErrors, value: requiresMessage };
    }
    if (!_.isNumber(discount)) {
      newErrors = { ...newErrors, discount: requiresMessage };
    }
    if (finalValue <= 0) {
      newErrors = { ...newErrors, finalValue: requiresMessage };
    }
    if (!_.isNumber(installments) || installments <= 0) {
      newErrors = { ...newErrors, installments: requiresMessage };
    }
    if (installmentValue <= 0) {
      newErrors = { ...newErrors, installmentValue: requiresMessage };
    }
    if (_.isEmpty(installmentDate)) {
      newErrors = { ...newErrors, installmentDate: requiresMessage };
    }

    dispatch(setErrors(newErrors));

    return _.isEmpty(newErrors);
  };

  const save = async () => {
    AnalyticsManager.track(
      "Clinic_finances create_button_save_release clicked"
    );
    if (validate()) {
      setSaving(true);
      try {
        await FinanceService.postFinance({
          franchise,
          type: financeType.value,
          description,
          competencyDate: competencyDate.valueOf(),
          paymentType,
          value,
          discount,
          finalValue,
          installments,
          installmentValue,
          nf,
          installmentDate: installmentDate.valueOf(),
          comments,
        });
        onSuccess();
      } catch (e) {
        onError(e);
      }
      setSaving(false);
    }
  };

  const renderEntry = () => (
    <Grid container className={classes.container} spacing={2}>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <HauxInput
            label={`Selecione o tipo de ${
              tab === 0 ? "entrada" : "saída"
            } a ser lançada`}
            fullWidth
            autocomplete
            autocompleteProps={{
              options: getFinanceTypes().map((f) => ({
                name: `${f.identifier} - ${f.title}` || "Sem nome",
                value: f._id,
              })),
              getOptionLabel: (option) => option.name || "",
            }}
            value={financeType.name || ""}
            onChange={(_e, newValue) =>
              dispatch(setData({ financeType: newValue || {} }))
            }
            error={!_.isEmpty(errors.financeType)}
            errorMsg={errors.financeType}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={8}>
          <HauxInput
            label="Descrição"
            fullWidth
            value={description}
            onChange={(e) => dispatch(setData({ description: e.target.value }))}
            error={!_.isEmpty(errors.description)}
            errorMsg={errors.description}
          />
        </Grid>
        <Grid item xs={4}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Data da competência"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            fullWidth
            value={moment(competencyDate)}
            onChange={(d) => dispatch(setData({ competencyDate: moment(d) }))}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={3}>
          <HauxInput
            label="Forma de pagamento"
            fullWidth
            autocomplete
            autocompleteProps={{
              options: PAYMENT_TYPE,
              getOptionLabel: (option) => option,
            }}
            value={paymentType}
            onChange={(_e, newValue) =>
              dispatch(
                setData({ paymentType: newValue !== null ? newValue : "" })
              )
            }
            error={!_.isEmpty(errors.paymentType)}
            errorMsg={errors.paymentType}
          />
        </Grid>
        <Grid item xs={3}>
          <HauxInput
            label="Valor"
            fullWidth
            value={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value || 0)}
            onChange={(e) =>
              dispatch(
                setData({
                  value:
                    parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0,
                })
              )
            }
            error={!_.isEmpty(errors.value)}
            errorMsg={errors.value}
          />
        </Grid>
        <Grid item xs={3}>
          <HauxInput
            label="Desconto"
            fullWidth
            value={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(discount || 0)}
            onChange={(e) =>
              dispatch(
                setData({
                  discount:
                    parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0,
                })
              )
            }
            error={!_.isEmpty(errors.discount)}
            errorMsg={errors.discount}
          />
        </Grid>
        <Grid item xs={3}>
          <HauxInput
            label="Valor Final"
            fullWidth
            value={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(finalValue || 0)}
            onChange={(e) =>
              dispatch(
                setData({
                  finalValue:
                    parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0,
                })
              )
            }
            error={!_.isEmpty(errors.finalValue)}
            errorMsg={errors.finalValue}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={3}>
          <HauxInput
            label="Número de parcelas"
            fullWidth
            type="number"
            value={installments || ""}
            onChange={(e) =>
              dispatch(
                setData({
                  installments: parseInt(e.target.value.replace(/\D/g, ""), 10),
                })
              )
            }
            onBlur={() => {
              if (_.isNaN(installments) || installments <= 0) {
                dispatch(setData({ installments: 1 }));
              }
            }}
            error={!_.isEmpty(errors.installments)}
            errorMsg={errors.installments}
          />
        </Grid>
        <Grid item xs={3}>
          <HauxInput
            label="Valor da parcela"
            fullWidth
            value={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(installmentValue)}
            onChange={(e) =>
              dispatch(
                setData({
                  installmentValue:
                    parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0,
                })
              )
            }
            error={!_.isEmpty(errors.installmentValue)}
            errorMsg={errors.installmentValue}
          />
        </Grid>
        <Grid item xs={3}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Vencimento da 1ª Parcela"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            fullWidth
            value={moment(installmentDate)}
            onChange={(d) => dispatch(setData({ installmentDate: moment(d) }))}
          />
        </Grid>
        <Grid item xs={3}>
          <HauxInput
            label="Número NF"
            fullWidth
            value={nf || ""}
            onChange={(e) =>
              dispatch(
                setData({
                  nf: e.target.value,
                })
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Observações"
            multiline
            fullWidth
            className={classes.contentText}
            inputProps={{ maxLength: 200 }}
            value={comments}
            onChange={(e) => dispatch(setData({ comments: e.target.value }))}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderExit = () => (
    <Grid container className={classes.container} spacing={2}>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <HauxInput
            label={`Selecione o tipo de ${
              tab === 0 ? "entrada" : "saída"
            } a ser lançada`}
            fullWidth
            autocomplete
            autocompleteProps={{
              options: getFinanceTypes().map((f) => ({
                name: `${f.identifier} - ${f.title}` || "Sem nome",
                value: f._id,
              })),
              getOptionLabel: (option) => option.name || "",
            }}
            value={financeType.name || ""}
            onChange={(_e, newValue) =>
              dispatch(setData({ financeType: newValue || {} }))
            }
            error={!_.isEmpty(errors.financeType)}
            errorMsg={errors.financeType}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12}>
          <HauxInput
            label="Descrição"
            fullWidth
            value={description}
            onChange={(e) => dispatch(setData({ description: e.target.value }))}
            error={!_.isEmpty(errors.description)}
            errorMsg={errors.description}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={4}>
          <HauxInput
            label="Valor"
            fullWidth
            value={Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value)}
            onChange={(e) =>
              dispatch(
                setData({
                  value:
                    parseInt(e.target.value.replace(/\D/g, ""), 10) / 100.0,
                })
              )
            }
            error={!_.isEmpty(errors.value)}
            errorMsg={errors.value}
          />
        </Grid>
        <Grid item xs={4}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Data da competência"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            fullWidth
            value={moment(competencyDate)}
            onChange={(d) => dispatch(setData({ competencyDate: moment(d) }))}
          />
        </Grid>
        <Grid item xs={4}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            label="Data do pagamento"
            format="DD/MM/YYYY"
            InputAdornmentProps={{ position: "end" }}
            fullWidth
            value={moment(installmentDate)}
            onChange={(d) => dispatch(setData({ installmentDate: moment(d) }))}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Observações"
            multiline
            fullWidth
            className={classes.contentText}
            inputProps={{ maxLength: 200 }}
            value={comments}
            onChange={(e) => dispatch(setData({ comments: e.target.value }))}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={4} className={classes.alignVertical}>
          <HauxCheckbox
            checked={isRecurring}
            handleChange={() =>
              dispatch(setData({ isRecurring: !isRecurring }))
            }
          />
          Está é uma despesa fixa
        </Grid>
        {isRecurring && (
          <>
            <Grid item xs={2}>
              <HauxInput
                label="Recorrente por"
                type="number"
                value={installments || ""}
                onChange={(e) =>
                  dispatch(
                    setData({
                      installments: parseInt(
                        e.target.value.replace(/\D/g, ""),
                        10
                      ),
                    })
                  )
                }
                onBlur={() => {
                  if (_.isNaN(installments) || installments <= 0) {
                    dispatch(setData({ installments: 1 }));
                  }
                }}
                error={!_.isEmpty(errors.installmentValue)}
                errorMsg={errors.installmentValue}
              />
            </Grid>
            <Grid item xs={2} className={classes.alignVertical}>
              meses
            </Grid>
            <Grid item xs={12}>
              <span className="hintText">
                Os próximos vencimentos possuem intervalo fixo de 30 dias.
              </span>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (!tab) {
      return renderEntry();
    }
    return renderExit();
  };

  return (
    <>
      <div>
        <StyledTabs
          variant="fullWidth"
          value={tab}
          onChange={(_event, newTab) => dispatch(setTab(newTab))}
          aria-label="nav tabs example"
        >
          <StyledTab label="Entrada" />
          <StyledTab label="Saída" />
        </StyledTabs>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {renderContent()}
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.footer}>
        <HauxButton
          text="Cancelar"
          type="secondary"
          onClick={() => {
            AnalyticsManager.track(
              "Clinic_finances create_button_cancel clicked"
            );
            onClose();
          }}
        />
        <HauxButton text="Salvar lançamento" onClick={save} />
      </div>
      <Backdrop className={classes.backdrop} open={saving}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

Create.propTypes = {
  franchise: PropTypes.string,
  financeTab: PropTypes.number,
  onChangeFinanceTab: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  handleRequestsErrors: PropTypes.func,
};

Create.defaultProps = {
  onClose: () => {},
  onSuccess: () => {},
  onError: () => {},
  handleRequestsErrors: () => {},
};

export default Create;
