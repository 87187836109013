/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './hauxConfirmDialog.scss';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import HAUXButton from '../HauxButton';

const styles = (theme) => ({
  root: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
    padding: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className="dialogTitle">{children}</div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    marginLeft: 32,
    marginRight: 32,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    marginBottom: 32,
    marginLeft: 32,
    marginRight: 32,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const HauxConfirmDialog = ({
  title, message, open, handleClose, handleConfirm, isDestructive,
}) => {
  const renderButtons = () => {
    if (isDestructive) {
      return (
        <DialogActions>
          <HAUXButton
            className="destructive"
            onClick={handleClose}
            text="Não"
            type="tertiary"
          />
          <HAUXButton
            className="destructive"
            onClick={handleConfirm}
            text="Sim"
          />
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <HAUXButton
          onClick={handleClose}
          text="Não"
          type="tertiary"
        />
        <HAUXButton
          onClick={handleConfirm}
          text="Sim"
          type="tertiary"
        />
      </DialogActions>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div className="paragraph">{message}</div>
        </DialogContent>
        {renderButtons()}
      </Dialog>
    </div>
  );
};

HauxConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  isDestructive: PropTypes.bool,
};

HauxConfirmDialog.defaultProps = {
  title: '',
  message: '',
  open: false,
  handleClose: () => {},
  handleConfirm: () => {},
  isDestructive: false,
};

export default HauxConfirmDialog;
