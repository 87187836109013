/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './hauxDropdown.scss';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { isUndefined } from 'lodash';

const CustomForm = withStyles({
  root: {
    fontFamily: 'Graviola',
    color: '#737373',

    '& label, & .MuiInput-root': {
      color: '#737373',
      fontFamily: 'Graviola',
    },
    '& label': {
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: '#685DA8',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#685DA8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#685DA8',
      },
    },
  },
})(FormControl);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 64,
  },
  labelInput: {
    fontFamily: 'Graviola',
    '&:focus': {
      color: '#685DA8',
    },
  },
  selectRoot: {
    fontFamily: 'Graviola',
    '&:focus': {
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
}));

const HauxDropdown = ({
  value, handleChange, label,
  options, error, fullWidth,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div>
      <CustomForm
        className={fullWidth ? `${classes.formControl} ${classes.fullWidth}` : classes.formControl}
        disabled={disabled}
      >
        {label && <InputLabel className={classes.labelInput}>{label}</InputLabel>}
        <Select
          className={fullWidth ? classes.fullWidth : ''}
          labelId={error ? 'demo-simple-select-error-label' : 'demo-simple-select-label'}
          id={error ? 'demo-simple-select-error' : 'demo-simple-select'}
          value={value}
          onChange={handleChange}
        >
          {options.map((
            option, index,
          ) => (
            <MenuItem
              value={isUndefined(option.value) ? option : option.value}
              key={index.toString()}
              className={classes.labelInput}
            >
              {option.label || option}
            </MenuItem>
          ))}
        </Select>
      </CustomForm>
    </div>
  );
};

HauxDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

HauxDropdown.defaultProps = {
  label: '',
  options: [],
  value: '',
  handleChange: () => { },
  error: false,
  fullWidth: false,
  disabled: false,
};

export default HauxDropdown;
