import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as AcLogout } from '../../assets/svgs/ac-logout.svg';
import { ReactComponent as AcChevronLeft } from '../../assets/svgs/ac-chevron-left.svg';
import { getCurrentUser, cleanUserAndToken } from '../../utils/Utils';
import AnalyticsManager from '../../AnalyticsManager';
import ViewContext from '../../ViewContext';
import './hauxHeader.scss';

const useStyles = makeStyles(() => ({
  headerContainer: {
    backgroundColor: '#685DA8',
    height: 180,
  },
  headerContentWrapper: {
    maxWidth: 1156,
    height: 80,
  },
  userInfoItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 16,
  },
}));

const HauxHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { routeProperties } = useContext(ViewContext);

  const logout = () => {
    AnalyticsManager.track('Header button_logout clicked');
    cleanUserAndToken();
    window.location.reload();
  };

  return (
    <Grid container className={classes.headerContainer} justify="center" alignItems="center">
      <Grid item container xs={10} justify="space-between" alignItems="center" className={classes.headerContentWrapper}>
        <Grid item xs={6} container direction="column">
          <Grid item>
            <span className="pageNameText">{routeProperties.pageTitle}</span>
          </Grid>
          {routeProperties.pageSubtitle
            ? (
              <Grid item>
                <span className="pageSubtitleText">{routeProperties.pageSubtitle}</span>
              </Grid>
            ) : null}
          {routeProperties.breadcrumbs
            && (
              <Grid item>
                <button
                  type="button"
                  className="breadcrumbs"
                  onClick={routeProperties.breadcrumbsAction
                    ? routeProperties.breadcrumbsAction
                    : () => history.goBack()}
                >
                  <div>
                    <AcChevronLeft />
                    <span>{routeProperties.breadcrumbs}</span>
                  </div>
                </button>
              </Grid>
            )}
        </Grid>
        <Grid item xs={4} container justify="space-evenly" alignItems="flex-start">
          <Grid item xs container direction="column" justify="flex-end">
            <Grid item className={classes.userInfoItem}>
              <span className="routeText">
                {getCurrentUser() ? getCurrentUser().name : ''}
              </span>
            </Grid>
            <Grid item className={classes.userInfoItem}>
              <span className="userProfession">
                {getCurrentUser() ? getCurrentUser().profileType : ''}
              </span>
            </Grid>
          </Grid>
          {location && location.pathname !== '/login' && (
            <Grid item>
              <button
                type="button"
                className="logoutButton"
                onClick={() => {
                  AnalyticsManager.track('Header button_logout clicked');
                  logout();
                  window.location.reload();
                }}
              >
                <div>
                  <AcLogout />
                  <span>SAIR</span>
                </div>
              </button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(HauxHeader);
