import React, {
  useEffect, useCallback, useReducer,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import strings from '../../../../assets/strings.json';
import { ReactComponent as AcChange } from '../../../../assets/svgs/ac-change-local.svg';
import { ReactComponent as AcExportXls } from '../../../../assets/svgs/ac-export-xls.svg';
import { ReactComponent as AcAddCircle } from '../../../../assets/svgs/ac-add-circle.svg';
import { ReactComponent as AcFilter } from '../../../../assets/svgs/ac-filter.svg';
import {
  HauxDropdownButton,
  HauxInput,
  HauxSearchList,
  HauxFilterDropdown,
  Grid,
} from '../../../../components';
import {
  setPayload, exportItems, getCurrentUser,
} from '../../../../utils/Utils';
import AnalyticsManager from '../../../../AnalyticsManager';
import ClientService from '../../../../service/ClientService';
import { reducer, initialState } from './reducer';
import useDebounce from '../../../../hooks/useDebounce';

const {
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DATE_FORMATS,
  CLINIC,
} = strings;

const newButtonOptions = ['Cliente', 'Pacote'];

const filterOptions = [
  { label: 'Ativos', name: 'active' },
  { label: 'Inativos', name: 'inactive' },
  { label: 'Somente inativações próximas', name: 'next' },
  { label: 'Somente inativações recentes', name: 'recent' },
];
const columns = ['Nome', 'Pacotes', 'Programa',
  'Status', 'Data inatividade', 'Telefone',
  'E-mail', 'Canal', 'Data de nascimento',
  'Cpf', 'Sexo biológico', 'Cidade', 'Estado',
];

const useStyles = makeStyles(() => ({
  headerBottonContainer: {
    marginLeft: -10,
    marginRight: -10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientsContainer: {
    flexDirection: 'column',
    padding: '0px 32px',
  },
  checkBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  leftSpace: {
    marginLeft: 16,
  },
  exportContainer: {
    marginBottom: 16,
    marginRight: -10,
  },
}));

export default function ClientsTab({
  handleRequestErrors, openCurrentContractPDF,
  handleChangeRegister, handleSnackbar,
  handleChangeExportAction,
}) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    clients, clientsCount, searchText,
    warnings, currentClinic, userClinics,
    selectedClients, loading, inactives,
    filters, page, totalPages,
  } = state;

  useEffect(() => {
    async function initialPageLoad() {
      try {
        const infos = await ClientService.getClientInfoWarning();

        dispatch(setPayload('INITIAL_PAGE_LOAD', {
          userClinics: getCurrentUser().franchises,
          warnings: infos.warnings,
          inactives: infos.inactives,
          loading: false,
        }));
      } catch (e) {
        handleRequestErrors(e);
      }
    }

    initialPageLoad();
  }, [handleRequestErrors]);

  const debouncedSearchText = useDebounce(searchText, 300);
  const getClients = useCallback(async (sort) => {
    dispatch(setPayload('SET_LOADING', true));
    try {
      const response = await ClientService.getPaginatedClients(
        page, debouncedSearchText, filters.active, filters.inactive, sort,
      );

      dispatch(setPayload('SET_PAGINATION_RESULTS', response));
    } catch (e) {
      handleRequestErrors(e);
    } finally {
      dispatch(setPayload('SET_LOADING', false));
    }
  }, [filters, page, debouncedSearchText, handleRequestErrors]);

  useEffect(() => {
    getClients(1);
  }, [getClients]);

  const handleChangeExport = useCallback(async (option) => {
    AnalyticsManager.track('Client button_export_data clicked');
    dispatch(setPayload('SET_LOADING', true));
    const clientsExport = [];
    let clientsByFilter = [];
    if (option === 'Todos os clientes') {
      clientsByFilter = await ClientService.getClients();
    } else if (option === 'Clientes ativos') {
      clientsByFilter = await ClientService.getClients(1);
    } else if (option === 'Clientes inativos') {
      clientsByFilter = await ClientService.getClients(0, 1);
    } else {
      clientsByFilter = selectedClients;
    }
    clientsByFilter.forEach((client) => {
      const updatedDate = (client.updatedStatusAt && !client.active) ? moment(client.updatedStatusAt).format(DATE_FORMATS.NORMAL) : '';
      clientsExport.push([
        client.name,
        client.contracts && client.contracts.length ? client.contracts[client.contracts.length - 1].date : '',
        client.program ? client.program.tag : '',
        client.active ? 'Ativo' : 'Inativo',
        updatedDate,
        client.phone,
        client.email,
        client.sourceChannel,
        client.birthDate,
        client.cpf,
        client.biologicalSex,
        client.address ? client.address.city : '',
        client.address ? client.address.state : '',
      ]);
    });
    const dataSet = [
      {
        columns,
        data: clientsExport,
      },
    ];
    handleChangeExportAction(option, dataSet);
    dispatch(setPayload('SET_LOADING', false));
  }, [selectedClients, handleChangeExportAction]);

  const updateClientActive = useCallback(async (item, index) => {
    try {
      await ClientService.updateClientStatus(item.clientId, !item.active);
      const newClients = [...clients];
      newClients[index].active = !newClients[index].active;
      dispatch(setPayload('UPDATE_CLIENTS_LIST', newClients));
    } catch (error) {
      handleRequestErrors(error, { type: 'error', message: DELETE_CLIENT_FAIL });
    }
  }, [clients, handleRequestErrors]);

  const deleteClientInfo = useCallback(async (id, index) => {
    try {
      await ClientService.deleteCient(id);
      const newClients = [...clients];
      newClients.splice(index, 1);
      dispatch(setPayload('UPDATE_CLIENTS_LIST', newClients));
      handleSnackbar({ type: 'success', message: DELETE_CLIENT_SUCCESS });
    } catch (error) {
      handleRequestErrors(error, { type: 'error', message: DELETE_CLIENT_FAIL });
    }
  }, [clients, handleSnackbar, handleRequestErrors]);

  const handleClinicChange = useCallback((_, index) => {
    AnalyticsManager.track('Client button_currentClinic clicked');
    dispatch(setPayload('CHANGE_CLINIC', index));
  }, []);

  const clinicOptions = useMemo(() => userClinics.map((clinic) => clinic.name), [userClinics]);

  const renderButtonClinics = () => (
    <>
      {userClinics.length && userClinics.length > 1
        ? (
          <Grid item xs={3}>
            <HauxDropdownButton
              label={currentClinic.name || CLINIC}
              options={clinicOptions}
              Icon={AcChange}
              handleChange={handleClinicChange}
            />
          </Grid>
        )
        : null}
    </>
  );

  const handleFilters = useCallback((event) => {
    AnalyticsManager.track('Client button_filter clicked', filters);
    const { name, checked } = event.target;
    if (name === filterOptions[2].name && checked && !filters.active) {
      const newFilters = {
        ...filters,
        [name]: checked,
        active: true,
      };
      dispatch(setPayload('CHANGE_FILTERS', newFilters));
    } else if (name === filterOptions[3].name && checked && !filters.inactive) {
      const newFilters = {
        ...filters,
        [name]: checked,
        inactive: true,
      };
      dispatch(setPayload('CHANGE_FILTERS', newFilters));
    } else {
      dispatch(setPayload('CHANGE_FILTERS', { ...filters, [name]: checked }));
    }
  }, [filters]);

  const filterClients = useCallback(() => {
    let filteredClients = [...clients];

    if (filters.next) {
      const nextToInactive = warnings
        .map((warning) => clients
          .find((client) => warning.id === client.clientId));

      filteredClients = nextToInactive
        .filter((client) => {
          if (client.active) {
            return !!nextToInactive.find((el) => el.clientId === client.clientId);
          }

          return true;
        });
    }

    if (filters.recent) {
      const recentInactive = clients
        .filter((client) => (inactives.find((inactive) => inactive.id === client.clientId)));
      filteredClients = filteredClients
        .filter((client) => {
          if (!client.active) {
            return !!recentInactive.find((el) => el.clientId === client.clientId);
          }

          return true;
        });
    }

    if (!currentClinic) {
      return filteredClients;
    }

    filteredClients = filteredClients
      .filter((client) => client.franchises && client.franchises[0].slug === currentClinic.slug);

    return filteredClients;
  }, [clients, currentClinic, filters.next, filters.recent, inactives, warnings]);

  return (
    <>
      <Grid container className={classes.clientsContainer}>
        <Grid item container className={classes.headerBottonContainer}>
          {renderButtonClinics()}
          <Grid item xs container justify="flex-end">
            <HauxDropdownButton
              label="NOVO"
              options={newButtonOptions}
              Icon={AcAddCircle}
              handleChange={handleChangeRegister}
              className="CTAPrimaryButton"
            />
          </Grid>
        </Grid>
        <Grid container justify="space-between" direction="column" className={classes.headerContainer}>
          <Grid item xs={4}>
            <HauxInput
              label="Buscar cliente"
              fullWidth
              value={searchText}
              onChange={(e) => {
                dispatch(setPayload('SET_SEARCH_TEXT', e.target.value));
              }}
            />
          </Grid>
          <Grid item container justify="flex-end" xs={8}>
            <Grid item>
              <HauxFilterDropdown
                options={filterOptions}
                Icon={AcFilter}
                label="Filtrar por"
                filters={filters}
                onChange={handleFilters}
              />
            </Grid>
            <Grid item className={classes.exportContainer}>
              <HauxDropdownButton
                label="Exportar dados"
                options={exportItems}
                handleChange={handleChangeExport}
                Icon={AcExportXls}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <HauxSearchList
          data={filterClients()}
          page={page}
          loading={loading}
          dispatch={dispatch}
          updateStatus={updateClientActive}
          getClients={getClients}
          totalPages={totalPages}
          openCurrentContractPDF={openCurrentContractPDF}
          totalItems={clientsCount}
          deleteClient={deleteClientInfo}
          selectedClients={selectedClients}
          warnings={warnings}
          inactives={inactives}
        />
      </Grid>
    </>
  );
}

ClientsTab.propTypes = {
  handleRequestErrors: PropTypes.func.isRequired,
  handleChangeRegister: PropTypes.func.isRequired,
  openCurrentContractPDF: PropTypes.func.isRequired,
  handleSnackbar: PropTypes.func.isRequired,
  handleChangeExportAction: PropTypes.func.isRequired,
};
