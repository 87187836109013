export const initialState = {
  cart: [],
  plans: [],
};

const addToCart = (cart, plan) => {
  const filteredCart = cart.filter((cartItem) => cartItem.plan.planId === plan.planId);
  if (filteredCart.length > 0) {
    const cartItem = filteredCart[0];
    cartItem.quantity += 1;
    return [...cart];
  }

  return [...cart, {
    quantity: 1,
    plan,
  }];
};

const subtractFromCart = (cart, plan) => {
  const filteredCart = cart.filter((cartItem) => cartItem.plan.planId === plan.planId);
  if (filteredCart.length > 0) {
    const cartItem = filteredCart[0];
    cartItem.quantity -= 1;
    return [...cart.filter((item) => item.quantity > 0)];
  }

  return cart;
};

const removeFromCart = (cart, plan) => [
  ...cart.filter((cartItem) => cartItem.plan.planId !== plan.planId),
];

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PLANS':
      return {
        ...state,
        plans: action.payload,
      };
    case 'ADD_TO_CART':
      return {
        ...state,
        cart: addToCart(state.cart, action.payload),
      };
    case 'SUBTRACT_FROM_CART':
      return {
        ...state,
        cart: subtractFromCart(state.cart, action.payload),
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cart: removeFromCart(state.cart, action.payload),
      };
    default:
      return {
        ...state,
      };
  }
};
