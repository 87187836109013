/* eslint-disable no-nested-ternary */
import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import './hauxButton.scss';

const getButtonClassType = (className, type, variant) => {
  let classType = 'destructive';
  let classVariant = '';
  if (className === 'CTA') {
    classType = 'CTA';
  }

  if (variant === 'outlined') {
    classVariant = 'Outlined';
  }
  if (variant === 'link') {
    classVariant = 'Link';
  }

  switch (type) {
    case 'secondary':
      return `${classType}${classVariant}SecondaryButton`;
    case 'tertiary':
      return `${classType}${classVariant}TertiaryButton`;
    case 'quaternary':
      return `${classType}${classVariant}QuaternaryButton`;
    case 'snackbarButton':
      return 'snackbarButton';
    default:
      return `${classType}${classVariant}PrimaryButton`;
  }
};

const HAUXButton = ({
  className,
  type,
  variant,
  disabled,
  text,
  fullWidth,
  onClick,
  selectedRoute,
  Icon,
  startIcon,
  endIcon,
}) => {
  const routesArray = ['Clientes', 'Agenda', 'Clínica'];
  const tabsArray = ['Clientes', 'Prospecção'];
  return (
    <div>
      {type === 'link' ? (
        <Button
          size="small"
          className={
            routesArray[selectedRoute - 1] === text
              ? 'linkClicked'
              : 'linkButton'
          }
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          <Icon className="imgStyle" />
          <span className="textStyle">{text}</span>
        </Button>
      ) : type === 'tab' ? (
        <Button
          size="small"
          className={
            tabsArray[selectedRoute - 1] === text
              ? 'tabClicked'
              : 'tabButton'
          }
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          <span className="textStyle">{text}</span>
        </Button>
      ) : type === 'linkWhite' ? (
        <Button
          size="small"
          className="tabClickedWhite"
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          <span className="descriptionWhite">{text}</span>
        </Button>
      ) : (
        <Button
          variant={variant}
          fullWidth={fullWidth}
          className={`${getButtonClassType(className, type, variant)}`}
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          <span className="textStyle">{text}</span>
        </Button>
      )}
    </div>
  );
};

export default HAUXButton;

HAUXButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  selectedRoute: PropTypes.number,
  disabled: PropTypes.bool,
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

HAUXButton.defaultProps = {
  className: 'CTA',
  type: 'primary',
  variant: 'contained',
  fullWidth: false,
  text: '',
  onClick: () => <></>,
  selectedRoute: 0,
  disabled: false,
  Icon: <></>,
  startIcon: undefined,
  endIcon: undefined,
};
