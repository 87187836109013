/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './hauxChip.scss';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  chip: {
    fontFamily: 'Graviola',
    color: '#737373',
    marginRight: 4,
    marginLeft: 4,
  },
}));

const HauxChip = ({
  label,
}) => {
  const classes = useStyles();
  return <Chip label={label} variant="outlined" className={classes.chip} />;
};

HauxChip.propTypes = {
  label: PropTypes.string,
};

HauxChip.defaultProps = {
  label: '',
};

export default HauxChip;
