/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import {
  useField,
  useFormikContext,
} from 'formik';

const FormikFormGroup = ({ children, ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();


  let errors = {};

  if (meta.error && (meta.touched || form.submitCount > 0)) {
    errors = { error: true, helperText: meta.error };
  }


  return (
    <>
      <FormGroup {...field} {...props}>
        {children}
      </FormGroup>
      {errors.error && <div style={{ color: '#F44336', marginTop: 10 }}>{errors.helperText}</div>}
    </>
  );
};

export default FormikFormGroup;
