export const initialState = {
  exitFormDialog: false,
  content: {},
  sendSettings: {},
  touched: false,
  btnDisabled: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EXIT_FORM_DIALOG':
      return {
        ...state,
        exitFormDialog: action.payload,
      };
    case 'CONTENT':
      return {
        ...state,
        content: action.payload,
      };
    case 'SEND_SETTINGS':
      return {
        ...state,
        sendSettings: action.payload,
      };
    case 'TOUCHED':
      return {
        ...state,
        touched: action.payload,
      };
    case 'BTN_DISABLED':
      return {
        ...state,
        btnDisabled: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
