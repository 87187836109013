/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import icClose from '../../assets/svgs/ic-close.svg';

import './HauxPresentationModal.scss';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const renderWidth = () => {
  let { innerWidth } = window;

  if (innerWidth > 1024) {
    innerWidth -= 400;
  } else {
    innerWidth -= 100;
  }

  return innerWidth;
};

const HauxPresentationModal = ({
  open, handleClose, link,
}) => {
  const [pages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <Modal
        className="modal-presentation"
        open={open}
        onClose={() => {
          handleClose();
          setPageNumber(1);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="paper-modal">
            <div className="icon-container">
              <Button
                onClick={() => {
                  handleClose();
                  setPageNumber(1);
                }}
              >
                <img src={icClose} alt="" />
              </Button>
            </div>

            <div className="haux__container__document">
              <Document
                file={{ url: link }}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                <Page
                  width={renderWidth()}
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                />

                <div className="page-controls">
                  <button
                    disabled={pageNumber <= 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    type="button"
                    aria-label="Previous page"
                  >
                    ‹
                  </button>
                  <span>
                    {pageNumber}
                    {' '}
                    de
                    {' '}
                    {pages}
                  </span>
                  <button
                    disabled={pageNumber >= pages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    type="button"
                    aria-label="Next page"
                  >
                    ›
                  </button>
                </div>
              </Document>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

HauxPresentationModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  link: PropTypes.string,
};

HauxPresentationModal.defaultProps = {
  open: false,
  handleClose: () => {},
  link: '',
};

export default HauxPresentationModal;
