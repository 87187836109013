/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NumberFormat from 'react-number-format';
import Input from '../Input';

function CurrencyInput(props) {
  return (
    <NumberFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      {...props}
    />
  );
}

export default CurrencyInput;
