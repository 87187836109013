/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './hauxTab.scss';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConsultasSVG from '../../assets/svgs/ic-consultation.svg';
import TrainingSVG from '../../assets/svgs/ic-training.svg';
import LogoSVG from '../../assets/svgs/ic-logo.svg';
import MaisVidaSVG from '../../assets/svgs/ic-mais-vida-menu.svg';

const TAB_HEIGHT = 74;

const ROUTES = ['consults', 'moreLife', 'training', 'tour'];

export const valueToRoute = (value) => {
  if (value < 0 || value > ROUTES.length - 1) {
    return ROUTES[0];
  }

  return ROUTES[value];
};

export const routeToValue = (route) => {
  const index = ROUTES.indexOf(route);

  return index >= 0 ? index : 0;
};

// eslint-disable-next-line react/prop-types
const LabelTab = ({ label, image }) => (
  <div className="tabLabelWrapper">
    <img src={image} alt={label} />
    <h1 className="tabTitle">{label}</h1>
  </div>
);

const StyledTabs = withStyles({
  root: {
    height: TAB_HEIGHT,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#685DA8',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    height: TAB_HEIGHT,
    marginRight: theme.spacing(1),
    opacity: 1,
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => (
  <Tab
    {...props}
    label={<LabelTab label={props.label} image={props.image} />}
  />
));

const HAUXTab = ({ onChange, route }) => {
  const handleChange = (event, newValue) => {
    onChange(valueToRoute(newValue));
  };

  return (
    <div className="tabContainer">
      <StyledTabs
        variant="fullWidth"
        value={routeToValue(route)}
        onChange={handleChange}
        aria-label="nav tabs example"
      >
        <StyledTab label="ENCONTROS HAUX" image={ConsultasSVG} />
        <StyledTab label="HAUX MAIS VIDA" image={MaisVidaSVG} />
        <StyledTab label="HAUX TRAINING" image={TrainingSVG} />
        <StyledTab label="HAUX TOUR" image={LogoSVG} />
      </StyledTabs>
    </div>
  );
};

export default HAUXTab;

HAUXTab.propTypes = {
  onChange: PropTypes.func,
  route: PropTypes.string,
};

HAUXTab.defaultProps = {
  onChange: () => <></>,
  route: '',
};
