import React, {
  useEffect, useReducer, useContext, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router-dom';
import strings from '../../../../assets/strings.json';
import pdfs from '../../../../assets/pdfs/index';
import { ReactComponent as IcChallengeNutrition } from '../../../../assets/svgs/ic-challenge-nutrition.svg';
import { ReactComponent as IcFoodNutrition } from '../../../../assets/svgs/ic-food-nutrition.svg';
import { ReactComponent as IcHistoric } from '../../../../assets/svgs/ic-historic.svg';
import HAUXButton from '../../../../components/HauxButton';
import HauxExpansionMeetingPanel from '../../../../components/HauxExpansionMeetingPanel';
import HauxFormModal from '../../../../components/HauxFormModal';
import HauxPdfModal from '../../../../components/HauxPdfModal';
import HauxRadio from '../../../../components/HauxRadio';
import HauxInput from '../../../../components/HauxInput';
import MeetingsHeader from '../../components/MeetingsHeader';
import MeetingService from '../../../../service/MeetingService';
import AnalyticsManager from '../../../../AnalyticsManager';
import ClientContext from '../../../../ClientContex';
import ViewContext from '../../../../ViewContext';
import ChallangeItem from './challengeItem';
import { getNutritionistForm, setPayload, getCurrentUser } from '../../../../utils/Utils';
import {
  yesOrNo, chewingOptionsArray,
  intestinalFunctionOptionsArray, appMealsArray,
} from './constants';
import { initialState, reducer } from './reducer';

const {
  nutritionPdf1, nutritionPdf2, nutritionPdf3,
  nutritionPdf4, nutritionPdf5, nutritionPdf6,
  nutritionPdf7, nutritionPdf8, nutritionPdf9,
} = pdfs;

const useStyles = makeStyles(() => ({
  rootContainer: {
    position: 'relative',
    top: -40,
    maxWidth: 1156,
  },
  headerContainer: {
    maxHeight: 200,
  },
  primaryButtonContainer: {
    margin: -10,
  },
  evaluationButtonContainer: {
    marginRight: 8,
  },
  itemsContainer: {
    marginBottom: 24,
  },
  sectionMargin: {
    marginTop: 40,
  },
  difficultiesContainer: {
    marginTop: 24,
  },
  importantQuestions: {
    marginRight: 16,
  },
  foodTypesContainer: {
    marginLeft: 16,
  },
  titleIcons: {
    marginRight: 8,
  },
}));

export default function NutritionMeetings() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { client } = useContext(ClientContext);
  const user = getCurrentUser();
  const {
    setOpenChat, handleRequestsErrors, setRouteProperties, setLoading,
    setSnackbarOption,
  } = useContext(ViewContext);
  const {
    clientMeetings, clientFeelings, notes, waterChallenge,
    fryFreeChallenge, realFoodChallenge, sugarFreeChallenge,
    appMeals, difficulties, chewingSpeed, foodTypes, heartburn,
    gastritis, nausea, vomiting, evacuationFrequency, intestinalFunction,
    editable, openModal, modalData, openFirst,
  } = state;

  useEffect(() => {
    setRouteProperties({
      pageTitle: 'Acompanhamento Nutricional',
      breadcrumbs: 'Dashboard',
    });
  }, [setRouteProperties]);

  useEffect(() => {
    if (location.state && Object.keys(location.state).length) {
      if (location.state.openMeeting) {
        dispatch(setPayload('EXPAND_PANNEL', clientMeetings.length));
      }

      if (location.state.isAtHome) {
        setOpenChat(true);
      }
    }
  }, [location.state, clientMeetings, setOpenChat]);

  const challenges = [
    {
      challengeLabel: 'WATER CHALLENGE - Água',
      checkedState: waterChallenge,
      handleChange: (value) => dispatch(setPayload('CHANGE_WATER_CHALLENGE', value)),
      challengeKey: 'waterChallenge',
    },
    {
      challengeLabel: 'FRY FREE CHALLENGE - Sem fritura',
      checkedState: fryFreeChallenge,
      handleChange: (value) => dispatch(setPayload('CHANGE_FRY_CHALLENGE', value)),
      challengeKey: 'fryFreeChallenge',
    },
    {
      challengeLabel: 'REAL FOOD CHALLENGE - Comida de verdade',
      checkedState: realFoodChallenge,
      handleChange: (value) => dispatch(setPayload('CHANGE_FOOD_CHALLENGE', value)),
      challengeKey: 'realFoodChallenge',
    },
    {
      challengeLabel: 'SUGAR FREE CHALLENGE - Sem açúcar',
      checkedState: sugarFreeChallenge,
      handleChange: (value) => dispatch(setPayload('CHANGE_SUGAR_CHALLENGE', value)),
      challengeKey: 'sugarFreeChallenge',
    },
  ];

  useEffect(() => {
    AnalyticsManager.track('Nutritionist meeting view');
    setLoading(true);
    MeetingService
      .getAllNutritionMeetingsByClient(client.clientId)
      .then((res) => dispatch(setPayload('INITIAL_LOAD', res)))
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  }, [client, handleRequestsErrors, setLoading]);

  useEffect(() => {
    if (editable >= 0 && editable < clientMeetings.length) {
      dispatch(setPayload('SET_EDITABLE_DATA', editable));
    }
  }, [clientMeetings, editable]);

  async function saveNutritionMeeting(index) {
    try {
      setLoading(true);
      if (!index) {
        await MeetingService
          .postNewNutritionMeeting({
            client: client.clientId,
            clientFeelings,
            notes,
          });
      } else {
        await MeetingService
          .postNewNutritionMeeting({
            client: client.clientId,
            clientFeelings,
            notes,
            waterChallenge,
            fryFreeChallenge,
            realFoodChallenge,
            sugarFreeChallenge,
            appMeals,
            difficulties,
            chewingSpeed,
            foodTypes,
            heartburn,
            gastritis,
            nausea,
            vomiting,
            evacuationFrequency,
            intestinalFunction,
          });
      }
      setSnackbarOption({
        open: true,
        type: 'success',
        message: `Encontro ${index + 1} salvo com sucesso!`,
      });
      setTimeout(() => history.goBack(), 2000);
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function editNutritionMeeting(index) {
    try {
      setLoading(true);
      let updatedFollowUp = {
        ...clientMeetings[index],
        ...{ notes, clientFeelings },
      };
      if (index) {
        updatedFollowUp = {
          ...updatedFollowUp,
          waterChallenge,
          fryFreeChallenge,
          realFoodChallenge,
          sugarFreeChallenge,
          appMeals,
          difficulties,
          chewingSpeed,
          foodTypes,
          heartburn,
          gastritis,
          nausea,
          vomiting,
          evacuationFrequency,
          intestinalFunction,
        };
      }
      await MeetingService
        .putNutritionMeeting(updatedFollowUp);
      const meetingsListUpdated = [...clientMeetings];
      meetingsListUpdated.splice(index, 1, updatedFollowUp);
      dispatch(setPayload('UPDATE_MEETING', {
        clientMeetings: meetingsListUpdated,
      }));
      setSnackbarOption({
        open: true,
        type: 'success',
        message: `Encontro ${index + 1} editado com sucesso!`,
      });
    } catch (error) {
      handleRequestsErrors(error);
    } finally {
      setLoading(false);
    }
  }

  function renderChallenges(index) {
    return challenges.map((challenge) => (
      <ChallangeItem
        key={challenge.challengeLabel}
        challenge={challenge}
        index={index}
        editable={editable}
        clientMeetings={clientMeetings}
      />
    ));
  }

  function renderImportantQuestionsSection(index) {
    return (
      <>
        <Grid item container alignItems="center" className={`${classes.difficultiesContainer}`}>
          <Grid item className={classes.titleIcons}>
            <IcHistoric />
          </Grid>
          <Grid item>
            <span className="paragraphPurple">Retomando questões importantes</span>
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item>
            <span className={`${classes.importantQuestions} paragraph`}>Como está a mastigação?</span>
          </Grid>
          {chewingOptionsArray.map((option) => (
            <Grid item key={option.value}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  editable !== index && clientMeetings[index] && clientMeetings[index].chewingSpeed
                    ? option.value === clientMeetings[index].chewingSpeed
                    : option.value === chewingSpeed
                }
                handleChange={() => dispatch(setPayload('SET_CHEWING_SPEED', option.value))}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={1}>
            <span className="paragraph">Sente azia?</span>
          </Grid>
          {yesOrNo.map((option) => (
            <Grid item key={option.value} xs={1}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  editable !== index && clientMeetings[index]
                    ? option.value === clientMeetings[index].heartburn
                    : option.value === heartburn
                }
                handleChange={() => dispatch(setPayload('TOGGLE_HEARTBUN', option.value))}
              />
            </Grid>
          ))}
          {heartburn || (clientMeetings[index] && clientMeetings[index].heartburn) ? (
            <Grid item className={classes.foodTypesContainer}>
              <HauxInput
                disabled={!heartburn || (clientMeetings.length > index && editable !== index)}
                placeholder="Com quais alimentos?"
                value={
                  editable !== index && clientMeetings[index] && clientMeetings[index].heartburn
                    ? clientMeetings[index].foodTypes
                    : foodTypes
                }
                onChange={(e) => dispatch(setPayload('SET_FOOD_TYPES', e.target.value))}
                label="Com quais alimentos?"
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={1}>
            <span className="paragraph">Gastrite?</span>
          </Grid>
          {yesOrNo.map((option) => (
            <Grid item key={option.value} xs={1}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  editable !== index && clientMeetings[index]
                    ? option.value === clientMeetings[index].gastritis
                    : option.value === gastritis
                }
                handleChange={() => dispatch(setPayload('TOGGLE_GASTRITIS', option.value))}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={1}>
            <span className="paragraph">Náuseas?</span>
          </Grid>
          {yesOrNo.map((option) => (
            <Grid item key={option.value} xs={1}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  editable !== index && clientMeetings[index]
                    ? option.value === clientMeetings[index].nausea
                    : option.value === nausea
                }
                handleChange={() => dispatch(setPayload('TOGGLE_NAUSEA', option.value))}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={1}>
            <span className="paragraph">Vômitos?</span>
          </Grid>
          {yesOrNo.map((option) => (
            <Grid item key={option.value} xs={1}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  editable !== index && clientMeetings[index]
                    ? option.value === clientMeetings[index].vomiting
                    : option.value === vomiting
                }
                handleChange={() => dispatch(setPayload('TOGGLE_VOMITING', option.value))}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <span className="paragraph">Frequência evatuatória:</span>
          </Grid>
          <Grid item>
            <HauxInput
              disabled={clientMeetings.length > index && editable !== index}
              placeholder="A cada dois dias"
              value={
                (
                  editable !== index
                  && clientMeetings[index]
                  && clientMeetings[index].evacuationFrequency
                )
                  ? clientMeetings[index].evacuationFrequency
                  : evacuationFrequency
              }
              onChange={(e) => dispatch(setPayload('SET_EVACUATION_FREQUENCY', e.target.value))}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center">
          <Grid item xs={2}>
            <span className="paragraph">Funcção intestinal:</span>
          </Grid>
          {intestinalFunctionOptionsArray.map((option) => (
            <Grid item key={option.value} xs={2}>
              <HauxRadio
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={clientMeetings.length > index && editable !== index}
                checked={
                  (
                    editable !== index
                    && clientMeetings[index]
                    && clientMeetings[index].intestinalFunction
                  )
                    ? option.value === clientMeetings[index].intestinalFunction
                    : option.value === intestinalFunction
                }
                handleChange={() => dispatch(setPayload('CHANGE_INTESTINAL_FUNCTION', option.value))}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  function renderExtraInfo(index) {
    return (
      <Grid item container direction="column" className={`${classes.itemsContainer} ${classes.sectionMargin}`}>
        <Grid item className={classes.itemsContainer} container alignItems="center">
          <Grid item className={classes.titleIcons}>
            <IcChallengeNutrition />
          </Grid>
          <Grid item>
            <span className="paragraphPurple">Atingiu a meta em quantos dias?</span>
          </Grid>
        </Grid>
        {renderChallenges(index)}
        <Grid item className={classes.sectionMargin} container alignItems="center">
          <Grid item className={classes.titleIcons}>
            <IcFoodNutrition />
          </Grid>
          <Grid item>
            <span className="paragraphPurple">Como está o seguimento das refeições propostas no aplicativo?</span>
          </Grid>
        </Grid>
        {appMealsArray.map((option) => (
          <Grid item key={option.value}>
            <HauxRadio
              key={option.value}
              label={option.label}
              value={option.value}
              disabled={clientMeetings.length > index && editable !== index}
              checked={
                editable !== index && clientMeetings[index] && clientMeetings[index].appMeals
                  ? option.value === clientMeetings[index].appMeals
                  : option.value === appMeals
              }
              handleChange={() => dispatch(setPayload('CHANGE_APP_MEALS', option.value))}
            />
          </Grid>
        ))}
        <Grid item className={`${classes.difficultiesContainer} ${classes.itemsContainer}`}>
          <span className="paragraph">Teve dificuldades para seguir o programa? Quais?</span>
          <TextField
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={
              (
                editable !== index
                && clientMeetings[index]
                && clientMeetings[index].difficulties
              )
              || difficulties
            }
            inputProps={{
              style: {
                fontSize: 16,
                color: '#737373',
                fontFamily: 'Graviola',
                padding: 16,
              },
            }}
            style={{ marginTop: 8 }}
            onChange={(e) => dispatch(setPayload('SET_DIFFICULTIES', e.target.value))}
            disabled={clientMeetings.length > index && editable !== index}
          />
        </Grid>
        {renderImportantQuestionsSection(index)}
      </Grid>
    );
  }

  function selectPDF(index) {
    switch (index) {
      case 0:
        return nutritionPdf1;
      case 1:
        return nutritionPdf2;
      case 2:
        return nutritionPdf3;
      case 3:
        return nutritionPdf4;
      case 4:
        return nutritionPdf5;
      case 5:
        return nutritionPdf6;
      case 6:
        return nutritionPdf7;
      case 7:
        return nutritionPdf8;
      default:
        return nutritionPdf9;
    }
  }
  const verifyFieldsExtraInfo = () => (
    !(clientFeelings
      && notes
      && waterChallenge
      && fryFreeChallenge
      && realFoodChallenge
      && sugarFreeChallenge
      && appMeals
      && difficulties
      && chewingSpeed
      && evacuationFrequency
      && intestinalFunction)
  );

  const verifyFieldsBasicInfo = () => (
    !(clientFeelings && notes)
  );

  function renderBasicMeeting(index) {
    return (
      <Grid container direction="column">
        <Grid item container justify="space-between">
          {client.franchises[0].slug === 'at_home' && (
            <Grid item xs={4} className={classes.primaryButtonContainer}>
              <HAUXButton
                text="Tele atendimento"
                onClick={() => {
                  setOpenChat(true);
                }}
              />
            </Grid>
          )}
          <Grid container item xs justify="flex-end">
            <Grid item className={classes.evaluationButtonContainer}>
              <HAUXButton
                type="tertiary"
                text="Ver avaliação nutricional"
                onClick={() => {
                  if (client.nutritionAnamnesis && client.nutritionAnamnesis.length) {
                    dispatch(setPayload('MANAGE_MODAL', {
                      openModal: true,
                      modalData: getNutritionistForm(
                        client.nutritionAnamnesis[client.nutritionAnamnesis.length - 1],
                        client,
                      ),
                    }));
                  } else {
                    setSnackbarOption({
                      open: true,
                      type: 'error',
                      message: 'Este cliente ainda não realizou nenhuma avaliação nutricional!',
                      action: (
                        <button
                          className="reloadButton"
                          type="button"
                          onClick={() => history.push('/client/nutritionConsult', { professional: user.name })}
                        >
                          Criar nova
                        </button>
                      ),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.primaryButtonContainer}>
              <HAUXButton
                text="Guia de atendimento"
                onClick={() => {
                  AnalyticsManager.track('Nutrition meeting guide clicked');
                  setOpen(true);
                  setLink(`${window.location.origin.toString()}${selectPDF(index)}`);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item className={`${classes.itemsContainer} ${classes.sectionMargin}`}>
            <span className="paragraph">Dados evolutivos relevantes do cliente</span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={
                (
                  editable !== index
                  && clientMeetings[index]
                  && clientMeetings[index].clientFeelings
                )
                || clientFeelings
              }
              disabled={clientMeetings.length > index && editable !== index}
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(e) => dispatch(setPayload('SET_CLIENT_FEELINGS', e.target.value))}
            />
          </Grid>
          <Grid item className={classes.itemsContainer}>
            <span className="paragraph">Outras anotações</span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              value={
                (
                  editable !== index
                  && clientMeetings[index]
                  && clientMeetings[index].notes
                )
                || notes
              }
              inputProps={{
                style: {
                  fontSize: 16,
                  color: '#737373',
                  fontFamily: 'Graviola',
                  padding: 16,
                },
              }}
              style={{ marginTop: 8 }}
              onChange={(e) => dispatch(setPayload('SET_NOTES', e.target.value))}
              disabled={clientMeetings.length > index && editable !== index}
            />
          </Grid>
          {index ? renderExtraInfo(index) : null}
          <Grid item container direction="row-reverse">
            <Grid item className={classes.primaryButtonContainer}>
              <HAUXButton
                text="Salvar"
                disabled={(clientMeetings.length > index && editable !== index)
                  || index ? verifyFieldsExtraInfo() : verifyFieldsBasicInfo()}
                onClick={() => {
                  if (editable !== index) {
                    saveNutritionMeeting(index);
                  } else {
                    editNutritionMeeting(index);
                  }
                }}
              />
            </Grid>
            {clientMeetings.length > index && editable !== index ? (
              <Grid item>
                <HAUXButton
                  text="Editar"
                  type="tertiary"
                  onClick={() => dispatch(setPayload('SET_EDITABLE', index))}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <HauxPdfModal open={open} link={link} handleClose={() => setOpen(false)} />
      </Grid>
    );
  }

  const nutritionMeetings = [
    {
      name: 'Encontro 1:',
      emphasisTitle: 'Explicando o programa',
      renderDetails: renderBasicMeeting(0),
    },
    {
      name: 'Encontro 2:',
      emphasisTitle: 'Superando desafios',
      renderDetails: renderBasicMeeting(1),
    },
    {
      name: 'Encontro 3:',
      emphasisTitle: 'Entendendo os rótulos dos alimentos',
      renderDetails: renderBasicMeeting(2),
    },
    {
      name: 'Encontro 4:',
      emphasisTitle: 'Refeições pré e pós treino',
      renderDetails: renderBasicMeeting(3),
    },
    {
      name: 'Encontro 5:',
      emphasisTitle: 'Escolhas alimentares',
      renderDetails: renderBasicMeeting(4),
    },
    {
      name: 'Encontro 6:',
      emphasisTitle: 'Combinações alimentares',
      renderDetails: renderBasicMeeting(5),
    },
    {
      name: 'Encontro 7:',
      emphasisTitle: 'Fome emocional',
      renderDetails: renderBasicMeeting(6),
    },
    {
      name: 'Encontro 8:',
      emphasisTitle: 'Dietas da moda',
      renderDetails: renderBasicMeeting(7),
    },
    {
      name: 'Encontro 9:',
      emphasisTitle: 'Passos para uma alimentação saudável',
      renderDetails: renderBasicMeeting(8),
    },
  ];

  return (
    <Grid container direction="column" className={classes.rootContainer}>
      <Grid item className={classes.headerContainer}>
        <MeetingsHeader consultType={strings.PROFESSIONALS.NUTRITIONIST} />
      </Grid>
      <Grid item>
        <HauxExpansionMeetingPanel
          openFirst={openFirst}
          items={nutritionMeetings.slice(0, clientMeetings.length + 1)}
          changeOpenPanelCallback={(index) => {
            if (editable >= 0) {
              dispatch(setPayload('SET_EDITABLE', -1));
            }
            if (index === clientMeetings.length) {
              dispatch(setPayload('RESET_STATE'));
            }
          }}
        />
      </Grid>
      <HauxFormModal
        data={modalData}
        open={openModal}
        handleClose={() => dispatch(setPayload('MANAGE_MODAL', { openModal: false, modalData: {} }))}
      />
    </Grid>
  );
}
