/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import './steps.scss';

import Stretching from './stretching';
import Mobilidade from './mobilidade';
import Power from './power';
import Force from './force';
import Aerobic from './aerobic';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    background: '#fff',
  },
}));

function getSteps() {
  return ['Alongamento', 'Mobilidade', 'Potência', 'Força', 'Aeróbico'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Stretching />;
    case 1:
      return <Mobilidade />;
    case 2:
      return <Power />;
    case 3:
      return <Force />;
    default:
      return <Aerobic />;
  }
}

const TrainingSteps = forwardRef(({
  initialStep,
}, ref) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(initialStep - 1);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  const isStepSkipped = (step) => skipped.has(step);

  useImperativeHandle(ref, () => ({

    back() {
      setActiveStep((prevActiveStep) => (prevActiveStep > 0 ? prevActiveStep - 1 : 0));
    },
    next() {
      if (activeStep >= getSteps().length) {
        return;
      }

      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    },

  }));

  return (

    <div className={classes.root}>
      <Stepper className={classes.label} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
        </div>
      </div>
    </div>
  );
});

TrainingSteps.propTypes = {
  initialStep: PropTypes.number,
};

TrainingSteps.defaultProps = {
  initialStep: 1,
};

export default TrainingSteps;
