import React, { useState, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import ChatDialog from './pages/chat/chatDialog';
import strings from './assets/strings.json';
import AnalyticsManager from './AnalyticsManager';
import { cleanUserAndToken } from './utils/Utils';

const { SECTION_EXPIRED, DEFAULT_ERROR_MESSAGE, RELOAD_PAGE } = strings;

const routes = [
  { name: 'Recepção', path: '/reception' },
  { name: 'Agendas', path: '/schedule/consults' },
  { name: 'Encontros', path: '/meetings' },
  { name: 'Haux mais vida', path: '/hauxMoreLife' },
  { name: 'Haux Training', path: '/training' },
  { name: 'Gestão da clínica', path: '/clinic/clients' },
  { name: 'Haux Company', path: '/company/finances' },
];

function saveCurrentRoute(route) {
  sessionStorage.setItem('currentRoute', route);
}

function loadCurrentRoute() {
  return parseInt(sessionStorage.getItem('currentRoute'), 10);
}

const ViewContext = createContext({});

const clearSnackbarOptions = {
  message: '',
  action: undefined,
  type: 'error',
  open: false,
  onClose: undefined,
};

export function ViewProvider({ children }) {
  const [selected, setSelected] = useState(loadCurrentRoute() || 0);
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [snackbarOption, setSnackbarOption] = useState(clearSnackbarOptions);
  const [routeProperties, setRouteProperties] = useState({
    breadcrumbs: '',
    pageTitle: '',
    pageSubtitle: '',
  });

  const logout = () => {
    AnalyticsManager.track('Logout expired_token');
    cleanUserAndToken();
    setSnackbarOption(clearSnackbarOptions);
    window.location.reload();
  };

  const handleRequestsErrors = useCallback((error, snackbarOptions) => {
    if (error.message.substring(error.message.length - 3) === '401') {
      setSnackbarOption({
        type: 'error',
        message: SECTION_EXPIRED,
        open: true,
        action: (
          <button
            className="reloadButton"
            type="button"
            onClick={() => logout()}
          >
            Ok
          </button>
        ),
        onClose: () => setSnackbarOption(clearSnackbarOptions),
      });
    } else {
      const defaultErrorSnackbar = {
        type: 'error',
        open: true,
        message: DEFAULT_ERROR_MESSAGE,
        action: (
          <button
            className="reloadButton"
            type="button"
            onClick={() => window.location.reload()}
          >
            {RELOAD_PAGE}
          </button>
        ),
        onClose: () => setSnackbarOption(clearSnackbarOptions),
      };
      setSnackbarOption({ ...defaultErrorSnackbar, ...snackbarOptions });
    }
  }, []);

  return (
    <ViewContext.Provider
      value={{
        routes,
        selected,
        setSelected: (newSelected) => {
          setSelected(newSelected);
          saveCurrentRoute(newSelected);
        },
        setOpenChat,
        routeProperties,
        setRouteProperties,
        loading,
        setLoading,
        tokenExpired,
        setTokenExpired,
        handleRequestsErrors,
        setSnackbarOption: (newOptions) => {
          setSnackbarOption({
            onClose: () => setSnackbarOption((s) => ({
              ...s,
              open: false,
            })),
            ...newOptions,
          });
        },
        snackbarOption,
      }}
    >
      <ChatDialog open={openChat} closeChat={() => setOpenChat(false)} />
      {children}
    </ViewContext.Provider>
  );
}

export default ViewContext;

ViewProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
