export const initialState = {
  clientMeetings: [],
  clientFeelings: '',
  notes: '',
  waterChallenge: '',
  fryFreeChallenge: '',
  realFoodChallenge: '',
  sugarFreeChallenge: '',
  appMeals: '',
  difficulties: '',
  chewingSpeed: '',
  foodTypes: '',
  heartburn: false,
  gastritis: false,
  nausea: false,
  vomiting: false,
  evacuationFrequency: '',
  intestinalFunction: '',
  editable: -1,
  openModal: false,
  modalData: {},
  tokenExpired: false,
  openFirst: -1,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return {
        ...state,
        clientMeetings: action.payload,
      };
    case 'RESET_STATE':
      return {
        ...state,
        notes: initialState.notes,
        clientFeelings: initialState.clientFeelings,
        waterChallenge: initialState.waterChallenge,
        fryFreeChallenge: initialState.fryFreeChallenge,
        realFoodChallenge: initialState.realFoodChallenge,
        sugarFreeChallenge: initialState.sugarFreeChallenge,
        appMeals: initialState.appMeals,
        difficulties: initialState.difficulties,
        chewingSpeed: initialState.chewingSpeed,
        foodTypes: initialState.foodTypes,
        heartburn: initialState.heartburn,
        gastritis: initialState.gastritis,
        nausea: initialState.nausea,
        vomiting: initialState.vomiting,
        evacuationFrequency: initialState.evacuationFrequency,
        intestinalFunction: initialState.intestinalFunction,
      };
    case 'SET_EDITABLE_DATA':
      if (action.payload) {
        return {
          ...state,
          notes: state.clientMeetings[action.payload].notes,
          clientFeelings: state.clientMeetings[action.payload].clientFeelings,
          waterChallenge: state.clientMeetings[action.payload].waterChallenge,
          fryFreeChallenge: state.clientMeetings[action.payload].fryFreeChallenge,
          realFoodChallenge: state.clientMeetings[action.payload].realFoodChallenge,
          sugarFreeChallenge: state.clientMeetings[action.payload].sugarFreeChallenge,
          appMeals: state.clientMeetings[action.payload].appMeals,
          difficulties: state.clientMeetings[action.payload].difficulties,
          chewingSpeed: state.clientMeetings[action.payload].chewingSpeed,
          foodTypes: state.clientMeetings[action.payload].foodTypes,
          heartburn: state.clientMeetings[action.payload].heartburn,
          gastritis: state.clientMeetings[action.payload].gastritis,
          nausea: state.clientMeetings[action.payload].nausea,
          vomiting: state.clientMeetings[action.payload].vomiting,
          evacuationFrequency: state.clientMeetings[action.payload].evacuationFrequency,
          intestinalFunction: state.clientMeetings[action.payload].intestinalFunction,
        };
      }
      return {
        ...state,
        notes: state.clientMeetings[action.payload].notes,
        clientFeelings: state.clientMeetings[action.payload].clientFeelings,
      };
    case 'CHANGE_WATER_CHALLENGE':
      return {
        ...state,
        waterChallenge: action.payload,
      };
    case 'CHANGE_FRY_CHALLENGE':
      return {
        ...state,
        fryFreeChallenge: action.payload,
      };
    case 'CHANGE_FOOD_CHALLENGE':
      return {
        ...state,
        realFoodChallenge: action.payload,
      };
    case 'CHANGE_SUGAR_CHALLENGE':
      return {
        ...state,
        sugarFreeChallenge: action.payload,
      };
    case 'SET_CHEWING_SPEED':
      return {
        ...state,
        chewingSpeed: action.payload,
      };
    case 'TOGGLE_HEARTBUN':
      return {
        ...state,
        heartburn: action.payload,
      };
    case 'SET_FOOD_TYPES':
      return {
        ...state,
        foodTypes: action.payload,
      };
    case 'TOGGLE_GASTRITIS':
      return {
        ...state,
        gastritis: action.payload,
      };
    case 'TOGGLE_NAUSEA':
      return {
        ...state,
        nausea: action.payload,
      };
    case 'TOGGLE_VOMITING':
      return {
        ...state,
        vomiting: action.payload,
      };
    case 'SET_EVACUATION_FREQUENCY':
      return {
        ...state,
        evacuationFrequency: action.payload,
      };
    case 'CHANGE_INTESTINAL_FUNCTION':
      return {
        ...state,
        intestinalFunction: action.payload,
      };
    case 'CHANGE_APP_MEALS':
      return {
        ...state,
        appMeals: action.payload,
      };
    case 'MANAGE_MODAL':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_EDITABLE':
      return {
        ...state,
        editable: action.payload,
      };
    case 'SET_CLIENT_FEELINGS':
      return {
        ...state,
        clientFeelings: action.payload,
      };
    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload,
      };
    case 'SET_DIFFICULTIES':
      return {
        ...state,
        difficulties: action.payload,
      };
    case 'UPDATE_MEETING':
      return {
        ...state,
        clientMeetings: action.payload.clientMeetings,
        editable: -1,
      };
    case 'TOKEN_EXPIRED':
      return {
        ...state,
        tokenExpired: action.payload,
      };
    case 'EXPAND_PANNEL':
      return {
        ...state,
        openFirst: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
