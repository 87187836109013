import Slider from '@material-ui/core/Slider';
import React from 'react';
import PropTypes from 'prop-types';
import '../../App.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const HauxSlider = ({
  basicColor, value, handleChange, initialValue, finalValue, disabled, invertedSlider,
}) => {
  const classes = useStyles();

  const PrettoSlider = withStyles({
    root: {
      color: basicColor,
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

  return (
    <div className={classes.root}>
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={2}
        min={invertedSlider ? finalValue : initialValue}
        max={invertedSlider ? initialValue : finalValue}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default HauxSlider;


HauxSlider.propTypes = {
  basicColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  initialValue: PropTypes.number,
  finalValue: PropTypes.number,
  disabled: PropTypes.bool,
  invertedSlider: PropTypes.bool,
};

HauxSlider.defaultProps = {
  basicColor: '#685DA8',
  value: '',
  handleChange: () => {},
  initialValue: 0,
  finalValue: 10,
  disabled: false,
  invertedSlider: false,
};
