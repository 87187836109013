/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheck = withStyles({
  root: {
    color: '#737373',
    '&$checked': {
      color: '#F57A3D',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function FormikCheck({
  option, onChange, value, ...props
}) {
  return (
    <div>
      <FormControlLabel
        value={value}
        control={(
          <CustomCheck onChange={onChange} />
    )}
        label=""
        {...props}
      />
      <span className="paragraph">{option}</span>
    </div>
  );
}

export default FormikCheck;
