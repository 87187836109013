import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';
import { ReactComponent as AcEdit } from '../../../assets/svgs/ac-edit.svg';
import { ReactComponent as AcDelete } from '../../../assets/svgs/ac-delete.svg';
import { ReactComponent as AcAddCircle } from '../../../assets/svgs/ac-add-circle.svg';
import strings from '../../../assets/strings.json';
import HauxConfirmDialog from '../../../components/HauxConfirmDialog';
import HauxTableSortLabel from '../../../components/HauxTableSortLabel';
import HauxDropdownButton from '../../../components/HauxDropdownButton';
import HauxCheckbox from '../../../components/HauxCheckbox';
import HAUXButton from '../../../components/HauxButton';
import ContentService from '../../../service/ContentService';
import ViewContext from '../../../ViewContext';
import AnalyticsManager from '../../../AnalyticsManager';
import '../content.scss';

const {
  DATE_FORMATS, PUBLISHED, DRAFT,
  CONTENT_TYPES, DELETE_CONTENT_DIALOG,
} = strings;

const FLEX_START = 'flex-start';
const NEW_CONTENT_ROUTE = '/newContent';

const COLUMNS = [
  { name: 'type', description: 'Tipo', sorted: false },
  { name: 'title', description: 'Título', sorted: true },
  { name: 'createdAt', description: 'Data de criação', sorted: true },
  { name: 'status', description: 'Status', sorted: false },
  { name: 'actions', description: 'Ações', sorted: false },
];

const FILTERS = [
  DRAFT, PUBLISHED, CONTENT_TYPES.ARTICLE,
  CONTENT_TYPES.CHALLENGE, CONTENT_TYPES.TIP,
];

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: 'column',
    padding: '0px 32px',
  },
  paperContainer: {
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 32,
    paddingBottom: 16,
    position: 'relative',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  filterOption: {
    marginLeft: 16,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  headerItems: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: FLEX_START,
    paddingLeft: '8px',
  },
  orderButton: {
    padding: 8,
    minWidth: 0,
    textTransform: 'none',
  },
  itemRow: {
    marginBottom: 16,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 48,
  },
  bottomBorder: {
    borderBottomColor: '#e0e0e0',
  },
}));

const Content = () => {
  const classes = useStyles();
  const history = useHistory();
  const { handleRequestsErrors, setLoading } = useContext(ViewContext);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [contents, setContents] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState({ contentId: 0 });
  const [filters, setFilters] = useState(FILTERS);
  const [sort, setSort] = useState({
    column: 'createdAt',
    titleDirection: 'asc',
    subjectDirection: 'asc',
    createdAtDirection: 'desc',
  });

  const initialSort = (a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    setLoading(true);
    ContentService.getPaginatedContent(currentPage, FILTERS.join(','))
      .then((data) => {
        setContents(data.contents.sort(initialSort));
        setTotalPages(data.totalPages);
      })
      .catch(handleRequestsErrors)
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage, handleRequestsErrors, setLoading]);

  const selectDirection = (columnName) => {
    switch (columnName) {
      case 'title':
        return sort.titleDirection;
      case 'subject':
        return sort.subjectDirection;
      default:
        return sort.createdAtDirection;
    }
  };

  const sortContentsByTitle = (direction) => contents.sort((a, b) => {
    if (a.title > b.title) {
      if (direction === 'asc') {
        return 1;
      }
      return -1;
    }
    if (a.title < b.title) {
      if (direction === 'asc') {
        return -1;
      }
      return 1;
    }
    return 0;
  });

  const sortContentByCreationDate = (direction) => contents.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      if (direction === 'asc') {
        return 1;
      }
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      if (direction === 'asc') {
        return -1;
      }
      return 1;
    }
    return 0;
  });

  const sortContent = (columnName, direction) => {
    let sortedContents = [];

    if (columnName === 'title') {
      sortedContents = sortContentsByTitle(direction);
    }

    if (columnName === 'createdAt') {
      sortedContents = sortContentByCreationDate(direction);
    }
    return sortedContents;
  };

  const renderHeaderSortedColumns = (column, index) => (
    <Grid item xs={index === 1 ? 5 : 2} className={classes.itemContainer} key={column.name}>
      <HauxTableSortLabel
        label={column.description}
        active={sort.column === column.name}
        direction={selectDirection(column.name)}
        onClick={() => {
          const newSort = { column: column.name };
          let newDirection = 'desc';
          if (column.name === 'title') {
            newDirection = sort.titleDirection === 'asc' ? 'desc' : 'asc';
            newSort.titleDirection = newDirection;
          } else if (column.name === 'createdAt') {
            newDirection = sort.createdAtDirection === 'asc' ? 'desc' : 'asc';
            newSort.createdAtDirection = newDirection;
          }
          setSort({
            ...sort,
            ...newSort,
          });
          const sortedContent = sortContent(column.name, newDirection);
          setContents(sortedContent);
        }}
      />
    </Grid>
  );

  const renderHeaderStaticColumns = (column) => (
    <Grid item xs={column.name === 'actions' ? 1 : 2} className={classes.itemContainer} key={column.name}>
      <span className="h6NormalStyle">{column.description}</span>
    </Grid>
  );

  const renderHeader = () => (
    <Grid item className={classes.headerContainer}>
      {COLUMNS.map((column, index) => {
        if (column.sorted) {
          return renderHeaderSortedColumns(column, index);
        }

        return renderHeaderStaticColumns(column);
      })}
    </Grid>
  );

  const renderTitle = (title, content) => (
    <Grid
      item
      key={title}
      xs={5}
      className={classes.itemContainer}
    >
      <button
        type="button"
        className="titleButton"
        onClick={() => history.push(NEW_CONTENT_ROUTE, { content, contentType: content.type })}
      >
        <span className="h6NormalPurpleStyle">{title}</span>
      </button>
    </Grid>
  );

  const renderCreatedAtText = (data) => (
    <Grid
      item
      key={data}
      xs={2}
      className={classes.itemContainer}
    >
      <span className="h6NormalStyle">{data}</span>
    </Grid>
  );

  const renderSpans = (data) => (
    <Grid
      item
      xs={2}
      className={classes.itemContainer}
    >
      <span key={data} className={`contentSpan ${data}`}>{data}</span>
    </Grid>
  );

  const renderList = () => {
    if (!contents.length) {
      return (
        <Grid container item justify="center">
          <span className="paragraph">Não foi possível encontrar nenhum resultado com esses filtros.</span>
        </Grid>
      );
    }

    return contents.map((content) => (
      <Grid container key={content._id} item direction="column" className={classes.itemRow}>
        <Grid item className={classes.headerItems}>
          {renderSpans(content.type)}
          {renderTitle(content.title, content)}
          {renderCreatedAtText(moment(content.createdAt).format(DATE_FORMATS.NORMAL))}
          <Grid item xs={2} className={classes.itemContainer}>
            {content.published
              ? (
                <span className="h6NormalStyle">{PUBLISHED}</span>
              )
              : (
                <span className="h6NormalStyle">{DRAFT}</span>
              )}
          </Grid>
          <Grid item xs={1} className={classes.itemContainer}>
            <AcEdit
              className="actionButton"
              onClick={() => {
                history.push(NEW_CONTENT_ROUTE, { content, contentType: content.type });
              }}
            />
            <AcDelete
              className="actionButton"
              onClick={() => setShowConfirmDialog({ contentId: content._id })}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box borderBottom="1px solid #e0e0e0" />
        </Grid>

        <HauxConfirmDialog
          isDestructive
          handleClose={() => setShowConfirmDialog({ contentId: 0 })}
          handleConfirm={async () => {
            try {
              await ContentService.deleteContent(content._id);
              setContents(
                contents.filter((contentFilter) => contentFilter._id !== content._id),
              );
            } catch (error) {
              handleRequestsErrors(error);
            }
          }}
          open={showConfirmDialog.contentId === content._id}
          title={DELETE_CONTENT_DIALOG.TITLE}
          message={DELETE_CONTENT_DIALOG.MESSAGE}
        />
      </Grid>
    ));
  };

  const handleCheckboxChange = (option) => {
    let newFilters;
    if (filters.includes(option)) {
      newFilters = filters.filter((filter) => filter !== option);
      setFilters(newFilters);
    } else {
      newFilters = [...filters, option];
      setFilters(newFilters);
    }
  };

  const filterContent = () => {
    AnalyticsManager.track('Content button_filter clicked', filters);
    ContentService.getPaginatedContent(0, filters)
      .then((newContent) => {
        setContents(newContent.contents.sort(initialSort));
        setSort({
          column: 'createdAt',
          createdAtDirection: 'desc',
        });
        setTotalPages(newContent.totalPages);
      });
  };

  const renderFilters = () => (
    <Grid container item xs className={classes.filterContainer}>
      <Grid item>
        <span className="paragraph">Filtrar:</span>
      </Grid>
      {Object.keys(CONTENT_TYPES).map((key) => (
        <Grid item key={key} className={classes.filterOption}>
          <HauxCheckbox
            name={CONTENT_TYPES[key]}
            value={CONTENT_TYPES[key]}
            checked={filters.includes(CONTENT_TYPES[key])}
            handleChange={() => {
              handleCheckboxChange(CONTENT_TYPES[key]);
            }}
            checkedColor="#685da8"
            size="small"
          />
        </Grid>
      ))}
      <Grid item>
        <span className="paragraph statusLabel">Status:</span>
      </Grid>
      <Grid item className={classes.filterOption}>
        <HauxCheckbox
          name={PUBLISHED}
          value={PUBLISHED}
          checked={filters.includes(PUBLISHED)}
          handleChange={() => handleCheckboxChange(PUBLISHED)}
          checkedColor="#685da8"
          size="small"
        />
      </Grid>
      <Grid item className={classes.filterOption}>
        <HauxCheckbox
          name={DRAFT}
          value={DRAFT}
          checked={filters.includes(DRAFT)}
          handleChange={() => handleCheckboxChange(DRAFT)}
          checkedColor="#685da8"
          size="small"
        />
      </Grid>
      <Grid item className={classes.filterButton}>
        <HAUXButton
          text="Filtrar"
          onClick={filterContent}
          type="secondary"
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.titleContainer}>
          <Grid item xs={8}>
            <span className="title">Conteúdo HAUX</span>
            <br clear="all" />
            <span className="hintTitle">Conteúdos disparados periodicamente conforme o andamento do programa para cada cliente</span>
          </Grid>
          <HauxDropdownButton
            label="NOVO"
            options={Object.keys(CONTENT_TYPES).map((key) => CONTENT_TYPES[key])}
            Icon={AcAddCircle}
            handleChange={(option) => {
              AnalyticsManager.track('Content button_new clicked', { contentType: option });
              history.push(NEW_CONTENT_ROUTE, { contentType: option });
            }}
            className="CTAPrimaryButton"
          />
        </Grid>
        {renderFilters()}
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={2} className={classes.paperContainer}>
              {renderHeader()}
              {renderList()}

              <Grid item>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel=""
                  breakClassName="breakme"
                  pageCount={totalPages}
                  marginPagesDisplayed={totalPages}
                  pageRangeDisplayed={totalPages}
                  onPageChange={(item) => {
                    setCurrentPage(item.selected);
                  }}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Content;
